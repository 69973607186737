class GamesService {
  constructor () {
    if (GamesService.instance) {
      return GamesService.instance
    }

    this.ratings = {
      V: {
        V: {
          min: 0,
          max: 1599
        },
        'V+': {
          min: 1600,
          max: 1799
        },
        'V++': {
          min: 1800,
          max: 1999
        }
      },
      VV: {
        VV: {
          min: 2000,
          max: 2599
        },
        'VV+': {
          min: 2600,
          max: 2799
        },
        'VV++': {
          min: 2800,
          max: 2999
        }
      },
      VVV: {
        VVV: {
          min: 3000,
          max: 3599
        },
        'VVV+': {
          min: 3600,
          max: 3799
        },
        'VVV++': {
          min: 3800,
          max: 3999
        }
      },
      VVVV: {
        VVVV: {
          min: 4000,
          max: 4599
        },
        'VVVV+': {
          min: 4600,
          max: 4799
        },
        'VVVV++': {
          min: 4800,
          max: 4999
        }
      },
      VVVVV: {
        VVVVV: {
          min: 5000,
          max: 5599
        },
        'VVVVV+': {
          min: 5600,
          max: 5799
        },
        'VVVVV++': {
          min: 5800,
          max: 10000
        }
      }
    }

    this.gamesAbout = {
      'pubg-mobile': {
        ru: {
          title: 'Pubg Mobile',
          text: 'Эпическая "Королевская битва" Множество доступных событий. Покорите Вершины рейтинга PUBG MOBILE,поливая противников огнем. PUBG MOBILE - Первая мобильная игра в жанре "Королевская битва" и один из лучших шутеров для мобильных устройств'
        },
        en: {
          title: 'Pubg Mobile',
          text: 'Climb the PUBG MOBILE leaderboard by pouring fire on your opponents. PUBG MOBILE is the first mobile battle royale game and one of the best mobile shooters.'
        },
        links: {
          android: 'https://play.google.com/store/apps/details?id=com.tencent.ig',
          ios: 'https://apps.apple.com/app/pubg-mobile/id1330123889'
        },
        content: {
          youtube: 'https://youtu.be/QelGTiVr-wM'
        }
      },
      'free-fire': {
        ru: {
          title: 'Free Fire',
          text: 'Королевская битва - ищи оружие, оставайся в безопасной зоне, лутай своих оппонентов. Победит тот, кто останется последним. Избегай авиаударов, следи за авиапосылками, там всегда легендарный лут'
        },
        en: {
          title: 'Free Fire',
          text: 'Join the world famous Free Fire Battle Royale! Climb to the top of the rankings. Each 10 minute session will take you to a lonely island, 49 players against you, and only one chance to win. Choose the starting point for landing and watch the safe zone. Use transport, cable cars, trenches, hide in the forest or blend in with the terrain by lying in the grass. Set up ambushes, aim, eliminate. The goal is the same: to be last.'
        },
        links: {
          android: 'https://play.google.com/store/apps/details?id=com.dts.freefireth',
          ios: 'https://apps.apple.com/app/garena-free-fire-%D0%B4%D1%80-5/id1300146617'
        },
        content: {
          youtube: 'https://youtu.be/RFNEtBJMexs'
        }
      },
      'pubg-new-state': {
        ru: {
          title: 'Pubg New State',
          text: 'Королевская битва - 7 раундов ожесточенного выживания в круговом бое насмерть, серия сражений 4 на 4 где надо победить минимум в 4 боях из 7, игровая зона начинает уменьшаться в момент начала игры, уничтожьте соперников и одержите победу, став последним выжившим '
        },
        en: {
          title: 'Pubg New State',
          text: 'Introducing NEW STATE MOBILE, the new battle royale game developed by PUBG STUDIOS, the company behind PLAYERUNKNOWN\'S BATTLEGROUNDS (PUBG). Embark on a vast battlefield, seek out strategically placed weapons, vehicles, and consumables, and fight for your life.'
        },
        links: {
          android: 'https://play.google.com/store/apps/details?id=com.pubg.newstate',
          ios: 'https://apps.apple.com/app/new-state-mobile/id1542727626'
        },
        content: {
          youtube: 'https://youtu.be/XTj778JyaTg'
        }
      },
      standoff: {
        ru: {
          title: 'Standoff 2',
          text: 'Динамичный экшн-шутер от первого лица. Вас ждут новые карты, новые виды оружия , новые режимы, в которых команды террористов и отряды сил спецназначения сойдутся в битве не на жизнь, а насмерть.'
        },
        en: {
          title: 'Standoff 2',
          text: 'Standoff 2 is a fast-paced, action-packed first-person shooter. Behold for new maps, new types of weapons, new modes in which teams of terrorists and special forces units. appointments will converge in a battle not for life, but to death.'
        },
        links: {
          android: 'https://play.google.com/store/apps/details?id=com.axlebolt.standoff2',
          ios: 'https://apps.apple.com/app/standoff-2/id1359706682'
        },
        content: {
          youtube: 'https://youtu.be/6HcmfaZEN90'
        }
      },
      lichess: {
        ru: {
          title: 'Lichess',
          text: 'Настольная игра для двух противников, изображающая сражение двух армий. Миллионы людей играют в шахматы как для развлечения, так и участвуя в серьезных соревнованиях'
        },
        en: {
          title: 'Lichess',
          text: 'Chess is a turn-based strategy with open information, where the influence of luck on the outcome of the fight is minimized. The social significance of chess lies in the fact that it is one of the best and most exciting leisure activities ever invented by mankind.'
        },
        links: {
          android: 'https://play.google.com/store/apps/details?id=org.lichess.mobileapp',
          ios: 'https://apps.apple.com/app/lichess-online-chess/id968371784'
        },
        content: {
          youtube: 'https://www.youtube.com/watch?v=Ib8XaRKCAfo'
        }
      },
      'pubg-desktop': {
        ru: {
          title: 'Pubg PC',
          text: 'Многопользовательская онлайн игра в жанре королевской битвы. Многочисленные карты и режимы на которых вам предстоит принять участие в захватывающей борьбе за выживание. Собирайте друзей и исследуйте новые режимы вместе с нами.'
        },
        en: {
          title: 'Pubg PC',
          text: 'Multiplayer online game in the royal scene genre. Numerous maps and modes that you have to take part in struggling to survive. Gather your friends and implement new modes with us.'
        },
        links: {
          steam: 'https://store.steampowered.com/app/578080/PUBG_BATTLEGROUNDS/'
        },
        content: {
          youtube: 'https://www.youtube.com/watch?v=u1oqfdh4xBY'
        }
      },
      'brawl-stars': {
        ru: {
          title: 'Brawl Stars',
          text: 'Игра для мобильных устройств в жанре MOBA. Выбери бойца и участвуй в различных событиях, там вы столкнетесь с другими бойцами, пытаясь выполнить специальную задачу, уникальную для каждого типа событий.'
        },
        en: {
          title: 'Brawl Stars',
          text: 'Time to BRAWL! Team up with your friends and get ready for epic multiplayer MAYHEM! Brawl Stars is the newest game from the makers of Clash of Clans and Clash Royale. Jump into your favorite game mode and play quick matches with your friends. Shoot \'em up, blow \'em up, punch \'em out and win the BRAWL.'
        },
        links: {
          android: 'https://play.google.com/store/apps/details?id=com.supercell.brawlstars',
          ios: 'https://apps.apple.com/app/brawl-stars/id1229016807'
        },
        content: {
          youtube: 'https://youtu.be/n8OZ7rVRsWk'
        }
      },
      'clash-royale': {
        ru: {
          title: 'Clash Royale',
          text: 'Стратегическая игра в реальном времени. Игра сочитает в себе элементы коллекционных карточных игр и многопользовательской онлайн-боевой арены.'
        },
        en: {
          title: 'Clash Royale',
          text: 'Welcome to the arena! Build a battle deck and fight in dynamic real-time battles. Real-time multiplayer combat game from the creators of CLASH OF CLANS with your favorite characters and more! Fight against players from all over the world!'
        },
        links: {
          android: 'https://play.google.com/store/apps/details?id=com.supercell.clashroyale',
          ios: 'https://apps.apple.com/app/clash-royale/id1053012308'
        },
        content: {
          youtube: 'https://youtu.be/ObjWo7aP2VM'
        }
      },
      dota2: {
        ru: {
          title: 'Dota2',
          text: 'Командная тактико - стратегическая игра с элементами компьютерной ролевой игры , в которой каждый игрок управляет одним героем. Основная задача игры для каждой из двух команд - уничтожить вражеский лагерь, защищенный башнями'
        },
        en: {
          title: 'Dota2',
          text: 'A team tactical and strategic game with elements of a computer role-playing game in which each player controls one hero. The main task of the game for each of the two teams is to destroy the enemy camp, protected by towers'
        },
        links: {
          steam: 'https://store.steampowered.com/app/570/Dota_2/'
        },
        content: {
          youtube: 'https://youtu.be/vlZB2AokQyw'
        }
      },
      'mobile-legends': {
        ru: {
          title: 'Mobile Legends',
          text: 'Присоединяйтесь к своим друзьям в захватывающем MOBA - противостоянии реальным оппонентам 5 на 5 - Mobile Legends: Bang Bang! Выберите своих любимых героев и создайте идеальную команду с верными союзниками! 10 секунд на подбор, 10 минут на сражение. Игра на линиях, в лесу, разрушение башен и командные бои - все веселье онлайн -игр на ПК теперь в ваших руках! Дайте волю своему киберспортивному духу!'
        },
        en: {
          title: 'Mobile Legends',
          text: 'Join your friends in Mobile Legends: Bang Bang, the brand new 5v5 MOBA showdown, and fight against real players! Choose your favorite herois and build the perfect team with your comrades - in - arms! 10 - second matcmaking, 10 - minute battles. Laning, jungling, pushing, and teamfighting, all the fun of PC MOBA and action games in the palm of your hand! Feed your eSports spirit!'
        },
        links: {
          android: 'https://play.google.com/store/apps/details?id=com.mobile.legends',
          ios: 'https://apps.apple.com/ru/app/mobile-legends-bang-bang/id1160056295?l=en'
        },
        content: {
          youtube: 'https://youtu.be/vlZB2AokQyw'
        }
      },
      valorant: {
        ru: {
          title: 'VALORANT',
          text: 'Riot Games представляет VALORANT - тактический шутер от первого лица с матчами 5 на 5, в которых точная стрельба сочетается с уникальными умениями агентов. Узнайте больше о проекте VALORANT и его потрясающих героях!'
        },
        en: {
          title: 'VALORANT',
          text: 'Riot Games presents VALORANT, a tactical first-person shooter with 5-on-5 matches in which accurate shooting is combined with the unique skills of agents. Learn more about the VALORANT project and its amazing heroes!'
        },
        links: {
          direct: 'https://playvalorant.com/'
        },
        content: {
          youtube: 'https://youtu.be/wlNmShwGaJY'
        }
      }
    }

    GamesService.instance = this

    return this
  }

  getGameAbout (gameName) {
    return this.gamesAbout[gameName]
  }

  getSimpleRatingGroups () {
    return Object.keys(this.ratings).map(key => Object.keys(this.ratings[key]).map(subkey => ({
      title: subkey,
      min: this.ratings[key][subkey].min,
      max: this.ratings[key][subkey].max
    }))).reduce((a, b) => [...a, ...b], [])
  }

  getRatingGroup (rating) {
    return Object.keys(this.ratings).map(key => Object.keys(this.ratings[key]).map(subkey => ({
      title: subkey,
      min: this.ratings[key][subkey].min,
      max: this.ratings[key][subkey].max
    }))).reduce((a, b) => [...a, ...b], [])
      .find(item => item.min <= rating && item.max >= rating)?.title
  }

  getRatingGroupObject (rating) {
    return Object.keys(this.ratings).map(key => Object.keys(this.ratings[key]).map(subkey => ({
      title: subkey,
      min: this.ratings[key][subkey].min,
      max: this.ratings[key][subkey].max
    }))).reduce((a, b) => [...a, ...b], [])
      .find(item => item.min <= rating && item.max >= rating)
  }

  getNextRatingGroupObject (rating) {
    return Object.keys(this.ratings).map(key => Object.keys(this.ratings[key]).map(subkey => ({
      title: subkey,
      min: this.ratings[key][subkey].min,
      max: this.ratings[key][subkey].max
    }))).reduce((a, b) => [...a, ...b], [])
      .find(item => item.min > rating)
  }
}

export default new GamesService()
