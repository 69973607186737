export const state = () => ({
  referrals: null,
  referralsCounts: null
})

export const actions = {
  async fetchReferrals ({ commit }) {
    try {
      const [refs, counts] = await Promise.all([
        await this.$axios.get('/referrals'),
        await this.$axios.get('/referrals/counts')
      ])

      if (refs?.data?.data) {
        commit('setReferralsData', refs.data.data)
      }

      if (counts?.data?.data) {
        commit('setCountsData', counts.data.data)
      }
    } catch (e) {
      console.error(
        `${new Date().toUTCString()} :: get referrals data error ::`,
        e
      )
    }
  }
}

export const mutations = {
  setReferralsData (state, payload) {
    state.referrals =
      payload.sort(
        (a, b) => new Date(b.user.created_at) - new Date(a.user.created_at)
      ) || []
  },

  setCountsData (state, payload) {
    state.referralsCounts = payload
  }
}
