<template lang="pug">
  .modal-user-login
    .modal__header {{ $t('loggingAsAnotherUser') }}

    .modal__body.modal-user-login__body
      NumberInput(:placeholder="$t('wantedId')" v-model="id" :title="$t('usersId')")

      transition(name="fade" duration="200")
        InfoMessage(v-if="error.length" type="error") {{ error }}

    .modal__footer
      Button(is-primary :is-disabled="!!error.length" @click="onAuth") {{ $t('signInAction') }}

</template>

<script>
import NumberInput from '~/components/NumberInput/NumberInput'
import InfoMessage from '~/components/InfoMessage/InfoMessage'
import UsersService from '~/services/UsersService'

export default {
  name: 'ModalUserLogin',

  components: { NumberInput, InfoMessage },

  data () {
    return {
      id: null,
      error: ''
    }
  },

  methods: {
    async onAuth () {
      this.$nuxt.$loading.start()

      try {
        const newToken = await UsersService.getTokenAsSuperAdmin(
          this.$axios,
          this.id
        )
        const currentToken = this.$cookiz.get('auth_token')
        const oldToken = this.$cookiz.get('old_token')

        this.$cookiz.set('auth_token', newToken)
        if (!oldToken) {
          this.$cookiz.set('old_token', currentToken)
        }

        this.$nuxt.$loading.finish()
        window.location.reload()
      } catch (e) {
        this.$errors.handle(e)
        console.error(
          `${new Date().toUTCString()} :: logging as another user error ::`,
          e
        )
        this.$nuxt.$loading.finish()
      }
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modaluserlogin {
  max-width: 480px;
  padding-bottom: 0;
}

.modal-user-login {
  &__body {
    margin: 24px 0;
  }

  .info-message {
    margin-top: 12px;
  }

  .number-input {
    flex-direction: column;

    &__label {
      width: 100%;
      margin-top: 8px;
    }
  }
}
</style>
