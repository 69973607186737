export const actions = {
  setNotifiedDuel ({ getters }, { id, state }) {
    const duels = getters.notifiedDuels

    if (!duels[state] || !Array.isArray(duels[state])) {
      duels[state] = [id]
    } else {
      duels[state].push(id)
    }

    localStorage.setItem('notifiedDuels', JSON.stringify(duels))
  }
}

export const getters = {
  notifiedDuels () {
    try {
      return JSON.parse(localStorage.getItem('notifiedDuels')) || {}
    } catch (e) {
      console.error(`${new Date().toUTCString()} :: notified duels error ::`, e)
      return {}
    }
  },

  setNotifiedDuels () {}
}
