import StoreService from '~/services/StoreService'
import PendingRewardsService from '~/services/PendingRewardsService'
import { addDaysToDate, getDaysBetweenDates } from '~/utils/utils'

export const state = () => ({
  token: null,
  user: null,
  tickets: null,
  subscriptions: null,
  products: null,
  orders: null,
  sales: null,
  verification: null,
  isPhoneVerified: false,
  freerollCount: 1,
  onlineCount: 0,
  isBalanceHide: false,
  isContactHide: false,
  showOfferwallPath: false,
  canBeWithdrawn: 0,
  withdrawalLimit: 0,
  gBalance: 0,
  is24Finished: false,
  reason: undefined
})

export const getters = {
  isAuthorized (state) {
    return !!state.token && !!state.user?.id
  },

  isAnticheatEnabled ({ user }) {
    return user.is_anticheat_enabled
  },

  isSuperAdmin (state) {
    return ['ceo', 'admin'].includes(state.user?.role)
  },

  isAdmin (state) {
    return [
      'ceo',
      'admin',
      'moderator',
      'moderator-pubg-mobile',
      'moderator-lichess',
      'moderator-brawl-stars',
      'moderator-pubg-pc',
      'moderator-clash-royale',
      'moderator-free-fire',
      'moderator-pubg-new-state',
      'moderator-standoff',
      'moderator-call-of-duty-pc',
      'moderator-call-of-duty-mob',
      'moderator-among-us',
      'senior-moderator-clash-royale',
      'senior-moderator-brawl-stars',
      'senior-moderator-pubg-mobile',
      'senior-moderator-pubg-pc',
      'senior-moderator-lichess',
      'senior-moderator-free-fire',
      'senior-moderator-pubg-new-state',
      'senior-moderator-standoff',
      'moderator-fortnite',
      'support'
    ].includes(state.user?.role)
  },

  gameAccount ({ user }) {
    return (gameName) => {
      if (!user) {
        return null
      }

      return user.game_accounts?.find(acc => acc.game === gameName)
    }
  },

  subscriptions (state) {
    return state.subscriptions
  },

  currency () {
    return 'G-Cash'
  },

  userCurrency (state) {
    return state.user.currency || 'EUR'
  },

  canBeWithdrawnAmount (state) {
    return (state.canBeWithdrawn / 100).toFixed(2).replace('.00', '')
  },

  withdrawalLimitAmount (state) {
    return (state.withdrawalLimit / 100).toFixed(2).replace('.00', '')
  },

  minWithdrawalLimit (state) {
    let result = state.withdrawalLimit
    if (state.canBeWithdrawn < state.withdrawalLimit) {
      result = state.canBeWithdrawn
    }
    return (result / 100).toFixed(2).replace('.00', '')
  }
}

export const actions = {
  // eslint-disable-next-line camelcase
  async login ({ commit, dispatch }, { login, password, session_id }) {
    const cid = this.$cookiz.get('_ga')?.replace(/GA.+?\..+?\./, '')

    const { data } = await this.$axios.post('user/account/login', {
      login,
      password,
      cid,
      session_id
    })

    if (data.data.token) {
      commit('setToken', data.data.token)
    }

    return data.data
  },

  // eslint-disable-next-line camelcase
  async register ({ commit, dispatch }, { email, username, login, password, promocode, session_id }) {
    const utmSource = this.$cookiz.get('utm_source')
    const utmMedium = this.$cookiz.get('utm_medium')
    const kochavaDeviceId = this.$cookiz.get('kochava_device_id', { parseJSON: false })

    const externalId = window.localStorage.getItem('keitaroId')
    const marketingSub1 = window.localStorage.getItem('marketingSub1')
    const externalHost = location.hostname

    const fbPixel = window.localStorage.getItem('pixel')
    const fbClid = window.localStorage.getItem('fbclid')

    const adgateClickId = window.localStorage.getItem('adgateclid')
    const language = window.location.hostname.startsWith('ru') ? 'ru' : 'en'
    const params = { utmSource, utmMedium }

    if (fbPixel && fbClid) {
      params.pixel = fbPixel
      params.fb_click_id = fbClid
    }

    if (externalId) {
      params.external_id = externalId
      params.external_host = externalHost
    }

    if (marketingSub1) {
      params.sub1 = marketingSub1
    }

    if (kochavaDeviceId) {
      params.kochava_device_id = kochavaDeviceId
    }

    if (adgateClickId) {
      params.adgate_click_id = adgateClickId
    }

    const cid = this.$cookiz.get('_ga')?.replace(/GA.+?\..+?\./, '')

    const { data } = await this.$axios.post(
      `user/account/register?cid=${cid}`,
      {
        email,
        username,
        login,
        password,
        cid,
        session_id,
        promocode,
        language,
        ref: this.$cookiz.get('ref') || window.localStorage.getItem('ref')
      },
      {
        params
      }
    )

    if (data?.data?.token) {
      commit('setToken', data.data.token)
    }
    dispatch('sendCid')

    return data.data
  },

  async resetPassword ({ commit }, { email }) {
    const { data } = await this.$axios.post('/user/account/password/reset', {
      email
    })

    return data.data
  },

  async setNewPassword ({ commit }, { code, password }) {
    const { data } = await this.$axios.post('/user/account/password/verify', {
      code,
      password
    })

    return data.data
  },

  logout ({ commit }, { $route }) {
    if ($route.path.includes('profile')) {
      window.location.reload(true)
    }

    commit('setUserData', null)
    commit('setToken', null)
    commit('setVerification', null)
    commit('setProducts', [])
  },

  async fetchVerification ({ commit, state }, { force = false } = {}) {
    if (!state.user) {
      return
    }

    if (state.verification && !force) {
      return
    }

    try {
      const { data } = await this.$axios.get(
        '/user/account/account-verification'
      )

      if (data.data) {
        commit('setVerification', data.data)
      }
    } catch (e) {
      console.error(
        `${new Date().toUTCString()} :: user fetch verification error ::`,
        e
      )
    }
  },

  async fetchUser ({ commit }) {
    try {
      const { data } = await this.$axios.get('/user')

      if (data.data) {
        // await dispatch('fetchFreerollCount')
        commit('setUserData', data.data)

        this.$cookiz.set('userId', data.data.id)
      }
    } catch (e) {
      console.error(
        `${new Date().toUTCString()} :: user fetch error (${
          this.$axios.headers
        }) ::`,
        this.$errors.getText(e),
        e
      )
    }
  },

  sendCid () {
    const cid = this.$cookiz.get('_ga')?.replace(/GA.+?\..+?\./, '')
    if (!cid) {
      return
    }

    try {
      const measurementId = process.env.NODE_ENV === 'development' ? 'G-PGC12ZDCEN' : 'G-CN927GR3HN'
      window?.gtag('get', measurementId, 'session_id', async (sessionId) => {
        try {
          await this.$axios.post('/user/account/cid', {
            cid,
            session_id: sessionId
          })
        } catch (e) {
          console.error(e)
        }
      })
    } catch (e) {
      console.error(`${new Date().toUTCString()} :: send cid error ::`, e)
    }
  },

  async fetchProducts ({ commit }) {
    try {
      const { data } = await this.$axios.get('/user/products')

      commit('setProducts', data.data)
    } catch (e) {
      console.error(`${new Date().toUTCString()} :: fetch products error ::`, e)
    }
  },

  async fetchOrders ({ commit }) {
    try {
      const { data } = await StoreService.getMarketplaceOrders(this.$axios)

      commit('setOrders', data.data?.orders)
    } catch (e) {
      console.error(`${new Date().toUTCString()} :: fetch orders error ::`, e)
    }
  },

  async fetchOrdersAsSeller ({ commit }) {
    try {
      const { data } = await StoreService.getMarketplaceOrdersAsSeller(this.$axios)

      commit('setSales', data.data?.orders)
    } catch (e) {
      console.error(`${new Date().toUTCString()} :: fetch orders error ::`, e)
    }
  },

  async fetchFreerollCount ({ commit, state }) {
    if (!state.token) {
      return
    }

    try {
      const { data } = await this.$axios.get(
        '/games/tournaments/remaining-freerolls'
      )

      commit('setFreerollCount', data.data)
    } catch (e) {
      console.error(
        `${new Date().toUTCString()} :: fetch freeroll status error ::`,
        e
      )
    }
  },

  async fetchCanBeWithdrawn ({ commit, state }) {
    if (!!state.token && !!state.user?.id) {
      try {
        const prResponse = await PendingRewardsService.getCanBeWithdrawal(this.$axios)
        commit('setCanBeWithdrawn', (prResponse)?.data?.data?.sum || 0)
        commit('setWithdrawalLimit', (prResponse)?.data?.data?.limit || 0)
        commit('setGBalance', (prResponse)?.data?.data?.g || 0)
      } catch (e) {
        console.log('canBeWithdrawn error:', e)
      }
    }
  }
}

export const mutations = {
  setToken (state, token) {
    this.$cookiz.set('auth_token', token, {
      maxAge: 60 * 60 * 24 * 365,
      path: '/'
    })
    state.token = token
  },

  setIs24Finished (state, payload) {
    state.is24Finished = payload
  },

  setReason (state, payload) {
    state.reason = payload
  },

  setCanBeWithdrawn (state, payload) {
    state.canBeWithdrawn = payload
  },

  setWithdrawalLimit (state, payload) {
    state.withdrawalLimit = payload
  },

  setGBalance (state, payload) {
    state.gBalance = payload
  },

  setOfferwallPathState (state, payload) {
    state.showOfferwallPath = payload
  },

  toggleUserBalanceVisibility (state, payload) {
    state.isBalanceHide = JSON.parse(payload)
    localStorage.setItem('isBalanceHide', payload)
  },

  toggleUserContactVisibility (state, payload) {
    state.isContactHide = JSON.parse(payload)
    localStorage.setItem('isContactHide', payload)
  },

  setUserData (state, payload) {
    if (payload) {
      if (payload?.balance) {
        payload.balance = payload.balance / 100 || 0
      }

      payload.realBalance = payload.balance
    }

    state.user = payload
  },

  setVerification (state, payload) {
    state.verification = payload
  },

  setOnline (state, online) {
    state.onlineCount = online
  },

  setAnticheatStatus (state, status) {
    state.user.is_anticheat_enabled = status
  },

  setPhoneVerification (state, payload) {
    state.isPhoneVerified = payload
  },

  setBalance (state, payload) {
    state.user.balance = payload
  },

  setCustomStatus (state, payload) {
    state.user.custom_status = payload
  },

  setAvatar (state, payload) {
    state.user.avatar = payload
  },

  setCover (state, payload) {
    state.user.cover = payload
  },

  resetBalance (state) {
    state.user.balance = state.user.realBalance
  },

  setFreerollCount (state, payload) {
    // state.freerollCount = payload
  },

  setOrders (state, orders) {
    state.orders = orders
  },

  setSales (state, orders) {
    state.sales = orders
  },

  setProducts (state, products) {
    state.products = products
    state.tickets = products.filter(({ type, state }) => {
      return type === 'ticket' && state === 'new'
    })

    const subscriptions = products.filter(({ type, state }) => {
      return type.startsWith('subscription_') && (state === 'new' || state === 'pending')
    })

    state.subscriptions = subscriptions.map((sub) => {
      const duration = sub.extra_data?.duration
      const endsAt = addDaysToDate(sub.created_at, duration)
      const daysLeft = getDaysBetweenDates(Date.now(), endsAt)

      return {
        ...sub,
        ends_at: endsAt,
        days_left: daysLeft,
        is_subscribed: true
      }
    })
  }
}
