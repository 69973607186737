<template lang="pug">
  .finish
    img.finish__img(:src="require(`~/assets/img/refresh.png`)" alt="Refresh password icon")
    .finish__container
      h1.finish__title {{ $t('updatePassword') }}
      .finish__text(v-html="$t('passwordProcedure')")

      Button.finish__button(is-primary @click="goToPasswordUpdate") {{ $t('restorePassword') }}
</template>

<script>
export default {
  methods: {
    goToPasswordUpdate () {
      this.$store.dispatch('modal/showModal', {
        component: 'ResetPassword'
      })
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalrefreshpassword {
  min-width: 360px;
  max-width: 508px !important;
  padding: 0 !important;
}

.finish {
  &__img {
    width: 100%;
  }

  &__container {
    margin-top: -36px;
    padding: 0 16px;
    color: $white;

    @media (min-width: 720px) {
      margin-top: -65px;
    }
  }

  &__title {
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
  }

  &__button {
    width: 100% !important;
    margin-bottom: 32px;
  }
}
</style>
