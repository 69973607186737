<template lang="pug">
  .players(v-if="players.length")
    .players__list
      .players__player(v-for="player in list" :key="players.id" v-tippy :content="player.full_name" @click="onPlayerClick(player)" :style="frameColor(player) ? { 'border': `2px solid ${frameColor(player)}` } : {}")
        img(v-if="player.avatar" :src="player.avatar" :alt="player.full_name" v-lazy-load)
      .players__player(v-if="moreCount > 0")
        | +{{ moreCount }}

    slot

</template>

<script>
export default {
  name: 'Players',

  props: {
    players: {
      type: Array,
      required: true
    },

    count: {
      type: Number,
      default: null
    },

    max: {
      type: Number,
      default: 5
    }
  },

  data () {
    return {}
  },

  computed: {
    moreCount () {
      return (this.count || this.players.length) - this.max
    },

    list () {
      return this.players.slice(0, this.max)
    }
  },

  methods: {
    onPlayerClick (player) {
      this.$router.push(this.localeLocation('/user/id/' + player.id))
    },

    frameColor (player) {
      return player.custom_status?.split('ϴ')[3]
    }
  }
}
</script>

<style lang="scss">
.players {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__list {
    display: flex;
    align-items: center;
  }

  &__player {
    width: 44px;
    height: 44px;
    min-width: 44px;
    min-height: 44px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $gradient-primary;
    color: $white;
    overflow: hidden;
    border: 4px solid $grey50;
    margin-left: -10px;
    font-weight: 900;
    font-size: 16px;
    letter-spacing: -0.7px;

    @media (max-width: $breakpointMobile) {
      margin-left: -20px;
    }

    &:first-child {
      margin-left: 0;
    }

    img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      cursor: pointer;
    }
  }
}
</style>
