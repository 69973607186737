// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_RIJ-R{width:100%;max-width:600px;padding-top:10px}.title_Sq0cy{color:#fff;font-size:32px;line-height:120%;font-weight:700;margin-bottom:32px}.field_zlWm1{margin:12px 0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "modal_RIJ-R",
	"title": "title_Sq0cy",
	"field": "field_zlWm1"
};
module.exports = ___CSS_LOADER_EXPORT___;
