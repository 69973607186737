<template lang="pug">
  .notifications-list
    .notifications-list__inner
      .notifications-list__empty(v-if="!notifications.length") {{ $t('notificationsNo') }}
      template(v-else)
        transition-group(name="fade-scale" duration="200")
          .notifications-list__item(
            v-for="item in list"
            v-if="item && item.id"
            :key="item.id"
            :class="{ '--not-read': !item.is_read }"
            @mouseenter="onHover(item)"
          )
            .notifications-list__item-top
              .notifications-list__item-left(v-if="item.icon")
                img(:src="require(`~/assets/img/notifications/${item.icon}.svg`)")
              .notifications-list__item-left(v-else-if="item.imageUrl")
                img(:src="item.imageUrl")

              .notifications-list__item-right
                .notifications-list__item-date {{ getDate(item) }}
                .notifications-list__item-title {{ item.title || item.type }}
                .notifications-list__item-text(v-if="item.text" v-html="item.text")
                .notifications-list__item-text(v-else-if="item.type === 'duel_accepted' && item.duel")
                  | {{ $t('duelNotificationText1') }}&nbsp;
                  PlayerGameName(:player="item.duel.opponent" :game="getGame(item)" :with-tooltip="false")
                  | &nbsp;{{ $t('duelNotificationText2') }} {{ item.gameTitle }}

            .notifications-list__item-actions(v-if="item.actions && item.actions.length")
              a.notifications-list__item-action(
                v-for="(action, index) in item.actions"
                :key="index"
                :class="{ '--primary': action.isPrimary }"
                @click.stop="onActionClick(action)"
              )
                | {{ action.title }}

</template>

<script>
import { mapState } from 'vuex'
import PlayerGameName from '~/components/PlayerGameName/PlayerGameName'

export default {
  name: 'NotificationsList',

  components: { PlayerGameName },

  data () {
    return {}
  },

  computed: {
    ...mapState('notifications', ['notifications']),
    ...mapState('games', ['gamesById']),

    list () {
      const uniqueIds = {}
      return this.notifications.filter(({ id }) => {
        if (!uniqueIds.id) {
          uniqueIds[id] = true
          return true
        }

        return false
      })
    }
  },

  mounted () {
    document.addEventListener('click', this.onClickOutside)

    this.$store.commit('notifications/clearNewNotifications')
  },

  beforeDestroy () {
    document.removeEventListener('click', this.onClickOutside)
  },

  methods: {
    async onActionClick (action) {
      this.$emit('close')
      await action.action()

      setTimeout(() => {
        this.$store.dispatch('notifications/fetchNotifications')
      }, 500)
    },

    getGame (item) {
      return this.gamesById[
        (item.extra_data?.duel || item.extra_data?.tournament).game_id
      ]
    },

    onClickOutside ({ target }) {
      if (!this.$el.contains(target)) {
        this.$emit('close')
      }
    },

    onHover (item) {
      if (item.is_read) {
        return
      }

      this.$store.dispatch('notifications/markAsRead', item)
    },

    getDate (item) {
      const date = item.created_at.replace(' ', 'T') + 'Z'

      return this.$dateFns.formatDistanceToNow(this.$dateFns.parseISO(date), {
        includeSeconds: true,
        addSuffix: true,
        locale: this.$i18n.locale
      })
    }
  }
}
</script>

<style lang="scss">
.notifications-list {
  box-sizing: border-box;
  position: absolute;
  top: 64px;
  right: 4px;
  margin-top: 10px;
  border: 1px solid $blue-grey40;
  border-radius: 8px;
  background: $blue-grey70;
  color: $blue-grey05;
  overflow: hidden;
  transition: top 0.2s $easing;

  .--mini & {
    top: 48px;
  }

  &__empty {
    @include caption-small;

    padding: 16px;
    width: 384px;

    @media (max-width: $breakpointHeaderMobile) {
      width: 100%;
    }
  }

  &__inner {
    @include scroll;

    width: 100%;
    max-width: 384px;
    max-height: calc(100vh - 148px);

    @media (max-width: $breakpointHeaderMobile) {
      max-width: 100%;
    }
  }

  &__item {
    padding: 16px;
    border-bottom: 1px solid $blue-grey40;
    transition: background-color 0.2s $easing;

    &-date {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 120%;
      color: $blue-grey10;
      padding-bottom: 8px;
    }

    &:last-child {
      border-bottom: none;
    }

    &-actions {
      margin-top: 12px;
      text-align: right;
    }

    &-action {
      @include heading6;

      cursor: pointer;
      color: $blue-grey10;

      &.--primary {
        color: $primary60;
      }
    }

    &-action + &-action {
      margin-left: 16px;
    }

    &-top {
      display: flex;
    }

    &-left {
      margin-right: 16px;
      width: 56px;

      img {
        min-height: 56px;
        min-width: 56px;
        border-radius: 8px;
        object-fit: cover;
      }
    }

    &-title {
      @include heading4;

      margin-bottom: 8px;
    }

    &-text {
      font-size: 16px;
      line-height: 130%;
      font-weight: 400;
      color: #9bb0cb;
      font-family: Roboto, sans-serif;
    }

    &:hover {
      background: $blue-grey60;
    }

    &.--not-read {
      background: $blue-grey50;
    }

    @media (max-width: $breakpointHeaderMobile) {
      &-left {
        width: 40px;

        img {
          min-height: 40px;
          min-width: 40px;
        }
      }
    }
  }

  @media (max-width: $breakpointHeaderMobile) {
    top: 44px;
    left: 4px;
  }
}
</style>
