import qs from 'qs'

class TournamentsService {
  constructor () {
    if (TournamentsService.instance) {
      return TournamentsService.instance
    }

    TournamentsService.instance = this

    return this
  }

  loadLitresTournaments (engine) {
    return engine.get('/games/tournaments/sponsored/halva')
  }

  loadSponsoredTournaments (engine) {
    return engine.get('/games/tournaments/sponsored/FreedomFinance')
  }

  findTournamentsForBeginner (engine) {
    return engine.get('/games/tournaments/sponsored/VVVGamers')
  }

  findFreerolls (engine, game = undefined) {
    const filters = [
      {
        name: 'state',
        type: 'string',
        values: [{ text: 'open' }]
      },
      {
        name: 'entry_fee',
        type: 'string',
        values: [{ text: '0' }]
      },
      {
        name: 'is_sponsored',
        type: 'string',
        values: [{ text: 'false' }]
      }
    ]
    const specialFilters = [
      {
        name: 'prize_settings.prize_currency',
        values: [{ text: 'money' }],
        type: 'string_list'
      }
    ]
    const sort = {
      name: 'date',
      order: 'asc'
    }
    const query = {
      offset: 0,
      limit: 4,
      filters,
      special_filters: specialFilters,
      sort
    }

    const url = game ? `/games/${game}/tournaments` : '/games/tournaments'

    return engine.get(url, {
      params: query,
      paramsSerializer: (params) => {
        return qs.stringify({ query: params })
      }
    })
  }

  findCommercialTournaments (engine, game = undefined) {
    const filters = [
      {
        name: 'state',
        type: 'string',
        values: [{ text: 'open' }]
      },
      {
        name: 'entry_fee',
        type: 'number',
        values: [{
          from: 1,
          to: 10000000
        }]
      }
    ]
    const specialFilters = [
      {
        name: 'prize_settings.entry_fee_currency',
        values: [{ text: 'money' }],
        type: 'string_list'
      }
    ]
    const sort = {
      name: 'date',
      order: 'asc'
    }
    const query = {
      offset: 0,
      limit: 4,
      filters,
      special_filters: specialFilters,
      sort
    }

    const url = game ? `/games/${game}/tournaments` : '/games/tournaments'

    return engine.get(url, {
      params: query,
      paramsSerializer: (params) => {
        return qs.stringify({ query: params })
      }
    })
  }

  findVVVPassTournaments (engine, game = undefined) {
    const filters = [
      {
        name: 'state',
        type: 'string',
        values: [{ text: 'open' }]
      }
    ]
    const specialFilters = [
      {
        name: 'prize_settings.prize_currency',
        values: [{ text: 'coins' }],
        type: 'string_list'
      },
      {
        name: 'prize_settings.entry_fee_currency',
        values: [{ text: 'coins' }],
        type: 'string_list'
      }
    ]
    const sort = {
      name: 'date',
      order: 'asc'
    }
    const query = {
      offset: 0,
      limit: 4,
      filters,
      special_filters: specialFilters,
      sort
    }

    const url = game ? `/games/${game}/tournaments` : '/games/tournaments'

    return engine.get(url, {
      params: query,
      paramsSerializer: (params) => {
        return qs.stringify({ query: params })
      }
    })
  }

  loadRecommendedTournaments (engine, gameName) {
    return engine.get(`/games/${gameName}/tournaments/recommended`)
  }

  findTournaments (engine, gameName, query) {
    return engine.get(`/games/${gameName}/tournaments`, {
      params: query,
      paramsSerializer: (params) => {
        return qs.stringify({ query: params })
      }
    })
  }

  loadTournamentById (engine, game, tournamentId, isMega = false) {
    const url = isMega ? `/games/${game}/mega-tournaments/${tournamentId}` : `/games/${game}/tournaments/${tournamentId}`
    return engine.get(url)
  }

  loadMegaTournamentStages (engine, game, tournamentId) {
    return engine.get(`/games/${game}/mega-tournaments/${tournamentId}/stages`)
  }

  joinTournament (engine, game, tournamentId, password = undefined, isMega = false) {
    return engine.post(
      `/games/${game}/${isMega ? 'mega-tournaments' : 'tournaments'}/${tournamentId}/join`,
      {
        entry_method: 'balance',
        private_password: password
      }
    )
  }

  leaveTournament (engine, game, tournamentId, isMega = false) {
    return engine.post(
      `/games/${game}/${isMega ? 'mega-tournaments' : 'tournaments'}/${tournamentId}/leave`
    )
  }

  getMyCompletedTournaments (engine) {
    const query = {
      offset: 0,
      limit: 1,
      filters: [
        {
          name: 'state',
          type: 'string_list',
          values: [{
            text: 'completed'
          }]
        }
      ],
      sort: {
        name: 'date',
        order: 'desc'
      }
    }
    return engine.get('/games/tournaments/my', {
      params: { query },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  }

  payForRatingChanged (engine, game, tournamentId) {
    engine.get(`/games/${game}/tournaments/${tournamentId}/get-consolation-coins`)
  }
}

export default new TournamentsService()
