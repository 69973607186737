<template lang="pug">
  .modal-phone-confirmation
    img.modal-phone-confirmation__image(src="./icon.svg" :alt="$t('phoneConfirmTitle')")

    .modal-phone-confirmation__title {{ $t('phoneConfirmTitle') }}

    .modal-phone-confirmation__body(v-if="!isSended")
      .modal-phone-confirmation__subtitle(v-html="$t('phoneConfirmSubtitle2', { phone: phone })")
      Button.modal-phone-confirmation__send(is-primary @click="onSendClick") {{ $t('buttonSend') }}

    .modal-phone-confirmation__body(v-else)
      .modal-phone-confirmation__subtitle(v-html="$t('phoneConfirmSubtitle', { phone: phone })")

      .modal-phone-confirmation__input
        .modal-phone-confirmation__input-title {{ $t('phoneCode') }}
        client-only
          CodeInput.modal-phone-confirmation__input-plugin(
            :fields="4"
            auto-focus
            :values="['·', '·', '·', '·']"
            @complete="onCodeComplete"
            @change="onCodeChange"
          )
        .modal-phone-confirmation__input-error.caption(v-if="codeError")
          | {{ `!Debug!: ${codeError}` }}

      .modal-phone-confirmation__resend.caption
        span(v-if="resendSeconds > 0") {{ $t('phoneResend') }} {{ resendSeconds }} {{ $t('secondsShort') }}
        a(v-else @click.prevent="savePhone") {{ $t('phoneResend') }}

</template>

<script>
import { mapActions, mapMutations } from 'vuex'

let timerId = null
const RESEND_SECONDS = 60

export default {
  name: 'ModalPhoneConfirmation',

  props: {
    phone: {
      type: String,
      required: true
    },

    onSuccess: {
      type: Function,
      default: () => {}
    }
  },

  data () {
    return {
      isDev: this.$config.PROJECT_ENV === 'development',
      code: '',
      codeError: '',
      resendSeconds: RESEND_SECONDS,
      isSended: false
    }
  },

  async mounted () {
    if (this.$config.PROJECT_ENV === 'production') {
      try {
        await this.$recaptcha.init()
      } catch (e) {
        console.error('recaptcha init error', e)
        this.$errors.handle(e)
      }
    } else {
      this.isSended = true
      await this.savePhone()

      this.$nextTick(() => {
        this.$el.querySelector('input').focus()
      })
    }
  },

  beforeDestroy () {
    this.$recaptcha.destroy()
    clearInterval(timerId)
  },

  methods: {
    ...mapActions('modal', ['showModal']),
    ...mapMutations('user', ['setPhoneVerification']),
    ...mapMutations('modal', ['toggle']),

    async onSendClick () {
      this.$nuxt.$loading.start()

      try {
        const token = await this.$recaptcha.execute('login')

        await this.savePhone(token)
      } catch (e) {
        console.log('recaptcha execute error', e)
        this.$errors.handle(e)
      }

      this.$nuxt.$loading.finish()
    },

    onCodeChange () {
      this.codeError = ''
    },

    onPhoneVerified () {
      this.toggle(false)
      // this.setPhoneVerification(true)
      this.onSuccess()
    },

    async onCodeComplete (code) {
      this.$nuxt.$loading.start()

      try {
        const { data } = await this.$axios.post('/user/account/phone/verify', {
          code
        })

        if (data.data === true) {
          this.onPhoneVerified()
        }
      } catch (e) {
        this.codeError = this.$errors.getText(e)
        console.error(
          `${new Date().toUTCString()} :: send verification code ::`,
          e
        )
      }

      this.$nuxt.$loading.finish()
    },

    startResendTimer () {
      clearInterval(timerId)
      this.resendSeconds = RESEND_SECONDS

      timerId = setInterval(() => {
        this.resendSeconds -= 1

        if (this.resendSeconds <= 0) {
          clearInterval(timerId)
        }
      }, 1000)
    },

    async savePhone (token) {
      this.setPhoneVerification(false)
      this.startResendTimer()

      const generalToken = await this.$recaptcha.execute('login')
      try {
        const { data } = await this.$axios.post('/user/account/phone', {
          phone: this.phone,
          token: generalToken
        })

        this.code = data.data
        this.isSended = true
      } catch (e) {
        this.$errors.handle(e)
        console.error(
          `${new Date().toUTCString()} :: send verification phone ::`,
          e
        )
      }
    },

    closeModal () {
      this.$store.commit('modal/toggle')
    },

    onAction ({ action, shouldClose = true }) {
      action?.()

      if (shouldClose) {
        this.closeModal()
      }
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalphoneconfirmation {
  padding: 0 24px 24px;
  max-width: 488px;

  @media (max-width: $breakpointMobile) {
    padding: 0 16px 24px;
  }
}

.modal-phone-confirmation {
  &__send {
    margin: 0 auto;
  }

  &__resend {
    position: absolute;
    right: 24px;
    bottom: 24px;
    text-align: right;

    @media (max-width: $breakpointMobile) {
      right: 16px;
      bottom: 16px;
    }

    a {
      cursor: pointer;
    }
  }

  &__image {
    display: block;
    margin: 24px auto 28px;
  }

  &__title {
    @include heading3;

    color: #fff;
    text-align: center;
  }

  &__subtitle {
    @include text-info120;

    margin: 16px 0 32px;
    color: $blue-grey10;
    text-align: center;

    span {
      color: #fff;
    }
  }

  &__input {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 54px;

    &-error.caption {
      color: $red40;
      margin-top: 12px;
    }

    &-title {
      @include caption-small;

      color: $blue-grey10;
    }

    &-plugin {
      margin-top: 12px;
      width: 100% !important;
      display: flex;
      justify-content: center;

      input {
        font-size: 24px !important;
        font-weight: $font-bold !important;
        background: $blue-grey60;
        font-family: $mainFont !important;
        border-radius: 8px !important;
        margin: 0 8px;
        border: none !important;
        width: 56px !important;
        height: 64px !important;
        color: #fff !important;
        transition: background-color 0.2s $easing;

        &:focus {
          caret-color: #fff !important;
          background-color: $blue-grey50;
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
