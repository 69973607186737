import plural from 'plural-ru'
import qs from 'qs'
import { isEuMember } from 'is-eu-member'
import copy from 'copy-to-clipboard'

export async function pay (direction, $store) {
  await $store.dispatch('user/fetchVerification')

  $store.dispatch('modal/showModal', {
    component: 'ModalCheckRequirements',
    data: {
      isPayIn: direction === 'in',
      isPayOut: direction === 'out',
      onSuccess: () => {
        $store.dispatch('modal/showModal', {
          component: direction === 'in' ? 'PayInModal' : 'NewPayOutModal'
        })
      }
    },
    shouldShow: false
  })
}

export function isUserAdult (verification, $dateFns) {
  if (!verification) {
    return false
  }

  const age = $dateFns.differenceInYears(
    new Date(),
    $dateFns.parse(verification.birthday, 'dd.MM.yyyy', new Date())
  )

  return age >= 16
}

export function isPhoneAndBirthdateNeeded (verification) {
  if (!verification) {
    return true
  }

  const fields = ['birthday', 'country', 'phone', 'is_phone_verified']

  return fields.some(field => !verification[field])
}

export function isFullContactsDataNeeded (verification) {
  if (!verification?.country) {
    return true
  }

  if (!isEuMember(verification.country)) {
    return false
  }

  const fields = [
    'address',
    'birthday',
    'city',
    'country',
    'last_name',
    'first_name',
    'is_phone_verified',
    'phone',
    'zip'
  ]

  return fields.some(field => !verification[field])
}

export function getDateText (date) {
  if (!date) {
    return ''
  }

  let dateToParse = date

  if (typeof dateToParse === 'string') {
    dateToParse = this.$dateFns.parseISO(dateToParse)
  }

  const time = this.$dateFns.format(dateToParse, 'HH:mm', {
    locale: this.$i18n.locale
  })

  if (this.$dateFns.isYesterday(dateToParse)) {
    return `${this.$t('yesterday')}, ${time}`
  }

  if (this.$dateFns.isToday(dateToParse)) {
    return `${this.$t('today')}, ${time}`
  }

  if (this.$dateFns.isTomorrow(dateToParse)) {
    return `${this.$t('tomorrow')}, ${time}`
  }

  return this.$dateFns.format(dateToParse, 'd MMMM, HH:mm', {
    locale: this.$i18n.locale
  })
}

export function getDateTextForTourCard (date, timeOfRegistration) {
  if (!date) {
    return ''
  }

  const currentTime = new Date().getTime()
  const dateToParse = typeof date === 'string' ? this.$dateFns.parseISO(date) : date
  const time = this.$dateFns.format(dateToParse, 'HH:mm', { locale: this.$i18n.locale })

  const registrationTime = this.$dateFns.parseISO(timeOfRegistration).getTime()
  const timeDifference = registrationTime - currentTime
  const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60))

  const hoursConvertResult = timeConvert(hoursDifference * 60, this.$i18n.locale)
  const minutesConvertResult = secondsToMinutes(Math.floor(timeDifference / (1000 * 60)), this.$i18n.locale)

  if (this.$dateFns.isYesterday(dateToParse)) {
    return `${this.$t('yesterday')}, ${time}`
  }

  if (this.$dateFns.isToday(dateToParse) && timeOfRegistration) {
    if (hoursDifference < 24 && hoursConvertResult) {
      return `${this.$t('today')}, ${time}. ${this.$t('signUpVia')} ${hoursConvertResult} ${minutesConvertResult}`
    } else {
      return `${this.$t('today')}, ${time}. ${this.$t('signUpOpen')}`
    }
  }

  if (this.$dateFns.isTomorrow(dateToParse)) {
    return `${this.$t('tomorrow')}, ${time}. ${this.$t('registration')} ${this.$t('tomorrow')}`
  }

  return this.$dateFns.format(dateToParse, 'd MMMM, HH:mm', {
    locale: this.$i18n.locale
  })
}

export function secondsToMinutes (time, locale) {
  const minutes = Math.floor(time % 60)

  let result =
    minutes > 0
      ? minutes + ' ' + plural(minutes, 'минута', 'минуты', 'минут')
      : ''

  if (locale !== 'ru') {
    result = minutes > 0 ? (minutes + ' ' + plural(minutes, 'minute', 'minutes')) : ''
  }
  if (time === 0) {
    result = ''
  }

  return result.trim()
}

export function timeConvert (num, locale) {
  const hours = Math.floor(num / 60)
  const minutes = Math.round(num % 60)

  let result =
    hours > 0 ? hours + ' ' + plural(hours, 'час', 'часа', 'часов') : ''

  if (locale !== 'ru') {
    result = hours > 0 ? (hours + ' ' + plural(hours, 'hour', 'hours')) : ''
  }

  if (minutes > 0) {
    if (locale !== 'ru') {
      result = minutes > 0 ? (minutes + ' ' + plural(minutes, 'minute', 'minutes')) : ''
    } else {
      result +=
        ' ' + minutes + ' ' + plural(minutes, 'минута', 'минуты', 'минут')
    }
  }

  if (num === 0) {
    result = '0'
  }

  return result
}

export function getPrizePool (event) {
  let pool = event.prize_pool
  const DUEL_DEFAULT_RAKE = 10

  // for duel
  if (['template'].includes(event.state) || event.account_id) {
    const rake = DUEL_DEFAULT_RAKE
    const rakeValue = (event.entry_fee / 100) * rake
    const cents = event.currency === 'money' ? 100 : 1

    pool = ((event.entry_fee / cents - rakeValue / cents) * 2).toFixed(2)
  }

  if (event.prize_settings?.prize_currency === 'money') {
    pool = Number(pool / 100).toFixed(2)
  }

  return pool
}

export function notify (title, options) {
  if (!('Notification' in window)) {
    console.log(
      'Ваш браузер не поддерживает HTML Notifications, его необходимо обновить.'
    )
  } else if (Notification.permission === 'granted') {
    const notification = new Notification(title)
    notification.onclick = () => {
      options.onClick()
    }
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission((permission) => {
      if (permission === 'granted') {
        const notification = new Notification(title)
        notification.onclick = () => {
          options.onClick()
        }
      }
    })
  }
}

export async function showRecommendedTournaments (store, { game, count = 3 }) {
  await store.dispatch('games/fetchRecommendedTournaments', game)

  if (store.state.games.gameRecommendedTournaments[game].length) {
    store.dispatch('modal/showModal', {
      component: 'RecommendedTournamentsModal',
      data: {
        game,
        count
      }
    })
  }
}

export function sendPostback ($axios, $config, tid = 'regs') {
  let query = sessionStorage.getItem('utmLabels')

  // console.log('postaback query', query)

  if (!query) {
    return
  }

  query = qs.parse(query)

  if (['media_buying', 'affiliate'].includes(query.utm_source)) {
    const params = {
      subid: 'vvvcash',
      tid,
      payout: 3,
      sub2: 1,
      creative_id: query.utm_content,
      source: query.utm_source
    }
    // console.log('postback sending', params, qs.stringify(params))

    $axios.get(`${$config.WWW_HOST}/postback`, {
      params
    })
  }
}

export function capitalizeString (str) {
  return str.charAt(0)?.toUpperCase() + str.slice(1)
}

export function randomNumber (min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export function copyText (input) {
  copy(input)
}

export function hasUnicode (str) {
  for (let i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 127) {
      return true
    }
  }

  return false
}

export function addDaysToDate (date, days) {
  const result = new Date(date.split(' ').join('T') + 'Z')
  result.setDate(result.getDate() + days)

  return result
}

export function getDaysBetweenDates (date1, date2) {
  const oneDay = 24 * 60 * 60 * 1000
  const firstDate = (new Date(date1)).getTime()
  const secondDate = (new Date(date2)).getTime()

  return Math.round(Math.abs((firstDate - secondDate) / oneDay))
}

export function postUrl (url) {
  const i = window.document.createElement('iframe')
  i.style.display = 'none'
  i.onload = function () {
    i.parentNode.removeChild(i)
  }
  i.src = url
  window.document.body.appendChild(i)
}

export function escapeHtml (str) {
  return str
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
    .replace(/`/g, '&#x60;')
}
