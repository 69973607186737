<template lang="pug">
  .finish
    img.finish__img(:src="require(`~/assets/img/convert.png`)" alt="Balance icon")
    .finish__container
      h1.finish__title {{ $t('balanceUpdated') }}
      p.finish__text {{ $t('balanceTransfered') }}
      .finish__text(v-html="$t('restoreExamples')")
      p.finish__text {{ $t('canAskSupport') }}
        a.finish__link(v-html="$t('supportButton')" v-support-button)
      Button.finish__button(is-primary @click="closeModal") {{ $t('clearThanks') }}
</template>

<script>
export default {
  methods: {
    closeModal () {
      this.$store.commit('modal/toggle')
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalbalanceinfo {
  min-width: 360px;
  max-width: 508px !important;
  padding: 0 !important;
}

.finish {
  &__img {
    width: 100%;
    max-height: 292px;
  }

  &__container {
    margin-top: -36px;
    padding: 0 16px;
    color: $white;
  }

  &__title {
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
  }

  &__link {
    cursor: pointer;
  }

  &__button {
    width: 100% !important;
    margin-bottom: 32px;
  }
}
</style>
