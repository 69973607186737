<template lang="pug">
.modal-choose-game-to-link-account
  img.modal-choose-game-to-link-account__icon(:src="require('~/assets/img/icons/shield.svg')" alt="shield")

  p.modal-choose-game-to-link-account__title(v-t="'chooseGameAndLinkAccount'")
  p.modal-choose-game-to-link-account__subtitle(v-t="'thisIsNecessaryToParticipateInTheTournament'")

  .modal-choose-game-to-link-account__games
    .modal-choose-game-to-link-account__game(v-for="(game, i) in gameList" :key="i" @click="onLink(game)")
      img.modal-choose-game-to-link-account__game-image(v-if="game.extra_data.icon" :src="game.extra_data.icon" :alt="game.name")
      .modal-choose-game-to-link-account__game-title {{ game.extra_data.title }}
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { postUrl } from '~/utils/utils'

export default {
  name: 'ModalChooseGameToLinkAccount',

  computed: {
    ...mapState('user', ['user']),
    ...mapState('games', ['games']),

    gameList () {
      return this.games
        .filter(game => game.is_enabled && game.is_tournament_enabled && game.canLink)
    }
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    async onLink (game) {
      // this.$gtag.event('game_account_link', { step: 2, game: game.name })

      postUrl('KochavaTracker://sendEventString?nameString=purchase&priceString=0.01')

      switch (game.name) {
        case 'lichess':
          try {
            const { data } = await this.$axios.get('/games/lichess/oauth/url')

            if (data.data) {
              window.location.href = data.data
            }
          } catch (e) {
            this.$errors.handle(e)
          }

          break

        case 'pubg-desktop':
        case 'cs-go':
        case 'dota2':
          this.showModal({ component: 'ModalSteamConnect', data: { game } })
          break

        case 'clash-royale':
          this.showModal({ component: 'ClashRoyaleAccount' })
          break

        case 'brawl-stars':
          this.showModal({ component: 'BrawlStarsAccount' })
          break

        case 'valorant':
          this.showModal({ component: 'ModalSteamConnect', data: { game } })
          break

        case 'fortnight':
        case 'fortnite':
        case 'apex-legends':
        case 'mobile-legends':
          this.showModal({ component: 'Dota2Account', data: { gameName: game.name } })
          break
        default:
          this.showModal({
            component: 'PubgMobileAccount',
            data: { gameName: game.name }
          })
      }
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalchoosegametolinkaccount {
  max-width: 488px !important;
}
</style>

<style lang="scss" scoped>
.modal-choose-game-to-link-account {
  &__icon {
    display: block;
    width: 114px;
    height: 114px;
    margin: 0 auto 16px;

    ::v-deep path {
      fill: $green60;
    }
  }

  &__title {
    padding: 0 20px;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    color: $white;
  }

  &__subtitle {
    margin: 24px 0 47px;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: $white;
  }

  &__games {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    max-height: 310px;
    padding: 0 45px;
    overflow-y: auto;
    @include scroll;
  }

  &__game {
    display: grid;
    grid-template-columns: 55px 1fr;
    grid-gap: 16px;
    padding: 0 8px;
    align-items: center;
    background-color: $blue-grey80;
    cursor: pointer;

    &-image {
      width: 55px;
      height: 55px;
    }

    &-title {
      font-size: 20px;
      font-weight: 500;
      line-height: 14px;
      color: $white;
      text-align: center;
    }
  }
}
</style>
