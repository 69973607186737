<template lang="pug">
  .modal-offer
    img.modal-offer__img(:src="imageUrl" alt="background")

    .modal-offer__container
      h1.modal-offer__title(v-html="$t('modalFreerollsTitle')")
      p.modal-offer__text(v-html="$t('modalFreerollsDescription')")
      Button.modal-offer__button(is-primary @click="onButtonClick(1)") {{ $t('buySubscription') }}
      Button.modal-offer__button(is-primary @click="onButtonClick(2)") {{ $t('selectTournament') }}

</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'ModalOffer',

  props: {
    type: {
      type: String,
      default: 'pubg-mobile-freerolls'
    }
  },

  computed: {
    imageUrl () {
      const locale = this.$i18n.locale

      if (locale === 'ru') {
        return 'https://cdn.gaming-goods.com/images/modals/pubgm-offer-ru.png'
      } else {
        return 'https://cdn.gaming-goods.com/images/modals/pubgm-offer-en.png'
      }
    }
  },

  methods: {
    ...mapMutations('modal', ['toggle']),

    onButtonClick (buttonNumber) {
      const actions = {
        'pubg-mobile-freerolls': {
          1: () => this.$router.push(this.localeLocation('/store/subscriptions')),
          2: () => this.$router.push(this.localeLocation('/pubg-mobile/tournaments'))
        }
      }

      actions[this.type][buttonNumber]()

      this.toggle()
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modaloffer {
  min-width: 360px;
  max-width: 508px !important;
  padding: 0 !important;
}

.modal-offer {
  &__img {
    width: 100%;
    max-height: 292px;
    border-radius: 16px;
  }

  &__container {
    background-color: $blue-grey70;
    margin-top: -16px;
    padding: 0 16px 16px;
    color: $white;
    border-radius: 0 0 16px 16px;
  }

  &__title {
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    margin-bottom: 16px;

    span {
      color: $primary60;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;

    a {
      color: $primary60;
    }
  }

  &__link {
    cursor: pointer;
  }

  &__button {
    width: 100% !important;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
