export function postToUrl (path, params, method = 'POST') {
  const form = document.createElement('form')
  form.setAttribute('method', method)
  form.setAttribute('action', path)

  for (const key in params) {
    if (key in params) {
      const hiddenField = document.createElement('input')
      hiddenField.setAttribute('type', 'hidden')
      hiddenField.setAttribute('name', key)
      hiddenField.setAttribute('value', params[key])

      form.appendChild(hiddenField)
    }
  }

  const buttonField = document.createElement('input')
  buttonField.setAttribute('type', 'button')

  form.appendChild(buttonField)

  document.body.appendChild(form)
  form.submit()
}
