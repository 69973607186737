export default ({ store, $config }) => {
  if (process.server) {
    try {
      const prodAndDevFeatures = []
      //   await fetch(
      //   'https://tg.gaming-goods.com/static/state.json'
      // ).then((res) => res.json())

      const env = $config.PROJECT_ENV || 'development'

      // console.log(
      //   `%c ENV: ${env}`,
      //   'background: #4ac481; color: #ffffff; border-radius: 4px; padding: 2px 4px 2px'
      // )

      const features = {}
      Object.keys(prodAndDevFeatures).map((key) => {
        features[key] = prodAndDevFeatures[key][env]
      })

      store.commit('global/setPlatformRemoteFeatures', features)
    } catch (e) {
      console.log(e)
    }
  }
}
