<template lang="pug">
  .image-slider
    .image-slider__text-wrapper
      p.image-slider__number
        | {{ slideIndex + 1 }}.
      p.image-slider__text
        | {{ currentSlide.text }}

    img.image-slider__image(:src="currentSlide.image")

    .image-slider__steps
      .image-slider__step(
        v-for="(item, i) of slides.length"
        @click="onSliderStepClick(i)"
        :class="{ '--active': slideIndex === i }"
      )
        .image-slider__step-inner(v-if="slideIndex === i" :style="{ width: `${ barWidth }%` }")
</template>

<script>
const INTERVAL = 200
let intervalId = null

export default {
  name: 'ImageSlider',

  props: {
    slides: {
      type: Array,
      required: true
    },

    slideDuration: {
      type: Number,
      default: 5000
    }
  },

  data: () => ({
    slideIndex: 0,
    slideTime: 0
  }),

  computed: {
    currentSlide () {
      return this.slides[this.slideIndex]
    },

    barWidth () {
      return (this.slideTime / this.slideDuration) * 100
    }
  },

  watch: {
    slides () {
      this.changeSlide(0)
    }
  },

  mounted () {
    if (this.slides.length > 1) {
      this.startInterval()
    }
  },

  beforeDestroy () {
    clearInterval(intervalId)
  },

  methods: {
    onSliderStepClick (slideIndex) {
      this.changeSlide(slideIndex)
    },

    changeSlide (slideIndex) {
      this.slideIndex = slideIndex
      this.slideTime = 0
    },

    autoSlideChange () {
      if (this.slideIndex === this.slides.length - 1) {
        this.slideIndex = 0
      } else {
        this.slideIndex += 1
      }
    },

    startInterval () {
      intervalId = setInterval(() => {
        this.slideTime += INTERVAL

        if (this.slideTime >= this.slideDuration) {
          this.slideTime = 0
          this.autoSlideChange()
        }
      }, INTERVAL)
    }
  }
}
</script>

<style lang="scss">
.image-slider {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__number {
    color: $blue-grey10;
    margin-right: 12px;
  }

  &__text {
    color: $white;
    margin-bottom: 16px;
    text-align: center;

    &-wrapper {
      display: flex;
    }
  }

  &__number,
  &__text {
    font-size: 16px;
    line-height: 19px;
  }

  &__image {
    width: 441px;
  }

  &__steps {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 16px;
    margin-top: 16px;
  }

  &__step {
    width: 64px;
    height: 4px;
    background: $blue-grey20;
    border-radius: 19px;
    cursor: pointer;

    &-inner {
      border-radius: 19px;
      background: $amber60;
      pointer-events: none;
      height: 4px;
      transition: width 0.2s linear;
    }

    &.--active,
    &:hover {
      background: $white;
    }
  }
}
</style>
