<template lang="pug">
  .players-list-modal
    .modal__header.players-list-modal__header {{ title || $t('tournamentMembers') }}

    .players-list-modal__tabs(v-if="bannedPlayers && bannedPlayers.length")
      Tabs(:items="tabs" :active-tab-index="currentTabIndex" @change="onTabChange")
    .players-list-modal__body(:class="{'--has-banned': bannedPlayers && bannedPlayers.length}")
      .players-list-modal__list(v-if="tournament.players")
        PlayerRow(
          v-for="player in currentList"
          :key="player.id"
          :player="player"
          with-game-account
          :with-menu="withMenu && currentTabIndex === 0"
          :location="location"
          :tournament="tournament"
        )
      .players-list-modal__list(v-if="tournament.teams")
        TeamRow(
          v-for="team in teams"
          :key="team.id"
          :team="team"
          :tournament="tournament"
        )

    .players-list-modal__footer
      .players-list-modal__counters(v-if="teams")
        .players-list-modal__text {{ $t('totalTeams') }}:
          .players-list-modal__counter {{ teams.length }}

        .players-list-modal__text(v-if="bannedPlayers && bannedPlayers.length" class="--banned")
          | {{ $t('bannedMembers') }}:
          .players-list-modal__counter {{ bannedPlayers.length }}

      .players-list-modal__counters(v-else)
        .players-list-modal__text {{ $t('total') }}:
          .players-list-modal__counter {{ players.length }}

        .players-list-modal__text(v-if="bannedPlayers && bannedPlayers.length" class="--banned")
          | {{ $t('bannedMembers') }}:
          .players-list-modal__counter {{ bannedPlayers.length }}

      Button.players-list-modal__download-button(v-if="isAdmin" is-primary @click="onDownloadClick") {{ $t('download') }}
      Button(is-primary @click="$store.commit('modal/toggle', false)") {{ $t('buttonClose') }}

</template>

<script>
import { mapGetters, mapState } from 'vuex'
import PlayerGameName from '~/components/PlayerGameName/PlayerGameName'
import PlayerRow from '~/components/PlayerRow/PlayerRow'
import Tabs from '~/components/Tabs/Tabs'
import TeamRow from '~/components/TeamRow/TeamRow'

export default {
  name: 'PlayersListModal',

  components: { TeamRow, Tabs, PlayerRow, PlayerGameName },

  props: {
    players: {
      type: Array,
      default: null
    },

    teams: {
      type: Array,
      default: null
    },

    bannedPlayers: {
      type: Array,
      default: null
    },

    title: {
      type: String,
      default: ''
    },

    location: {
      type: String,
      required: true
    },

    tournament: {
      type: Object,
      default: null
    },

    isPhased: {
      type: Boolean,
      default: false
    }
  },

  data ({ bannedPlayers }) {
    let tabs

    if (bannedPlayers?.length) {
      tabs = [
        { title: this.$t('allMembers') },
        { title: this.$t('theyAreBanned') }
      ]
    }
    return {
      currentTabIndex: 0,
      tabs
    }
  },

  computed: {
    ...mapGetters('user', ['isAdmin']),
    ...mapState('games', ['gamesById', 'gamesByName']),

    game () {
      return this.tournament
        ? this.gamesById[this.tournament.game_id]
        : this.gamesByName[this.$route.params.game]
    },

    currentList () {
      return this.currentTabIndex === 0 ? this.players : this.bannedPlayers
    },

    withMenu () {
      return ['lichess', 'pubg-mobile', 'free-fire'].includes(this.game?.name)
    }
  },

  methods: {
    plural: require('plural-ru'),

    onTabChange ({ index }) {
      this.currentTabIndex = index
      this.$emit('recalculatePosition')
    },

    async onDownloadClick () {
      this.$nuxt.$loading.start()

      const action = this.isPhased ? 'mega-tournaments' : 'tournaments'
      const fileStart = this.isPhased ? 'mega-' : ''

      try {
        const { data } = await this.$axios.get(
          `games/${this.game.name}/${action}/${this.tournament.id}/users`
        )

        const blob = new Blob([data], { type: 'text/plain;charset=utf-8' })
        this.$fs.saveAs(blob, fileStart + `players-${this.tournament.id}.csv`)
      } catch (e) {
        this.$errors.getText(e)
      }

      this.$nuxt.$loading.finish()
    }
  }
}
</script>

<style lang="scss">
.modal.modal--playerslistmodal {
  max-width: 735px;
  padding-bottom: 12px;
}

.players-list-modal {
  &__header {
    display: flex;
  }

  &__tabs {
    height: 68px;
    background: $blue-grey60;
    border-bottom: 1px solid $blue-grey40;
    margin: 0 -24px;
    display: flex;
    align-items: center;

    .tabs {
      height: 100%;
    }

    @media (max-width: $breakpointMobile) {
      margin: 0 -16px;
    }
  }

  .player-menu__menu {
    margin-right: 8px;
    margin-top: 0;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
  }

  &__footer {
    display: flex;
    align-items: center;
    padding: 12px 24px 0;
    margin: 0 -24px;
    border-top: 1px solid $blue-grey40;

    .button:first-of-type {
      margin-left: auto;

      &__text {
        color: $primary60;
      }

      svg,
      path,
      rect {
        fill: $primary60;
      }
    }
  }

  &__counters {
    height: 48px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__text {
    @include caption;

    margin-right: 16px;
    display: flex;
    align-items: center;
    color: $blue-grey10;
  }

  &__counter {
    margin-left: 5px;
    color: $primary60;

    .--banned & {
      color: $red60;
    }
  }

  &__body {
    @include scroll;

    padding: 12px 24px;
    margin: 0 -24px;
    overflow-y: auto;
    max-height: calc(100vh - 200px);

    &.--has-banned {
      max-height: calc(100vh - 262px);
    }

    @media (max-width: $breakpointMobile) {
      padding: 8px 16px;
    }
  }

  &__download-button {
    margin-right: 8px;
  }

  &__list-title {
    display: flex;
    align-items: center;
    padding: 32px 0 16px;
    background: $blue-grey70;
    position: sticky;
    z-index: 2;
    top: 0;

    @media (max-width: $breakpointMobile) {
      display: none;
    }
  }

  &__list-name {
    @include caption-small;

    color: $blue-grey10;

    &:first-child {
      margin: 0 auto 0 104px;

      @media (max-width: $breakpointDesktop) {
        margin: 0 auto 0 88px;
      }
    }

    &:last-child {
      margin: 0 104px 0 0;

      @media (max-width: $breakpointDesktop) {
        margin: 0 78px 0 0;
      }
    }
  }

  .player-row,
  .team-row {
    margin: 0;
    border-bottom: 2px solid #3341533b;
    border-radius: 0;
    padding: 0;

    &:last-child {
      border-bottom: 0;
    }

    @media (max-width: $breakpointMobile) {
      margin-bottom: 0;
      padding: 0;
    }

    &__cell {
      border: none;

      &.--wide {
        position: relative;

        &:not(:last-child)::before {
          content: "";
          position: absolute;
          width: 1px;
          display: block;
          height: 48px;
          top: 50%;
          transform: translateY(-50%);
          background: $blue-grey40;
          right: 0;
        }
      }
    }
  }
}
</style>
