import { getDeviceType } from '~/utils/get-device-type'

class UsersService {
  constructor () {
    if (UsersService.instance) {
      return UsersService.instance
    }

    this.employeesIds = [15, 2, 20, 5, 20634, 189432, 2276, 3088]
    UsersService.instance = this

    return this
  }

  isUserEmployee (user) {
    return this.employeesIds.includes(user?.id)
  }

  getTokenAsSuperAdmin (engine, userId) {
    return engine
      .get(`/admin/user/${userId}/token`)
      .then((response) => {
        return response.data?.data
      })
      .catch((e) => {
        console.error(
          `${new Date().toUTCString()} :: get token as super admin error ::`,
          e
        )
      })
  }

  influencerVerificationRequest (engine, verificationData) {
    return engine.post('/influencers', verificationData)
  }

  getInfluencerVerificationStas (engine) {
    return engine.get('/influencers')
  }

  getUserStatus (engine, id) {
    if (id) {
      return engine.get(`/stars/${id}`)
    }
    return engine.get('/stars')
  }

  getUsersNamesByIdList (engine, idList) {
    return engine.get(`/user/list-by-id?ids=${idList.join(',')}`)
  }

  getUserById (engine, id) {
    return engine.get(`/user/${id}`)
  }

  sendPoll (engine, data) {
    return engine.post('/poll', data)
  }

  getPollsList (engine) {
    return engine.get('/poll')
  }

  updateProfile (engine, data) {
    return engine.put('/user', data)
  }

  becomeSeller (engine) {
    return engine.post('/user/account/become-seller')
  }

  sendSellerForm (engine, params) {
    return engine.post('/user/account/seller-form', params)
  }

  getMyVVData (engine, country) {
    const device = getDeviceType()
    return engine.get(`/user/my-vvv?country=${country}&device=${device}`)
  }

  linkSocial (engine, id, params = {}) {
    return engine.post(`/user/account/social-network/${id}/add`, params)
  }
}

export default new UsersService()
