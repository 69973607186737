<template lang="pug">
  transition(name="slide-up" duration="200")
    .cookie-policy(v-if="isVisible")
      .cookie-policy__text
        | gaming-goods.com&nbsp;
        a(href="/info/cookie-policy" target="_blank") {{ $t('cookieText1') }}
        |
        | {{ $t('cookieText2') }}
        |
        a(href="/info/cookie-policy" target="_blank") {{ $t('cookieText3') }}
        | .
        div {{ $t('cookieText4') }}

      .cookie-policy__button
        Button(is-primary @click="onCloseClick") {{ $t('buttonAccept') }}

</template>

<script>
export default {
  name: 'CookiePolicy',

  data () {
    return {
      isVisible: false
    }
  },

  mounted () {
    this.isVisible =
      window.localStorage.getItem('is_cookie_policy_shown') !== 'true'
  },

  methods: {
    onCloseClick () {
      this.isVisible = false
      window.localStorage.setItem('is_cookie_policy_shown', 'true')
    }
  }
}
</script>

<style lang="scss">
.cookie-policy {
  position: fixed;
  bottom: 40px;
  left: 97px;
  right: 32px;
  background: $blue-grey70;
  z-index: 6;
  font-size: 16px;
  line-height: 19px;
  padding: 24px;
  color: #fff;
  border-radius: 16px;
  border: 1px solid $blue-grey40;
  display: flex;

  a {
    text-decoration: underline;
  }

  &__text {
    width: 100%;
  }

  &__button {
    padding-left: 24px;

    @media (max-width: $breakpointHeaderMobile) {
      padding-left: 0;
      padding-top: 16px;
    }
  }

  @media (max-width: $breakpointHeaderMobile) {
    flex-direction: column;
    padding: 16px;
    bottom: 54px;
    left: 16px;
    right: 16px;
  }
}
</style>
