<template lang="pug">
  .report-player
    .modal__header
      | {{ $t('complaintOn', { name: player.full_name }) }}

    .report-player__body
      .report-player__field(v-for="(field, key) in fields" :key="key")
        FormInput(
          v-if="field.type === 'textarea' || field.type === 'text'"
          v-bind="field" v-model="field.value"
          @input="onFieldInput(field, key)"
          @blur="onFieldBlur(field, key)"
        )
        BaseSelect(
          v-if="field.type === 'select'"
          v-bind="field" v-model="field.value"
          @input="onFieldInput(field, key)"
          @blur="onFieldBlur(field, key)"
        )
        FileUpload(
          v-if="field.type === 'file'"
          v-bind="field" v-model="field.value"
          @input="onFieldInput(field, key)"
          @blur="onFieldBlur(field, key)"
        )

      transition(name="fade" duration="200")
        InfoMessage(v-if="errorMessage" type="error")
          | {{ errorMessage }}

    .modal__footer
      Button(is-primary @click="send" :is-disabled="isButtonDisabled") {{ $t('sendComplaint') }}

</template>

<script>
import { mapActions, mapState } from 'vuex'
import FormInput from '~/components/FormInput/FormInput'
import BaseSelect from '~/components/BaseSelect/BaseSelect'
import FileUpload from '~/components/FileUpload/FileUpload'

const MAX_FILE_SIZE = 52428800 // 50 megabytes

export default {
  name: 'ReportPlayer',

  components: {
    BaseSelect,
    FormInput,
    FileUpload
  },

  props: {
    player: {
      type: Object,
      required: true
    },

    location: {
      type: String,
      required: true
    },

    tournament: {
      type: Object,
      default: null
    }
  },

  data () {
    const mainFields = {
      reason: {
        type: 'select',
        label: this.$t('reason'),
        placeholder: this.$t('setReason'),
        value: '',
        options: [
          { value: 'unfair_play', title: this.$t('unfairPlay') },
          { value: 'unfair_behavior', title: this.$t('unfairBehavior') },
          { value: 'other', title: this.$t('other') }
        ],
        error: ''
      },

      comment: {
        type: 'textarea',
        title: this.$t('yourComment'),
        value: '',
        error: ''
      }
    }

    let gameFields = {
      link: {
        type: 'text',
        title: this.$t('linkOfParty'),
        value: '',
        error: ''
      }
    }

    // pubg mobile
    if (this.tournament?.game_id === 3) {
      gameFields = {
        video: {
          type: 'file',
          accept: 'video/mp4,video/x-m4v,video/*',
          title: this.$t('videoProof'),
          value: '',
          error: ''
        }
      }
    }

    return {
      errorMessage: '',
      fields: { ...mainFields, ...gameFields }
    }
  },

  computed: {
    ...mapState('games', ['gamesById']),

    isButtonDisabled () {
      if (
        Object.values(this.fields).some((field) => {
          return field.error
        })
      ) {
        return true
      }

      return Object.values(this.fields).some(field => !field.value)
    },

    game () {
      return this.gamesById[this.tournament?.game_id] || {}
    }
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    onFieldInput (field, key) {
      this.errorMessage = ''
      this.fields[key].error = ''
    },

    onFieldBlur (field, key) {
      this.fields[key].error = ''

      if (!field.value) {
        this.fields[key].error = this.$t('fieldRequired')
      } else if (field.type === 'file' && field.value.size > MAX_FILE_SIZE) {
        this.errorMessage = this.$t('maxFileSizeError')
      }

      this.$forceUpdate()
    },

    async send () {
      this.errorMessage = ''

      if (Object.values(this.fields).some(field => !field.value)) {
        if (!this.fields.reason.value || !this.fields.comment.value) {
          return
        }
      }

      const values = {
        location: this.location,
        player: this.player,
        type: this.fields.reason.value,
        reason: this.fields.comment.value,
        extra_data: {
          link: this.fields.link?.value
        }
      }

      if (this.tournament) {
        values.tournament_id = this.tournament.id
      }

      this.$nuxt.$loading.start()

      try {
        if (this.game?.name === 'pubg-mobile') {
          delete values.extra_data
          const form = new FormData()
          form.append('file', this.fields.video.value)
          form.append('body', JSON.stringify(values))

          await this.$axios.post(`/user/${this.player.id}/report`, form)
        } else {
          await this.$axios.post(`/user/${this.player.id}/report`, values)
        }

        this.showModal({
          component: 'ModalMessage',
          data: {
            title: this.$t('success'),
            type: 'info',
            text: this.$t('yourMessageWasSent')
          }
        })
      } catch (e) {
        this.$errors.handle(e)
      }

      this.$nuxt.$loading.finish()
    }
  }
}
</script>

<style lang="scss">
.modal.modal--reportplayer {
  max-width: 480px;
  padding-bottom: 0;
}

.report-player {
  &__field {
    margin-top: 24px;
  }

  &__body {
    margin-bottom: 24px;
  }

  .info-message {
    margin-top: 24px;
  }
}
</style>
