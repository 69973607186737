<template lang="pug">
.header-currency
  .header-currency__title
    img(
      v-if="user"
      :src="require(`~/assets/img/icons/angle-left.svg`)"
      alt="back"
      @click="$emit('back')"
    )
    | {{ $t('chooseCurrencyShort') }}
  .header-currency__item(
    v-for="item in items"
    :key="item.id"
    :class="{'--active': item.code === currency}"
    @click="onAction(item)"
  )
    .header-currency__text {{ item.code }}
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import CurrencyService from '~/services/CurrencyService'
export default {
  name: 'HeaderCurrency',
  data () {
    return {
      items: []
    }
  },
  computed: {
    ...mapGetters('user', ['currency']),
    ...mapState('user', ['user'])
  },
  mounted () {
    document.addEventListener('click', this.onClickOutside)
    this.loadCurrencies()
  },
  beforeDestroy () {
    document.removeEventListener('click', this.onClickOutside)
  },
  methods: {
    ...mapActions('user', ['fetchUser']),
    onAction (item) {
      CurrencyService.setCurrencyForCurrentUser(this.$axios, item.code)
      setTimeout(
        () =>
          this.fetchUser()
            .then(() => {
              this.$emit('close')
            })
            .catch((err) => {
              console.error(err)
              this.$emit('close')
            }),
        1000
      )
    },
    loadCurrencies () {
      CurrencyService.getAllCurrencies(this.$axios)
        .then((response) => {
          this.items = response
        })
        .catch((err) => {
          console.error(err)
        })
    },
    onClickOutside ({ target }) {
      if (!this.$el.contains(target)) {
        this.$emit('close')
      }
    }
  }
}
</script>
<style lang="scss">
.header-currency {
  box-sizing: border-box;
  position: absolute;
  top: 64px;
  right: 16px;
  margin-top: 8px;
  border: 1px solid $blue-grey40;
  border-radius: 8px;
  background: $blue-grey70;
  padding: 8px;
  width: 280px;
  color: #fff;
  overflow-y: auto;
  max-height: calc(100vh - 148px);
  transition: top 0.2s $easing;

  .--mini & {
    top: 48px;
  }

  &__title {
    @include heading5;
    height: 40px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    img {
      path {
        fill: #fff;
      }
      width: 20px;
      height: 20px;
      margin-right: 8px;
      cursor: pointer;
    }
  }
  &__item {
    @include hint-small;
    display: flex;
    font-weight: 600;
    font-size: 15px;
    align-items: center;
    padding-left: 10px;
    height: 40px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s $easing;
    &:hover {
      background: $blue-grey60;
    }
    &.--active {
      color: $primary60;
    }
    img {
      margin-right: 8px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
