<template lang="pug">
.modal-tournaments-after-account-link
  .modal-tournaments-after-account-link__head
    img.modal-tournaments-after-account-link__cover(:src="require('~/assets/img/modal-congratulations.png')" alt="congratulations")
  .modal-tournaments-after-account-link__body
    p.modal-tournaments-after-account-link__title {{ $t("nowYouCanParticipateIn", { game: gameName }) }}
    p.modal-tournaments-after-account-link__text(v-html="$t('hereAFewForYou')" @click="openSubscriptionsStore")
    .modal-tournaments-after-account-link__tournaments
      TournamentCardNewDesign(v-for="tournament in tournaments" :key="tournament.id" :tournament="tournament" always-short)
    Button.modal-tournaments-after-account-link__button(is-primary :class="{'modal-tournaments-after-account-link__button--disabled': times > 0}" @click="onButtonClick")
      | {{ $t("buttonClose") }}
      span(v-if="times > 0") ({{ times }})
</template>

<script>
import { mapMutations } from 'vuex'
import TournamentsService from '~/services/TournamentsService'
import TournamentCardNewDesign from '~/components/TournamentCard/TournamentCardNewDesign'

export default {
  name: 'ModalTournamentsAfterAccountLink',

  components: {
    TournamentCardNewDesign
  },

  props: {
    gameName: {
      type: String,
      required: true
    },

    gameId: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      tournaments: undefined,
      times: 5,
      interval: undefined
    }
  },

  mounted () {
    this.findTournaments()
  },

  beforeDestroy () {
    clearInterval(this.interval)
  },

  methods: {
    ...mapMutations('modal', ['toggle']),

    async findTournaments () {
      const { data } = await TournamentsService.findTournamentsForBeginner(this.$axios)
      this.tournaments = data?.data?.filter(item => item.game_id === this.gameId).slice(0, 4)
      this.startTimer()
    },

    openSubscriptionsStore () {
      this.$router.push(this.localeLocation('/store/subscriptions'))
      this.close()
    },

    close () {
      this.toggle()
    },

    onButtonClick () {
      if (this.times < 1) {
        this.close()
      }
    },

    startTimer () {
      this.interval = setInterval(() => {
        this.times--
        if (this.times < 1) {
          clearInterval(this.interval)
        }
      }, 1000)
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modaltournamentsafteraccountlink {
  max-width: 600px;
  padding: 0;
  background: $blue-grey80;
}
</style>

<style lang="scss" scoped>
.modal-tournaments-after-account-link {
  width: 100%;

  &__body {
    padding: 23px 20px 13px;

    @media (min-width: $breakpointTablet) {
      padding: 20px 30px 18px;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    color: $white;
    min-height: 66px;
    margin-bottom: 14px;

    @media (min-width: $breakpointTablet) {
      min-height: auto;
    }
  }

  &__text {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: $white;
  }

  &__tournaments {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    margin: 20px 0;
    padding-right: 12px;
    max-height: 40vh;
    @include scroll;
    overflow-y: auto;

    @media (min-width: $breakpointTablet) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__button {
    display: block;
    height: 48px;
    min-width: 225px;
    margin: 0 auto;

    &--disabled {
      background: $fc-grey-2;
      cursor: default;
    }
  }
}
</style>
