<template lang="pug">
  .dota2-account
    .modal__header {{ $t('gameAccount', { game: gameName  }) }}
    form(@submit.prevent="onSubmit")
      .dota2-account__body
        InfoMessage(v-if="!isNickChanging" type="warning").dota2-account__warning
          | {{ $t('doNotForgetChangeNickname') }}

        FormInput(
          v-bind="fields.username"
          v-model="fields.username.value"
          @input="onFieldInput('username', fields.username)"
          @blur="onFieldBlur('username', fields.username)"
        )
        FormInput(
          v-bind="fields.id"
          v-model="fields.id.value"
          @input="onFieldInput('id', fields.id)"
          @blur="onFieldBlur('id', fields.id)"
        )
      .modal__footer
        transition(name="fade" duration="200")
          InfoMessage(v-if="errorMessage" type="error")
            | {{ errorMessage }}
        Button(is-primary :is-disabled="isButtonDisabled" type="submit") {{ $t('buttonLink') }}

</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import FormInput from '~/components/FormInput/FormInput'

export default {
  name: 'Dota2Account',

  components: { FormInput },

  props: {
    gameName: {
      type: String,
      default: 'pubg-mobile'
    }
  },

  data () {
    return {
      errorMessage: '',

      fields: {
        username: {
          type: 'text',
          value: '',
          title: this.$t('gameNickname'),
          placeholder: this.$t('gameNickname'),
          isRequired: true,
          error: ''
        },
        id: {
          type: 'text',
          value: '',
          title: this.$t('accountId'),
          placeholder: this.$t('accountId'),
          isRequired: true,
          error: ''
        }
      }
    }
  },

  computed: {
    ...mapState('games', ['gamesByName'])
  },

  methods: {
    ...mapMutations('modal', ['toggle']),
    ...mapActions('modal', ['showModal']),

    async onSubmit () {
      this.errorMessage = ''

      Object.keys(this.fields).forEach(key => this.onFieldBlur(key))

      if (this.isButtonDisabled) {
        return
      }

      this.$nuxt.$loading.start()

      try {
        await this.$axios.post(`/games/${this.gameName}/account`, {
          username: this.fields.username.value,
          id: this.fields.id.value
        })

        await this.$store.dispatch('user/fetchUser')

        // this.$gtag.event('game_account_link', { step: 3, game: this.gameName })

        // this.toggle(false)
        this.showModal({
          component: 'ModalTournamentsAfterAccountLink',
          canClose: false,
          data: {
            gameName: this.gamesByName[this.gameName]?.extra_data.title,
            gameId: this.gamesByName[this.gameName]?.id
          }
        })
      } catch (e) {
        this.errorMessage = this.$errors.getText(e)
        console.error(
          `${new Date().toUTCString()} :: dota 2 account error ::`,
          e
        )

        this.$nextTick(() => {
          this.$emit('recalculatePosition')
        })
      }

      this.$nuxt.$loading.finish()
    },

    onFieldInput (name) {
      this.errorMessage = ''
      this.$set(this.fields[name], 'error', '')

      this.$nextTick(() => {
        this.$emit('recalculatePosition')
      })
    },

    onFieldBlur (name) {
      const field = this.fields[name]

      if (!field.value && field.isRequired) {
        field.error = this.$t('fieldRequired')
      }

      if (name === 'username' && /[\u180C\u3164]/.test(field.value)) {
        field.error = this.$t('hiddenSymbolsWarning')
      }

      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss">
.modal.modal--dota2account {
  padding-bottom: 0;
  max-width: 614px;
}

.dota2-account {
  &__gif {
    margin-top: 16px;

    &-caption {
      font-style: normal;
      font-weight: $font-medium;
      font-size: 12px;
      line-height: 100%;
      text-transform: uppercase;
      color: $grey20;
      margin-bottom: 8px;
    }

    img {
      width: 100%;
    }

    @media (max-width: $breakpointMobile) {
      &-caption {
        font-size: 10px;
        line-height: 12px;
      }
    }
  }

  &__body {
    padding: 20px 24px;
    margin: 0 -24px;
    overflow-y: auto;
    max-height: calc(100vh - 180px);

    @media (max-width: $breakpointMobile) {
      padding: 20px 16px;
      margin: 0 -16px;
      max-height: calc(100vh - 145px);
    }
  }
  .form-input,
  .file-upload {
    margin-top: 24px;

    &.--error {
      padding-bottom: 20px;
    }
  }

  &__warning {
    margin-bottom: 16px;
  }

  &__warning + &__warning {
    margin-bottom: 24px;
  }

  .button {
    height: fit-content;
  }

  .modal__footer {
    flex-wrap: wrap;
  }

  .info-message.--error {
    width: 100%;
    margin-bottom: 16px;
  }
}
</style>
