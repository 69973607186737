<template lang="pug">
  .google-pay-btn(aria-label="Google Pay" @click="googlePayClick" :class="{'google-pay-btn--disabled': isDisabled}")
    img.google-pay-btn__img(:src="googleBtnImage")
</template>

<script>
import PaymentService from '~/services/PaymentService'

export default {
  name: 'GooglePayButton',
  props: {
    transactionInfo: {
      type: Object,
      required: true
    },

    isDisabled: {
      type: Boolean,
      required: true
    },

    amount: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      googlePayScript: null,
      paymentsClient: null,
      config: null,
      buttonIsReady: false,
      gettingPaymentInterval: undefined
    }
  },

  computed: {
    googleBtnImage () {
      const locale = this.$i18n.locale.split('-')[0]
      return `https://www.gstatic.com/instantbuy/svg/light/${locale}.svg`
    }
  },

  mounted () {
    this.loadGooglePayScript()
    this.loadConfig()
  },

  methods: {
    loadGooglePayScript () {
      if (this.googlePayScript) { return }

      this.googlePayScript = document.createElement('script')
      this.googlePayScript.setAttribute(
        'src',
        'https://pay.google.com/gp/p/js/pay.js'
      )
      this.googlePayScript.setAttribute('async', 'async')
      this.googlePayScript.onload = () => {
        this.paymentsClient = new window.google.payments.api.PaymentsClient({
          environment: 'PRODUCTION'
        })
      }

      this.$el.firstChild.appendChild(this.googlePayScript)
    },

    loadConfig () {
      PaymentService.getGooglePayConfig(this.$axios)
        .then((response) => {
          const { data } = response
          this.config = data.data
          this.checkIsReadyToPay()
        })
        .catch((e) => {
          console.error(
            `${new Date().toUTCString()} :: load google pay config error ::`,
            e
          )
        })
    },

    checkIsReadyToPay () {
      const { apiVersion, apiVersionMinor, allowedPaymentMethods } = this.config

      const isReadyToPay = {
        apiVersion,
        apiVersionMinor,
        allowedPaymentMethods: [
          allowedPaymentMethods.find(method => method.type === 'CARD')
        ]
      }
      this.paymentsClient
        .isReadyToPay(isReadyToPay)
        .then((response) => {
          if (response.result) {
            this.buttonIsReady = true
          }
        })
        .catch((e) => {
          console.error(
            `${new Date().toUTCString()} :: check ready to pay error ::`,
            e
          )
        })
    },

    googlePayClick () {
      if (this.isDisabled) { return false }

      const paymentData = {
        ...this.config,
        transactionInfo: this.transactionInfo
      }
      this.paymentsClient
        .loadPaymentData(paymentData)
        .then((paymentData) => {
          const paymentToken =
            paymentData.paymentMethodData.tokenizationData.token
          const parsed = {
            ...JSON.parse(paymentToken),
            amount: this.amount,
            return_url: `${location.protocol}//${location.host}${location.pathname}`
          }

          this.open3DS(parsed)
        })
        .catch((e) => {
          console.error(
            `${new Date().toUTCString()} :: google pay click error ::`,
            e
          )
        })
    },

    stopGettingPayment () {
      clearInterval(this.gettingPaymentInterval)
      this.$nuxt.$loading.finish()
    },

    open3DS (paymentToken) {
      this.$nuxt.$loading.start()

      PaymentService.getGooglePay3DSUrl(this.$axios, paymentToken)
        .then((response) => {
          if (response.error) {
            this.$emit('onError', response.error)
          } else {
            const { data } = response.data
            window.location.href = data
          }
        })
        .catch((e) => {
          this.$emit('onError', e)
          console.error(`${new Date().toUTCString()} :: open 3DS error ::`, e)
        })
    }
  }
}
</script>

<style lang="scss">
.google-pay-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  height: 40px;
  min-height: 40px;
  border-radius: 4px;
  margin-left: 6px;
  background-color: #fff;
  cursor: pointer;

  &--disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}
</style>
