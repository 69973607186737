<template lang="pug">
  .pubg-account.pubg-desktop-account
    .modal__header {{ $t('gameAccount', { game: 'PUBG' }) }}

    form(@submit.prevent="onSubmit")
      .pubg-account__body
        FormInput(
          v-bind="fields.username"
          v-model="fields.username.value"
          @input="onFieldInput('username', fields.username)"
          @blur="onFieldBlur('username', fields.username)"
        )

        .pubg-account__hint {{ $t('pubgDesktopHint') }}

        .pubg-account__gif
          .pubg-account__gif-caption {{ $t('screenshotExample') }}
          img(:src="exampleUrl" :alt="$t('screenshotExample')")

      .modal__footer
        transition(name="fade" duration="200")
          InfoMessage(v-if="errorMessage" type="error") {{ errorMessage }}

        Button(is-primary :is-disabled="isButtonDisabled" type="submit") {{ $t('buttonLink') }}

</template>

<script>
import PubgMobileAccount from '~/components/PubgMobileAccount/PubgMobileAccount'

export default {
  name: 'PubgDesktopAccount',

  extends: PubgMobileAccount,

  data () {
    return {
      errorMessage: '',

      fields: {
        username: {
          type: 'text',
          value: '',
          title: this.$t('gameNicknamePubgDesktop'),
          placeholder: this.$t('gameNicknamePubgDesktop'),
          isRequired: true,
          error: ''
        },

        id: {
          isRequired: false
        },

        screenshot: {
          isRequired: false
        }
      }
    }
  },

  computed: {
    isButtonDisabled () {
      return !this.fields.username?.value?.length
    },

    exampleUrl () {
      return 'https://cdn.gaming-goods.com/images/pubg-desktop-example.png'
    }
  },

  methods: {
    async onSubmit () {
      this.errorMessage = ''

      Object.keys(this.fields).forEach(key => this.onFieldBlur(key))

      if (this.isButtonDisabled) {
        return
      }

      this.$nuxt.$loading.start()

      try {
        let params = this.$cookiz.get('steam_data')

        params = {
          ...params,
          username: this.fields.username.value
        }

        await this.$axios.get('/steam/verify', { params })
        await this.$store.dispatch('user/fetchUser')

        // this.$gtag.event('game_account_link', { step: 3, game: 'pubg-desktop' })

        this.toggle(false)
      } catch (e) {
        this.errorMessage = this.$errors.getText(e)
        console.error(
          `${new Date().toUTCString()} :: pubg desktop account error ::`,
          e
        )

        this.$nextTick(() => {
          this.$emit('recalculatePosition')
        })
      }

      this.$nuxt.$loading.finish()
    }
  }
}
</script>

<style lang="scss">
.modal.modal--pubgdesktopaccount {
  padding-bottom: 0;
  max-width: 614px;
}

.pubg-account {
  &__hint {
    color: $grey20;
    margin-top: 12px;
  }
}
</style>
