<script>
import FormInput from '@/components/FormInput/FormInput.vue'
import BaseSelect from '@/components/BaseSelect/BaseSelect.vue'
import SeoService from '@/services/SeoService'
import { mapActions } from 'vuex'
import { NATIVE_LANG_BY_LOCALE } from '~/utils/lang'

export default {
  name: 'EditSEOModal',

  components: {
    BaseSelect,
    FormInput
  },

  props: {
    category: {
      type: Object,
      required: true
    },

    loadApp: {
      type: Function,
      required: true
    }
  },

  data () {
    return {
      lang: '',
      languages: this.$i18n.localeCodes.map((code) => {
        return {
          title: NATIVE_LANG_BY_LOCALE[code],
          value: code.split('-')[0]
        }
      }),
      title: '',
      productTitle: '',
      description: '',
      h1: '',
      article: '',
      keywords: ''
    }
  },

  computed: {
    locale () {
      return this.$i18n.locale?.split?.('-')[0] || 'en'
    },

    isRu () {
      return this.locale === 'ru'
    },

    seoData () {
      return {
        title: this.title?.length ? this.title : undefined,
        productTitle: this.productTitle?.length ? this.productTitle : undefined,
        description: this.description?.length ? this.description : undefined,
        h1: this.h1?.length ? this.h1 : undefined,
        article: this.article?.length ? this.article : undefined,
        keywords: this.keywords?.length ? this.keywords : undefined
      }
    }
  },

  watch: {
    lang () {
      this.getCurrentSeoData()
    }
  },

  mounted () {
    this.lang = this.locale
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    getCurrentSeoData () {
      const seo = this.category?.seo_data?.[this.lang] || {}
      this.title = seo.title || ''
      this.productTitle = seo.productTitle || ''
      this.description = seo.description || ''
      this.h1 = seo.h1 || ''
      this.article = seo.article || ''
      this.keywords = seo.keywords || ''
    },

    async saveSEOData () {
      try {
        await SeoService.updateMarketplaceCategorySeoData(this.$axios, this.category.id, this.lang, this.seoData)
        this.loadApp()
        this.showModal({
          component: 'ModalMessage',
          data: {
            title: this.$t('success'),
            type: 'success',
            text: this.$t('success')
          }
        })
      } catch {
        this.showModal({
          component: 'ModalMessage',
          data: {
            type: 'error',
            text: this.$t('errorHasOccurred')
          }
        })
      }
    }
  }
}
</script>

<template>
  <div :class="$style.modal">
    <p :class="$style.title">
      {{ $t('editSEO') }}
    </p>

    <BaseSelect
      v-model="lang"
      :class="$style.field"
      :options="languages"
      :with-empty-value="false"
      :placeholder="$t('chooseLang')"
    />

    <FormInput v-model="seoData.title" :class="$style.field" :title="$t('categoryPageTitle')" />
    <FormInput v-model="seoData.h1" :class="$style.field" :title="$t('categoryPageH1')" />
    <FormInput v-model="seoData.description" :class="$style.field" type="textarea" :title="$t('categoryPageDescription')" />
    <FormInput v-model="seoData.keywords" :class="$style.field" type="textarea" :title="$t('categoryPageKeywords')" />
    <FormInput v-model="seoData.article" :class="$style.field" type="textarea" :title="$t('categoryPageArticle')" />
    <FormInput v-model="seoData.productTitle" :class="$style.field" :title="$t('productOfCategoryPageTitle')" />

    <Button is-primary @click="saveSEOData">
      {{ $t("buttonSave") }}
    </Button>
  </div>
</template>

<style>
.modal--editseomodal {
  width: 100%;
  max-width: 600px !important;
}
</style>

<style lang="scss" module>
.modal {
  width: 100%;
  max-width: 600px;
  padding-top: 10px;
}

.title {
  color: $white;
  font-size: 32px;
  line-height: 120%;
  font-weight: 700;
  margin-bottom: 32px;
}

.field {
  margin: 12px 0;
}
</style>
