export const state = () => ({
  platformRemoteFeatures: {}
})

export const mutations = {
  setPlatformRemoteFeatures (state, value) {
    state.platformRemoteFeatures = value
  }
}

export const getters = {
  getPlatformRemoteFeatures: state => state.platformRemoteFeatures
}
