export function getDeviceType () {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  if (/windows phone/i.test(userAgent)) {
    return {
      device: 'windows phone',
      os_version: userAgent.match(/.*Windows [a-zA-Z]* ([0-9]*).*/)[1]
    }
  }

  if (/android/i.test(userAgent)) {
    return {
      device: 'android',
      os_version: userAgent.match(/.*Android ([0-9]{1,}).*/)[1]
    }
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad/.test(userAgent) && !window.MSStream) {
    return {
      device: 'ipad',
      os_version: userAgent.match(/.*OS ([0-9]{1,}).*/)[1]
    }
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPod/.test(userAgent) && !window.MSStream) {
    return {
      device: 'ipod',
      os_version: userAgent.match(/.*OS ([0-9]{1,}).*/)[1]
    }
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPhone/.test(userAgent) && !window.MSStream) {
    return {
      device: 'iphone',
      os_version: userAgent.match(/.*OS ([0-9]{1,}).*/)[1]
    }
  }

  return {
    device: 'desktop',
    os_version: userAgent.match(/.*Windows [a-zA-Z]* ([0-9]*).*/)?.[1] || '10'
  }
}

export function getDeviceTypeForOffers () {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  if (/windows phone/i.test(userAgent)) {
    return 'windows'
  }

  if (/android/i.test(userAgent)) {
    return 'android'
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad/.test(userAgent) && !window.MSStream) {
    return 'apple'
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPod/.test(userAgent) && !window.MSStream) {
    return 'apple'
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPhone/.test(userAgent) && !window.MSStream) {
    return 'ios'
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/Mac OS/.test(userAgent) && !window.MSStream) {
    return 'apple'
  }

  return 'windows'
}
