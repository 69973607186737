export const state = () => ({
  isActive: false,
  isVisible: false,
  component: null,
  componentData: null,
  canClose: true
})

export const actions = {
  showModal (
    { commit },
    { component, data, canClose = true, shouldShow = true }
  ) {
    commit('toggle', true)
    commit('setVisibility', shouldShow)
    commit('setComponent', { component, data, canClose })
  }
}

export const mutations = {
  toggle (state, isActive) {
    document.body.style.overflow = ''

    if (isActive !== undefined) {
      state.isActive = isActive
    } else {
      state.isActive = !state.isActive
    }

    // if (state.isActive) {
    //   document.body.classList.add('overlay')
    // } else {
    //   document.body.classList.remove('overlay')
    // }
  },

  setVisibility (state, isVisible) {
    state.isVisible = isVisible
  },

  setComponent (state, { component, data, canClose }) {
    state.component = component
    state.componentData = data
    state.canClose = canClose
  },

  updateComponentData (state, data) {
    if (data.component !== state.component) {
      return
    }

    Object.keys(data.data).forEach((key) => {
      state.componentData[key] = data.data[key]
    })
  }
}
