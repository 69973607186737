<template lang="pug">
.modal-pending
  h2.modal-pending__title {{ $t('weFindViolation') }}
  p.modal-pending__text(v-html="$t('violationRule')")

  .modal-pending__text.modal-pending__text--bold {{ $t('ensureRisk') }}

  .modal-pending__text {{ $t('canObserve') }}

  .modal-pending__table-container
    .modal-pending__table(:style="{ '--rows': items.length}")
      .modal-pending__first
        span {{ $t('offerwall') }}
        span {{ $t('idName') }}
        span {{ $t('payout') }}
        span {{ $t('verificationIn') }}
      .modal-pending__item(v-for="item in items" :key="item.id")
        span {{ item.title }}
        span
        span {{ payoutAmount(item.amount) }}
        Countdown(:end-time="blockedUntil(item)" :key="item.id + '_' + blockedUntil(item)")
          span(slot="process" slot-scope="{ timeObj }")
            | {{ timeObj.d }} d {{ timeObj.h }} h {{ timeObj.m }} m

</template>

<script>
import { mapMutations } from 'vuex'
import PendingRewarsService from '../../services/PendingRewardsService'

export default {
  data () {
    return {
      items: []
    }
  },

  async mounted () {
    this.items = (await PendingRewarsService.get(this.$axios))?.data?.data?.pending_rewards
  },

  methods: {
    ...mapMutations('modal', ['toggle']),

    closeModal () {
      this.toggle(false)
      this.$router.push(this.localeLocation('/leaderboard'))
    },

    payoutAmount (amount) {
      return (amount / 100).toFixed(2).replace('.00', '')
    },

    blockedUntil (item) {
      if (!item.blocked_until) {
        return null
      }

      const date = item.blocked_until?.replace(' ', 'T') + 'Z'
      console.log('blocked until', date)

      return this.$dateFns.parseISO(date)
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalpending {
  max-width: 350px;

  @media (min-width: $breakpointDesktop) {
    padding: 22px 24px !important;
    max-width: 740px;
  }
}
</style>

<style lang="scss" scoped>
.modal-pending {
  color: $white;

  &__title {
    text-align: left;
    margin-bottom: 32px;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;

    @media (min-width: $breakpointDesktop) {
      font-size: 32px;
      line-height: 36px;
    }
  }

  &__text {
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    &--bold {
      font-weight: 500;
      margin-top: 16px;
    }
  }

  &__table {
    display: grid;
    grid-template-columns: repeat(var(--rows), max-content);
    min-height: 170px;
    overflow-x: auto;
    font-size: 12px;
    line-height: 16px;

    @media (min-width: $breakpointDesktop) {
      grid-template-columns: 1fr;
      min-height: 0;
      font-size: 14px;
      line-height: 18px;
    }

    &-container {
      position: relative;
      width: 100%;
      overflow-x: hidden;
      padding-left: 90px;
      margin-top: 20px;

      @media (min-width: $breakpointDesktop) {
        overflow-x: auto;
        padding-left: 0;
        margin-top: 24px;
      }
    }
  }

  &__first {
    position: absolute;
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    grid-gap: 16px;
    left: 0;
    top: 0;
    width: 90px;
    height: 100%;
    font-weight: 700;
    background: $blue-grey80;
    border-radius: 8px;
    padding: 16px;

    @media (min-width: $breakpointDesktop) {
      position: static;
      grid-template-rows: 1fr;
      grid-template-columns: repeat(4, 1fr);
      width: 100%;
      overflow-x: auto;
      padding: 12px 20px;
    }
  }

  &__item {
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    min-width: 90px;
    height: 100%;
    border-right: 1px solid $blue-grey40;
    grid-gap: 16px;
    padding: 16px;

    @media (min-width: $breakpointDesktop) {
      position: static;
      grid-template-rows: 1fr;
      grid-template-columns: repeat(4, 1fr);
      width: 100%;
      height: auto;
      border-bottom: 1px solid $blue-grey40;
      border-right: none;
      padding: 12px 20px;
    }
  }
}
</style>
