<template lang="pug">
  .copy-input
    .copy-input__title(v-if="title") {{ title }}

    .copy-input__bottom
      .copy-input__box
        img(v-if="icon" :src="require(`~/assets/img/icons/${icon}.svg`)" width="24" alt="icon")
        input.copy-input__input(
          type="text"
          readonly
          :value="isCopied ? $t('isCopied') : text"
          ref="input"
        )

      Button(is-square icon="copy" @click="onCopyClick" :title="$t('copyInput')")

</template>

<script>
export default {
  name: 'CopyInput',

  props: {
    value: {
      type: [String, Number],
      required: true
    },

    icon: {
      type: String,
      default: 'link'
    },

    title: {
      type: String,
      default: ''
    },

    hideHttps: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isCopied: false,
      text: ''
    }
  },

  computed: {
    canCopy () {
      return process.client && document.queryCommandSupported('copy')
    },

    trueValue () {
      return this.hideHttps ? this.value.replace('https://', '') : this.value
    }
  },

  mounted () {
    this.text = this.trueValue
  },

  methods: {
    onCopyClick () {
      if (!this.canCopy || this.isCopied) {
        return
      }

      this.text = this.value

      this.$nextTick(() => {
        this.$refs.input.focus()
        this.$refs.input.select()

        try {
          document.execCommand('copy')

          this.isCopied = true
          setTimeout(() => {
            this.isCopied = false
            this.text = this.trueValue
          }, 5e3)
        } catch (e) {
          console.error(`${new Date().toUTCString()} :: copy input error ::`, e)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.copy-input {
  display: flex;
  flex-direction: column;

  &__input {
    padding: 0;
    margin: 0;
    bottom: 0;
    background: 0;
    border: 0;
    color: #fff;
    width: 100%;
  }

  &__title {
    @include caption-small;

    margin-bottom: 12px;
  }

  &__bottom {
    display: flex;
  }

  &__box {
    display: flex;
    align-items: center;
    background: $blue-grey60;
    border-radius: 8px;
    padding: 0 16px;
    height: 48px;
    max-width: 302px;
    width: 100%;

    @include text-display100;

    color: $white;
    margin-right: 16px;

    @include text-overflow;

    img {
      margin-right: 16px;

      path {
        fill: $grey20;
      }
    }
  }

  button.button--square {
    min-width: 48px;
    min-height: 48px;
  }
}
</style>
