<template lang="pug">
  .modal-steam-connect
    .modal-steam-connect__header
      .modal-steam-connect__images
        .modal-steam-connect__game
          img(:src="game.extra_data.icon")
        img.modal-steam-connect__link(:src="require(`~/assets/img/icons/link-extra.svg`)" alt="link extra")
        img.modal-steam-connect__steam(v-if="game.extra_data.full_title.toUpperCase() !== 'VALORANT'" :src="require(`~/assets/img/socials/steam.svg`)" alt="steam")
        img.modal-steam-connect__steam(v-else :src="require(`~/assets/img/socials/site.svg`)" alt="site")

      .modal-steam-connect__title(v-if="game.extra_data.full_title.toUpperCase() !== 'VALORANT'") {{ $t('steamAccountConnecting') }}
      .modal-steam-connect__title(v-if="game.extra_data.full_title.toUpperCase() === 'VALORANT'") {{ $t('riotAccountConnecting') }}
      .modal-steam-connect__subtitle {{ game.extra_data.full_title }}

    .modal-steam-connect__body
      .modal-steam-connect__section(v-if="game.extra_data.full_title.toUpperCase() !== 'VALORANT'")
        img.modal-steam-connect__section-icon(:src="require(`~/assets/img/icons/lock-open.svg`)" alt="lock open")

        .modal-steam-connect__section-text
          .modal-steam-connect__section-title {{ $t('steamAccountOpen') }}

          .modal-steam-connect__section-description
            .modal-steam-connect__section-item {{ $t('steamAccountDesc') }}

      .modal-steam-connect__section
        img.modal-steam-connect__section-icon(:src="require(`~/assets/img/icons/shield.svg`)" alt="shield")

        .modal-steam-connect__section-text
          .modal-steam-connect__section-title {{ $t('steamAccountLinking', { acc: game.extra_data.full_title }) }}

          .modal-steam-connect__section-description(v-if="game.extra_data.full_title.toUpperCase() !== 'VALORANT'")
            .modal-steam-connect__section-item {{ $t('steamAccountLinking1') }}
            .modal-steam-connect__section-item {{ $t('steamAccountLinking2') }}
          .modal-steam-connect__section-description(v-else)
            .modal-steam-connect__section-item {{ $t('riotAccountLinking1') }}
            .modal-steam-connect__section-item {{ $t('riotAccountLinking2') }}
    .modal__footer
      Button(is-primary @click="onButtonClick") {{ $t('steamAccountConnect') }}

</template>

<script>
export default {
  name: 'ModalSteamConnect',

  props: {
    game: {
      type: Object,
      required: true
    }
  },

  methods: {
    async onButtonClick () {
      if (this.game.extra_data.full_title.toUpperCase() !== 'VALORANT') {
        window.open(this.$config.VUE_APP_API_DOMAIN + '/steam', '_self')
      } else {
        const { data } = await this.$axios.get('/riot/')

        if (data.data) {
          window.open(data.data, '_self')
        }
      }
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalsteamconnect {
  max-width: 736px;
  padding: 40px 24px 0;
}

.modal-steam-connect {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__images {
    display: flex;
    align-items: center;
  }

  &__game {
    width: 104px;
    height: 104px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $blue-grey60;

    img {
      max-width: 72px;
      max-height: 72px;
      width: 100%;
      height: 100%;
    }
  }

  &__link {
    width: 24px;
    height: 24px;
    margin: 0 16px;
  }

  &__steam {
    width: 104px;
    height: 104px;

    path {
      fill: $primary90;
    }
  }

  &__title {
    @include heading2;

    color: $white;
    margin-top: 16px;
  }

  &__subtitle {
    @include caption;

    margin-top: 8px;
    color: $primary60;
  }

  &__body {
    margin-top: 24px;
    padding-bottom: 40px;
  }

  &__section {
    background-color: $blue-grey60;
    padding: 24px;
    border-radius: 8px;
    display: flex;

    &:not(:first-child) {
      margin-top: 12px;
    }

    &-icon {
      width: 48px;
      height: 48px;
      min-width: 48px;
      min-height: 48px;
      margin-right: 17px;

      path {
        fill: $white;
      }
    }

    &-title {
      @include heading3;

      color: $white;
      margin-bottom: 16px;
    }

    &-item {
      color: $blue-grey05;

      &:not(:first-child) {
        margin-top: 12px;
      }
    }
  }
}
</style>
