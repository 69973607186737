<template lang="pug">
.tab-overview
  div
    .tab-overview__title {{ gameName }}
    .tab-overview__text {{ gameText }}

  div
    .tab-overview__title(v-t="'tournamentPageTabOverViewTournamentDetails'")

    .tab-overview__details
      .tab-overview__details-pair
        .tab-overview__details-pair-key(v-t="'tournamentPageTabOverViewTournamentDetailsPair1Key'")
        .tab-overview__details-pair-value {{ gameName }}
      .tab-overview__details-pair
        .tab-overview__details-pair-key(v-t="'tournamentPageTabOverViewTournamentDetailsPair2Key'")
        .tab-overview__details-pair-value {{ tournamentStartText }}
      .tab-overview__details-pair
        .tab-overview__details-pair-key(v-t="'tournamentPageTabOverViewTournamentDetailsPair3Key'")
        .tab-overview__details-pair-value {{ tournamentParticipantsText }}
      .tab-overview__details-pair
        .tab-overview__details-pair-key(v-t="'tournamentPageTabOverViewTournamentDetailsPair4Key'")
        .tab-overview__details-pair-value {{ tournamentGameMode }}

  div
    .tab-overview__title(v-t="'tournamentPageTabOverViewHowToPlay'")

    .tab-overview__points
      .tab-overview__point(v-for="point in howToPlayPoints" :key="point.id")
        .tab-overview__point-block
          .tab-overview__point-block-number {{ point.id }}
          .tab-overview__point-block-text(v-html="$t(point.text)")
        .tab-overview__point-info(v-t="point.info")

  div
    .tab-overview__title(v-t="'tournamentPageTabWinnersTitle'")

    TournamentWinners.tab-overview__winners(:tournament="tournament")

  div.tab-overview__nominations(v-if="tournamentHasNominations")
    .tab-overview__title(v-t="'tournamentPageTabOverviewNominationsTitle'")

    TournamentNominations(:tournament="tournament")

  div.tab-overview__rules-block
    .tab-overview__title(v-t="'tournamentPageTabOverviewPlatformRules'")

    .tab-overview__rules(v-if="rules" v-html="rules")
</template>

<script>
import GamesService from '@/services/GamesService'
import { getDateText } from '@/utils/utils'
import AvatarWithStars from '@/components/AvatarWithStars'
import TournamentWinners from './TournamentWinners'
import TournamentNominations from './TournamentNominations'

export default {
  name: 'TabOverview',

  components: { TournamentNominations, AvatarWithStars, TournamentWinners },

  props: {
    tournament: {
      type: Object,
      required: true
    },

    game: {
      type: Object,
      required: true
    }
  },

  computed: {
    howToPlayPoints () {
      return [
        {
          id: 1,
          text: 'howToPlayPoints1'
        },
        {
          id: 2,
          text: 'howToPlayPoints2'
        },
        {
          id: 3,
          text: 'howToPlayPoints3'
        },
        {
          id: 4,
          text: 'howToPlayPoints4'
        },
        {
          id: 5,
          text: 'howToPlayPoints5'
          // info: 'howToPlayPoints5Info'
        },
        {
          id: 6,
          text: 'howToPlayPoints6'
          // info: 'howToPlayPoints6Info'
        }
      ]
    },

    nominations () {
      return this.tournament?.prize_settings?.nominations?.map((nomination) => {
        const amount = nomination.currency === 'money' ? nomination.amount / 100 : nomination.amount

        return { ...nomination, amount }
      })
    },

    tournamentHasNominations () {
      return this.nominations && this.nominations.length
    },

    gameName () {
      return this.game?.extra_data?.full_title
    },

    gameAbout () {
      return GamesService.getGameAbout(this.game?.name)
    },

    gameText () {
      const locale = this.$i18n.locale?.split?.('-')?.[0] || 'en'
      return this.gameAbout?.[locale]?.text || ''
    },

    tournamentStart () {
      return this.tournament?.date?.replace(' ', 'T') + 'Z'
    },

    tournamentStartText () {
      if (this.tournamentStart) {
        return getDateText.call(this, this.tournamentStart)
      }
      return '...'
    },

    tournamentParticipantsText () {
      return `${this.tournament?.players_count} / ${this.tournament?.tournament_player_settings?.max_players_count}`
    },

    tournamentGameMode () {
      return this.tournament?.extra_data?.config?.mode
    },

    tournamentPrizes () {
      return this.tournament?.prizes?.places
    },

    prizeCurrency () {
      return this.tournament?.prize_settings?.prize_currency
    },

    isCoinTournament () {
      return this.prizeCurrency === 'coins'
    },

    rules () {
      let rules
      const locale = this.$i18n.locale?.split?.('-')[0] || 'en'
      if (['en', 'ru'].includes(locale)) {
        rules = this.tournament?.rules_array.find(rule => rule.language === locale)?.text
      }

      if (!rules || rules === '') {
        try {
          const rulesForGames = {
            'brawl-stars': 'tournamentsRulesBrawlStars',
            'clash-royale': 'tournamentsRulesClashRoyale',
            lichess: 'tournamentsRulesLichess',
            'pubg-mobile': 'tournamentsRulesPubgMobile'
          }
          rules = this.$t(rulesForGames[this.game?.name])
        } catch (e) {
          console.error(
            `${new Date().toUTCString()} :: tournament (game ${
              this.game.name
            }) rules error ::`,
            e
          )
        }
      }

      return rules
    }
  },

  methods: {
    placeAvatar (place) {
      const player = this.tournament?.players?.find(player => player.place === place)
      if (player) {
        return player.avatar
      }
    },

    placePlayerId (place) {
      const player = this.tournament?.players?.find(player => player.place === place)
      if (player) {
        return player.id
      }
    },

    placePlayerName (place) {
      const player = this.tournament?.players?.find(player => player.place === place)
      if (player) {
        return player.full_name
      }
    },

    placePrizeWithSubscription (place) {
      return this.tournament?.prizes?.places?.[place]
    },

    placePrizeWithSubscriptionText (place) {
      if (this.isCoinTournament) {
        return this.placePrizeWithSubscription(place)
      }
      return (this.placePrizeWithSubscription(place) / 100).toFixed(2)
    },

    placePrizeWithoutSubscription (place) {
      if (this.isCoinTournament) {
        return this.placePrizeWithSubscription(place)
      }

      return (this.placePrizeWithSubscription(place) * 12).toFixed(0)
    },

    getNominationColor (nomination) {
      return nomination?.icon_color?.replace('#', '') || ''
    },

    getNominationStyle (nomination) {
      return [
        'tab-overview__nomination-header-icon',
        `tab-overview__nomination-header-icon--${this.getNominationColor(nomination)}`
      ]
    },

    nominationHasIcon (nomination) {
      return nomination?.icon_name
    },

    nominationIcon (nomination) {
      return require(`~/components/TournamentRewards/icons/${nomination?.icon_name}.svg`)
    },

    nominationAvatar (nomination) {
      return nomination?.winner?.avatar
    },

    nominationUserId (nomination) {
      return nomination?.winner?.id
    },

    nominationWinnerName (nomination) {
      return nomination?.winner?.full_name
    },

    nominationCurrency (nomination) {
      return nomination?.currency
    },

    nominationPrize (nomination) {
      return nomination?.amount
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-overview {
  @media (min-width: $breakpointDesktopWide) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;
  }

  &__title {
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: $white;
  }

  &__text {
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $white;
  }

  &__details {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    margin-bottom: 16px;
    padding: 16px 12px;
    background: $blue-grey70;
    border-radius: 8px;

    @media (min-width: $breakpointTablet) {
      padding: 20px 35px;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 54px;
    }

    @media (min-width: $breakpointDesktopWide) {
      margin-bottom: 0;
    }

    &-pair {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (min-width: $breakpointTablet) {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 4px;
      }

      &-key {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $blue-grey10;
      }

      &-value {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $white;
      }
    }
  }

  &__points {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;
    margin-bottom: 20px;

    @media (min-width: $breakpointDesktopWide) {
      margin-bottom: 0;
    }
  }

  &__point {
    &-block {
      display: grid;
      grid-template-columns: max-content 1fr;
      grid-gap: 12px;
      align-items: start;
      padding: 16px;
      background: $blue-grey70;
      border-radius: 8px;

      &-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: $white;
      }

      &-number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        background: rgba($white, 0.1);
        color: $white;
      }
    }

    &-info {
      margin-top: 12px;
      padding: 0 16px;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: $white;
    }
  }

  &__winners {
    width: 100%;
    overflow-x: auto;
    padding-bottom: 15px;
    margin-bottom: 10px;

    @media (min-width: $breakpointDesktopWide) {
      margin-bottom: 0;
    }

    &-header {
      display: grid;
      grid-template-columns: 40px 1fr 94px;
      grid-gap: 12px;
      align-items: center;
      justify-content: center;
      padding: 8px 4px;
      margin-bottom: 5px;
      background: $blue-grey70;
      border-radius: 8px;

      @media (min-width: $breakpointTablet) {
        grid-template-columns: 40px 1fr 130px;
      }

      &-title {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: $blue-grey05;
        text-align: center;
      }
    }

    &-table {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 4px;
    }

    &-line {
      display: grid;
      grid-gap: 12px;
      grid-template-columns: 40px 1fr 94px;
      align-items: center;
      padding: 12px 4px;
      background: $blue-grey60;
      border-radius: 8px;

      @media (min-width: $breakpointTablet) {
        grid-template-columns: 40px 1fr 130px;
      }

      &-place {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: rgba($white, 0.9);
        border-right: 1px solid $blue-grey30;

        &-number {
          margin-top: 4px;
        }

        &-icon {
          width: 16px;
        }
      }

      &-player {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &-name {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: $white;
          margin-left: 9px;
          max-width: 130px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &-prize {
        text-align: center;

        &-sum {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: $white;
        }
      }
    }
  }

  &__nominations {
    margin-bottom: 24px;

    @media (min-width: $breakpointDesktopWide) {
      grid-column: 1 / 3;
    }
  }

  &__rules {
    color: $white;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    &-block {
      @media (min-width: $breakpointDesktopWide) {
        grid-column: 1 / 3;
      }
    }
  }
}
</style>
