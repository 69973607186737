<template lang="pug">
.modal-progress-info
  h2.modal-progress-info__title {{ $t('yourProgressOnVVV') }}
  p.modal-progress-info__text {{ $t('fillTheScale') }}

  h3.modal-progress-info__subtitle {{ $t('howToComplete') }}
  p.modal-progress-info__text {{ $t('hereIsTheProgress') }}

  .modal-progress-info__container
    .modal-progress-info__example(v-html="$t('beforePrize')")
    img(:src="require('~/assets/img/backgrounds/progress_bar.svg')" alt="progress bar")

  .modal-progress-info__list(v-html="$t('chooseAway')")

  p.modal-progress-info__starText {{ $t('getOneStar') }}

  Button.modal-progress-info__button(is-primary @click="closeModal") {{ $t('iUnderstand') }}
</template>

<script>
import { mapMutations } from 'vuex'

export default {

  methods: {
    ...mapMutations('modal', ['toggle']),

    closeModal () {
      this.toggle(false)
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalprogressinfo {
  max-width: 420px;

  @media (min-width: $breakpointTablet) {
    padding: 40px 51px !important;
    max-width: 520px;
  }
}
</style>

<style lang="scss" scoped>
.modal-progress-info {
  color: $white;
  text-align: center;

  &__title {
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }

  &__text {
    color: #90A4AE;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  &__subtitle {
    margin-top: 24px;
    margin-bottom: 4px;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }

  &__container {
    padding: 20px 32px;
    margin: 16px auto 24px;
    border: 1px solid #0076FE;
    border-radius: 16px;
  }

  &__example {
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }

  &__starText {
    font-size: 18px;
    margin-top: 24px;
  }

  &__list {
    font-weight: 700;
    font-size: 16px;
  }

  &__button {
    margin-top: 32px;
    width: 100% !important;
  }
}
</style>
