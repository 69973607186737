<template lang="pug">
  .card-form
    .card-form__field.first
      span.card-form__field-label {{ $t('cardNumber') }}
      FormInput.card-form__field-input(
        v-model="pan"
        mask="#### #### #### ####"
        placeholder="1234 1234 1234 1234"
        maxlength="19"
      )

    .card-form__field.second
      span.card-form__field-label {{ $t('cardDate') }}
      FormInput.card-form__field-input(
        v-model="expiration"
        mask="##/##"
        placeholder="__/__"
      )

    .card-form__field.third
      span.card-form__field-label {{ $t('cardHolder') }}
      FormInput.card-form__field-input(
        v-model="recipient"
        :placeholder="$t('upperCaseName')"
        @input="onRecipientInput"
      )

</template>

<script>
import FormInput from '~/components/FormInput'

export default {
  name: 'CardForm',

  components: { FormInput },

  data () {
    return {
      pan: '',
      expiration: '',
      recipient: ''
    }
  },

  watch: {
    pan (value) {
      this.$emit('setCardPan', value)
    },

    expiration (value, oldValue) {
      // dont react if value is "xx/" (because of mask)
      if (value.length === 3) { return }
      // remove "/" from oldValue if value is "xx/y"
      if (value.length === 4) { oldValue = oldValue.slice(0, -1) }

      const isDateCorrect = this.checkExpirationDate(value)

      if (isDateCorrect) {
        this.$emit('setCardExpiration', value)
      } else {
        this.expiration = oldValue
      }
    },

    recipient (value) {
      this.$emit('setCardRecipient', value)
    }
  },

  methods: {
    onRecipientInput () {
      this.recipient = this.recipient?.toUpperCase()
    },

    checkExpirationDate (str) {
      const symbols = str.split('')
      const index = str.length - 1
      const value = symbols[index]
      const firstSymbol = symbols[0]
      const thirdSymbol = symbols[2]

      switch (index) {
        case 0:
          return ['0', '1'].includes(value)
        case 1:
          return firstSymbol === '1' ? ['0', '1', '2'].includes(value) : true
        case 3:
          return ['2', '3', '4', '5'].includes(value)
        case 4:
          return thirdSymbol === '2'
            ? ['2', '3', '4', '5', '6', '7', '8', '9'].includes(value)
            : true
        default:
          return true
      }
    }
  }
}
</script>

<style lang="scss">
.card-form {
  padding: 16px 24px;
  margin-right: 4px;
  background-color: $blue-grey60;

  &__field {
    display: flex;
    flex-direction: column;
    padding-bottom: 8px;

    &-label {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: $blue-grey05;
      margin-bottom: 8px;
    }
  }

  .form-input {
    min-width: 65%;

    &__label {
      background-color: $blue-grey70;
    }

    @media (max-width: $breakpointMobile) {
      width: 100%;
    }
  }

  @media (min-width: $breakpointTablet) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px 10px;
    grid-template-areas:
      "First First"
      "Second Third";
  }
}

.first {
  grid-area: First;
}

.second {
  grid-area: Second;
}

.third {
  grid-area: Third;
}
</style>
