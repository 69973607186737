<template lang="pug">
  .header-lang
    .header-lang__title
      img(
        v-if="user"
        :src="require(`~/assets/img/icons/angle-left.svg`)"
        alt="back"
        @click="$emit('back')"
      )
      | {{ $t('chooseLang') }}

    .header-lang__item(
      v-for="(item, i) in items"
      :key="i"
      :class="{'--active': item.locale === $i18n.locale}"
      @click="onAction(item)"
    )
      img(:src="require(`~/assets/img/country/${item.icon}.svg`)" :alt="item.title" width="20" height="20")
      .header-lang__text {{ item.title }}

</template>

<script>
import { mapState } from 'vuex'
import { NATIVE_LANG_BY_LOCALE } from '~/utils/lang'

export default {
  name: 'HeaderLang',

  data () {
    return {
      items: this.$i18n.localeCodes.map((code) => {
        return {
          title: NATIVE_LANG_BY_LOCALE[code],
          icon: code.split('-')[0],
          locale: code
        }
      })
    }
  },

  computed: {
    ...mapState('user', ['user'])
  },

  mounted () {
    document.addEventListener('click', this.onClickOutside)
  },

  beforeDestroy () {
    document.removeEventListener('click', this.onClickOutside)
  },

  methods: {
    onAction (item) {
      this.$router.push(this.switchLocalePath(item.locale))
      this.$emit('close')
    },
    onClickOutside ({ target }) {
      if (!this.$el.contains(target)) {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss">
.header-lang {
  box-sizing: border-box;
  position: absolute;
  top: 64px;
  right: 16px;
  margin-top: 8px;
  border: 1px solid $blue-grey40;
  border-radius: 8px;
  background: $blue-grey70;
  padding: 8px;
  width: 280px;
  color: #fff;
  overflow-y: auto;
  max-height: calc(100vh - 148px);
  transition: top 0.2s $easing;

  .--mini & {
    top: 48px;
  }

  &__title {
    @include heading5;

    height: 40px;
    padding-left: 10px;
    display: flex;
    align-items: center;

    img {
      path {
        fill: #fff;
      }

      width: 20px;
      height: 20px;
      margin-right: 8px;
      cursor: pointer;
    }
  }

  &__item {
    @include hint-small;

    display: flex;
    font-weight: 600;
    font-size: 15px;
    align-items: center;
    padding-left: 10px;
    height: 40px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s $easing;

    &:hover {
      background: $blue-grey60;
    }

    &.--active {
      color: $primary60;
    }

    img {
      margin-right: 8px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
