<template lang="pug">
  .modal-eur-or-coins
    .modal__header {{ $t('choosePayMethod') }}

    .modal-eur-or-coins__buttons
      Button.modal-eur-or-coins__button(is-secondary @click="onAction('coins')")
        Currency(type="coins")
          span.modal-eur-or-coins__amount 10 000 000
      Button.modal-eur-or-coins__button(is-secondary @click="onAction('money')")
        Currency(type="money")
          span.modal-eur-or-coins__amount 750

    .modal__footer
      Button(is-primary) {{ $t('buttonPay') }}

</template>

<script>
export default {
  name: 'ModalEurOrCoins',

  methods: {
    closeModal () {
      this.$store.commit('modal/toggle')
    },

    onAction (method) {
      this.$router.push(this.localeLocation(`/buy-device?payment=${method}`))
      this.closeModal()
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modaleurorcoins {
  padding-bottom: 0;
  max-width: 500px;

  .modal__footer {
    flex-direction: column;
    align-items: flex-end;

    @media (min-width: $breakpointMobile) {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap-reverse;
    }
  }

  .button {
    width: 100%;

    @media (min-width: $breakpointMobile) {
      margin: 4px 0;
    }
  }
}

.modal-eur-or-coins{
  &__buttons {
    padding: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;
  }

  &__button {
    padding: 16px !important;
  }

  &__amount {
    padding-left: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  }
}
</style>
