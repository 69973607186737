import { getDeviceType } from '~/utils/get-device-type'

class OfferwallService {
  constructor () {
    if (OfferwallService.instance) {
      return OfferwallService.instance
    }

    this.ayetStudiosData = {
      slotId: 9566,
      url: 'https://www.ayetstudios.com/offers/offerwall_api/'
    }

    this.lootablyData = {
      url: 'https://api.lootably.com/api/v1/offers/get',
      apiKey: 'ypclvpizhycktk66p8ncumbedcw3d5xff346wcwtz20p',
      placementID: 'cl3if1hfx0217010jfxru8to2'
    }

    this.mobSuccessData = {
      pubid: '3014'
    }

    this.adToGameData = {
      url: 'https://api.eflow.team/v1/affiliates/offersrunnable?page=1&page_size=100',
      apiKey: 'OIcLRJL5SsCx3T6Y6tTonQ'
    }

    this.adGateMediaData = {
      url: '/offerwall/adgate', // 'https://api.adgatemedia.com/v3/offers',
      aff: '104399',
      apiKey: 'fba56ba82022fbfc8f93eb189c736e31',
      wallCode: 'oK-Uqg'
    }

    OfferwallService.instance = this

    return this
  }

  pwaTaskRequest (engine) {
    return engine.post('/user/account/pwa-status', { status: true })
  }

  async getCountry (engine) {
    const countryData = await engine.get(`https://api.country.is/?timestamp=${new Date().getTime()}`)
    return countryData?.data?.country
  }

  getFarlyIframeUrl (engine, userId) {
    // const countryData = await engine.get(`https://api.country.is/?timestamp=${new Date().getTime()}`)
    const params = {
      userid: userId || `3_${Date.now()}`,
      device: getDeviceType().device,
      os_version: getDeviceType().os_version
      // country: countryData?.data?.country
    }
    return engine.get('/offerwall/farly', { params })
  }

  async loadOffers (engine, page, size, countryCode, device, category, sort, searchText, source) {
    let country = countryCode
    if (country === '') {
      const countryData = await engine.get(`https://api.country.is/?timestamp=${new Date().getTime()}`)
      country = countryData?.data?.country
    }
    const search = searchText === '' ? undefined : searchText
    const result = await engine.get('/offer', { params: { country, page, size, device, category, sort, search, source } })

    return result.data.data
  }

  async loadFeaturedOffers (engine, countryCode, device) {
    let country = countryCode
    if (country === '') {
      const countryData = await engine.get(`https://api.country.is/?timestamp=${new Date().getTime()}`)
      country = countryData?.data?.country
    }

    const result = await engine.get('/offer/featured', { params: { country, device } })

    return result.data.data
  }

  async loadBestOffers (engine, countryCode, device, page = 1, size = 4) {
    let country = countryCode
    if (country === '') {
      const countryData = await engine.get(`https://api.country.is/?timestamp=${new Date().getTime()}`)
      country = countryData?.data?.country
    }

    const result = await engine.get('/offer/best', { params: { country, device, page, size } })

    return result.data?.data
  }

  async loadOffersForMyVVV (engine, countryCode, device) {
    let country = countryCode
    if (country === '') {
      const countryData = await engine.get(`https://api.country.is/?timestamp=${new Date().getTime()}`)
      country = countryData?.data?.country
    }

    const result = await engine.get('/offer/myvvv', { params: { country, device } })

    return result.data.data
  }

  async loadOneOffer (engine, id) {
    const result = await engine.get(`/offer/${id}`)
    console.log('offer::::::', result)

    return result.data.data
  }

  async subscribeToNewOffers (engine, email) {
    return await engine.post('/offer/subscribe', { email })
  }

  async checkStatus (engine, offerwall, offerId) {
    // console.log(offerwall)
    // if (offerwall === 'Offer Toro') {
    //   const html = await engine.get('https://dev.gaming-goods.com/offertoro-status/?pub_id=30057&user_id=560969&app_id=14417&showhtml=yes_please')
    //   const regex = `.*openMCRModal\\('${offerId}','1\\.5EUR','0','(.*)','1','0',''\\).*`
    //   const r = new RegExp(regex)
    //   const result = html.data.replace(/ /g, '').replace(/\n/g, '').replace(/\r/g, '').match(r)[1]
    //   console.log('result:', regex, result)
    //   return result
    // }
  }

  async loadOfferwalls (engine, clickId, partner = 'ad-gate-media') {
    let params
    let countryData

    switch (partner) {
      case 'ayet':
        params = {
          external_identifier: clickId,
          offer_sorting: 'payout'
        }
        return engine.get(`${this.ayetStudiosData.url}/${this.ayetStudiosData.slotId}`, { params })
      case 'lootably':
        return engine.get('/offerwall/lootably')
      case 'offer-toro':
        countryData = await engine.get(`https://api.country.is/?timestamp=${new Date().getTime()}`)
        params = {
          country: countryData?.data?.country
        }
        return engine.get('/offerwall/offertoro', { params })
      case 'farly':
        countryData = await engine.get(`https://api.country.is/?timestamp=${new Date().getTime()}`)
        params = {
          userid: clickId || 3,
          device: getDeviceType().device,
          os_version: getDeviceType().os_version,
          country: countryData?.data?.country
        }
        return engine.get('/offerwall/mobsuccess', { params })
      case 'ad-to-game':
        params = {
          filter: ''
        }
        return engine.get(this.adToGameData.url, { headers: { 'x-eflow-api-key': this.adToGameData.apiKey } })
      case 'ad-gate-media':
        countryData = await engine.get(`https://api.country.is/?timestamp=${new Date().getTime()}`)
        params = {
          aff: this.adGateMediaData.aff,
          api_key: this.adGateMediaData.apiKey,
          wall_code: this.adGateMediaData.wallCode,
          limit: 8,
          useMobileRank: true,
          countries: [countryData?.data?.country]
        }
        return engine.get(this.adGateMediaData.url, { params })
      case 'ovalio':
        return engine.get(`/offerwall/${partner}?&page=1&limit=100`)
      default:
        return engine.get(`/offerwall/${partner}`)
    }
  }

  getSurveysGroup (userId, surveyId, isAdmin = false) {
    const surveys = [
      {
        id: 1004,
        title: 'InBrain Surveys',
        name: 'in-brain-surveys',
        subtitle: '',
        icon: 'https://cdn.gaming-goods.com/images/other/surveys/InBrain.png',
        isOfferWall: true,
        offerWallLink: () =>
          `https://www.surveyb.in/configuration?params=aHJiazZERkdZdDFPWHZvVVFZZkVLek1xWWRsYVVCVHRXREx5andVbnZ2ZkdnbElDeVlCc1kzL01sOXozeGJqeVpRWWN0M0gyU1AwQlJ6cUg3MFF6cHJma252MEpFT200WGhyMzlkcXdNUktqN3VnS0Ura2tScEJPRnMzeE5YQXFzd245UC9yejFEUTdyV0REczd6YjZSSG50MnhMS2hzZ0xDa20zSlQxd2tyQ2tQdVNWYlI4NWJuS0hZRnJEbnNOMUZBYUE2WjV4UFJTMWVkUlprR1V0QT09&app_uid=${userId}`
      },
      {
        id: 1001,
        title: 'Pollfish',
        name: 'pollfish',
        subtitle: '',
        icon: 'https://cdn.gaming-goods.com/images/other/surveys/Pollfish.png',
        isOfferWall: true,
        mode: 'sdk',
        offerWallLink: () => ''
      }
      // {
      //   id: 1002,
      //   title: 'CPX surveys',
      //   name: 'cpx-surveys',
      //   subtitle: '',
      //   icon: 'https://cdn.gaming-goods.com/images/other/surveys/CPX.png',
      //   isOfferWall: true,
      //   offerWallLink: () =>
      //     `https://offers.cpx-research.com/index.php?app_id=14008&ext_user_id=${userId}`
      // }
      // {
      //   id: 1005,
      //   title: 'TapResearch',
      //   subtitle: '',
      //   icon: 'https://cdn.gaming-goods.com/images/other/surveys/TapResearch.png',
      //   isOfferWall: true,
      //   offerWallLink: () =>
      //     `https://wall.lootably.com/?placementID=cl3if1hfx0217010jfxru8to2&sid=${userId}`
      // },
      // {
      //   id: 1006,
      //   title: 'TheoremReach',
      //   subtitle: '',
      //   icon: 'https://cdn.gaming-goods.com/images/other/surveys/TheoremReach.png',
      //   isOfferWall: true,
      //   offerWallLink: () =>
      //     `https://theoremreach.com/respondent_entry/direct?api_key=a9d9e33fed8e8469ad16c11fc24d&user_id=${userId}`
      // }
    ]

    if (surveyId) {
      return surveys.filter(item => isAdmin || !item.adminOnly).find(item => item.id === surveyId)
    }

    return surveys.filter(item => isAdmin || !item.adminOnly)
  }

  getOfferwallGroup (engine, userId, offerwallId, isAdmin = false) {
    let farlyUrl

    const offerwalls = [
      {
        id: 7,
        title: 'Farly',
        name: 'farly',
        subtitle: 'upTo50',
        icon: 'https://cdn.gaming-goods.com/images/other/missions/Farly.png',
        iconShort: 'https://cdn.gaming-goods.com/images/stats/offerwalls/MOBSUCCESS.webp',
        isOfferWall: true,
        mode: 'async-iframe',
        // adminOnly: true,
        offerWallLink: () => {
          return farlyUrl
        },
        asyncOfferWallLink: async () => {
          return (await this.getFarlyIframeUrl(engine, userId))?.data?.data
        },
        desktopWallLink: async (engine, clickId) => {
          return (await this.loadOfferwalls(engine, clickId, 'farly'))?.data?.data
        },
        offerwallDataFunc: (engine, clickId) => this.loadOfferwalls(engine, clickId, 'farly')
      },
      {
        id: 4,
        title: 'Torox',
        name: 'torox',
        subtitle: 'upTo50',
        icon: 'https://cdn.gaming-goods.com/images/other/missions/torox.png',
        iconShort: 'https://cdn.gaming-goods.com/images/stats/offerwalls/OFFERTORO.webp',
        isOfferWall: true,
        mode: isAdmin ? 'api' : 'iframe',
        offerWallLink: () =>
          `https://www.offertoro.com/ifr/show/30057/${userId || 3}/14417`,
        offerwallDataFunc: (engine, clickId) => this.loadOfferwalls(engine, clickId, 'offer-toro')
      },
      {
        id: 11,
        title: 'Notik',
        name: 'notik',
        subtitle: 'upTo50',
        icon: 'https://cdn.gaming-goods.com/images/other/missions/notik.png',
        iconShort: 'https://cdn.gaming-goods.com/images/stats/offerwalls/NOTIK.webp',
        isOfferWall: true,
        // adminOnly: true,
        mode: 'iframe',
        offerWallLink: () => `https://notik.me/coins?api_key=KyVcVyyLjJtpGooB6FuFj74lTSadnM2q&pub_id=3vdk&user_id=${userId || 3}`
      },
      {
        id: 40,
        title: 'HangMyAds',
        name: 'hang-my-ads',
        subtitle: 'upTo50',
        icon: 'https://cdn.gaming-goods.com/images/other/missions/hang-my-ads.webp',
        iconShort: 'https://cdn.gaming-goods.com/images/other/missions/hang-my-ads.webp',
        isOfferWall: true,
        // adminOnly: true,
        mode: 'iframe',
        offerWallLink: () => `https://offerwall.hangmyads.com/offerwall.php?pubid=6430&subid=${userId || 3}`
      },
      {
        id: 1003,
        title: 'BitLabs',
        name: 'bit-labs',
        subtitle: '',
        icon: 'https://cdn.gaming-goods.com/images/other/surveys/BitLabs.png',
        isOfferWall: true,
        offerWallLink: () =>
          `https://web.bitlabs.ai/?uid=${userId}&token=8e347419-5fed-427c-8a1d-c155f676c121`
      },
      {
        id: 6,
        title: 'Monlix',
        name: 'monlix',
        subtitle: 'upTo50',
        icon: 'https://cdn.gaming-goods.com/images/other/missions/moonlix.png',
        iconShort: 'https://cdn.gaming-goods.com/images/other/missions/moonlix.png',
        isOfferWall: true,
        offerWallLink: () =>
          `https://offers.monlix.com/?appid=3543&userid=${userId || 3}`
      },
      {
        id: 2,
        title: 'AyetStudios',
        name: 'ayet-studios',
        subtitle: 'upTo50',
        icon: 'https://cdn.gaming-goods.com/images/other/missions/aye.png',
        iconShort: 'https://cdn.gaming-goods.com/images/stats/offerwalls/AYET.webp',
        isOfferWall: true,
        mode: isAdmin ? 'api' : 'iframe',
        offerWallLink: () =>
          `https://www.ayetstudios.com/offers/web_offerwall/8087?external_identifier=${userId || 3}`,
        offerwallDataFunc: (engine, clickId) => this.loadOfferwalls(engine, clickId, 'ayet')
      },
      {
        id: 3,
        title: 'Lootably',
        name: 'lootably',
        subtitle: 'upTo50',
        icon: 'https://cdn.gaming-goods.com/images/other/missions/Lootably.png',
        iconShort: 'https://cdn.gaming-goods.com/images/stats/offerwalls/LOOTABLY.webp',
        isOfferWall: true,
        mode: isAdmin ? 'api' : 'iframe',
        offerWallLink: () =>
          `https://wall.lootably.com/?placementID=cl3if1hfx0217010jfxru8to2&sid=${userId || 3}`,
        offerwallDataFunc: (engine, clickId) => this.loadOfferwalls(engine, clickId, 'lootably')
      },
      {
        id: 9,
        title: 'AdGateMedia',
        name: 'ad-gate-media',
        subtitle: 'upTo50',
        icon: 'https://cdn.gaming-goods.com/images/other/missions/adGateMedia.png',
        iconShort: 'https://cdn.gaming-goods.com/images/stats/offerwalls/ADGATE.webp',
        isOfferWall: true,
        mode: 'iframe',
        offerWallLink: () => `https://wall.adgaterewards.com/${this.adGateMediaData.wallCode}/${userId || 3}`,
        offerwallDataFunc: (engine, clickId) => this.loadOfferwalls(engine, clickId, 'ad-gate-media')
      },
      // {
      //   id: 21,
      //   title: 'MyChips',
      //   name: 'mychips',
      //   subtitle: 'upTo50',
      //   icon: 'https://cdn.gaming-goods.com/images/other/missions/MyChips.png',
      //   iconShort: 'https://cdn.gaming-goods.com/images/stats/offerwalls/MYCHIPS.png',
      //   isOfferWall: true,
      //   mode: 'iframe',
      //   // adminOnly: true,
      //   offerWallLink: () =>
      //     `https://trk301.com?cid=2600771&pid=2691&adunit_id=567f14cd-5ed0-4c3a-815e-296a60a3e131&gender=&age=&user_id=${userId || 3}`
      // },
      // {
      //  id: 10,
      //  title: 'OfferDaddy',
      //  name: 'offer-daddy',
      //  subtitle: '',
      //  icon: 'https://cdn.gaming-goods.com/images/other/surveys/OfferDaddy.png',
      //  isOfferWall: true,
      //  offerWallLink: () => `https://www.offerdaddy.com/wall/132786/${userId || 3}/`
      // },
      // {
      //   id: 5,
      //   title: 'Timewall',
      //   name: 'timewall',
      //   subtitle: 'upTo50',
      //   icon: 'https://cdn.gaming-goods.com/images/other/missions/Timewall.png',
      //   iconShort: 'https://cdn.gaming-goods.com/images/other/missions/Timewall.png',
      //   isOfferWall: true,
      //   offerWallLink: () =>
      //     `https://timewall.io/users/login?oid=973fdf19db3f632c&uid=${userId || 3}&tab=tasks`
      // },
      // {
      //   id: 8,
      //   title: 'AdToGame',
      //   name: 'ad-to-game',
      //   subtitle: 'upTo50',
      //   icon: 'https://cdn.gaming-goods.com/images/other/missions/AdToGame.png',
      //   iconShort: 'https://cdn.gaming-goods.com/images/other/missions/AdToGame.png',
      //   isOfferWall: true,
      //   mode: 'api',
      //   offerWallLink: () => {},
      //   offerwallDataFunc: (engine, clickId) => this.loadOfferwalls(engine, clickId, 'ad-to-game')
      // },
      // {
      //  id: 12,
      //  title: 'Luckywall',
      //  name: 'luckywall',
      //  subtitle: 'upTo50',
      //  icon: 'https://cdn.gaming-goods.com/images/other/missions/luckywall.png',
      //  isOfferWall: true,
      // adminOnly: true,
      //  mode: 'iframe',
      //  offerWallLink: () => `https://luckywall.net/offerwall/21j5z4r/${userId || 3}`
      // },
      // {
      //  id: 13,
      //  title: 'Kiwiwall',
      //  name: 'kiwiwall',
      //  subtitle: 'upTo50',
      //  icon: 'https://cdn.gaming-goods.com/images/other/missions/kiwiwall.png',
      //  isOfferWall: true,
      // adminOnly: true,
      //  mode: 'iframe',
      //  offerWallLink: () => `https://www.kiwiwall.com/wall/yvSbrC361QS5IfqRBKFvbZXgMYw4NWki/${userId || 3}`
      // },
      {
        id: 16,
        title: 'Ovalio',
        name: 'ovalio',
        subtitle: 'upTo50',
        icon: 'https://cdn.gaming-goods.com/images/other/missions/ovalio.webp',
        iconShort: 'https://cdn.gaming-goods.com/images/stats/offerwalls/OVALIO.webp',
        isOfferWall: true,
        // adminOnly: true,
        mode: 'api',
        offerWallLink: () => '',
        offerwallDataFunc: (engine, clickId) => this.loadOfferwalls(engine, clickId, 'ovalio')
      },
      {
        id: 17,
        title: 'Make Money',
        name: 'make-money',
        subtitle: 'upTo50',
        icon: 'https://dashboard.make-money.top/assets/mm_wall_icon.png',
        iconShort: 'https://cdn.gaming-goods.com/images/stats/offerwalls/MAKEMONEY.webp',
        isOfferWall: true,
        // adminOnly: true,
        mode: 'iframe',
        offerWallLink: () => `https://wall.make-money.top/?p=506&u=${userId || 3}`
      },
      {
        id: 14,
        title: 'Wannads',
        name: 'wannads',
        subtitle: 'upTo50',
        icon: 'https://cdn.gaming-goods.com/images/other/missions/wannads.webp',
        iconShort: 'https://cdn.gaming-goods.com/images/stats/offerwalls/WANNADS.webp',
        isOfferWall: true,
        // adminOnly: true,
        mode: 'iframe',
        offerWallLink: () => `https://wall.wannads.com?apiKey=63df8e00d2ec4507059617&userId=${userId || 3}`
      },
      // {
      //   id: 15,
      //   title: 'RevU',
      //   name: 'revu',
      //   subtitle: 'upTo50',
      //   icon: 'https://cdn.gaming-goods.com/images/other/missions/revu.webp',
      //   iconShort: 'https://cdn.gaming-goods.com/images/other/missions/revu.webp',
      //   isOfferWall: true,
      //   // adminOnly: true,
      //   mode: 'iframe',
      //   offerWallLink: () => `https://publishers.revenueuniverse.com/wallresp/775/offers?uid=${userId || 3}`
      // },
      //   {
      //     id: 18,
      //     title: 'Revlum',
      //     name: 'revlum',
      //     subtitle: 'upTo50',
      //     icon: 'https://cdn.gaming-goods.com/images/other/missions/revlum.webp',
      //     iconShort: 'https://cdn.gaming-goods.com/images/stats/offerwalls/REVLUM.webp',
      //     isOfferWall: true,
      //     // adminOnly: true,
      //     mode: 'iframe',
      //     offerWallLink: () => `https://revlum.com/offerwall/mnt1qrl43hb1nn1lyu2g2sqi78gej1/${userId || 3}`
      //   },
      // {
      //   id: 19,
      //   title: 'AdGem',
      //   name: 'adgem',
      //   subtitle: 'upTo50',
      //   icon: 'https://dashboard.adgem.com/images/AdGem_Logo_Large_Purple_Icon_White_Text.png',
      //   iconShort: 'https://dashboard.adgem.com/images/AdGem_Logo_Large_Purple_Icon_White_Text.png',
      //   isOfferWall: true,
      //   adminOnly: true,
      //   mode: 'iframe',
      //   offerWallLink: () => `https://api.adgem.com/v1/wall?appid=27453&playerid=${userId || 3}`
      // },
      {
        id: 23,
        title: 'MyLead',
        name: 'mylead',
        subtitle: 'upTo50',
        icon: 'https://pub.mylead.eu/assets/images/MyLead_logo_opposite_colors.svg',
        iconShort: 'https://pub.mylead.eu/assets/images/MyLead_logo_opposite_colors.svg',
        isOfferWall: true,
        adminOnly: false,
        mode: 'iframe',
        offerWallLink: () => `https://reward-me.eu/0e28e05c-9a9d-11ee-b880-8a5fb7be40ea?ml_sub2=${userId || 3}`
      },
      {
        id: 22,
        title: 'Admantium',
        name: 'admantium',
        subtitle: 'upTo50',
        icon: 'https://s3.eu-west-1.amazonaws.com/www.admantium.net/assets/img/logo-horizontal-blanco-gris.png',
        iconShort: 'https://s3.eu-west-1.amazonaws.com/www.admantium.net/assets/img/logo-horizontal-blanco-gris.png',
        isOfferWall: true,
        adminOnly: true,
        mode: 'iframe',
        offerWallLink: () => `https://wall.admantium.net/wall?apiKey=659ea33917f33844984974&userId=${userId || 3}&aff_sub=${userId || 3}`
      },
      /* {
        id: 24,
        title: 'CPAlead',
        name: 'cpalead',
        subtitle: 'upTo50',
        icon: 'https://www.cpalead.com/favicon-32x32.png',
        iconShort: 'https://www.cpalead.com/favicon-32x32.png',
        isOfferWall: true,
        adminOnly: false,
        mode: 'iframe',
        offerWallLink: () => `https://fastsvr.com/list/518997?subid=${userId || 3}`
      }, */
      {
        id: 1,
        title: 'VVV',
        name: 'vvv',
        subtitle: 'upTo50',
        icon: 'https://cdn.gaming-goods.com/images/other/missions/missionVVV.png',
        iconShort: 'https://cdn.gaming-goods.com/images/other/missions/missionVVV.png',
        isOfferWall: false,
        offerWallLink: () => ''
      }
    ]

    if (offerwallId) {
      return offerwalls.filter(item => isAdmin || !item.adminOnly).find(item => item.id === offerwallId)
    }

    return offerwalls.filter(item => isAdmin || !item.adminOnly)
  }
}

export default new OfferwallService()
