<template lang="pug">
  .freebet
    .freebet__img
      img(:src="product.image_url" alt="img")

    .freebet__holder
      .freebet__type Freebet 7EUR
      .freebet__title {{ product.title }}

      .freebet__price
        Currency(size="24" type="coins") {{ product.price  }}
        .freebet__coins {{ $t('countCoins') }}
      Button.freebet__button(is-primary, is-small, @click="buy") {{ $t("buy") }}

      .freebet__subtitle {{ $t('characteristics') }}

      .freebet__characteristics {{ $t('mainCharacteristics') }}

      .freebet__description-modal(v-html="description")

</template>

<script>
import { mapActions } from 'vuex'
import StoreService from '~/services/StoreService'

export default {
  name: 'ModalCheckoutFreespin',

  props: {
    product: {
      type: Object,
      default: null
    }
  },

  computed: {
    locale () {
      let locale = this.$i18n.locale

      if (!this.product.extra_data?.text?.[locale]) {
        locale = 'en'
      }

      return locale
    },

    extraData () {
      return this.product.extra_data
    },

    description () {
      return this.extraData?.text?.[this.locale]?.description
    },

    productLink () {
      return this.extraData?.purchase_link
    }
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    async buy () {
      const { data } = await StoreService.purchase(this.$axios, this.product?.id, { data: '' })
      if (data?.data) {
        // this.$gtag.event('buy_freespin', { product_id: this.product?.id })

        this.showModal({
          component: 'ModalMessageNewDesign',
          data: {
            title: this.$t('checkoutSuccessTitle'),
            type: 'success',
            text: this.$t('yourPromocodeIs'),
            promocode: 'VVVGAMERS',
            image: {
              src: require('~/assets/img/icons/like.svg')
            },
            buttons: [
              {
                props: { isPrimary: true },
                title: this.$t('activatePromoCode'),
                action: () => {
                  window.open(this.productLink, '_blank')
                }
              }
            ]
          }
        })
      } else {
        this.showModal({
          component: 'ModalMessageNewDesign',
          data: {
            title: this.$t('errorCode37'),
            type: 'error',
            text: this.$t('youCanEarnItInMissions'),
            image: {
              src: require('~/assets/img/icons/error.svg')
            },
            buttons: [
              {
                props: { isPrimary: true },
                title: this.$t('Перейти'),
                action: () => {
                  this.$router.push(this.localeLocation('/missions/vvv'))
                }
              }
            ]
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalcheckoutfreespin {
  padding: 20px;
  max-width: 90vw;
  max-height: 95%;
  overflow-y: auto;

  @media (min-width: $breakpointTablet) {
    max-width: 440px;
    padding: 24px;
  }

  @media (min-width: $breakpointDesktop) {
    max-width: 850px;
    padding: 24px 24px 40px;

    .modal__close {
      top: 24px;
    }
  }
}

.freebet__description-modal {
  div {
    p {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 16px;
    }

    span {
      max-width: 163px;
      text-align: right;
    }

    b {
      font-weight: 400;
      color: $blue-grey10;
    }
  }
}
</style>

<style lang="scss" scoped>
.freebet {
  color: $white;
  font-size: 12px;
  background-color: $blue-grey70;
  overflow: hidden;

  @media (min-width: $breakpointDesktop) {
    display: flex;
  }

  &__img {
    margin-bottom: 24px;
    text-align: center;

    @media (min-width: $breakpointDesktop) {
      margin-right: 24px;
    }
  }

  &__holder {
    z-index: 1;
  }

  &__type {
    margin-bottom: 8px;
    color: #90A4AE;
    text-transform: uppercase;
  }

  &__title {
    margin-bottom: 8px;
    font-size: 24px;
    font-weight: 700;
  }

  &__price {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 700;
  }

  &__button {
    width: 100%;
    margin-top: 16px;
  }

  &__coins {
    color: #90A4AE;
    margin-left: 26px;
  }

  &__subtitle {
    margin-top: 32px;
    font-size: 20px;
    font-weight: 700;
  }

  &__characteristics {
    margin: 12px 0;
    color: $blue-grey05;
  }
}
</style>
