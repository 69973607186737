<template lang="pug">
  .create-duel
    .modal__header
      | {{ $t('createDuelTitle') }} {{ game.extra_data.title }}

    .create-duel__body
      InfoMessage(v-if="warnings")
        .create-duel__warning-title
          | {{ $t('payAttention') }}
        ul.create-duel__warning-menu
          li.create-duel__warning-item(v-for="warning of warnings")
            | {{ warning }}

      .create-duel__setting
        .create-duel__setting-title
          | {{ $t('createDuelSettings') }}
        ul.create-duel__menu
          li.create-duel__item(v-for="key in topFields" :key="key")
            .create-duel__title
              | {{ fields[key].title }}
            .create-duel__params
              .create-duel__input(:class="`--${fields[key].type || 'text'}`")
                FormInput(
                  v-if="!fields[key].type || fields[key].type === 'text'"
                  v-model="fields[key].value"
                  :placeholder="fields[key].placeholder"
                  :max="fields[key].max"
                  @input="onChange(fields[key])"
                )
                template(v-else-if="fields[key].type === 'number'")
                  NumberInput(
                    v-model="fields[key].value"
                    :increment="fields[key].increment"
                    :max="fields[key].max"
                    :fixed="fields[key].fixed"
                    @input="onChange(fields[key])"
                  )
                  CurrencySelect(
                    v-if="fields[key].withCurrency"
                    v-model="currency"
                    @input="onChange(fields[key])"
                  )
                  InfoMessage(v-if="currency === 'money' && fields[key].withCurrency && (isContactsDataNeeded() || !isUserAdult())" type="warning")
                    div(v-if="isContactsDataNeeded()")
                      | {{ $t('createDuelContactsText') }}
                      |
                      a(href="#" @click.stop.prevent="onContactsClick") {{ $t('createDuelContactsLink') }}

                    div(v-else-if="!isUserAdult()")
                      | {{ $t('createDuelAge') }}

                BaseSelect(
                  v-else-if="fields[key].type === 'select'"
                  v-model="fields[key].value"
                  :options="fields[key].options"
                  :with-empty-value="fields[key].withEmptyValue"
                  @input="onChange(fields[key])"
                )

          li.create-duel__item(v-if="isClashRoyale && fields.is_private.value !== 'true'")
            .create-duel__title
              | {{ fields.creator_link.title }}
            .create-duel__params
              .create-duel__input(class="--text")
                FormInput(
                  v-model="fields.creator_link.value"
                  :placeholder="fields.creator_link.placeholder"
                  @input="onChange(fields.creator_link)"
                )

          li.create-duel__item
            .create-duel__title
              | {{ $t('rating') }}
            .create-duel__params
              .create-duel__input
                NumberInput(
                  v-model="fields.min_rating.value"
                  v-bind="fields.min_rating"
                  with-title
                  @input="onChange(fields.min_rating)"
                )
              .create-duel__input
                NumberInput(
                  v-model="fields.max_rating.value"
                  v-bind="fields.max_rating"
                  with-title
                  @input="onChange(fields.max_rating)"
                )

      //.create-duel__checkbox(v-if="game.name === 'lichess'")
      //  CheckboxSwitch(
      //    v-model="fields.is_webcam_required.value"
      //    :title="fields.is_webcam_required.title"
      //    :subtitle="fields.is_webcam_required.subtitle"
      //    @input="onChange(fields.is_webcam_required)"
      //  )

      .create-duel__money-wrap
        .create-duel__money
          .create-duel__money_value
            Currency(size="40" :type="currency") {{ fields.entry_fee.value }}
          .create-duel__money_name {{ $t('entryFee') }}
        .create-duel__money
          .create-duel__money_value
            Currency(size="40" :type="currency") {{ getRake() }}
          .create-duel__money_name {{ $t('commission') }}
        .create-duel__money
          .create-duel__money_value
            Currency(size="40" :type="currency") {{ getPrizePool() }}
          .create-duel__money_name {{ $t('prizePool') }}
    .create-duel__footer.modal__footer
      transition(name="fade" duration="200")
        InfoMessage(v-if="errors.length" type="error")
          ul
            li(v-for="(err, index) in errors" :key="index") {{ err }}

      .create-duel__footer-buttons(class="--error")
        Button(v-if="shouldShowMyDuels" is-primary @click="onMyDuelsClick") {{ $t('showCurrentDuels') }}

      .create-duel__footer-buttons
        Button(is-secondary @click="toggleModal(false)") {{ $t('buttonCancel') }}
        Button(is-primary @click="createDuel" :is-disabled="isButtonDisabled") {{ $t('buttonCreateDuel') }}

</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import FormInput from '~/components/FormInput/FormInput'
import NumberInput from '~/components/NumberInput/NumberInput'
import BaseSelect from '~/components/BaseSelect/BaseSelect'
import CheckboxSwitch from '~/components/CheckboxSwitch/CheckboxSwitch'
import CurrencySelect from '~/components/CurrencySelect/CurrencySelect'
import DuelMixin from '~/mixins/Duel'

import {
  isPhoneAndBirthdateNeeded,
  isFullContactsDataNeeded,
  hasUnicode
} from '~/utils/utils'

const DEFAULT_RAKE = 10

export default {
  name: 'CreateDuel',

  components: {
    CurrencySelect,
    CheckboxSwitch,
    BaseSelect,
    FormInput,
    NumberInput
  },

  mixins: [DuelMixin],

  data () {
    return {
      currency: 'money',

      mainTopFields: ['title', 'entry_fee'],

      gameTopFields: {
        lichess: [
          'color',
          'clock_increment',
          'clock_limit',
          'creator_handicap',
          'opponent_handicap'
        ],

        'clash-royale': ['is_private'],

        'brawl-stars': ['is_private', 'event_id', 'brawler_id', 'lobby_data']
      },

      errors: []
    }
  },

  computed: {
    ...mapState('games', ['gamesByName']),
    ...mapState('user', ['user']),
    ...mapGetters('user', ['gameAccount']),

    gameWarnings () {
      return {
        lichess: [this.$t('createDuelLichessWarning')],

        'clash-royale': [
          this.$t('createDuelClashWarning1'),
          this.$t('createDuelClashWarning2'),
          this.$t('createDuelClashWarning3')
        ],

        'brawl-stars': [
          this.$t('brawlStarsCreateDuelWarning1'),
          this.$t('brawlStarsCreateDuelWarning2')
        ]
      }
    },

    gameFields () {
      return {
        lichess: {
          clock_increment: {
            title: `${this.$t('clockIncrement')} (${this.$t('inSeconds')})`,
            type: 'number',
            increment: 1,
            value: '0'
          },

          clock_limit: {
            title: this.$t('clockLimit'),
            type: 'select',
            value: '60',
            options: [
              { title: this.$t('seconds30'), value: '30' },
              { title: this.$t('minutes1'), value: '60' },
              { title: this.$t('minutes2'), value: '120' },
              { title: this.$t('minutes3'), value: '180' },
              { title: this.$t('minutes4'), value: '240' },
              { title: this.$t('minutes5'), value: '300' }
            ],
            withEmptyValue: false
          },

          color: {
            title: this.$t('lichessColor'),
            type: 'select',
            value: 'random',
            options: [
              { title: this.$t('lichessColorRandom'), value: 'random' },
              { title: this.$t('lichessColorWhite'), value: 'white' },
              { title: this.$t('lichessColorBlack'), value: 'black' }
            ],
            withEmptyValue: false
          },

          creator_handicap: {
            title: this.$t('lichessCreatorHandicap'),
            type: 'number',
            increment: 1,
            value: '0',
            max: 1000
          },

          opponent_handicap: {
            title: this.$t('lichessOpponentHandicap'),
            type: 'number',
            increment: 1,
            value: '0',
            max: 1000
          }

          // is_webcam_required: {
          //   type: 'checkbox',
          //   title: this.$t('videoIsRequired'),
          //   subtitle: this.$t('createDuelVideoText'),
          //   value: false
          // }
        },

        'clash-royale': {
          creator_link: {
            title: this.$t('createDuelFriendsLink'),
            placeholder: this.$t('createDuelLinkPlaceholder'),
            value: ''
          }
        },

        'brawl-stars': {
          event_id: {
            title: this.$t('mapAndGameMode'),
            type: 'select',
            value: 15000295,
            options: [
              {
                title: this.$t('brawlStarsEventPurpleParadise'),
                value: 15000295
              }
            ],
            withEmptyValue: false
          },

          brawler_id: {
            title: this.$t('brawlerForDuel'),
            type: 'select',
            value: 16000001,
            options: [
              { title: this.$t('brawlStarsBrawlerColt'), value: 16000001 },
              { title: this.$t('brawlStarsBrawlerPiper'), value: 16000015 },
              { title: this.$t('brawlStarsBrawlerByron'), value: 16000042 }
            ],
            withEmptyValue: false
          },

          lobby_data: {
            title: this.$t('brawlLobbyTitle'),
            placeholder: this.$t('brawlLobbyPlaceholder'),
            value: '',
            max: 30
          }
        }
      }
    },

    game () {
      return this.gamesByName[this.$route.params.game]
    },

    warnings () {
      return this.gameWarnings[this.game.name] || []
    },

    mainFields () {
      const fields = {
        title: {
          title: this.$t('createDuelDuelTitle'),
          placeholder: this.$t('createDuelDuelTitlePlaceholder'),
          value: '',
          max: 30
        },

        currency: {
          title: this.$t('currency'),
          type: 'currency'
        },

        is_private: {
          title: this.$t('createDuelPrivacy'),
          type: 'select',
          options: [
            { title: this.$t('createDuelPrivacyPublic'), value: 'false' },
            { title: this.$t('createDuelPrivacyPrivate'), value: 'true' }
          ],
          value: 'false',
          withEmptyValue: false
        },

        min_rating: {
          title: this.$t('rangeFrom'),
          increment: 100,
          name: 'min_rating',
          value: '',
          max: 100000
        },

        max_rating: {
          title: this.$t('rangeTo'),
          increment: 100,
          name: 'max_rating',
          value: '',
          max: 100000
        }
      }

      fields.entry_fee =
        this.currency === 'money'
          ? {
            title: this.$t('entryFee'),
            type: 'number',
            increment: 0.1,
            value: '0.10',
            fixed: 2,
            withCurrency: true,
            max: 500
          }
          : {
            title: this.$t('entryFee'),
            type: 'number',
            increment: 1,
            value: '1',
            withCurrency: true,
            max: 1000
          }

      return fields
    },

    fields () {
      return {
        ...this.mainFields,
        ...(this.gameFields[this.game.name] || {})
      }
    },

    topFields () {
      return this.mainTopFields.concat(this.gameTopFields[this.game.name] || [])
    },

    shouldShowMyDuels () {
      return this.errors.some(err => err === this.$t('errorCode33'))
    },

    isButtonDisabled () {
      if (
        this.currency === 'money' &&
        (this.isContactsDataNeeded() || !this.isUserAdult())
      ) {
        return true
      }

      const emptyRequiredFields = Object.values(this.fields).filter(
        field => field.required && !field.value
      )

      if (emptyRequiredFields?.length || this.errors.length) {
        return true
      }

      return false
    },

    isClashRoyale () {
      return this.game.name === 'clash-royale'
    }
  },

  mounted () {
    this.$store.dispatch('user/fetchVerification')
    this.checkForAutoFill()
  },

  methods: {
    ...mapActions('modal', ['showModal']),
    ...mapMutations('modal', { toggleModal: 'toggle' }),

    onContactsClick () {
      if (isPhoneAndBirthdateNeeded(this.verification)) {
        this.showModal({
          component: 'ModalVerification',
          data: { title: this.$t('duelVerification') }
        })
      } else if (isFullContactsDataNeeded(this.verification)) {
        this.$router.push(this.localeLocation('/profile/personal-data'))
      }
    },

    getPrizePool () {
      return (this.fields.entry_fee.value - this.getRake()).toFixed(2)
    },

    getRake () {
      return ((this.fields.entry_fee.value / 100) * DEFAULT_RAKE).toFixed(2)
    },

    async createDuelRequest (duelData) {
      this.$nuxt.$loading.start()

      try {
        const { data } = await this.$axios.post(
          `/games/${this.game.name}/duels`,
          duelData
        )

        this.sendCreateAnalytics(data.data)

        this.toggleModal(false)
        this.$router.push(
          this.localeLocation(`/${this.game.name}/duels/${data.data.password}`)
        )
      } catch (e) {
        console.error('duel create error', e)
        this.errors.push(this.$errors.getText(e))

        // Модалка для списания евро закрывает модалку создания дуэли,
        // поэтому ошибки показываем отдельной модалкой
        if (this.currency === 'money') {
          this.$errors.handle(e)
        }
      }

      this.$nuxt.$loading.finish()
    },

    createDuel () {
      this.errors = []

      if (hasUnicode(this.fields.title.value)) {
        this.errors.push(this.$t('duelTitleError'))
      }

      if (this.game.name === 'brawl-stars' && !this.fields.lobby_data.value) {
        this.errors.push(this.$t('brawlStarsLobbyDataError'))
      }

      if (
        this.fields.min_rating.value &&
        this.fields.max_rating.value &&
        +this.fields.min_rating.value >= +this.fields.max_rating.value
      ) {
        this.errors.push(this.$t('ratingError'))
      }

      if (
        isNaN(parseInt(this.fields.entry_fee.value)) ||
        this.fields.entry_fee.value < 0.1
      ) {
        this.errors.push(
          this.$t('createDuelMinError') +
            ' ' +
            (this.currency === 'money'
              ? this.$t('countEuro')
              : this.$t('countCoins'))
        )
      }

      if (
        this.fields?.creator_link &&
        this.fields?.is_private?.value === 'false' &&
        !this.fields?.creator_link?.value?.startsWith(
          'https://link.clashroyale.com/invite/friend/'
        )
      ) {
        this.errors.push(this.$t('createDuelLinkError'))
      }

      if (this.errors.length) {
        return
      }

      let config = {}

      if (this.game.name === 'lichess') {
        config = {
          'clock.limit': +this.fields.clock_limit.value,
          'clock.increment': +this.fields.clock_increment.value,
          color: this.fields.color.value,
          variant: 'standard',
          creator_handicap_seconds: +this.fields.creator_handicap.value,
          opponent_handicap_seconds: +this.fields.opponent_handicap.value
        }
      } else if (this.game.name === 'clash-royale') {
        config = {
          creator_link: this.fields.creator_link.value
        }
      } else if (this.game.name === 'brawl-stars') {
        config = {
          event_id: this.fields.event_id.value,
          brawler_id: this.fields.brawler_id.value,
          lobby_data: this.fields.lobby_data.value
        }
      }

      const duelData = {
        title: this.fields.title.value,
        is_private: this.fields.is_private.value === 'true',
        // отправляем на бэк значение в центах если евро
        entry_fee:
          this.currency === 'money'
            ? parseInt((+this.fields.entry_fee.value * 100))
            : +this.fields.entry_fee.value,
        is_anticheat_required: false,
        is_webcam_required: this.fields.is_webcam_required?.value || false,
        extra_data: {
          config
        },
        currency: this.currency
      }

      if (this.fields.min_rating.value) {
        duelData.min_rating = this.fields.min_rating.value
      }

      if (this.fields.max_rating.value) {
        duelData.max_rating = this.fields.max_rating.value
      }

      if (duelData.currency === 'money') {
        this.showModal({
          component: 'ModalPayForParticipation',
          data: {
            title: this.$t('createDuelPayTitle'),
            text: this.$t('createDuelPaySubtitle'),
            duel: {
              ...duelData,
              rake: DEFAULT_RAKE
            },
            afterPay: () => {
              this.$store.commit('modal/toggle', false)
              this.createDuelRequest(duelData)
              // setTimeout(window.location.reload(), 700)
            }
          }
        })
      } else {
        this.createDuelRequest(duelData)
        // setTimeout(window.location.reload(), 700)
      }
    },

    onMyDuelsClick () {
      this.toggleModal()
      this.$router.push(this.localeLocation('/profile/events#history-duels'))
    },

    onChange (field) {
      // todo: проверять не по имени, а добавить id
      if (this.isClashRoyale && field.title === this.$t('createDuelPrivacy')) {
        const isRequired = field.value !== 'true'

        this.gameFields['clash-royale'].creator_link.required = isRequired
      } else if (field.title === this.$t('createDuelFriendsLink')) {
        const cookieValue = `${new Date()}^${field.value}`

        // todo: использовать локалсторадж и два отдельных поля
        this.$cookiz.set('cr_friend_link', cookieValue)
      }

      this.errors = []
      this.$forceUpdate()
    },

    checkForAutoFill () {
      if (this.isClashRoyale) {
        const previousLinkCookie = this.$cookiz.get('cr_friend_link')

        if (!previousLinkCookie) {
          return
        }

        const previousLinkTime = Date.parse(previousLinkCookie.split('^')[0])
        const previousLinkValue = previousLinkCookie.split('^')[1]
        const diff = this.$dateFns.differenceInMinutes(
          new Date(),
          previousLinkTime
        )

        if (diff < 20) {
          this.gameFields['clash-royale'].creator_link.value = previousLinkValue
        }
      }
    }
  }
}
</script>

<style lang="scss">
.modal.modal--createduel {
  max-width: 735px;
  padding-bottom: 0;
}

.create-duel {
  &__body {
    padding: 32px 0;
    display: flex;
    flex-direction: column;
  }

  &__warning {
    display: flex;
  }

  .currency-select {
    margin-left: 8px;
  }

  &__warning-title {
    @include heading4;

    color: $white;
  }

  &__warning-menu {
    margin-top: 4px;
  }

  &__warning-item {
    margin-top: 12px;
    color: $blue-grey05;
    list-style-type: disc;
    margin-left: 20px;
  }

  &__setting {
    margin-top: 32px;
  }

  &__setting-title {
    @include heading4;

    width: 100%;
    position: relative;
    padding-bottom: 16px;
    border-bottom: 1px solid #5568772b;
    color: $white;
  }

  &__menu {
    width: 100%;
    margin-bottom: 24px;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-top: 24px;
  }

  &__title {
    @include caption-small;

    width: 30%;
    color: $blue-grey10;
  }

  &__params {
    width: 70%;
    padding-left: 90px;
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__info {
    position: absolute;
    left: 44px;
    display: flex;
    align-items: flex-start;
  }

  .form-input {
    width: 100%;
  }

  &__input {
    &.--text {
      width: 100%;
    }

    &.--number {
      display: flex;
      flex-wrap: wrap;

      .info-message {
        width: 100%;
        margin-top: 16px;

        a {
          color: #fff;
          text-decoration: underline;
        }
      }
    }

    & + .create-duel__input {
      margin-left: 16px;
    }
  }

  &__checkbox {
    padding: 24px 16px;
    border-top: 1px solid $blue-grey30;
    border-bottom: 1px solid $blue-grey30;
    margin-top: -1px;

    a {
      color: $white;
      text-decoration: underline;
    }
  }

  &__money-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 32px;
  }

  &__money {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 32px;
    position: relative;

    &::before {
      content: "";
      height: 32px;
      width: 1px;
      background: $blue-grey30;
      position: absolute;
      left: 0;
      display: block;
      top: 50%;
      transform: translateY(-50%);
    }

    &:first-child {
      &::before {
        display: none;
      }
    }
  }

  &__money_value {
    @include heading2;

    color: $white;

    .currency {
      img {
        @media (max-width: $breakpointDesktopMedium) {
          width: 30px;
        }

        @media (min-width: $breakpointDesktopMedium) {
          width: 40px;
        }
      }
    }
  }

  &__money_name {
    @include caption-small;

    margin-top: 12px;
    color: $blue-grey10;
    text-align: center;
  }

  &__footer {
    &.modal__footer {
      display: block;
    }

    &-buttons {
      display: flex;
      justify-content: flex-end;

      .button--primary {
        margin-left: 16px;
      }

      &.--error {
        margin: 11px 0 16px;
      }
    }
  }
}

@media (max-width: $breakpointMobile) {
  .create-duel {
    &__body {
      padding: 24px 0;
    }

    &__money {
      padding: 0 12px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-left: 0;
      }
    }

    &__footer {
      padding: 19px 16px;
    }

    &__item {
      flex-direction: column;
      position: relative;
    }

    &__title {
      width: 100%;
    }

    &__params {
      width: 100%;
      padding: 0;
      margin-top: 13px;
      position: static;
    }

    &__input {
      width: 100%;

      & + .create-duel__input {
        margin-top: 16px;
        margin-left: 0;
      }

      .form-input {
        width: 100%;
      }
    }

    &__info {
      top: 0;
      right: 0;
      left: auto;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
