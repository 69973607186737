<script>
export default {
  name: 'GetForFreeModal'
}
</script>

<template>
  <div :class="$style.modal">
    <p :class="$style.title">
      {{ $t('getForFree') }}
    </p>

    <p :class="$style.header">
      {{ $t("makeMoney3StepsTitle") }}
    </p>

    <div :class="$style.points">
      <div :class="$style.point">
        <div :class="$style.point__left">
          <div :class="[$style.point__number, $style.point__numberBlue]">
            01
          </div>
          <div :class="[$style.point__line, $style.point__lineBlue]"></div>
          <img :class="[$style.point__icon, $style.point__iconBlue]" :src="require('~/assets/img/icons/arrow-down.svg')" alt="arrow down" />
        </div>
        <p :class="$style.point__text">
          {{ $t("makeMoney3StepsStep1") }}
        </p>
      </div>
      <div :class="$style.point">
        <div :class="$style.point__left">
          <div :class="[$style.point__number, $style.point__numberOrange]">
            02
          </div>
          <div :class="[$style.point__line, $style.point__lineOrange]"></div>
          <img :class="[$style.point__icon, $style.point__iconOrange]" :src="require('~/assets/img/icons/arrow-down.svg')" alt="arrow down" />
        </div>
        <p :class="$style.point__text">
          {{ $t("makeMoney3StepsStep2") }}
        </p>
      </div>
      <div :class="$style.point">
        <div :class="$style.point__left">
          <div :class="[$style.point__number, $style.point__numberTeal]">
            03
          </div>
          <div :class="[$style.point__line, $style.point__lineTeal]"></div>
          <img :class="[$style.point__icon, $style.point__iconTeal]" :src="require('~/assets/img/icons/double-check.svg')" alt="double check" />
        </div>
        <p :class="$style.point__text">
          {{ $t("makeMoney3StepsStep3") }}
        </p>
      </div>
    </div>

    <p :class="$style.header">
      {{ $t("bestOffers") }}
    </p>

    <div :class="$style.offers">
      <NuxtLink :to="localeLocation('/missions')">
        <img :class="$style.offers__img" :src="require('~/assets/img/offerwall/img-in-modal.webp')" alt="Best offers" />
      </NuxtLink>
    </div>

    <div :class="$style.footer">
      <NuxtLink :to="localeLocation('/missions')">
        <Button is-primary>
          {{ $t("allOffers") }}
        </Button>
      </NuxtLink>
    </div>
  </div>
</template>

<style>
.modal--getforfreemodal {
  width: 100%;
  max-width: 600px !important;
}
</style>

<style lang="scss" module>
.modal {
  width: 100%;
  max-width: 600px;
  padding-top: 10px;
}

.title {
  color: $white;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  padding-bottom: 18px;
  margin-bottom: 24px;
  border-bottom: 1px solid $blue-grey40;
}

.header {
  color: $white;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 24px;
}

.points {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  margin-bottom: 48px;

  @media (min-width: $breakpointTablet) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.point {
  display: grid;
  grid-template-columns: 38px 1fr;
  grid-gap: 16px;
  color: $white;

  @media (min-width: $breakpointTablet) {
    grid-template-columns: 1fr;
    grid-gap: 24px;
  }

  &__text {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    margin: 0;
  }

  &__left {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: $breakpointTablet) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    width: 38px;
    border-radius: 8px;
    background: $black;
    font-size: 20px;
    font-weight: 700;
    line-height: 100%;
    flex-shrink: 0;

    &Blue {
      background: $primary60;
    }

    &Orange {
      background: $orange60;
    }

    &Teal {
      background: $teal60;
    }
  }

  &__line {
    display: none;

    @media (min-width: $breakpointTablet) {
      display: block;
      height: 2px;
      width: 100%;
      background: transparent;
      margin: 0 16px;
    }

    &Blue {
      border-top: 2px solid $primary60;
    }

    &Orange {
      border-top: 2px solid $orange60;
    }

    &Teal {
      border-top: 2px solid $teal60;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-top: 16px;
    flex-shrink: 0;

    @media (min-width: $breakpointTablet) {
      margin: 0;
    }

    &Blue {
      @media (min-width: $breakpointTablet) {
        transform: rotate(-90deg);
      }

      path, rect {
        fill: $primary60;
      }
    }

    &Orange {
      @media (min-width: $breakpointTablet) {
        transform: rotate(-90deg);
      }

      path, rect {
        fill: $orange60;
      }
    }

    &Teal {
      path, rect {
        fill: $teal60;
      }
    }
  }
}

.offers {
  width: 100%;

  &__img {
    height: 212px;
    width: auto;
    object-fit: cover;
    object-position: left;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid $blue-grey40;
  padding: 16px 0;
  margin-top: 24px;
}
</style>
