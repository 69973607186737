<template lang="pug">
.teams-list-modal
  .modal__header
    | {{ $t('teamsList') }}
  .teams-list-modal__body
    .teams-list-modal__list
      TeamRow(v-for="team in teams" :key="team.id" :team="team" with-members)

  .teams-list-modal__footer
    .teams-list-modal__text
      | {{ $t('totalTeams') }}:
      .teams-list-modal__counter {{ teams.length }}
    Button(is-primary @click="$store.commit('modal/toggle', false)") {{ $t('buttonClose') }}
</template>

<script>
import TeamRow from '~/components/TeamRow/TeamRow'

export default {
  name: 'TeamsListModal',

  components: { TeamRow },

  props: {
    teams: {
      type: Array,
      required: true
    }
  },

  methods: {
    plural: require('plural-ru')
  }
}
</script>

<style lang="scss">
.modal.modal--teamslistmodal {
  max-width: 735px;
  padding-bottom: 12px;
}

.teams-list-modal {
  &__body {
    @include scroll;

    padding: 16px 24px;
    margin: 0 -24px;
    margin-bottom: 8px;
    overflow-y: auto;
    max-height: calc(100vh - 200px);
  }

  &__footer {
    display: flex;
    align-items: center;
    padding: 12px 24px 0;
    margin: 0 -24px;
    border-top: 1px solid $blue-grey40;

    .button:first-of-type {
      margin-left: auto;

      &__text {
        color: $primary60;
      }

      svg,
      path,
      rect {
        fill: $primary60;
      }
    }
  }

  &__text {
    @include caption-small;

    display: flex;
    align-items: center;
    color: $blue-grey10;
  }

  &__counter {
    margin-left: 5px;
    color: #fff;
  }

  .team-row {
    background: $blue-grey60;
  }
}
</style>
