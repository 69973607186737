<template lang="pug">
.modal-slots
  .modal__header {{ $t('slotInTournamentLobby') }}
  .modal__body
    InfoMessage.page-head__warning(type="warning") {{ $t('takeSlotWithYourNumber') }}
    .modal-slots__content
      .modal-slots__slot(
        v-for="(slot, index) in teams"
        :key="slot.id || index"
        :class="{'--my': slot.id === team.id}"
      )
        strong {{ $t('teamNumber', { number: index + 1, teamName: slot.name }) }}

        .modal-slots__players(v-if="slot.id")
          .modal-slots__player(
            v-for="(player, index) in slot.members"
            :key="index"
            @click="onPlayerClick(player)"
          )
            img(v-if="player && player.avatar" :src="player.avatar" :alt="player.full_name" width="40" height="40")
        .modal-slots__empty(v-else) {{ $t('slotIsEmpty') }}

</template>

<script>
import TournamentMixin from '~/mixins/Tournament'

export default {
  name: 'ModalSlots',

  mixins: [TournamentMixin],

  props: {
    tournament: {
      type: Object,
      required: true
    }
  },

  data: () => {
    return {}
  },

  computed: {
    teams () {
      const teams = []

      for (
        let i = 0;
        i < this.tournament.tournament_player_settings?.max_players_count;
        i++
      ) {
        teams[i] = this.tournament.teams[i] || {}
      }

      return teams
    }
  },

  methods: {
    onPlayerClick (player = {}) {
      window.open(`${this.$config.WWW_HOST}/user/${player.alias}`, '_blank')
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalslots {
  max-width: 720px;
  padding-bottom: 0;
}

.modal-slots {
  &__slot {
    border-radius: 8px;
    background: $blue-grey60;
    padding: 12px;
    position: relative;

    strong {
      @include hint-small;

      display: block;
      margin-bottom: 8px;
      color: $blue-grey10;
    }

    &.--my {
      overflow: hidden;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 4px;
        height: 100%;
        background: $deep-blue60;
      }
    }
  }

  &__empty {
    @include hint-small;

    border: 1px dashed #fff;
    color: #fff;
    border-radius: 8px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__players {
    display: flex;
  }

  &__player {
    margin-right: 8px;
    position: relative;
    cursor: pointer;

    img {
      border-radius: 10px;
      object-fit: cover;
      max-height: 40px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__content {
    padding: 24px;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr 1fr;

    @media (max-width: $breakpointTablet) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: $breakpointMobile) {
      padding: 16px 8px;
      grid-template-columns: 1fr;
      grid-gap: 8px;
    }
  }

  .modal {
    &__body {
      @include scroll;

      margin: 0 -24px;
      max-height: calc(100vh - 120px);

      @media (max-width: $breakpointMobile) {
        margin: 0 -16px;
        max-height: calc(100vh - 80px);
      }
    }
  }
}
</style>
