import CheckPermissionsService from '~/services/CheckPermissionsService'

// example: create24MetricsScript (this.$el, this.$cookiz, this.$axios, this.offer, this.user?.id, 'open-offer-modal', (fingerprint, uuid) => {this.fingerprint = fingerprint; this.uuid = uuid})
export function create24MetricsScript (el, cookies, axios, offer, userId, actionName, clickId, callback) {
  // const kochavaDeviceId = cookies.get('kochava_device_id', { parseJSON: false })

  console.log('24METRICS: start')

  // eslint-disable-next-line no-unused-vars,camelcase,no-var
  window.cs_params = {
    integration_id: '798hr8z2wd',
    publisher: offer?.source,
    offer: offer?.id,
    sub_id: userId
    // device_id: kochavaDeviceId
  }

  const scriptEl = document.createElement('script')

  scriptEl.setAttribute('src', 'https://static.24metrics.com/js/index.js')
  scriptEl.setAttribute('defer', 'defer')
  scriptEl.onload = async () => {
    console.log('24METRICS: script loaded')

    let fingerprint
    try {
      // eslint-disable-next-line no-undef
      fingerprint = await Ne()
      console.log('24METRICS: fingerprint ok:', fingerprint)
    } catch (e) {
      console.log('24METRICS: fingerprint error:', e)
    }

    let uuid
    try {
      // eslint-disable-next-line no-undef
      uuid = localStorage.getItem('cs-uuid-device') ? localStorage.getItem('cs-uuid-device') : Ye()
      console.log('24METRICS: uuid ok:', fingerprint)
    } catch (e) {
      console.log('24METRICS: uuid error:', e)
    }

    const gret = cookies.get('gret')
    if (gret) {
      console.log('24METRICS: gret ok:', gret)
    }

    if (userId) {
      console.log('24METRICS: checkPermissions start')
      if (offer?.source === 'Tournaments') {
        CheckPermissionsService.checkPermissions(axios, actionName, gret, fingerprint, uuid, window?.navigator?.userAgent, clickId)
      } else {
        console.log('24METRICS: checkPermissions offer', offer?.id, ', user', userId, ', publisher', offer?.source)
        const fullData = {
          visitorId: fingerprint,
          uuid
        }

        try {
          await CheckPermissionsService.checkPermissions(axios, actionName, fullData, fingerprint, uuid, window?.navigator?.userAgent, clickId)
          console.log('24METRICS: checkPermissions finished')
        } catch (e) {
          console.log('24METRICS error:', e)
        }
      }
    }

    console.log('24METRICS: send callback to the component')
    callback(fingerprint, uuid)
    console.log('24METRICS: finished')
  }

  el.appendChild(scriptEl)
  console.log('24METRICS: appendChild')
}
