<template lang="pug">
.upload-image
  .modal__header(v-t="title")
  img.upload-image__img(:src="imageUrl" alt="image")

  .modal__footer
    .upload-image__buttons
      Button.upload-image__button(v-t="'buttonCancel'" is-secondary @click="closeModal")
      Button(v-t="'buttonSave'" is-primary @click="updateImage(imageUrl)")
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'ModalUploadImage',

  props: {
    imageUrl: {
      type: String,
      required: true
    },

    title: {
      type: String,
      required: true
    },

    updateImage: {
      type: Function,
      required: true
    }
  },

  methods: {
    ...mapMutations('modal', ['toggle']),

    closeModal () {
      this.toggle()
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modaluploadimage {
  max-width: 528px;
  background: $blue-grey70;
}
</style>

<style lang="scss" scoped>
.upload-image {
  &__img {
    width: 100%;
    max-height: 50vh;
    object-fit: contain;
    margin: 72px 0;
  }

  &__buttons {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 16px;
    align-items: center;
    justify-content: end;
  }

  &__button {
    color: $white;
  }
}
</style>
