import Vue from 'vue'

export default ({ app }) => {
  Vue.directive('support-button', {
    inserted (el) {
      el.addEventListener('click', (e) => {
        e.preventDefault()
        e.stopPropagation()

        window?.jivo_api?.open()
      })
    },
    bind () {
      window.addEventListener('hashchange', () => {
        if (window.location.hash === '#support') {
          window.location.hash = ''
          window?.jivo_api?.open()
        }
      })
    }
  })
}
