<template lang="pug">
NuxtLink.card-tournament(:to="tournamentLink" :class="{'--long': !alwaysShort}")
  .card-tournament__head(:class="{'--long': !alwaysShort}")
    .card-tournament__head-title(:class="{'--long': !alwaysShort}") {{ tournament.title }}
    .card-tournament__head-start-info(:class="{'--long': !alwaysShort}")
      span.card-tournament__hide-desktop(v-t="'startOfTournament'" :class="{'--long': !alwaysShort}")
      span {{ tournamentStartText }}
    NuxtLink.card-tournament__head-aditional(:to="tournamentLink" :class="{'--long': !alwaysShort}")
      | {{ $t('additionalInformationAboutTournament') }}
  .card-tournament__body(:class="{'--long': !alwaysShort}")
    .card-tournament__body-pair(:class="{'--long': !alwaysShort}")
      .card-tournament__body-pair-title(:class="{'--long': !alwaysShort}") {{ $t('rating') }}:
      .card-tournament__body-pair-value(:class="{'--long': !alwaysShort}") {{ tournamentRating }}
    .card-tournament__body-pair(:class="{'--long': !alwaysShort}")
      .card-tournament__body-pair-title(:class="{'--long': !alwaysShort}") {{ $t('region') }}:
      .card-tournament__body-pair-value(:class="{'--long': !alwaysShort}") {{ tournamentRegion }}
    .card-tournament__body-pair(:class="{'--long': !alwaysShort}")
      .card-tournament__body-pair-title(:class="{'--long': !alwaysShort}") {{ $t('playersAmount') }}:
      .card-tournament__body-pair-value(:class="{'--long': !alwaysShort}") {{ playersAmount }}
    .card-tournament__body-pair(:class="{'--long': !alwaysShort}" v-if="tournamentFormat")
      .card-tournament__body-pair-title(:class="{'--long': !alwaysShort}") {{ $t('format') }}:
      .card-tournament__body-pair-value(:class="{'--long': !alwaysShort}") {{ tournamentFormat }}
    .card-tournament__body-pair(:class="{'--long': !alwaysShort}")
      .card-tournament__body-pair-title.card-tournament__body-pair-title--gold(:class="{'--long': !alwaysShort}") {{ $t('prizePool') }}:
      .card-tournament__body-pair-value(:class="{'--long': !alwaysShort}")
        template(v-if="isMoneyPrize")
          Currency(type="money" is-reversed)
            span.card-tournament__body-pair-value(:class="{'--long': !alwaysShort}") {{ tournamentMoneyPrize }}
        template(v-else)
          Currency(type="coins" is-reversed)
            span.card-tournament__body-pair-value(:class="{'--long': !alwaysShort}") {{ tournamentCoinPrize }}
          | +&nbsp;
          Currency(type="tickets" is-reversed)
            span.card-tournament__body-pair-value(:class="{'--long': !alwaysShort}") {{ tournamentTrophyPrize }}
    .card-tournament__body-pair(:class="{'--long': !alwaysShort}")
      .card-tournament__body-pair-title(:class="{'--long': !alwaysShort}") {{ $t('filterEntryFee') }}:
      .card-tournament__body-pair-value(:class="{'--long': !alwaysShort}")
        Currency(v-if="tournamentWithEntranceTicket" type="money" is-reversed)
          span.card-tournament__body-pair-value(:class="{'--long': !alwaysShort}") {{ tournamentEntryFee }}
        NuxtLink.card-tournament__body-pair-value.card-tournament__body-pair-value--link(v-else-if="prizeInCoins" :class="{'--long': !alwaysShort}" :to="localeLocation('/store/subscriptions')") {{ $t('subscription') }}
        span.card-tournament__body-pair-value(v-else :class="{'--long': !alwaysShort}") {{ $t('free') }}
</template>

<script>
import { getDateTextForTourCard } from '@/utils/utils'
import { mapState } from 'vuex'

export default {
  name: 'TournamentCardNewDesign',

  props: {
    tournament: {
      type: Object,
      required: true
    },

    alwaysShort: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState('games', ['gamesById']),

    game () {
      return this.gamesById[this.tournament?.game_id]
    },

    gameName () {
      return this.game?.name
    },

    subGame () {
      return this.tournament?.sub_game
    },

    tournamentPageUrl () {
      return this.localeLocation(`/${this.gameName}/tournaments/${this.tournamentId}`)
    },

    tournamentLink () {
      return this.tournamentFormat === 'Multi stage'
        ? this.localeLocation(`/${this.gameName}/tournaments/new/m${this.tournament?.mega_tournament_id}`)
        : this.tournamentPageUrl
    },

    tournamentId () {
      return this.tournament?.id
    },

    tournamentWithEntranceTicket () {
      return this.tournament?.prize_settings?.entry_fee_currency === 'money'
    },

    prizeInCoins () {
      return this.prizeCurrency === 'coins'
    },

    tournamentEntryFee () {
      return (this.tournament?.entry_fee / 100).toFixed(2).replace('.00', '')
    },

    playersAmount () {
      return `${this.tournament?.players_count} / ${this.tournament?.tournament_player_settings?.max_players_count}`
    },

    tournamentFormat () {
      return this.tournament?.mega_tournament_id ? 'Multi stage' : 'Default'
    },

    prizeCurrency () {
      return this.tournament?.prize_settings?.prize_currency
    },

    isMoneyPrize () {
      return this.prizeCurrency === 'money'
    },

    tournamentCoinPrize () {
      let prizePool = this.tournament?.prize_pool

      if (this.risingPrizePool) {
        prizePool = this.maxPrizePool
      }

      return this.isMoneyPrize ? prizePool * 12 : prizePool
    },

    tournamentTrophyPrize () {
      return Math.ceil(this.tournamentCoinPrize / 1000).toFixed(0)
    },

    maxPrizePool () {
      let membersCount =
        this.tournament?.tournament_player_settings?.max_players_count

      if (this.tournament?.teams) {
        membersCount =
          this.tournament?.tournament_player_settings?.max_players_count *
          this.tournament?.tournament_player_settings?.team_size
      }

      const entryFee = this.tournament?.prize_settings?.entry_fee_currency === 'money' ? this.tournament.entry_fee / 100 : this.tournament.entry_fee

      return (
        Number(entryFee -
          (entryFee / 100) * this.tournament?.prize_settings?.rake) *
        membersCount
      ).toFixed(2).replace('.00', '')
    },

    risingPrizePool () {
      return ['not_fixed_with_guarantee', 'not_fixed'].includes(this.tournament?.prize_settings?.prize_pool_type)
    },

    tournamentMoneyPrize () {
      let prizePool = (this.tournament?.prize_pool / 100).toFixed(2).replace('.00', '')

      if (this.risingPrizePool) {
        prizePool = this.maxPrizePool
      }
      return this.isMoneyPrize ? prizePool : '0'
    },

    tournamentStart () {
      return this.tournament?.date?.replace(' ', 'T') + 'Z'
    },

    tournamentRegistrationStart () {
      return this.tournament?.registration_starts_at?.replace(' ', 'T') + 'Z'
    },

    tournamentStartText () {
      if (this.tournamentStart) {
        return getDateTextForTourCard.call(this, this.tournamentStart, this.tournamentRegistrationStart)
      }
      return '...'
    },

    isSponsored () {
      return this.tournament?.is_sponsored
    },

    tournamentRating () {
      if (!this.tournament?.min_rating_group && !this.tournament?.max_rating_group) {
        return 'All'
      }
      if (!this.tournament?.min_rating_group) {
        return `D - ${this.tournament?.max_rating_group}`
      }
      if (!this.tournament?.max_rating_group) {
        return `${this.tournament?.min_rating_group} - S`
      }
      if (this.tournament?.min_rating_group === this.tournament?.max_rating_group) {
        return `${this.tournament?.max_rating_group}`
      }
      return `${this.tournament?.min_rating_group} - ${this.tournament?.max_rating_group}`
    },

    tournamentRegion () {
      return this.tournament?.region || 'All'
    },

    starsCount () {
      return Math.ceil(this.tournament?.avg_star_count || 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.card-tournament {
  border-radius: 16px;
  background: $blue-grey70;
  overflow: hidden;

  &.--long {
    @media (min-width: $breakpointDesktopWide) {
      display: grid;
      grid-template-columns: 10fr 24fr;
      grid-gap: 30px;
    }
  }

  &__head {
    padding: 12px 16px;
    background: $blue-grey60;

    &.--long {
      @media (min-width: $breakpointDesktopWide) {
        display: grid;
        grid-template-columns: 3fr 2fr;
        grid-gap: 0 30px;
        background: $blue-grey70;
        padding: 12px 0 12px 24px;
      }
    }

    &-title {
      margin-bottom: 4px;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: $white;

      &.--long {
        @media (min-width: $breakpointDesktopWide) {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }

    &-start-info {
      font-weight: 500;
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: $primary40;

      &.--long {
        @media (min-width: $breakpointDesktopWide) {
          font-size: 16px;
          line-height: 20px;
        }
      }

    }

    &-aditional {
      display: none;

      &.--long {
        @media (min-width: $breakpointDesktopWide) {
          display: block;
          font-size: 12px;
          line-height: 16px;
          color: $blue-grey10;
          text-decoration-line: underline;
        }
      }
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
    padding: 8px 16px 10px;

    &.--long {
      @media (min-width: $breakpointDesktopWide) {
        grid-template-columns: 2fr 2fr 2fr 2fr 3fr 2fr;
        grid-gap: 30px;
        padding: 12px 24px 12px 0;
      }
    }

    &-pair {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 2px;

      &--double {
        grid-column: 1 / 3;

        &.--long {
          @media (min-width: $breakpointDesktopWide) {
            grid-column: auto;
          }
        }
      }

      &-title {
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #90A4AE;

        &.--long {
          @media (min-width: $breakpointDesktopWide) {
            display: none;
          }
        }

        &--gold {
          color: $amber60;
        }
      }

      &-value {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: $white;

        ::v-deep .currency__icon {
          img {
            width: 12px;
            height: 12px;

            @media (min-width: $breakpointDesktopWide) {
              width: 20px;
              height: 20px;
            }
          }
        }

        &--link {
          color: $primary60;
          text-decoration: underline;
        }

        &.--long {
          @media (min-width: $breakpointDesktopWide) {
            width: 100%;
            text-align: center;
            font-size: 16px;
            line-height: 20px;
          }

          ::v-deep .currency__icon {
            img {
              @media (min-width: $breakpointDesktopWide) {
                width: 12px;
                height: 12px;
              }
            }
          }
        }

        &-separator {
          margin: 0 4px;
        }
      }
    }
  }

  &__hide-desktop {
    margin-right: 3px;

    &.--long {
      @media (min-width: $breakpointDesktopWide) {
        display: none;
      }
    }
  }

  &__stars {
    display: grid;
    grid-template-columns: repeat(5, 20px);
  }

  &__star {
    width: 20px;
    height: 20px;
  }
}
</style>
