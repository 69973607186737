export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: false,
}
export const options = {
  vueI18n: {"fallbackLocale":["en-US"],"silentTranslationWarn":true},
  vueI18nLoader: false,
  locales: [{"code":"en-US","iso":"en-US","file":"en.js"},{"code":"de","iso":"de-DE","file":"de.js"},{"code":"es","iso":"es-ES","file":"es.js"},{"code":"it","iso":"it-IT","file":"it.js"},{"code":"pt","iso":"pt-PT","file":"pt.js"},{"code":"kk","iso":"kk-KZ","file":"kk.js"}],
  defaultLocale: "en-US",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/tmp/vvvgamers.com/lang",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":true,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_locale","cookieSecure":false,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":false,"useCookie":true},
  differentDomains: false,
  seo: true,
  baseUrl: "https://gaming-goods.com",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en-US","iso":"en-US","file":"en.js"},{"code":"de","iso":"de-DE","file":"de.js"},{"code":"es","iso":"es-ES","file":"es.js"},{"code":"it","iso":"it-IT","file":"it.js"},{"code":"pt","iso":"pt-PT","file":"pt.js"},{"code":"kk","iso":"kk-KZ","file":"kk.js"}],
  localeCodes: ["en-US","de","es","it","pt","kk"],
}

export const localeMessages = {
  'en.js': () => import('../../lang/en.js' /* webpackChunkName: "lang-en.js" */),
  'de.js': () => import('../../lang/de.js' /* webpackChunkName: "lang-de.js" */),
  'es.js': () => import('../../lang/es.js' /* webpackChunkName: "lang-es.js" */),
  'it.js': () => import('../../lang/it.js' /* webpackChunkName: "lang-it.js" */),
  'pt.js': () => import('../../lang/pt.js' /* webpackChunkName: "lang-pt.js" */),
  'kk.js': () => import('../../lang/kk.js' /* webpackChunkName: "lang-kk.js" */),
}
