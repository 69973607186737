export function normalizeNotification (
  item,
  { router, $axios, state, $errors, $t, localeLocation }
) {
  const gamesById = state.games.gamesById

  function handleError (e) {
    $errors.handle(e)
  }

  return new Promise((resolve) => {
    let newItem = item

    if (item.type === 'team_invite') {
      const game = gamesById[item.extra_data.team.game_id]
      ;(() => {
        try {
          // const { data } = await $axios.get(
          //   `/games/${game.name}/tournaments/${item.extra_data.team.tournament_id}`
          // )
          // const tournament = data.data

          const accept = async () => {
            // const path = `/${game.name}/tournaments/${item.extra_data.team.tournament_id}`

            try {
              await $axios.post(
                `/teams/${game.name}/${item.extra_data.team.id}/join`
              )

              // if (window.location.pathname === path) {
              //   window.location.reload()
              // } else {
              //   router.push(localeLocation(path))
              // }
            } catch (e) {
              handleError(e)
            }
          }

          const decline = async () => {
            try {
              await $axios.post(
                `/teams/${game.name}/${item.extra_data.team.id}/leave`
              )
            } catch (e) {
              handleError(e)
            }
          }

          newItem = {
            ...item,
            icon: 'team_invite',
            title: $t('teamInvite'),
            text: $t('teamInviteText', {
              name: item.extra_data.team.captain_full_name,
              title: `#${item.extra_data.team.id}`,
              game: game.extra_data.title
            }),
            actions: [
              {
                title: $t('buttonDecline'),
                action () {
                  decline()
                }
              },
              {
                title: $t('buttonAccept'),
                action () {
                  accept()
                },
                isPrimary: true
              }
            ]
          }

          resolve(newItem)
        } catch (e) {
          console.error(
            `${new Date().toUTCString()} :: notification team tournament error ::`,
            e
          )
          resolve({})
        }
      })()
    } else if (item.type === 'rating_update') {
      const game = gamesById[item.extra_data.game_account.game_id]
      const adjustment = item.extra_data.game_account.rating_adjustment

      newItem = {
        ...item,
        icon: 'rating_group_update',
        title:
          adjustment > 0
            ? $t('ratingUp', { game: game.extra_data.title })
            : $t('ratingDown', { game: game.extra_data.title }),
        text: $t(adjustment > 0 ? 'ratingChangeUp' : 'ratingChangeDown', {
          adjustment: Math.abs(adjustment),
          game: game.extra_data.title
        })
      }
      resolve(newItem)
    } else if (item.type === 'tournament_won') {
      const game = gamesById[item.extra_data.tournament.game_id]
      const tournamentId = item.extra_data.tournament.id

      newItem = {
        ...item,
        icon: 'tournament_won',
        title: $t('winCongrats'),
        text: $t('winText', {
          game: game.extra_data.title
        }),
        actions: [
          {
            title: $t('checkOut'),
            action: () => {
              router.push(
                localeLocation(
                  `/${game.name}/tournaments/${tournamentId}#rewards`
                )
              )
            },
            shouldClose: true,
            isPrimary: true
          }
        ]
      }
      resolve(newItem)
    } else if (item.type === 'tournament_lobby_ready') {
      newItem = {
        ...item,
        icon: 'tournament_created',
        title: $t('tournamentCreated'),
        text: $t('tournamentCreatedText', {
          game: gamesById[item.extra_data.tournament.game_id].extra_data.title
        }),
        actions: [
          {
            title: $t('gamesGoTo'),
            action: () => {
              if (item.extra_data.tournament.mega_tournament_id) {
                router.push(
                  localeLocation(
                    `/${
                      gamesById[item.extra_data.tournament.game_id].name
                    }/phased-tournaments/${
                      item.extra_data.tournament.mega_tournament_id
                    }#stages-${item.extra_data.tournament.id}`
                  )
                )
              } else {
                router.push(
                  localeLocation(
                    `/${
                      gamesById[item.extra_data.tournament.game_id].name
                    }/tournaments/${item.extra_data.tournament.id}`
                  )
                )
              }
            },
            shouldClose: true,
            isPrimary: true
          }
        ]
      }

      resolve(newItem)
    } else if (item.type === 'new_referral') {
      newItem = {
        ...item,
        icon: 'new_referral',
        title: $t('newRef'),
        text: $t('newRefText'),
        actions: [
          {
            title: $t('checkOut'),
            action: () => {
              router.push(localeLocation('/profile/ref'))
            },
            shouldClose: true,
            isPrimary: true
          }
        ]
      }
      resolve(newItem)
    } else if (
      item.type === 'duel_accepted' &&
      item?.extra_data?.duel?.game_id &&
      item?.extra_data?.duel?.password
    ) {
      ;(async () => {
        const game = gamesById[item.extra_data.duel.game_id].name
        try {
          const { data } = await $axios.get(
            `/games/${game}/duels/${item.extra_data.duel.password}`
          )
          const duel = data.data

          const validateDuel = async (accept) => {
            router.push(localeLocation(`/${game}/duels/${duel.password}`))

            try {
              await $axios.post(
                `/games/${game}/duels/${duel.password}/validate`,
                { accept }
              )
            } catch (e) {
              handleError(e)
            }
          }

          newItem = {
            ...item,
            icon: 'duel_accepted',
            title: $t('duelAccepted'),
            duel,
            gameTitle: gamesById[item.extra_data.duel.game_id].extra_data.title,
            actions: [
              {
                title: $t('buttonDecline'),
                action: () => {
                  validateDuel(false)
                }
              },
              {
                title: $t('buttonAccept'),
                action () {
                  validateDuel(true)
                },
                isPrimary: true
              }
            ]
          }
          resolve(newItem)
        } catch (e) {
          console.error(
            `${new Date().toUTCString()} :: notification duel error ::`,
            e
          )
          resolve({})
        }
      })()
    } else if (item.type === 'tournament_created') {
      newItem = {
        ...item,
        icon: 'tournament_created',
        title: $t('newTournament'),
        text: $t('newTournamentText'),
        actions: [
          {
            title: $t('gamesGoTo'),
            action: () => {
              router.push(
                localeLocation(
                  `/${
                    gamesById[item.extra_data.tournament.game_id].name
                  }/tournaments/${item.extra_data.tournament.id}`
                )
              )
            },
            shouldClose: true,
            isPrimary: true
          }
        ]
      }
      resolve(newItem)
    } else if (item.type === 'subscription_prolong') {
      // todo: check not user's subscription list, but store list

      const subscription = state.user?.subscriptions?.find(
        s => s.product_id === item.extra_data?.product?.id
      )
      const title = subscription ? `«${subscription.title}» ` : ''

      newItem = {
        ...item,
        icon: 'subscription_prolong',
        title: $t('subscriptionProlong'),
        text: $t('subscriptionProlonged', { title })
      }

      resolve(newItem)
    } else if (item.type === 'subscription_purchase') {
      const subscription = state.user?.subscriptions?.find(
        s => s.product_id === item.extra_data?.product?.id
      )
      const title = subscription ? `«${subscription.title}» ` : ''

      newItem = {
        ...item,
        icon: 'subscription_prolong',
        title: $t('subscriptionPurchase'),
        text: $t('subscriptionPurchased', { title })
      }

      resolve(newItem)
    } else if (item.type === 'subscription_over') {
      const subscription = state.user?.subscriptions?.find(
        s => s.product_id === item.extra_data?.product?.id
      )
      const title = subscription ? `«${subscription.title}» ` : ''

      newItem = {
        ...item,
        icon: 'subscription_prolong',
        title: $t('subscriptionOver'),
        text: $t('subscriptionIsOver', { title })
      }

      resolve(newItem)
    } else if (item.type === 'admin_info') {
      const extraData = item.extra_data.admin_info
      const actions = []

      if (extraData.link_url) {
        actions.push({
          title: $t('gamesGoTo'),
          action: () => window.open(extraData.link_url),
          isPrimary: true,
          shouldClose: true
        })
      }

      newItem = {
        ...item,
        title: extraData.title || $t('message'),
        text: extraData.text,
        imageUrl: extraData.image_url,
        actions
      }

      resolve(newItem)
    } else {
      resolve(newItem)
    }
  })
}
