<template lang="pug">
  .daily-reward(:class="{ '--subscribed': subscription, '--not-subscribed': !subscription }")
    .daily-reward__premium(v-if="subscription")
      img(:src="require('~/assets/img/icons/diamond.svg')" alt="diamonds")
      h3.daily-reward__title(class="--gold") {{ $t('x2coins') }}
      h3.daily-reward__title {{ $t('dailyRewardTitle') }}

    h3.daily-reward__title(v-else) {{ $t('dailyRewardTitle') }}

    //.daily-reward__reward-stripe
      .daily-reward__stripe-val +{{ bonusData.bonus_distribution[bonusData.day] }};
        img.daily-reward__stripe-ico(src="~/assets/img/icons/coins.svg" alt="coins" width="48" height="48")

    p.daily-reward__subtitle(v-html="captionText")

    // todo: get store subscription

    .daily-reward__tabs(:class="{ 'daily-reward__tabs__rows': tabs.length > 6 }")
      RewardTab(
        v-for="tab in tabs"
        :key="tab.day"
        :tab="tab"
        :has-subscription="subscription"
      )

    InfoMessage.daily-reward__error(v-if="error" type="error") {{ $t('errorMessageDefault') }}

    .daily-reward__buttons-wrap
      Button.daily-reward__button-info(is-secondary @click="getInfo") {{ $t('addedInfo') }}
      Button.daily-reward__button-take(is-primary icon="plus" @click="getReward") {{ $t('dailyRewardButton') }}

</template>

<script>
import { mapMutations, mapState } from 'vuex'
import RewardTab from '~/components/DailyReward/RewardTab'

export default {
  name: 'DailyReward',

  components: {
    RewardTab
  },

  props: {
    bonusData: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      error: false,
      subscriptionPrice: 3.49
    }
  },

  computed: {
    ...mapState('user', ['subscriptions']),

    tabs () {
      return Object.values(this.bonusData.bonus_distribution).map(
        (coins, index, arr) => {
          let state = ''

          if (index < this.bonusData.day) {
            state = 'achieved'
          } else if (index === this.bonusData.day) {
            state = 'active'
          }

          return {
            title: `${this.$t('day')} ${index + 1}`,
            reward: coins,
            state,
            isLast: index + 1 === arr.length
          }
        }
      )
    },

    subscription () {
      return this.subscriptions?.find((s) => {
        return s.type === 'subscription_bonus' && s.extra_data?.duration > 0
      })
    },

    captionText () {
      let text = this.$t('dailyRewardCaption')

      if (this.subscription) {
        const durationText = this.$t('subscriptionDaysLeft', {
          duration: this.subscription.days_left
        })

        text += `.<br>${durationText}`
      }

      return text
    }
  },

  methods: {
    ...mapMutations('modal', ['toggle']),

    async getReward () {
      this.$nuxt.$loading.start()

      try {
        await this.$axios.post('/user/get-coins')

        this.toggle(false)
      } catch (e) {
        this.error = true
        console.error(`${new Date().toUTCString()} :: daily reward error ::`, e)
      }

      this.$nuxt.$loading.finish()
    },

    getInfo () {
      this.toggle(false)
      this.$router.push(this.localeLocation('/info/coins'))
    }
  }
}
</script>

<style lang="scss">
.modal.modal--dailyreward {
  max-width: fit-content;

  & .--not-subscribed {
    max-width: 488px;
  }

  & .--subscribed {
    max-width: 596px;
  }
}

.daily-reward {
  overflow: hidden;
  margin: -24px;

  &.--subscribed {
    border-radius: 16px;
    border: 2px solid $amber60;

    .daily-reward__reward-stripe {
      width: 634px;
      margin-left: -16px;
    }
  }

  @media (max-width: $breakpointMobile) {
    margin: -16px;
  }

  &__title {
    @include heading3;

    margin-top: 24px;
    color: $white;
    text-align: center;

    @media (max-width: $breakpointMobile) {
      margin-top: 16px;
      padding: 0 16px;
    }
  }

  &__premium {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .daily-reward__title {
      font-size: 28px;
      margin-top: 4px;

      &.--gold {
        color: $amber80;
      }
    }
  }

  &__reward-stripe {
    @include heading1;

    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-4deg);
    margin-top: 32px;
    margin-left: -30px;
    margin-bottom: 32px;
    background: $gradient-amber;
    width: 536px;
    height: 72px;
    text-align: center;
    color: $white;

    img {
      max-height: 48px;
      margin-left: 8px;
    }

    @media (max-width: $breakpointMobile) {
      margin-top: 16px;
    }
  }

  &__stripe-val {
    display: flex;
    align-items: center;
  }

  &__stripe-ico {
    transform: translate(0 -5px);
    height: 64px;
  }

  &__subtitle {
    @include text-display100;

    color: $white;
    line-height: 24px;
    text-align: center;
    margin: 8px 0 16px;
  }

  &__error {
    margin-top: 15px;
  }

  &__buttons-wrap {
    display: flex;
    justify-self: flex-start;
    justify-content: flex-end;
    margin-top: 24px;
    padding: 0 24px 16px;

    @media (max-width: $breakpointMobileSmall) {
      padding: 0 8px 16px;
    }
  }

  &__button-take {
    margin-left: 16px;
    padding: 15px 24px;

    @media (max-width: $breakpointMobileSmall) {
      margin-left: 8px;
    }

    svg rect {
      fill: #fff;
    }
  }

  &__button-info {
    padding: 15px 24px;
  }

  &__offer {
    display: flex;
    padding: 14px;
    border-radius: 12px;
    background-color: $blue-grey60;
    margin: 0 24px 24px;
    border: 2px solid $amber70;
    overflow: hidden;
    position: relative;

    &-left {
      max-width: 252px;
      z-index: 1;
    }

    &-title {
      @include heading3;

      color: $white;
      margin-bottom: 12px;
    }

    &-description {
      @include body;

      color: $white;
      margin-bottom: 40px;
    }

    &-price {
      display: flex;

      .currency {
        @include heading4;

        color: $white;
        margin-right: 16px;
      }
    }

    &-image {
      position: absolute;
      height: 260px;
      right: -66px;
    }

    &-line {
      position: absolute;
      width: 108px;
      height: 300px;
      left: 330px;
      top: -90px;
      background: #1183f3;
      transform: rotate(-45deg);
    }

    @media (max-width: $breakpointMobile) {
      margin: 0 16px 24px;

      &-title {
        display: none;
      }

      &-description {
        margin-bottom: 16px;
      }

      &-line {
        width: 45px;
        left: 290px;
      }

      &-image {
        right: -76px;
        height: 164px;
      }
    }
  }
}

.daily-reward__tabs {
  width: calc(100% - 48px);
  display: grid;
  column-gap: 16px;
  row-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0 24px;

  @media (max-width: $breakpointMobile) {
    margin: 0 16px;
    width: calc(100% - 32px);
    column-gap: 8px;
    row-gap: 8px;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: $breakpointMobile) {
  .daily-reward__reward-stripe {
    width: 125% !important;
    margin-left: -38px !important;
  }
}
</style>
