<template lang="pug">
  .error-404
    MainBanner(:banners="banners")

    .page__content
      TournamentsList.page__section(
        v-if="recommendedTournaments && recommendedTournaments.length"
        :tournaments="recommendedTournaments"
      )
        template(v-slot:title)
          SectionTitle {{ $t('recommendedTournaments') }}
</template>

<script>
import { mapState } from 'vuex'
import MainBanner from '~/components/MainBanner/MainBanner'
import TournamentsList from '~/components/TournamentsList/TournamentsList'

export default {
  name: 'Error404',

  components: { TournamentsList, MainBanner },

  data () {
    return {
      banners: [
        {
          background_image_url:
            'https://cdn.gaming-goods.com/images/banners/banner_head1.jpg',
          btn1_text: this.$t('goToMainPage'),
          btn1_url: '/',
          title: '404 page not found'
        }
      ]
    }
  },

  computed: {
    ...mapState('games', ['recommendedTournaments']),
    ...mapState('user', ['user'])
  },

  watch: {
    $route: {
      handler (route, prev) {
        if (prev) {
          this.banners[0].btn1_url = '#back'
          this.banners[0].btn1_text = this.$t('goBack')
        } else {
          this.banners[0].btn1_url = '/'
          this.banners[0].btn1_text = this.$t('goToMainPage')
        }
      },

      immediate: true
    }
  },

  mounted () {
    this.$store.dispatch('games/fetchRecommendedTournaments')
    this.$socket.on('tournaments', this.updateRecommendedTournaments)
  },

  beforeDestroy () {
    this.$socket.off('tournaments', this.updateRecommendedTournaments)
  },

  methods: {
    updateRecommendedTournaments () {
      this.$store.dispatch('games/fetchRecommendedTournaments')
    }
  }
}
</script>
