<template lang="pug">
.tab-lobby
  template(v-if="isPlayer && (lobbyName || lobbyPassword)")
    .tab-lobby__title(v-t="'accessToTheTournament'")
    CopyInput.tab-lobby__input(v-if="lobbyName" :value="lobbyName" :title="$t('lobbyName')")
    CopyInput.tab-lobby__input(v-if="lobbyPassword" :value="lobbyPassword" icon="lock-open" :title="$t('tournamentPassword')")
  template(v-else-if="isPlayer")
    .tab-lobby__info(v-t="'tabLobbyText15Minutes'")
  template(v-else)
    .tab-lobby__info(v-t="'tabLobbyTextOnlyPlayers'")
</template>

<script>
import { mapState } from 'vuex'
import CopyInput from '@/components/CopyInput/CopyInput'

export default {
  name: 'TabLobby',

  components: {
    CopyInput
  },

  props: {
    tournament: {
      type: Object,
      required: true
    },

    game: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState('user', ['user']),

    lobbyName () {
      return this.tournament?.extra_data?.state?.game?.extra_data?.id
    },

    lobbyPassword () {
      return this.tournament?.extra_data?.state?.game?.extra_data?.password
    },

    isPlayer () {
      return this.tournament?.players?.find(item => item.id === this.user?.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-lobby {
  &__title {
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: $white;
  }

  &__input {
    margin-bottom: 16px;
  }

  &__info {
    padding: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    background: $blue-grey60;
    color: $white;
    border-radius: 8px;
  }
}
</style>
