/* eslint-disable camelcase */
export const ERROR_CODES = {
  1: 'Token is not valid',
  2: 'Not found',
  3: 'Actions on the platform are limited',
  4: 'Bot for {gameName} is not found',
  5: 'There are no free bots for {gameName}',
  6: 'Lichess error: {error}',
  7: 'Creation error',
  8: 'Lichess account is not linked',
  9: 'The opponent does not have a Lichess account linked',
  10: 'You entered an incorrect phone number',
  11: 'Invalid request parameters',
  12: 'Incorrect user data',
  13: 'Reason for blocking: {ban_reason}. End date of blocking {ban_end}',
  14: 'This login is already occupied',
  15: 'This e-mail is already registered',
  16: 'E-mail is not confirmed',
  17: 'You used a social network to log in',
  18: 'The user was not found or an incorrect password was entered!',
  19: 'Confirm the phone number!',
  20: 'Verification under consideration',
  21: 'Code not found or expired',
  22: 'There is not enough money in the account!',
  23: 'It is necessary to fill in the contact information',
  24: 'Only 18+ players are allowed in the tournament!',
  25: 'Configuration error',
  26: 'Stage(s) not found!',
  27: 'Tournament not found',
  28: 'Duel not found',
  29: 'The date of the duel cannot be in the past',
  30: 'The cost of entry is not specified',
  31: 'Details of the duel are missing',
  32: 'There are not enough funds on your balance to participate',
  33: 'You have unfinished duels. Finish the duel before creating a new one!',
  34: 'You have insufficient rating to participate in the duel!',
  35: 'You have too high a rating to participate in a duel',
  36: 'There is no opponent',
  37: 'Not enough coins!',
  38: 'The opponent doesn\'t have enough coins',
  39: 'You or your opponent have unfinished duels. Finish the duel before creating a new one!',
  40: 'You cannot cancel a duel in which there is an opponent!',
  41: 'The duel was not found or not moderated or not by Pubg Mobile',
  42: 'Missing status',
  43: 'The user is in unfinished tournaments',
  44: 'The user is in unfinished duels',
  45: 'User not found',
  46: 'Account {gameName} not confirmed',
  47: 'Account not found',
  48: 'The account is verified or does not have verification data',
  49: 'Missing account {gameName}',
  50: 'This account is already linked to the user {username}',
  51: 'The tournament is closed!',
  52: 'The entrance to the lobby is closed, there are 15 minutes left before the start of the tournament.',
  53: 'It is impossible to pay for the tournament with coins!',
  54: 'Your rating {rating}, it does not fit the conditions of the tournament',
  55: 'You must play at least {min_lichess_game} on Lichess to join the tournament!',
  56: 'Your level is below {minimal_level}, you cannot participate in the tournament!',
  57: 'The maximum number of participants in the tournament!',
  58: 'You are already participating in the tournament!',
  59: 'You are already participating in a tournament that takes place simultaneously with this tournament!',
  60: 'The tournament is about to start, you can\'t get out!',
  61: 'You are not participating in the tournament!',
  62: 'You need to verify your account',
  63: 'It is impossible to withdraw less than {limit} €',
  64: 'It is necessary to pass additional verification to withdraw more than 1,000 €',
  65: 'Game not found',
  66: 'Not enough tickets to participate',
  67: 'There is not enough ticket balance to participate!',
  68: 'Product not found',
  69: 'Enter all data',
  70: 'The tournament was not found or not moderated or not by Pubg Mobile',
  71: 'Registration for the tournament has not started yet',
  72: 'The entrance to the tournament is closed',
  73: 'It is necessary to verify the FIDE profile for entry into the tournament',
  74: 'The tournament can only be played from the {os} platform',
  75: 'Unsupported mime type {mimetype}',
  76: 'There are not enough cards in your deck',
  77: 'Personal account error',
  78: 'Bot error {gameName}',
  79: 'The player is already registered in the match',
  80: 'The user is not registered in the match',
  81: 'Event duplication',
  82: 'The game {gameName} is not supported',
  83: 'Missing game event',
  84: 'The event is not supported',
  85: 'The method is not supported',
  86: 'There is no linked account for the game!',
  87: 'Automatic output is disabled',
  88: 'This is a team tournament!',
  89: 'You are already a member of the team',
  90: 'Team not found',
  91: 'You are not a member of the team or are not a captain!',
  92: 'The maximum number of participants in the team!',
  93: 'Search parameters are not set',
  94: 'The tournament is already starting, you can\'t leave',
  95: 'This is an individual tournament',
  96: 'Team size does not fit tournament rules',
  97: 'The team does not participate in the tournament',
  98: 'Have you already paid for participation or are you not a member of the team',
  99: 'You are not a captain',
  100: 'Server error',
  101: 'You need to confirm the contact details',
  102: 'This nickname is already linked to another user',
  103: 'Game account not found',
  104: 'Such an account has already been registered',
  105: 'A password is required to enter this tournament',
  106: 'Invalid tournament password',
  107: 'File size exceeded',
  108: 'Failed to save file',
  109: 'Invalid format of the uploaded file',
  110: 'Tournament under moderation',
  111: 'The social network is already linked to another user',
  112: 'Top players are unavailable',
  113: 'You have reached the limit of freerolls for today. Buy a subscription or come back tomorrow :)',
  114: 'The discipline of the cancelled tournament is not correct',
  115: 'Requirements for sponsors are not met',
  116: 'Withdrawal of funds in the process',
  117: 'Invalid e-mail domain. Try it @gmail.com or another',
  122: 'Such referral link already exists',
  123: 'Captcha is not valid',
  124: 'Changing the phone is too frequent',
  127: 'Sorry, you can withdraw not more than you have won',
  128: 'You already have this subscription',
  132: 'Error 132',
  133: 'Error 133',
  134: 'Error 134',
  10001: 'Google Pay canceled the payment',
  20001: 'Invalid expiration date of the card',
  20002: 'Invalid card number',
  20003: 'Empty value of the recipient when paying by card',
  20004: 'Invalid e-wallet number',
  20005: 'Invalid e-mail',
  20006: 'Bitcoin wallet number length must be between 26 and 100',
  40001: 'That is not a valid address for that coin!'
}

export default ({ app: { store, i18n } }, inject) => {
  const plugin = {
    handle (e) {
      store.dispatch('modal/showModal', {
        component: 'ModalMessage',
        data: {
          text: this.getText(e)
        }
      })
    },

    getText (e) {
      let error
      const errorData = e?.response?.data || e?.data

      if (e?.response?.data?.error_data) {
        error = e.response.data.error_data.error
        return error
      } else {
        error = ERROR_CODES[errorData?.code]
      }

      if (!error) {
        return e?.message || i18n.t('unknownError', { code: errorData?.code })
      }

      // console.log('errors', errorData, { ...errorData.error_data })

      return i18n.t(`errorCode${errorData?.code}`, { ...errorData.error_data })
    }
  }

  inject('errors', plugin)
}
