export const EVENT_STATUSES = {
  open: 'stateOpen',
  in_progress: 'stateInProgress',
  completed: 'stateCompleted',
  moderation: 'stateOnModeration',
  cancelled: 'stateCancelled'
}

export const DUEL_STATUSES = {
  open: 'duelStateOpen',
  ready: 'duelsStateReady',
  in_progress: 'stateInProgress',
  moderation: 'stateOnModeration',
  completed: 'duelStateCompleted',
  cancelled: 'duelStateCancelled',
  template: 'duelStateRoom',
  date_template: 'duelStateRoom',
  waiting_for_lobby: 'stateWaiting'
}

export const BLOG_SUBJECTS = [
  { id: 'news', slug: 'novosti' },
  { id: 'interesting', slug: 'intieriesnoie' },
  { id: 'characters', slug: 'piersonazhi' },
  { id: 'modes', slug: 'riezhimy' }
]

export const PER_PAGE_DEFAULT = 6

/* eslint-disable-next-line */
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const TICKETS_NOMINAL = 1

export const IDS_FOR_LOGGINS_AS_USER = [15, 2, 20, 5, 20634, 2276, 3088]
