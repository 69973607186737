import { render, staticRenderFns } from "./ModalLeaveFeedback.vue?vue&type=template&id=7c8f3cc1"
import script from "./ModalLeaveFeedback.vue?vue&type=script&lang=js"
export * from "./ModalLeaveFeedback.vue?vue&type=script&lang=js"
import style0 from "./ModalLeaveFeedback.vue?vue&type=style&index=0&id=7c8f3cc1&prod&lang=css"
import style1 from "./ModalLeaveFeedback.vue?vue&type=style&index=1&id=7c8f3cc1&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports