import axios from 'axios'

export default function (req, res) {
  let query = req.url.split('?')

  if (!query[1]) {
    res.statusCode = 400
    return res.end('Not enough parameters')
  }

  query = query[1]

  if (process.env.NODE_ENV === 'development') {
    console.log(
      'sending postback',
      'http://91.210.165.102/0ccf49f/postback?' + query
    )
  }

  axios
    .get('http://91.210.165.102/0ccf49f/postback?' + query)
    .then((data) => {
      // console.log('postback sended', data.data)
      res.end(data.data)
    })
    .catch((e) => {
      res.statusCode = 500
      const error =
        (e && e.response && e.response.data && e.response.data.message) ||
        '[nuxt] Ошибка при отправке posback'
      res.end(error)
      console.error(`${new Date().toUTCString()} :: postback error ::`, error)
    })
}
