<template lang="pug">
  button.button.button--secondary.video-chat-button(
    v-bind="$attrs"
    :disabled="isDisabled"
    :type="$attrs.type || 'button'"
    @click="onClick"
  )
    .button__body
      span.button__text(v-if="$slots.default")
        slot
      Tooltip(popup-class-name="video-chat")
        template(v-slot:tooltip)
          .video-chat-button__tooltip
            ol
              li(v-html="$t(isDuel ? 'videoChatButtonLi1Duel' : 'videoChatButtonLi1Tournament')")
              li {{ $t('videoChatButtonLi2') }}
              li {{ $t('videoChatButtonLi3') }}
              li {{ $t('videoChatButtonLi4') }}
              li {{ $t('videoChatButtonLi5') }}
              li(v-html="$t(isDuel ? 'videoChatButtonLi6Duel' : 'videoChatButtonLi6Tournament')")

</template>

<script>
import Button from '~/components/Button/Button'
import Tooltip from '~/components/Tooltip/Tooltip'

export default {
  name: 'VideoChatButton',

  components: { Tooltip },

  extends: Button,

  props: {
    isDuel: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.tooltip__popup.--video-chat {
  &.--bottom-left {
    margin-top: 40px;
    margin-left: 40px;
  }

  &.--top-left {
    margin-top: -40px;
    margin-left: 40px;
  }

  &.--top-right {
    margin-top: -40px;
    margin-left: -40px;
  }

  &.--bottom-right {
    margin-top: 40px;
    margin-left: -40px;
  }
}

.video-chat-button__tooltip {
  max-width: 408px;

  a {
    text-decoration: underline;
  }

  li {
    @include text-display120;

    font-size: 16px;
    color: $blue-grey05;
    line-height: 22.4px;
    list-style-type: disc;
    padding-left: 8px;
    margin-left: 16px;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ol li {
    list-style-type: decimal;
  }
}

button.video-chat-button.button {
  .tooltip {
    margin-left: 10px;
  }

  img {
    path {
      fill: #fff;
    }
  }

  &--secondary {
    background: $pink60;

    &:hover {
      background: $pink50 !important;
    }

    &:active {
      background: $pink40 !important;
    }
  }
}
</style>
