<template lang="pug">
.modal-select-payment-method
  p.modal__header.modal-select-payment-method__title(v-t="'choosePayMethod'")

  .modal__body
    .modal-select-payment-method__methods
      .modal-select-payment-method__method(v-for="paymentMethod in paymentMethods" :key="paymentMethod.id")
        span.modal-select-payment-method__method-text

  .modal__footer
</template>

<script>
export default {
  name: 'ModalSelectPaymentMethod',

  data () {
    return {
      paymentMethods: [
        {
          id: 'BANKCARD',
          text: 'creditCard'
        },
        {
          id: 'BALANCE',
          text: 'prolongFromSite'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalselectpaymentmethod {
  max-width: 668px;
}
</style>

<style lang="scss" scoped>
.modal-select-payment-method {
  color: $white;
  padding: 22px 24px 12px;

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
  }

  &__methods {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
  }

  &__method {
    padding: 20px 16px;
    background: $blue-grey60;
    border-radius: 8px;

    &-text {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }
  }
}
</style>
