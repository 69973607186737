<template lang="pug">
  .ban-modal
    .modal__header
      | {{ $t('bannedTitle') }}

    .ban-modal__body
      InfoMessage(type="error").ban-modal__info-message
        | {{ $t('youWasBanned') }}

      p.ban-modal__text {{ reason }}
      p.ban-modal__text
        | {{ $t('bannedAppeal') }}&nbsp;
        a(href="mailto:unblock@gaming-goods.com").ban-modal__email-link unblock@gaming-goods.com
      p.ban-modal__text
        | {{ $t('bannedAppealText') }}
        | {{ $t('bannedAppealText2') }}

    .modal__footer
      Button(is-secondary @click="onCloseClick").ban-modal__btn {{ $t('buttonClose') }}
      Button(is-primary @click="onSendAppealClick").ban-modal__btn {{ $t('bannedAppealButton') }}

</template>

<script>
import InfoMessage from '~/components/InfoMessage/InfoMessage'

export default {
  name: 'BanModal',

  components: {
    InfoMessage
  },

  props: {
    reason: {
      type: String,
      default: ''
    }
  },

  methods: {
    onSendAppealClick () {
      window.open('mailto:unblock@gaming-goods.com')
    },

    onCloseClick () {
      this.$store.commit('modal/toggle', false)
    }
  }
}
</script>

<style lang="scss">
.modal.modal--banmodal {
  max-width: 736px;
  padding-bottom: 0;
}

.ban-modal {
  &__body {
    padding: 24px 0;
  }

  &__info-message {
    margin-bottom: 24px;
  }

  &__text {
    @include body;

    color: #cfd8dc;
    margin-bottom: 16px;
  }

  &__email-link {
    color: $white;
    text-decoration: underline;
  }

  &__btn {
    margin-left: 16px;
  }
}
</style>
