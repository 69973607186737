<template lang="pug">
  .modal-bysubphased
    img.modal-bysubphased__image(:src="require('~/assets/img/backgrounds/euros.svg')" alt="eur")

    h2.modal-bysubphased__title {{ texts.title }}
    p.modal-bysubphased__subtitle {{ texts.subtitle }}

    p.modal-bysubphased__text {{ texts.text }}

    Button.modal-bysubphased__button(is-primary @click="closeModal") {{ texts.buttonBySub }}

    Button.modal-bysubphased__button.modal-bysubphased__button--muted(is-secondary @click="onHidden") {{ texts.buttonNoSub }}
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {

  props: {
    onHidden: {
      type: Function,
      required: true
    },
    stage: {
      type: String,
      default: 'quarter'
    }
  },

  data (props) {
    return {
      texts: {
        title: this.$t('getAChance'),
        subtitle: this.$t('buySub'),
        text: this.$t('noSub'),
        buttonBySub: this.$t('buttonBySub'),
        buttonNoSub: this.$t('buttonNoSub')
      },
      urls: {
        url: ''
      },
      stages: props.stage
    }
  },
  mounted () {
    // Установка сообщения в зависимости от случайного числа
    if (this.stages === 'quarter') {
      this.texts.title = this.$t('getAChance')
      this.texts.subtitle = this.$t('buySub')
      this.texts.text = this.$t('noSub')
      this.texts.buttonBySub = this.$t('buttonBySub')
      this.texts.buttonNoSub = this.$t('buttonNoSub')
      this.urls.url = this.localeLocation('/store/subscriptions?purchase=6')
    }
    if (this.stages === 'semi-final') {
      this.texts.title = this.$t('getAChance2')
      this.texts.subtitle = this.$t('buySub2')
      this.texts.text = this.$t('noSub2')
      this.texts.buttonBySub = this.$t('buttonBySub2')
      this.texts.buttonNoSub = this.$t('buttonNoSub2')
      this.urls.url = this.localeLocation('/missions')
    }
    if (this.stages === 'final') {
      this.texts.title = this.$t('getAChance2')
      this.texts.subtitle = this.$t('buySub2')
      this.texts.text = this.$t('noSub2')
      this.texts.buttonBySub = this.$t('buttonBySub2')
      this.texts.buttonNoSub = this.$t('buttonNoSub2')
      this.urls.url = this.localeLocation('/missions')
    }
  },

  methods: {
    ...mapMutations('modal', ['toggle']),
    ...mapActions('modal', ['showModal']),

    closeModal () {
      this.toggle(false)
      this.$router.push(this.urls.url)
    }
    /* joinToTournament () {
        // Вызов метода toggle для открытия модального окна FreerollAdModal
        this.showModal({
          component: 'FreerollAdModal',
          data: {
            onAdEnd: () => {
              this.joinTournament()
            }
          }
        })
      } */
  }
}
</script>

<style lang="scss">
.modal.modal--modalbysubphased {
  max-width: 420px;

  @media (min-width: $breakpointTablet) {
    padding: 40px 51px !important;
    max-width: 520px;
  }
}
</style>

<style lang="scss" scoped>
.modal-bysubphased {
  color: $white;
  text-align: center;

  &__image {
    margin-bottom: 29px;
  }

  &__title {
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
  }

  &__text {
    margin-bottom: 32px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  &__subtitle {
    margin-top: 20px;
    margin-bottom: 28px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }

  &__button {
    margin-top: 32px;
    width: 100% !important;

    &--muted {
      &::v-deep {
        .button__text {
          color: #90A4AE !important;
        }
      }
    }
  }
}
</style>
