<template lang="pug">
  .modal-create-team
    .modal__header {{ $t('teamCreation') }}

    .modal__body.modal-create-team__body
      FormInput(:placeholder="$t('comeUpWithCoolName')" v-model="name" :title="$t('teamName')")

      transition(name="fade" duration="200")
        InfoMessage(v-if="error.length" type="error") {{ error }}

    .modal__footer
      Button(is-primary :is-disabled="isButtonDisabled" @click="onCreateTeam") {{ $t('create') }}

</template>

<script>
import FormInput from '~/components/FormInput/FormInput'
import TournamentMixin from '~/mixins/Tournament'
import InfoMessage from '~/components/InfoMessage/InfoMessage'

const MAX_NAME_LENGTH = 20

export default {
  name: 'ModalCreateTeam',

  components: { FormInput, InfoMessage },

  mixins: [TournamentMixin],

  props: {
    tournament: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      name: '',
      error: ''
    }
  },

  computed: {
    isButtonDisabled () {
      return !this.name || !!this.error.length
    }
  },

  watch: {
    name () {
      this.error = ''

      if (this.name.length > MAX_NAME_LENGTH) {
        this.error = this.$t('teamNameError', { length: MAX_NAME_LENGTH })
      }
    }
  },

  methods: {
    async onCreateTeam () {
      await this.createTeam({ name: this.name })
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalcreateteam {
  max-width: 480px;
  padding-bottom: 0;
}

.modal-create-team {
  &__body {
    margin: 24px 0;
  }

  .info-message {
    margin-top: 12px;
  }
}
</style>
