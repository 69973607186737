import { render, staticRenderFns } from "./ModalEditSocials.vue?vue&type=template&id=f59e6c54&scoped=true&lang=pug"
import script from "./ModalEditSocials.vue?vue&type=script&lang=js"
export * from "./ModalEditSocials.vue?vue&type=script&lang=js"
import style0 from "./ModalEditSocials.vue?vue&type=style&index=0&id=f59e6c54&prod&lang=scss"
import style1 from "./ModalEditSocials.vue?vue&type=style&index=1&id=f59e6c54&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f59e6c54",
  null
  
)

export default component.exports