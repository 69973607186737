import Vue from 'vue'
import { normalizeNotification } from '~/utils/notifications'

const NOTIFICATION_DURATION = 5000

export const state = () => ({
  notifications: [],
  newNotifications: [],
  isNotificationsVisible: false
})

export const actions = {
  async fetchNotifications ({ commit, rootState }) {
    try {
      const { data } = await this.$axios.get('/notifications')

      const list = await Promise.all(
        data.data.map(item =>
          normalizeNotification(item, {
            router: this.$router,
            $axios: this.$axios,
            errors: this.$errors,
            $t: this.$i18n.t.bind(this.$i18n),
            state: rootState,
            localeLocation: this.localeLocation
          })
        )
      )

      commit('setNotifications', list)
    } catch (e) {
      console.error(`${new Date().toUTCString()} :: notifications error ::`, e)
    }
  },

  markAsRead ({ commit, dispatch }, item) {
    if (item.is_read) {
      return
    }
    this.$axios.post('/notifications', { ids: [item.id] })
    commit('markAsRead', item)
  },

  setNewNotification ({ commit, dispatch }, item) {
    dispatch('fetchNotifications')
    commit('setNewNotification', item)

    setTimeout(() => {
      commit('removeNewNotification', item)
    }, NOTIFICATION_DURATION)
  }
}

export const mutations = {
  toggleNotifications (state, value) {
    if (value === undefined) {
      state.isNotificationsVisible = !state.isNotificationsVisible
    } else {
      state.isNotificationsVisible = value
    }
  },

  markAsRead (state, payload) {
    state.notifications.concat(state.newNotifications).forEach((item) => {
      if (item.id === payload.id) {
        item.is_read = true
      }
    })
  },

  setNotifications (state, payload) {
    // eslint-disable-next-line import/no-named-as-default-member
    Vue.set(
      state,
      'notifications',
      payload.filter(item => item.id)
    )
  },

  clearNewNotifications (state) {
    state.newNotifications = []
  },

  removeNewNotification (state, item) {
    state.newNotifications = state.newNotifications.filter(
      notification => notification.id !== item.id
    )
  },

  setNewNotification (state, item) {
    if (
      !state.newNotifications.find(
        notification => notification.id === item.id
      )
    ) { state.newNotifications.push(item) }
  },

  addNotification (state, item) {
    state.notifications.unshift(item)
  }
}
