// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_7US\\+9{width:100%;max-width:600px;padding-top:10px}.title_Wh\\+8s{color:#fff;font-size:32px;line-height:120%;font-weight:700;margin-bottom:32px}.stars_L\\+gB2{display:grid;grid-template-columns:repeat(5, 40px);grid-gap:9px;margin:24px auto;width:max-content}.stars_L\\+gB2:hover .modal-questionnaire__star-img_Kk66G path{fill:#ffca28}.star_p45sE{width:100%}.star_p45sE:hover~.modal-questionnaire__star_laBkc .modal-questionnaire__star-img_Kk66G path{fill:#90a4ae}.star__img_S9MNy{cursor:pointer}.star__imgActive_5SjOy path{fill:#ff6f00 !important}.star__img_S9MNy path{fill:#90a4ae}.star__img_S9MNy:hover path{fill:#ffca28}.button_7D-Zd{min-width:100%;margin-top:60px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "modal_7US+9",
	"title": "title_Wh+8s",
	"stars": "stars_L+gB2",
	"modal-questionnaire__star-img": "modal-questionnaire__star-img_Kk66G",
	"star": "star_p45sE",
	"modal-questionnaire__star": "modal-questionnaire__star_laBkc",
	"star__img": "star__img_S9MNy",
	"star__imgActive": "star__imgActive_5SjOy",
	"button": "button_7D-Zd"
};
module.exports = ___CSS_LOADER_EXPORT___;
