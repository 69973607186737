import numeral from 'numeral'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { EVENT_STATUSES, TICKETS_NOMINAL } from '~/utils/constants'
import { getDateText, postUrl } from '~/utils/utils'
import { create24MetricsScript } from '~/utils/24metrics'
import CheckPermissionsService from '~/services/CheckPermissionsService'

export default {
  data () {
    return {
      isJoiningRequestLoading: false,
      fingerprint: undefined,
      uuid: undefined,
      times: 0
    }
  },

  computed: {
    ...mapState('games', ['gamesById', 'gamesByName']),
    ...mapState('user', ['user', 'verification', 'tickets', 'freerollCount', 'is24Finished', 'reason']),
    ...mapState('team', ['team']),
    ...mapGetters('user', [
      'isAnticheatEnabled',
      'gameAccount',
      'isAuthorized',
      'subscriptions'
    ]),

    hasFreerollSubscriprion () {
      const subscription = this.subscriptions?.find(
        sub => sub.type === 'subscription_freeroll'
      )

      return !!(subscription && subscription?.extra_data?.duration > 0)
    },

    isLobbyReady () {
      return this.pubgLobby || this.clashRoyaleTag || this.brawlStarsRoomCode
    },

    pubgLobby () {
      return this.tournament.extra_data?.state?.game?.extra_data?.id
    },

    pubgPassword () {
      return this.tournament.extra_data?.state?.game?.extra_data?.password
    },

    clashRoyaleTag () {
      return this.tournament.extra_data?.state?.game?.tag
    },

    clashRoyalePassword () {
      return this.tournament.extra_data?.config?.password
    },

    brawlStarsRoomCode () {
      return this.tournament.extra_data?.state?.game?.extra_data?.code
    },

    bonus () {
      if (
        this.tournament.bonus_configuration?.is_enabled &&
        this.tournament.bonus_configuration?.prize_settings
      ) {
        // checking if bonus is expired
        if (
          this.tournament.bonus_configuration?.type === 'date' &&
          Date.parse(this.tournament.bonus_configuration?.date) -
            Date.parse(new Date()) <
            0
        ) {
          return null
        }

        const prize = this.tournament.bonus_configuration?.prize_settings
        let amount =
          prize.currency === 'money'
            ? this.formatNumber(prize.amount / 100)
            : this.formatNumber(prize.amount)
        let percents = this.tournament.entry_fee === 0 ? 100 : this.formatNumber(
          (prize.amount / this.tournament.entry_fee) * 100
        )

        if (prize.currency === 'entry_fee') {
          const cents = this.isPaid ? 100 : 1
          amount = ((this.tournament.entry_fee / 100) * prize.amount) / cents
          percents = prize.amount
        }

        return {
          ...prize,
          percents,
          crossed: this.entryFee,
          value: this.entryFee - amount
        }
      }

      return null
    },

    youtubeLink () {
      return this.tournament?.stream_links?.youtube
    },

    twitchLink () {
      return this.tournament?.stream_links?.twitch
    },

    tiktokLink () {
      return this.tournament?.stream_links?.tiktok
    },

    telegramLink () {
      return 'https://t.me/VVVGamersBot'
    },

    discordLink () {
      return 'https://discord.gg/XvagBkhPpS'
    },

    commission () {
      const total = this.entryFee

      return (
        this.formatNumber(total / 100) * this.tournament.prize_settings.rake
      )
    },

    entryFeeMinusCommission () {
      const total = this.entryFee

      return total - this.commission
    },

    entryFee () {
      let result

      if (this.isPaid) {
        result = this.tournament.entry_fee / 100
      } else {
        result = this.tournament.entry_fee
      }

      return result
    },

    ticketsPrice () {
      return Math.ceil(
        this.entryFee /
          Math.floor(
            this.tickets?.length
              ? this.tickets[0].extra_data.amount / 100
              : TICKETS_NOMINAL
          )
      )
    },

    canSendReport () {
      return this.game.name === 'lichess'
    },

    rules () {
      let rules = ''
      let allRules = ''
      const isArray = Array.isArray(this.tournament?.rules_link_array)
      const locale = this.$i18n.locale

      if (isArray) {
        rules = this.tournament.rules_array?.find(r => r.language === locale)

        if (!rules) {
          rules = this.tournament.rules_array?.find(
            r => r.language === 'en-US' || r.language === 'en'
          )
        }
      }

      if (rules) {
        allRules = rules.text
      } else if (this.localisedRules) {
        allRules = this.localisedRules
      } else {
        try {
          const rulesForGames = {
            'brawl-stars': 'tournamentsRulesBrawlStars',
            'clash-royale': 'tournamentsRulesClashRoyale',
            lichess: 'tournamentsRulesLichess',
            'pubg-mobile': 'tournamentsRulesPubgMobile'
          }
          allRules = this.$t(rulesForGames[this.game.name])
        } catch (e) {
          console.error(
            `${new Date().toUTCString()} :: tournament (game ${
              this.game.name
            }) rules error ::`,
            e
          )
        }
      }

      return allRules
    },

    rulesLink () {
      let rulesLink = ''
      const isArrayRulesLink = Array.isArray(this.tournament?.rules_array)
      const locale = this.$i18n.locale

      if (isArrayRulesLink) {
        rulesLink = this.tournament.rules_link_array?.find(
          r => r.language === locale
        )

        if (!rulesLink) {
          rulesLink = this.tournament.rules_link_array?.find(
            r => r.language === 'en-US' || r.language === 'en'
          )
        }
      }

      return rulesLink?.text
    },

    game () {
      if (this.$route.params.game) {
        return this.gamesByName[this.$route.params.game]
      }

      return this.gamesById[this.tournament?.game_id]
    },

    maxPlayersCount () {
      if (
        isNaN(
          parseInt(
            this.tournament?.tournament_player_settings?.max_players_count
          )
        )
      ) {
        return '∞'
      }

      return (
        this.tournament?.tournament_player_settings.max_players_count -
        (this.tournament.players_count ||
          (this.tournament.players || this.tournament.teams)?.length)
      )
    },

    isLichessTeamRequired () {
      return this.isLichess && this.lichessTeam
    },

    isPubgDesktop () {
      return this.game.name === 'pubg-desktop'
    },

    isPubg () {
      return this.game.name === 'pubg-mobile'
    },

    isPubgNewState () {
      return this.game.name === 'pubg-new-state'
    },

    isFreeFire () {
      return this.game.name === 'free-fire'
    },

    isFortnite () {
      return this.game.name === 'fortnite'
    },

    isLichess () {
      return this.game.name === 'lichess'
    },

    isClashRoyale () {
      return this.game.name === 'clash-royale'
    },

    isBrawlStars () {
      return this.game.name === 'brawl-stars'
    },

    isStandoff () {
      return this.game.name === 'standoff'
    },
    isDota () {
      return this.game.name === 'dota2'
    },
    isMobileLegends () {
      return this.game.name === 'mobile-legends'
    },
    isCallOfDuty () {
      return this.game.name === 'call-of-duty-mobile' || this.game.name === 'call-of-duty-pc'
    },
    lichessTeam () {
      return this.tournament?.extra_data?.config?.conditions?.teamMember?.teamId
    },

    webcamLink () {
      return this.tournament?.extra_data?.webcam?.link
    },

    statusText () {
      return this.$t('tournamentState', {
        state: this.$t(EVENT_STATUSES[this.tournament.state])?.toLowerCase()
      })
    },

    isCounterVisible () {
      return (
        this.tournament.state === 'open' &&
        this.$dateFns.differenceInDays(this.startsAt, new Date()) < 1
      )
    },

    isPhased () {
      return this.$route.name.includes('phased-tournaments')
    },

    statusClass () {
      if (!this.tournaments) {
        return
      }

      const state = this.tournament.state

      return {
        'page-head__entry-state--opened': state === 'open',
        'page-head__entry-state--closed': ['completed', 'cancelled'].includes(
          state
        ),
        'page-head__entry-state--started': state === 'in_progress'
      }
    },

    timeText () {
      const date = this.startsAt

      return this.$dateFns.format(date, 'HH:mm', { locale: this.$i18n.locale })
    },

    regTimeText () {
      const date = this.regAt

      return this.$dateFns.format(date, 'HH:mm', { locale: this.$i18n.locale })
    },

    dateText () {
      return getDateText.call(this, this.startsAt)
    },

    endDateText () {
      return getDateText.call(this, this.endsAt)
    },

    regAt () {
      if (!this.tournament?.registration_starts_at) {
        return null
      }

      const date =
        this.tournament.registration_starts_at?.replace(' ', 'T') + 'Z'

      return this.$dateFns.parseISO(date)
    },

    regStarted () {
      if (!this.tournament?.registration_starts_at) {
        return true
      }

      return this.$dateFns.isBefore(this.regAt, new Date())
    },

    startsAt () {
      if (!this.tournament?.date) {
        return null
      }

      const date = this.tournament.date?.replace(' ', 'T') + 'Z'

      return this.$dateFns.parseISO(date)
    },

    endsAt () {
      if (!this.tournament?.date) {
        return null
      }
      const date = this.tournament.ends_at?.replace(' ', 'T') + 'Z' || ''

      return this.$dateFns.parseISO(date)
    },

    hasAccess () {
      if (
        this.team?.status === 'paid' &&
        this.tournament.teams?.find(team => team.id === this.team?.id)
      ) {
        return true
      }

      return (
        this.user?.id &&
        this.tournament?.players?.some(player => player.id === this.user?.id)
      )
    },

    hasAnticheat () {
      if (!this.tournament.is_anticheat_required) {
        return true
      }

      return !this.$device.isWindows || this.isAnticheatEnabled
    },

    teamSize () {
      return this.tournament?.tournament_player_settings?.team_size
    },

    isTeamNeeded () {
      if (this.tournament?.state !== 'open' || !this.tournament.teams) {
        return false
      }

      if (this.team?.tournament_id) {
        return this.tournament.id !== this.team.tournament_id
      }

      return !this.team
    },

    canJoinTournament () {
      const canPayTeam =
        !this.isTeamNeeded &&
        this.team?.members?.find(
          player =>
            player?.id === this.user.id &&
            !['invited', 'paid'].includes(player.team_member_status)
        )

      if (this.tournament.teams && !canPayTeam) {
        return false
      }

      let LATE_JOIN_MINUTES_AFTER_TOURNAMENT_START = 15
      if (this.tournament.registration_ends_at) {
        LATE_JOIN_MINUTES_AFTER_TOURNAMENT_START = this.$dateFns.differenceInMinutes(new Date(this.tournament.registration_ends_at.replace(' ', 'T') + 'Z'), this.startsAt)
      }
      // const BRAWL_MINUTES_BEFORE_TOURNAMENT_START_CLOSE = -10
      // const BRAWL_MINUTES_BEFORE_TOURNAMENT_START_OPEN = -120
      const PHASED_MINUTES_BEFORE_TOURNAMENT_START_CLOSE = -15

      const diff = this.$dateFns.differenceInMinutes(new Date(), this.startsAt)

      if (this.$route.name.startsWith('game-phased-tournaments-id_')) {
        return (
          diff < PHASED_MINUTES_BEFORE_TOURNAMENT_START_CLOSE &&
          this.tournament.state === 'open'
        )
      } else if (this.isPubg || this.isFreeFire || this.isPubgNewState) {
        return (
          new Date() < this.startsAt &&
          this.tournament.state === 'open'
        )
      } else if (['lichess', 'brawl-stars'].includes(this.game.name)) {
        return (
          diff < LATE_JOIN_MINUTES_AFTER_TOURNAMENT_START &&
          ['open', 'in_progress'].includes(this.tournament.state)
        )
      } else {
        return this.tournament.state === 'open'
      }
    },

    headWarning () {
      let warning = null

      if (this.tournament?.state === 'open') {
        if (this.hasAccess) {
          if (
            (this.isPubg || this.isFreeFire || this.isPubgNewState) &&
            this.tournament.extra_data?.config?.map !== 'Эрангель'
          ) {
            warning = this.$t('pubgmMapWarning', {
              map: this.$t(this.tournament.extra_data?.config?.map)
            })
          }
        } else if (this.$route.name.startsWith('game-phased-tournaments-id_')) {
          warning = this.$t('registrationCloseWarning')
        }
        // else if (this.isBrawlStars) {
        //   warning = this.$t('brawlStarsClosingWarning')
        // }
      }

      return warning
        ? {
          text: warning
        }
        : null
    },

    isTournamentFull () {
      return (
        this.tournament?.players_count >=
        this.tournament?.tournament_player_settings?.max_players_count
      )
    },

    isPaid () {
      return this.tournament?.prize_settings?.entry_fee_currency === 'money'
    },

    maxPrizePool () {
      let membersCount =
        this.tournament?.tournament_player_settings?.max_players_count

      if (this.tournament?.teams) {
        membersCount =
          this.tournament?.tournament_player_settings?.max_players_count *
          this.tournament?.tournament_player_settings?.team_size
      }

      return this.formatNumber(
        (this.entryFee -
          (this.entryFee / 100) * this.tournament?.prize_settings?.rake) *
          membersCount
      )
    },

    isDoublePrize () {
      const prize = this.tournament?.prize_settings

      if (!prize) {
        return false
      }

      return prize.prize_distribution_type === 'double'
    },

    doublePrize () {
      if (!this.isDoublePrize) {
        return null
      }

      return this.entryFeeMinusCommission * 2
    },

    bannedPlayersList () {
      if (this.tournament?.state !== 'completed') {
        return null
      }

      if (this.tournament.teams) {
        const players = []

        this.tournament.teams.forEach(({ members }) =>
          members.forEach((player) => {
            if (
              player.is_on_moderation ||
              player.ban_reason ||
              player.is_prize_removed
            ) {
              players.push(player)
            }
          })
        )

        return players?.length ? players : null
      }

      return this.tournament.players.filter((player) => {
        return (
          (player.is_on_moderation ||
            player.ban_reason ||
            player.is_prize_removed) &&
          !player.place
        )
      })
    },

    withRewardsPlaces () {
      return (
        !this.tournament.prize_settings?.place_distribution_type ||
        ['mixed', 'place'].includes(
          this.tournament.prize_settings?.place_distribution_type
        )
      )
    },

    withRewardsPoints () {
      return ['mixed', 'point'].includes(
        this.tournament.prize_settings?.place_distribution_type
      )
    },

    isFreeroll () {
      return (
        ['money', 'tickets'].includes(
          this.tournament.prize_settings?.prize_currency
        ) && this.tournament.prize_settings?.entry_fee_currency === 'coins'
      )
    },

    isFreerollBlocked () {
      if (
        this.hasFreerollSubscriprion ||
        // !this.isFreeroll ||
        this.isTournamentSponsored
      ) {
        return false
      }

      return true // this.freerollCount < 1
    },

    isFreerollSponsored () {
      return this.tournament.is_sponsored
    },

    isTournamentSponsored () {
      return this.tournament?.is_sponsored
    },

    areTournamentResultsNeeded () {
      return (
        this.hasAccess &&
        ['moderation'].includes(this.tournament.state) &&
        this.game.name === 'standoff'
      )
    },

    canTeamJoinTournament () {
      return this.team && !this.team?.tournament_id
    },

    isTeamCaptain () {
      if (!this.team) {
        return false
      }

      return this.team.members?.some(
        p => p?.is_captain && this.user?.id === p.id
      )
    },

    needToWaitTeamToJoin () {
      if (
        this.tournament.teams &&
        this.canTeamJoinTournament &&
        this.regStarted &&
        !this.isTeamCaptain
      ) {
        return true
      }

      return false
    }
  },

  methods: {
    ...mapActions('modal', ['showModal']),
    ...mapActions('user', ['fetchFreerollCount']),
    ...mapMutations('team', ['setTeam']),
    ...mapMutations('user', ['setIs24Finished', 'setReason']),

    async leaveTeam (team) {
      try {
        await this.$axios.post(`/teams/${this.game.name}/${team.id}/leave`)
      } catch (e) {
        console.error(`${new Date().toUTCString()} :: team leave error ::`, e)
        this.$errors.handle(e)
      }

      return await this.fetchTeam()
    },

    openCreateTeamModal () {
      this.$store.dispatch('modal/showModal', {
        component: 'ModalCreateTeam',
        data: {
          tournament: this.tournament
        },
        shouldClose: false
      })
    },

    async createTeam ({ name }) {
      if (this.team && !this.team.tournament_id) {
        await this.leaveTeam(this.team)
      }

      this.$store.dispatch('modal/showModal', {
        component: 'ModalCheckRequirements',
        data: {
          tournament: this.tournament,
          onSuccess: async () => {
            try {
              await this.$axios.post(`/teams/${this.game.name}`, {
                max_players_count: this.teamSize,
                name
              })
            } catch (e) {
              console.error(
                `${new Date().toUTCString()} :: team create err ::`,
                e,
                this.$errors.getText(e)
              )

              if (e.response?.data?.code === 89 && this.team) {
                const isPhased = !!this.team.mega_tournament_id

                this.$store.dispatch('modal/showModal', {
                  component: 'ModalMessage',
                  data: {
                    text: this.$errors.getText(e),
                    buttons: [
                      {
                        title: this.$t('checkOut'),
                        action: () => {
                          this.$router.push(
                            this.localeLocation(
                              `/${this.game.name}/${
                                isPhased ? 'phased-tournaments' : 'tournaments'
                              }/${this.team.tournament_id}`
                            )
                          )
                        }
                      }
                    ]
                  }
                })
              } else {
                this.$errors.handle(e)
              }
            }

            await this.fetchTeam()

            if (!this.isTeamNeeded) {
              this.$store.dispatch('modal/showModal', {
                component: 'ModalTeam',
                data: {
                  team: this.team,
                  tournament: this.tournament,
                  onTeamUpdate: () => this.fetchTeam()
                }
              })
            }
          }
        },
        shouldShow: false
      })
    },

    async fetchTeam () {
      try {
        const { data } = await this.$axios.get(`/teams/${this.game.name}/my`)
        const teams = data?.data
        let team = null

        for (let i = 0; i < teams?.length; i++) {
          const t = teams[i]

          if (t.tournament_id) {
            if (this.tournament.id === t.tournament_id) {
              team = t
              break
            } else if (i === teams?.length - 1) {
              team = t
              break
            }
          } else {
            team = t
            break
          }
        }

        if (team?.mega_tournament_id) {
          team.tournament_id = team.mega_tournament_id
        }

        this.setTeam(team || null)

        if (this.team) {
          this.$socket.off(`team:${this.team.id}`)
          this.$socket.on(`team:${this.team.id}`, () => {
            this.fetchTeam()
          })
        }
      } catch (e) {
        console.error(`${new Date().toUTCString()} :: get team error ::`, e)
        this.setTeam(null)
      }

      this.$store.commit('modal/updateComponentData', {
        component: 'ModalTeam',
        data: { team: this.team }
      })
    },

    formatNumber (number) {
      return Number(number).toFixed(2).replace('.00', '')
    },

    formatPrice (number) {
      return numeral(number).format('0,0').replace(/,/g, ' ')
    },

    getRatingText () {
      const min = this.tournament.min_rating
      const max = this.tournament.max_rating

      if (!min && !max) {
        return this.$t('ratingAny')
      }

      if (!min) {
        return this.$t('ratingBefore', { rating: this.formatPrice(max) })
      }

      if (!max) {
        return this.$t('ratingAfter', { rating: this.formatPrice(min) })
      }

      return `${this.formatPrice(min)} — ${this.formatPrice(max)}`
    },

    onWebcamClick () {
      window.open(this.webcamLink)
    },

    onCreateDuelClick () {
      if (!this.isAuthorized) {
        this.showModal({
          component: 'Auth',
          data: {
            mode: 'register',
            afterLogin: () => {
              this.onCreateDuelClick()
            }
          }
        })

        return
      }

      this.showModal({
        component: 'ModalCheckRequirements',
        data: {
          duel: { state: 'create' },
          onSuccess: () => {
            this.showModal({ component: 'CreateDuel' })
          }
        },
        shouldShow: false
      })
    },

    async joinTournamentAsTeam () {
      this.$nuxt.$loading.start()

      try {
        const tournamentType = this.getTournamentPath()

        await this.$axios.post(
          `/teams/${this.game.name}/${this.team.id}/${tournamentType}/${this.tournament.id}/join`,
          {}
        )
      } catch (e) {
        const errorCode = e.response?.data?.code

        if (errorCode === 90) {
          this.showModal({
            component: 'ModalMessage',
            data: {
              title: this.$t('error'),
              type: 'error',
              text: this.$t('teamJoiningError')
            }
          })
        } else {
          this.$errors.handle(e)
        }

        console.error(
          `${new Date().toUTCString()} :: tournament join as team error ::`,
          e
        )
      }

      this.$nuxt.$loading.finish()
    },

    async joinTournamentRequest (tournamentType, method = 'balance', password, isFreeroll) {
      if (this.isJoiningRequestLoading) {
        return
      }
      this.isJoiningRequestLoading = true

      this.$nuxt.$loading.start()

      try {
        let joinData = null

        if (this.tournament.teams && this.team) {
          const { data } = await this.$axios.post(
            `/teams/${this.game.name}/${this.team.id}/${tournamentType}/${this.tournament.id}/confirm`,
            {
              entry_method: method,
              private_password: password
            }
          )

          joinData = data
        } else {
          const { data } = await this.$axios.post(
            `/games/${this.game.name}/${tournamentType}/${this.tournament.id}/join`,
            {
              entry_method: method,
              private_password: password
            }
          )

          joinData = data
        }

        // if (this.game?.name === 'pubg-mobile' && this.isFreeroll) {
        //   this.showModal({
        //     component: 'ModalOffer',
        //   })
        // }

        postUrl('KochavaTracker://sendEventString?nameString=purchase&priceString=0.02')

        if (isFreeroll) {
          this.showModal({
            component: 'OfferwallModal',
            data: {
              id: 2
            }
          })
        } else {
          this.showModal({
            component: 'EnterTournamentSuccess',
            data: {
              tournament: this.tournament,
              isPhasedTournament: tournamentType.startsWith('mega-tournament')
            }
          })
        }

        await this.fetchFreerollCount()
        this.sendAnalytics(tournamentType, method, joinData.data)
      } catch (e) {
        console.error(
          `${new Date().toUTCString()} :: tournament join error ::`,
          e
        )
        this.handleJoiningError(this.$errors.getText(e), e.response?.data?.code)

        this.$gtag.event('tournament_join_fail', {
          game: this.game.name,
          error: this.$errors.getText(e),
          tournament_id: this.tournament.id
        })
      }

      this.$nuxt.$loading.finish()
      this.isJoiningRequestLoading = false
    },

    joinTournament (tournamentType = 'tournaments') {
      if (!this.user) {
        this.showModal({
          component: 'Auth',
          data: {
            afterLogin: () => {
              this.joinTournament(tournamentType)
            }
          }
        })

        return
      }

      const afterRequirements = (password) => {
        if (this.isPaid) {
          this.showModal({
            component: 'ModalPayForParticipation',
            data: {
              title: this.$t('payTournamentTitle'),
              text: this.$t('tournamentParticipation').toLowerCase(),
              tournament: this.tournament,
              afterPay: (method = 'balance') => {
                this.joinTournamentRequest(tournamentType, method, password)
              }
            }
          })
        } else {
          this.joinTournamentRequest(tournamentType, 'balance', password)
        }
      }

      const checkForSponsorship = (password) => {
        afterRequirements(password)
      }

      this.showModal({
        component: 'ModalCheckRequirements',
        data: {
          tournament: this.tournament,
          onSuccess: () => {
            if (this.tournament.is_private) {
              this.showModal({
                component: 'ModalPassword',
                data: {
                  tournament: this.tournament,
                  onNext: (password) => {
                    checkForSponsorship(password)
                  }
                }
              })
            } else {
              checkForSponsorship()
            }
          }
        },
        shouldShow: false
      })
    },

    handleJoiningError (message, code) {
      let text = message
      let buttons

      // рейтинг не подходит
      if (code === 54) {
        text = this.$t('errorCode54')
        // нужно сыграть еще партий
      } else if (code === 55) {
        const ratedCount =
          this.user.game_accounts.find(acc => acc.game === this.game.name)
            ?.extra_data?.data?.perfs?.blitz?.games || 0
        const ratedNeededCount = this.tournament.extra_data.config.conditions
          ? this.tournament.extra_data.config.conditions.nbRatedGame.nb
          : this.tournament.extra_data.config['conditions.nbRatedGame.nb']

        text = this.$t('lichessMoreGamesNeededError', {
          count: ratedNeededCount - ratedCount
        })
      } else if (code === 45) {
        text = this.$t('gameAccountNotLinked')
        buttons = [
          {
            title: this.$t('buttonLink'),
            action: () => {
              this.$router.push(this.localeLocation('/profile/game-accounts'))
            }
          }
        ]
      } else if (code === 37) {
        buttons = [
          {
            title: this.$t('buttonClose'),
            props: { isSecondary: true }
          },
          {
            title: this.$t('toCheckoutBalance'),
            action: () => {
              this.$router.push(this.localeLocation('/profile/balance'))
            }
          }
        ]
      }

      this.showModal({
        component: 'ModalMessage',
        data: {
          text,
          buttons
        }
      })
    },

    async leaveTournament (tournamentType) {
      this.$nuxt.$loading.start()

      try {
        await this.$axios.post(
          `/games/${this.game.name}/${tournamentType}/${this.tournament.id}/leave`
        )

        await this.fetchFreerollCount()
      } catch (e) {
        this.$errors.handle(e)
      }

      this.$nuxt.$loading.finish()
    },

    onEnterEventClick () {
      if (this.isLichess) {
        window.open(
          'https://lichess.org/tournament/' +
            this.tournament.extra_data.state.id
        )
      } else if (this.isClashRoyale) {
        window.open('https://link.clashroyale.com/')
      } else if (this.isBrawlStars) {
        window.open(
          `https://link.brawlstars.com/invite/gameroom/ru?tag=${this.brawlStarsRoomCode}`
        )
      }
    },

    participateTournament () {
      // if (this.isFreerollBlocked) {
      //   return () => {
      //     return this.showModal({
      //       component: 'ModalBySub',
      //       data: {
      //         joinTournament: this.participateTournament
      //       }
      //     })
      //   }
      // }

      return this.joinTournament(this.getTournamentPath())
    },

    checkUserForAmericanTournaments () {
      const date = new Date().getTime()

      create24MetricsScript(this.$el, this.$cookiz, this.$axios, { id: `t_us_${new Date().getTime()}`, source: 'T-US' }, this.user?.id, 'check-t-result', `${this.user?.id}_${date}`, (a, b) => {
        this.fingerprint = a
        this.uuid = b
      })
    },

    async checkResult () {
      const { data } = await CheckPermissionsService.checkResultUserPaymentChance(this.$axios, this.transactionId)

      this.reason = data.data?.result

      if (!this.reason && this.times < 20) {
        this.times++
        setTimeout(() => {
          this.checkResult()
        }, 5000)
        return
      }

      this.setIs24Finished(true)
      this.setReason(this.reason)
    },

    async check24 () {
      const { data } = await CheckPermissionsService.checkUserTUS(this.$axios, this.fingerprint, this.uuid)

      this.transactionId = data.data?.transaction

      setTimeout(() => {
        this.checkResult()
      }, 5000)
    },

    onJoinTournamentClick () {
      this.$gtag.event('join_tournament_click', {
        game: this.game.name,
        tournament_id: this.tournament.id
      })

      if (!this.is24Finished) {
        return
      }

      if (this.reason !== 'OK') {
        this.$gtag.event('join_tournament_click_blocked', {
          game: this.game.name,
          tournament_id: this.tournament.id,
          reason: this.reason
        })
        this.showModal({
          component: 'ModalMessage',
          data: {
            type: 'error',
            text: 'Wrong GEO'
          }
        })
        return
      }

      console.log('this.hasFreerollSubscriprion:', this.hasFreerollSubscriprion, 'this.isTournamentSponsored', this.isTournamentSponsored)

      if (!this.hasFreerollSubscriprion && !this.isTournamentSponsored && this.tournament.prize_settings?.entry_fee_currency === 'coins' && this.tournament.entry_fee > 0) {
        return this.showModal({
          component: 'ModalBySub',
          data: {
            joinTournament: this.participateTournament
          }
        })
      } else {
        return this.participateTournament()
      }
    },

    getTournamentPath () {
      if (this.tournament?.teams) {
        return this.isPhased ? 'mega-tournament' : 'tournament'
      }

      return this.isPhased ? 'mega-tournaments' : 'tournaments'
    },

    sendAnalytics (tournamentType, entryMethod, { paymentID }) {
      // const commission =
      //   Math.ceil(this.entryFee / 100) * this.tournament.prize_settings.rake
      //
      // const { id } = await this.$ga.getOperationData('tournament_registration')

      if (tournamentType.includes('mega-tournament')) {
        // this.$ga.onlineEvent({
        //   event: 'purchase',
        //   cm1: this.isPaid ? 0 : this.entryFee,
        //   cm2: this.isPaid ? this.entryFee : 0,
        //   cm3: commission,
        //   cd3: this.game.name,
        //   cd4: this.tournament.id,
        //   cd5: this.isPaid ? 'paid' : 'freeroll',
        //   cd6: this.gameAccount(this.game.name)?.ratings?.default,
        //   cd8: 'mega_tournament_fee',
        //   cd9: id,
        //   transactionId: paymentID,
        //   transactionTotal: this.isPaid ? this.entryFee : 0,
        //   transactionProducts: [
        //     {
        //       sku: this.tournament.id,
        //       name: 'mega_tournament',
        //       category: this.game.name,
        //       price: this.isPaid ? this.entryFee : 0,
        //       quantity: 1
        //     }
        //   ]
        // })
      } else {
        // this.$ga.onlineEvent({
        //   event: 'purchase',
        //   cm1: this.isPaid ? 0 : this.entryFee,
        //   cm2: this.isPaid ? this.entryFee : 0,
        //   cm3: commission,
        //   cd3: this.game.name,
        //   cd4: this.tournament.id,
        //   cd5: this.isPaid ? 'paid' : 'freeroll',
        //   cd6: this.gameAccount(this.game.name)?.ratings?.default,
        //   cd8: 'tournament_fee',
        //   cd9: id,
        //   transactionId: paymentID,
        //   transactionTotal: this.isPaid ? this.entryFee : 0,
        //   transactionProducts: [
        //     {
        //       sku: this.tournament.id,
        //       name: 'tournament',
        //       category: this.game.name,
        //       price: this.isPaid ? this.entryFee : 0,
        //       quantity: 1
        //     }
        //   ]
        // })
      }
    },

    onSendTournamentResultsClick () {
      this.showModal({
        component: 'ModalSendTournamentResults',
        data: { tournament: this.tournament, game: this.game }
      })
    }
  }
}
