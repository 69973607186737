<template lang="pug">
  .bottom-modal
    .bottom-modal__holder
      .bottom-modal__close(@click="close")
        img(:src="require('~/assets/img/icons/close.svg')" alt="close")

      .bottom-modal__wrapper
        .bottom-modal__card(v-for="(item, index) in vvvItems" :key="index+item.order" :class="getItemClassNames(item)" @click="onClick(item)")
          img.bottom-modal__image(v-if="item.type === 'purchase_subscription'" :src="require('~/assets/img/icons/star-gold.svg')" alt="star")
          img.bottom-modal__image(v-else-if="tournamentImage(item)" :src="tournamentImage(item)" width="34" height="34")

          .bottom-modal__info
            p.bottom-modal__title(:class="{'bottom-modal__title--half': item.type === 'purchase_subscription'}") {{ getItemTitle(item) }}
            .bottom-modal__prize-block(v-if="item.type === 'play_tournament'")
              .bottom-modal__prize
                span.bottom-modal__reward {{ $t("prizePool") }}:
                template(v-if="isMoneyPrize(item.data.tournament)")
                  Currency(type="money" is-reversed size="16")
                    span.bottom-modal__pair-value {{ tournamentMoneyPrize(item.data.tournament) }}
                  span.bottom-modal__pair-value-separator(v-if="!isSponsored(item.data.tournament)") /
                Currency(v-if="!isSponsored(item.data.tournament)" type="coins" is-reversed size="16")
                  span.bottom-modal__pair-value {{ tournamentCoinPrize(item.data.tournament) }}
              Countdown(:end-time="itemDate(item)")
                span.bottom-modal__time(slot="process" slot-scope="{ timeObj }")
                  | {{ timeObj.d * 24 + +timeObj.h }}:{{ timeObj.m }}:{{ timeObj.s }}
            span.bottom-modal__reward(v-else-if='getItemReward(item)')
              span.bottom-modal__reward {{ $t('reward') }}:
              Currency(type="money" is-reversed size="16")
                span.bottom-modal__pair-value {{ getItemReward(item) }}
</template>

<script>
import { mapState } from 'vuex'
import UsersService from '~/services/UsersService'

export default {
  data () {
    return {
      vvvItems: []
    }
  },

  computed: {
    ...mapState('games', ['gamesById'])
  },

  async created () {
    try {
      const country = await this.$axios.get(`https://api.country.is/?timestamp=${new Date().getTime()}`)
      const data = await UsersService.getMyVVData(this.$axios, country.data.country)
      this.vvvItems = data.data.data.sort(function (a, b) {
        if (a.order > b.order) {
          return 1
        }
        if (a.order < b.order) {
          return -1
        }
      })
    } catch (e) {
      console.log(e)
    }
  },

  methods: {
    close () {
      this.$emit('close')
    },

    tournamentImage (item) {
      return item?.data?.tournament?.image_url || item?.data?.offer?.cover
    },

    getItemClassNames (item) {
      switch (item.type) {
        case 'play_tournament':
          return 'bottom-modal__card--blue'

        case 'offer':
          return 'bottom-modal__card--green'

        default:
          return 'bottom-modal__card--yellow'
      }
    },

    getItemTitle (item) {
      if (item.type === 'purchase_subscription') {
        return this.$t('purchaseSubscription')
      }

      if (item.type === 'connect_game_account') {
        return this.$t('connectAccount')
      }

      if (item.type === 'play_tournament') {
        return item.data?.tournament?.title
      }

      if (item.type === 'offer') {
        return item.data?.offer?.name
      }
    },

    getItemReward (item) {
      if (item.type === 'offer') {
        return (item.data.offer?.payout).toFixed(2).replace('.00', '')
      }
    },

    isMoneyPrize (tournament) {
      return tournament?.prize_settings?.prize_currency === 'money'
    },

    tournamentMoneyPrize (tournament) {
      return this.isMoneyPrize(tournament) ? (tournament?.prize_pool / 100).toFixed(2).replace('.00', '') : '0'
    },

    tournamentCoinPrize (tournament) {
      return this.isMoneyPrize(tournament) ? tournament?.prize_pool * 12 : tournament?.prize_pool
    },

    isSponsored (tournament) {
      return tournament?.is_sponsored
    },

    itemDate (item) {
      return item?.data?.tournament?.date?.replace(' ', 'T') + 'Z'
    },

    onClick (item) {
      switch (item.type) {
        case 'purchase_subscription':
          // this.$gtag.event(`click_my_vvv_modal_item_${item.type}`, {})
          this.$router.push(this.localeLocation('/store/subscriptions'))
          break
        case 'connect_game_account':
          // this.$gtag.event(`click_my_vvv_modal_item_${item.type}`, {})
          this.$router.push(this.localeLocation('/profile/game-accounts'))
          break
        case 'play_tournament':
          // this.$gtag.event(`click_my_vvv_modal_item_${item.type}`, { tournament_id: item.data?.tournament?.id })
          this.$router.push(this.localeLocation(`/${this.gamesById[item.data?.tournament?.game_id].name}/tournaments/${item.data?.tournament?.id}`))
          break
        case 'offer':
          // this.$gtag.event(`click_my_vvv_modal_item_${item.type}`, { offer_id: item.data?.offer?.id })
          this.$router.push(this.localeLocation(`/offer/${item.data?.offer?.id}`))
          break
      }
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.bottom-modal {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background-color: rgba($black, .8);
  overflow: hidden;

  &__holder {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  &__wrapper {
    padding: 16px;
    font-size: 12px;
    color: $white;
    background: #1C2735;
    border-radius: 24px 24px 0 0;
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: 4px;
    margin-bottom: 4px;
    width: 32px;
    height: 32px;
    background: #232F3F;
    border-radius: 54px;
    cursor: pointer;
  }

  &__image {
    height: 34px;
    width: 34px;
    object-fit: cover;
  }

  &__card {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 8px;
    margin-bottom: 4px;
    padding: 6px 8px;
    border-radius: 8px;
    min-height: 48px;

    &--blue {
      border: 1px solid $primary60;
    }

    &--green {
      border: 1px solid $green60;
    }

    &--yellow {
      border: 1px solid $yellow60;
    }
  }

  &__title {
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;

    &--half {
      max-width: 80%;
    }
  }

  &__reward {
    margin-right: 8px;
    color: #90A4AE;
    font-weight: 400;
  }

  &__prize {
    &-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__time {
    color: $primary60;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
  }

  &__pair {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2px;

    &--double {
      grid-column: 1 / 3;
    }

    &-title {
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #90A4AE;

      &--gold {
        color: $amber60;
      }
    }

    &-value {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: $white;

      ::v-deep .currency__icon {
        img {
          width: 12px;
          height: 12px;

          @media (min-width: $breakpointDesktopWide) {
            width: 20px;
            height: 20px;
          }
        }
      }

      &-separator {
        margin: 0 4px;
      }
    }
  }
}
</style>
