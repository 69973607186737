<template lang="pug">
.modal-info
  p.modal-info__text(v-html="text")

</template>

<script>
import { mapMutations } from 'vuex'

export default {
  props: {
    text: {
      type: String,
      default: ''
    }
  },

  methods: {
    ...mapMutations('modal', ['toggle']),

    closeModal () {
      this.toggle(false)
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalinfo {
  max-width: 420px;

  @media (min-width: $breakpointTablet) {
    padding: 40px 51px !important;
    max-width: 520px;
  }
}
</style>

<style lang="scss" scoped>
.modal-info {
  color: $white;

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
