<template lang="pug">
  .new-notification
    transition-group(name="slide-left" duration="200")
      .notifications-list__item(
        v-for="item in newNotifications"
        v-if="item && item.id"
        :key="item.id"
        :class="{ '--not-read': !item.is_read }"
        @mouseenter="onHover(item)"
        @click="onItemClick(item)"
      )
        .notifications-list__item-top
          .notifications-list__item-left(v-if="item.icon")
            img(:src="require(`~/assets/img/notifications/${item.icon}.svg`)")
          .notifications-list__item-left(v-else-if="item.imageUrl")
            img(:src="item.imageUrl")

          .notifications-list__item-right
            .notifications-list__item-date {{ getDate(item) }}
            .notifications-list__item-title {{ item.title || item.type }}
            .notifications-list__item-text(v-if="item.text" v-html="item.text")
            .notifications-list__item-text(v-else-if="item.type === 'duel_accepted' && item.duel")
              | {{ $t('duelNotificationText1') }}&nbsp;
              PlayerGameName(:player="item.duel.opponent" :game="getGame(item)" :with-tooltip="false")
              | &nbsp;{{ $t('duelNotificationText2') }} {{ item.gameTitle }}

        .notifications-list__item-actions(v-if="item.actions && item.actions.length")
          a.notifications-list__item-action(
            v-for="(action, index) in item.actions"
            :key="index"
            :class="{ '--primary': action.isPrimary }"
            @click.prevent="onActionClick(action, item)"
          )
            | {{ action.title }}

</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { normalizeNotification } from '~/utils/notifications'
import PlayerGameName from '~/components/PlayerGameName/PlayerGameName'

export default {
  name: 'NewNotification',
  components: { PlayerGameName },
  data () {
    return {}
  },

  computed: {
    ...mapState('notifications', ['newNotifications', 'notifications']),
    ...mapState('games', ['gamesById']),
    ...mapState('user', ['user'])
  },

  mounted () {
    this.$socket.on('notification:new', async (item) => {
      item = await normalizeNotification(item, {
        router: this.$router,
        $axios: this.$axios,
        errors: this.$errors,
        $t: this.$t.bind(this),
        state: this.$store.state,
        localeLocation: this.localeLocation
      })

      this.setNewNotification(item)
      setTimeout(() => this.checkForTournament(item), 2000) // чтобы данные турнира обновлялись на бекенде вовремя
    })
  },

  beforeDestroy () {
    this.$socket.off('notification:new')
  },

  methods: {
    ...mapActions('notifications', [
      'setNewNotification',
      'fetchNotifications'
    ]),
    ...mapMutations('notifications', ['removeNewNotification']),

    getGame (item) {
      return this.gamesById[
        (item.extra_data?.duel || item.extra_data?.tournament).game_id
      ]
    },

    onItemClick (item) {
      this.removeNewNotification(item)
    },

    async onActionClick (action, item) {
      await action.action()
      this.removeNewNotification(item)

      setTimeout(() => {
        this.fetchNotifications()
      }, 500)
    },

    getDate (item) {
      const date = item.created_at.replace(' ', 'T') + 'Z'

      return this.$dateFns.formatDistanceToNow(this.$dateFns.parseISO(date), {
        includeSeconds: true,
        addSuffix: true,
        locale: this.$i18n.locale
      })
    },

    onHover (item) {
      if (item.is_read) {
        return
      }

      this.$store.dispatch('notifications/markAsRead', item)
    },

    async checkForTournament (item) {
      if (item.type !== 'tournament_won') {
        return
      }

      await this.$store.dispatch('user/fetchFreerolStatus')

      const game = this.gamesById[item.extra_data.tournament.game_id]
      const tournamentId = item.extra_data.tournament.id

      const { data } = await this.$axios.get(
        `/games/${game.name}/tournaments/${tournamentId}`
      )

      const tournament = data.data
      const isFreeroll =
        ['money'].includes(
          tournament.prize_settings?.prize_currency
        ) && tournament.prize_settings?.entry_fee_currency === 'coins'
      const me = tournament.players.find(player => player.id === this.user.id)
      const freerollCount = this.$store.state.user.freerollCount

      if (isFreeroll && me?.place && !freerollCount) {
        const currency = tournament.prize_settings.prize_currency
        const prize = tournament.prizes.places[me.place - 1]

        this.$store.dispatch('modal/showModal', {
          component: 'ModalAfterFreeroll',
          data: { prize, currency }
        })
      }

      // const fetched = await this.$store.dispatch('games/fetchTournaments', {
      //   query: {
      //     filters: [
      //       {
      //         name: 'state',
      //         type: 'string_list',
      //         values: [{ text: 'open' }]
      //       }
      //     ],
      //     limit: 50,
      //     sort: { name: 'date', order: 'asc' }
      //   },
      //   url: `/games/${game.name}/tournaments`
      // })
      //
      // const tournaments = fetched.data
      //   .filter((tournament) => {
      //     return tournament?.prize_settings?.entry_fee_currency === 'money'
      //   })
      //   .slice(0, 6)
      //
      // if (tournaments.length) {
      //   this.$store.dispatch('modal/showModal', {
      //     component: 'ModalAfterFreeroll',
      //     data: { tournaments, prize, currency }
      //   })
      // }
    }
  }
}
</script>

<style lang="scss">
.new-notification {
  box-sizing: border-box;
  position: absolute;
  top: 64px;
  right: 4px;
  max-width: 384px;
  width: 100%;
  z-index: 3;
  transition: top 0.2s $easing;

  .--mini & {
    top: 48px;
  }

  .notifications-list {
    &__item {
      border-radius: 8px;
      background: $blue-grey50;
      margin-top: 10px;
    }
  }

  @media (max-width: $breakpointHeaderMobile) {
    top: 44px;
    left: 4px;
  }
}
</style>
