class CheckPermissionsService {
  constructor () {
    if (CheckPermissionsService.instance) {
      return CheckPermissionsService.instance
    }

    CheckPermissionsService.instance = this

    return this
  }

  checkPermissions (engine, step, data, fingerprint = undefined, uuid = undefined, useragent = undefined, clickId = undefined) {
    return engine.post('/check-permissions', { step, web_id: JSON.stringify({ ...data, fingerprint24m: fingerprint, uuid24m: uuid, useragent24m: useragent, click_id: clickId }) })
  }

  checkUserPaymentChance (engine, fingerprint, uuid) {
    return engine.get(`/account-check/check?fingerprint=${fingerprint}&uuid=${uuid}`)
  }

  checkUserTUS (engine, fingerprint, uuid) {
    return engine.get(`/account-check/check-t?fingerprint=${fingerprint}&uuid=${uuid}`)
  }

  checkResultUserPaymentChance (engine, transaction) {
    return engine.get(`/account-check/get-check-result?transaction=${transaction}`)
  }
}

export default new CheckPermissionsService()
