<template lang="pug">
  .reward-tab(:class="classList")
    .reward-tab__day {{ tab.title }}

    .reward-tab__reward
      .reward-tab__value
        .reward-tab__text {{ tab.reward }}
        .reward-tab__icon
          img(:src="require(`~/assets/img/icons/coins-gold.svg`)" alt="coins-gold")

      .reward-tab__crossed(v-if="hasSubscription")
        .reward-tab__crossed-text {{ tab.reward / 2 }}
        .reward-tab__crossed-icon
          img(:src="require(`~/assets/img/icons/coins-gold.svg`)" alt="coins-gold")

</template>

<script>
export default {
  name: 'RewardTab',

  props: {
    tab: {
      type: Object,
      default: () => ({})
    },

    hasSubscription: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classList () {
      return {
        '--subscribed': this.hasSubscription,
        '--achieved': this.tab.state === 'achieved',
        '--active': this.tab.state === 'active',
        '--last': this.tab.isLast
      }
    }
  }
}
</script>

<style lang="scss">
.reward-tab {
  min-width: 136px;
  background: $blue-grey60;
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 96px;
  justify-content: space-between;
  border-radius: 8px;
  position: relative;

  &__day {
    @include caption-small;

    color: $blue-grey10;

    @media (max-width: $breakpointMobile) {
      font-size: 12px;
    }
  }

  &__reward {
    //display: flex;
  }

  &__crossed {
    position: relative;
    display: flex;
    align-items: center;

    &-text {
      font-weight: $font-bold;
      font-size: 16px;
      line-height: 22px;
      color: $blue-grey20;
    }

    &-icon {
      margin-left: 2px;

      img {
        position: relative;
        bottom: 2px;
        width: 16px;
        height: 16px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      width: 40px;
      height: 1px;
      background-color: $white;
      border-radius: 2px;
      transform: rotate(-24deg);
    }
  }

  &__value {
    display: flex;
    text-transform: uppercase;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $breakpointMobile) {
      max-height: 24px;
      align-items: flex-end;
    }
  }

  &__text {
    font-weight: $font-bold;
    font-size: 20px;
    line-height: 100%;
    color: $white;

    @media (max-width: $breakpointMobile) {
      font-size: 18px;
    }
  }

  &__icon {
    margin-left: 4px;

    img {
      position: relative;
      bottom: 1px;
    }
  }

  &.--subscribed {
    min-width: 170px;
    min-height: 100px;

    .reward-tab {
      &__reward {
        display: flex;
        justify-content: space-between;
      }

      &__value {
        justify-content: left;
      }

      &__crossed {
        g {
          fill: $blue-grey10;
        }
      }
    }

    @media (max-width: $breakpointMobile) {
      min-height: 58px;
      min-width: 152px;
    }
  }

  &.--achieved {
    border: 1px solid $blue-grey40;
    background: $blue-grey80;
    background-image: url(~@/assets/img/icons/checkmark.svg);
    background-position: center center;
    background-size: 56px 56px;
    background-repeat: no-repeat;

    @media (max-width: $breakpointMobile) {
      background-size: 32px 32px;
    }
  }

  &.--active {
    background: #18c13914;
    border: 2px solid #4caf50;

    .reward-tab {
      &__day {
        color: #fff;
      }

      //&__text {
      //  //@include heading3;
      //
      //  @media (max-width: $breakpointMobile) {
      //    font-size: 20px;
      //  }
      //}

      &__icon {
        margin-top: auto;
      }
    }
  }

  &.--last {
    grid-column-start: 1;
    grid-column-end: 4;

    // border: 2px solid $amber60;

    .reward-tab {
      &__text {
        @include heading2;

        @media (max-width: $breakpointMobile) {
          font-size: 22px;
        }
      }

      &__icon {
        margin-top: auto;
      }
    }

    @media (max-width: $breakpointMobile) {
      grid-column-end: 3;
    }
  }

  @media (max-width: $breakpointMobile) {
    height: 68px;
    padding: 12px;
  }
}
</style>
