<template lang="pug">
  .new-year-modal
    .modal__header {{ $t('happyNewYear') }}

    .modal__body
      .new-year-modal__text(v-html="$t('newYearModalText')")

    .modal__footer
      Button(is-red @click="onHoHoHoClick") {{ $t('hoHoHo') }}

</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'NewYearModal',

  methods: {
    ...mapMutations('modal', ['toggle']),

    onHoHoHoClick () {
      this.toggle()
    }
  }
}
</script>

<style lang="scss">
.modal.modal--newyearmodal {
  max-width: 480px;
  padding-bottom: 0;
}

.new-year-modal {
  &__text {
    @include body;

    margin: 16px 0;
    font-weight: 500;
    color: $white;
  }

  .button {
    background: $red80 !important;

    &:hover {
      background: $red60 !important;
    }
  }
}
</style>
