<template lang="pug">
.modal-status-info
  .modal-status-info__title {{ $t('statusOnThePlatform') }}
  .modal-status-info__line
  .modal-status-info__stars-block
    .modal-status-info__stars
      .modal-status-info__star(v-for="i in 5" :key="i")
        img(:src="require('~/assets/img/star.svg')" :class="getStarClass(i)" @click="setCurrentStar(i)" alt="star")
    .modal-status-info__stars-info
      span.modal-status-info__stars-info-text(v-if="currentStarType === ''" v-t="'clickTheStar'")
      .modal-status-info__stars-info-full-block(v-else)
        .modal-status-info__stars-info-full-block-title(v-t="starTitles[currentStarType]")
        .modal-status-info__stars-info-full-block-text(v-t="'expiresIn'")
        .modal-status-info__stars-info-full-block-time(v-if="currentStarExpires")
          Countdown(:end-time="currentStarExpires" :key="currentStarType")
            span(slot="process" slot-scope="{ timeObj }")
              | {{ timeObj.d }} {{$t('days')}} {{ timeObj.h }} {{$t('hours')}}
  .modal-status-info__text-title(v-t="'statusIs'")
  .modal-status-info__text(v-html="$t('statusDescription')")
  Button.modal-status-info__button(is-primary @click="closeModal") {{ $t('iUnderstand') }}
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  props: {
    stars: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      currentStarType: '',
      starTitles: {
        subscription_star: 'subscription',
        offerwall_star: 'offerwalls',
        survey_star: 'surveys'
      }
    }
  },

  computed: {
    starsCount () {
      return this.stars.length
    },

    currentStarExpires () {
      return this.$dateFns.parseISO(this.stars.find(star => star.type === this.currentStarType)?.expires_at.replace(' ', 'T') + 'Z')
    }
  },

  methods: {
    ...mapMutations('modal', ['toggle']),

    getStarClass (i) {
      if (i > this.starsCount) {
        return 'modal-status-info__star-img modal-status-info__star-img--grey'
      }
      return 'modal-status-info__star-img'
    },

    setCurrentStar (i) {
      this.currentStarType = this.stars.map(star => star.type).reduce((a, b) => {
        const result = [...a]
        if (b === 'subscription_star') {
          result.push(b)
          result.push(b)
          result.push(b)
        } else {
          result.push(b)
        }
        return result
      }, [])[i - 1] || ''
    },

    closeModal () {
      this.toggle(false)
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalstatusinfo {
  padding: 60px 0 0 !important;
  max-width: 520px;
}

.modal-status-info {
  padding: 0 20px 60px;

  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    color: $white;
    margin-top: -40px;
  }

  &__line {
    height: 1px;
    width: 100%;
    border-top: 1px solid $blue-grey40;
    margin: 22px 0;
  }

  &__stars {
    display: grid;
    grid-template-columns: repeat(5, max-content);
    grid-gap: 20px;
    width: max-content;
    margin: auto;

    &-block {
      background-color: $blue-grey70;
      border-radius: 16px;
      padding: 28px;
      margin-bottom: 24px;
      border: 1px solid $primary60;
    }

    &-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 14px 54px;
      background-color: $blue-grey60;
      margin-top: 30px;
      border-radius: 8px;

      &-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $white;
        max-width: 228px;
        text-align: center;
      }

      &-full {
        &-block {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 12px;
          background-color: $blue-grey60;
          width: 100%;
          border-radius: 8px;
          color: $white;

          &-title {
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 4px;
          }

          &-text {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 2px;
            color: $blue-grey05;
          }

          &-time {
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }

  &__star {
    cursor: pointer;

    &-img {
      width: 42px;
      height: 42px;

      &--grey {
        cursor: auto;

        path {
          fill: $blue-grey50;
        }
      }
    }
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $blue-grey05;

    b {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: $white;
      margin-bottom: 8px;
    }

    &-title {
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
      color: $white;
      margin-bottom: 8px;
    }
  }

  &__button {
    margin-top: 24px;
    width: 100% !important;
  }
}
</style>
