<template lang="pug">
  .modal(:class="[`modal--${component.toLowerCase()}`, {'modal--large': hasTooLargeHeight}]")
    img.modal__decoration(
      v-if="isNewYear"
      :src="require('~/assets/img/icons/santa-hat.svg')"
      :class="classList"
      @click="onDecorationClick"
      alt="santa hat"
    )

    a.modal__close(v-if="canClose" @click.prevent="toggle(false)")
      img(:src="require('~/assets/img/icons/close.svg')" alt="close")

    component(
      :is="component"
      ref="component"
      v-bind="componentData"
      @recalculatePosition="checkHeight"
      @visibility="setVisibility"
    )
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

import Auth from '~/components/Auth/Auth'
import PlayersListModal from '~/components/PlayersListModal/PlayersListModal'
import CreateDuel from '~/components/CreateDuel/CreateDuel'
import DailyReward from '~/components/DailyReward/DailyReward'
import ModalMessage from '~/components/ModalMessage/ModalMessage'
import ModalMessageNewDesign from '~/components/ModalMessage/ModalMessageNewDesign'
import EnterTournamentSuccess from '~/components/EnterTournamentSuccess/EnterTournamentSuccess'
import ResetPassword from '~/components/ResetPassword/ResetPassword'
import ReportPlayer from '~/components/ReportPlayer/ReportPlayer'
import AuthAnticheat from '~/components/AuthAnticheat/AuthAnticheat'
import AuthOverwolf from '~/components/AuthOverwolf/AuthOverwolf'
import SuccessModal from '~/components/SuccessModal/SuccessModal'
import RecommendedTournamentsModal from '~/components/RecommendedTournamentsModal/RecommendedTournamentsModal'
import BanModal from '~/components/BanModal/BanModal'
import PhaseModal from '~/components/PhaseModal/PhaseModal'
import TeamsListModal from '~/components/TeamsListModal/TeamsListModal'
import PayInModal from '~/components/PayInModal/PayInModal'
import NewPayOutModal from '~/components/PayOutModal/NewPayOutModal'
import PubgMobileAccount from '~/components/PubgMobileAccount/PubgMobileAccount'
import ClashRoyaleAccount from '~/components/ClashRoyaleAccount/ClashRoyaleAccount'
import ModalPhoneConfirmation from '~/components/ModalPhoneConfirmation/ModalPhoneConfirmation'
import BrawlStarsAccount from '~/components/BrawlStarsAccount/BrawlStarsAccount'
import ModalPayForParticipation from '~/components/ModalPayForParticipation/ModalPayForParticipation'
import ModalCheckout from '~/components/ModalCheckout/ModalCheckout'
import PubgDesktopAccount from '~/components/PubgDesktopAccount/PubgDesktopAccount'
import ModalCheckRequirements from '~/components/ModalCheckRequirements/ModalCheckRequirements'
import EmailModal from '~/components/EmailModal/EmailModal'
import ModalTeam from '~/components/ModalTeam/ModalTeam'
import ModalSlots from '~/components/ModalSlots/ModalSlots'
import ModalVerification from '~/components/ModalVerification/ModalVerification'
import ModalPassword from '~/components/ModalPassword/ModalPassword'
import ModalProduct from '~/components/ModalProduct/ModalProduct'
import ModalCreateTeam from '~/components/ModalCreateTeam/ModalCreateTeam'
import Dota2Account from '~/components/Dota2Account/Dota2Account'
import ModalUserLogin from '~/components/ModalUserLogin/ModalUserLogin'
import NewYearModal from '~/components/NewYearModal/NewYearModal'
import ModalRefreshPassword from '~/components/ModalRefreshPassword/ModalRefreshPassword'
import ModalBalanceInfo from '~/components/ModalBalanceInfo/ModalBalanceInfo'
import ModalSubscriptionAdvert from '~/components/ModalSubscriptionAdvert/ModalSubscriptionAdvert'
import ModalSteamConnect from '~/components/ModalSteamConnect/ModalSteamConnect'
import ModalSendTournamentResults from '~/components/ModalSendTournamentResults/ModalSendTournamentResults'
import ModalPUBGMobileTDM from '~/components/ModalPUBGMobileTDM/ModalPUBGMobileTDM'
import ModalOffer from '~/components/ModalOffer/ModalOffer'
import ModalAfterFreeroll from '~/components/ModalAfterFreeroll/ModalAfterFreeroll'
import FreerollAdModal from '~/components/FreerollAdModal/FreerollAdModal'
import ModalCheckoutDevice from '~/components/ModalCheckoutDevice/ModalCheckoutDevice'
import ModalEurOrCoins from '~/components/ModalCheckoutDevice/ModalEurOrCoins'
import ModalMission from '~/components/ModalMission/ModalMission'
import OfferwallModal from '~/components/OfferwallModal/OfferwallModal'
import ModalOfferDetails from '~/components/ModalOfferDetails/ModalOfferDetails'
import ModalStatusInfo from '~/components/ModalStatusInfo/ModalStatusInfo'
import ModalProgressInfo from '~/components/ModalProgressInfo/ModalProgressInfo'
import ModalLeaderboardInfo from '~/components/ModalLeaderboardInfo/ModalLeaderboardInfo'
import ModalSubInfo from '~/components/ModalSubInfo/ModalSubInfo'
import ModalPending from '~/components/ModalPending/ModalPending'
import ModalQuestionnaire from '~/components/ModalQuestionnaire'
import ModalQualityCheck from '~/components/ModalQualityCheck'
import ModalInfo from '~/components/ModalInfo/ModalInfo'
import ModalBySub from '~/components/ModalBySub/ModalBySub'
import ModalBySubPhased from '~/components/ModalBySubPhased/ModalBySubPhased'
import ModalChangeColor from '~/components/ModalsCustomisation/ModalChangeColor'
import ModalUploadImage from '~/components/ModalsCustomisation/ModalUploadImage'
import ModalEditSocials from '~/components/ModalsCustomisation/ModalEditSocials'
import ModalCheckoutProxy from '~/components/ModalCheckoutProxy/ModalCheckoutProxy'
import ModalCheckoutFreespin from '~/components/ModalCheckoutFreespin/ModalCheckoutFreespin'
import ModalChooseGameToLinkAccount from '~/components/ModalChooseGameToLinkAccount'
import ModalTournamentsAfterAccountLink from '~/components/ModalTournamentsAfterAccountLink/ModalTournamentsAfterAccountLink'
import ModalRatingChanged from '~/components/ModalRatingChanged'
import ModalSelectPaymentMethod from '~/components/ModalSelectPaymentMethod/ModalSelectPaymentMethod'
import ModalVVVBonusReceived from '~/components/ModalVVVBonusReceived/ModalVVVBonusReceived'
import AddNewAppModal from '~/components/MarketplaceComponents/Modals/AddNewAppModal.vue'
import AddNewCategoryModal from '~/components/MarketplaceComponents/Modals/AddNewCategoryModal.vue'
import ModalLeaveFeedback from '~/components/MarketplaceComponents/Modals/ModalLeaveFeedback.vue'
import EditProductModal from '~/components/MarketplaceComponents/Modals/EditProductModal.vue'
import LinkTelegram from '~/components/MarketplaceComponents/Modals/LinkTelegram.vue'
import GetForFreeModal from '~/components/MarketplaceComponents/Modals/GetForFreeModal.vue'
import EditSEOModal from '~/components/MarketplaceComponents/Modals/EditSEOModal.vue'

export default {
  name: 'Modal',

  components: {
    ModalPassword,
    ModalVerification,
    ModalSlots,
    ModalTeam,
    ModalCheckRequirements,
    PubgDesktopAccount,
    ModalCheckout,
    ModalPayForParticipation,
    BrawlStarsAccount,
    ModalPhoneConfirmation,
    NewPayOutModal,
    PayInModal,
    TeamsListModal,
    PhaseModal,
    ReportPlayer,
    EnterTournamentSuccess,
    Auth,
    CreateDuel,
    PlayersListModal,
    ModalMessage,
    DailyReward,
    ResetPassword,
    AuthAnticheat,
    SuccessModal,
    RecommendedTournamentsModal,
    BanModal,
    AuthOverwolf,
    PubgMobileAccount,
    ClashRoyaleAccount,
    EmailModal,
    ModalProduct,
    ModalCreateTeam,
    Dota2Account,
    ModalUserLogin,
    NewYearModal,
    ModalRefreshPassword,
    ModalBalanceInfo,
    ModalSubscriptionAdvert,
    ModalSteamConnect,
    ModalSendTournamentResults,
    ModalPUBGMobileTDM,
    ModalOffer,
    ModalAfterFreeroll,
    FreerollAdModal,
    ModalCheckoutDevice,
    ModalEurOrCoins,
    ModalMission,
    OfferwallModal,
    ModalOfferDetails,
    ModalStatusInfo,
    ModalProgressInfo,
    ModalLeaderboardInfo,
    ModalSubInfo,
    ModalPending,
    ModalQuestionnaire,
    ModalQualityCheck,
    ModalInfo,
    ModalBySub,
    ModalBySubPhased,
    ModalChangeColor,
    ModalUploadImage,
    ModalEditSocials,
    ModalMessageNewDesign,
    ModalCheckoutProxy,
    ModalCheckoutFreespin,
    ModalChooseGameToLinkAccount,
    ModalTournamentsAfterAccountLink,
    ModalRatingChanged,
    ModalSelectPaymentMethod,
    ModalVVVBonusReceived,
    AddNewAppModal,
    AddNewCategoryModal,
    ModalLeaveFeedback,
    EditProductModal,
    LinkTelegram,
    GetForFreeModal,
    EditSEOModal
  },

  data () {
    return {
      hasTooLargeHeight: false,
      classList: []
    }
  },

  computed: {
    ...mapState('modal', ['component', 'componentData', 'canClose', 'delay']),
    ...mapGetters('events', ['isNewYear'])
  },

  watch: {
    '$route.path' () {
      this.toggle(false)
    },

    component: {
      handler (value) {
        if (!value) {
          return
        }

        this.checkHeight()
      },
      immediate: true
    }
  },

  mounted () {
    window.addEventListener('resize', this.checkHeight)
    document.addEventListener('keydown', this.onKeyPress)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.checkHeight)
    window.removeEventListener('keydown', this.onKeyPress)
  },

  methods: {
    ...mapMutations('modal', ['toggle', 'setVisibility']),

    checkHeight () {
      this.$nextTick(() => {
        const MODAL_MARGIN = 10

        this.hasTooLargeHeight =
          this.$el.offsetHeight + MODAL_MARGIN > window.innerHeight
      })
    },

    onKeyPress (event) {
      if (event.key === 'Escape') {
        // if esc key was not pressed in combination with ctrl or alt or shift
        const isNotCombined = !(event.ctrlKey || event.altKey || event.shiftKey)

        if (isNotCombined) {
          this.toggle(false)
        }
      }
    },

    onDecorationClick () {
      this.classList.push('bounce')

      setTimeout(() => (this.classList = []), 2000)
    }
  }
}
</script>

<style lang="scss">
.modal {
  width: 100%;
  max-width: 50vw;
  padding: 20px 24px 24px;
  background: $blue-grey70;
  border: 1px solid $blue-grey40;
  height: fit-content;
  border-radius: 16px;
  position: relative;
  top: 50%;
  left: 50vw;
  transform: translateX(-50%) translateY(-50%);
  transform-origin: -150% -150%;
  z-index: 998;

  &:empty {
    opacity: 0;
  }

  &--large {
    top: 24px;
    transform: translateX(-50%);
    transform-origin: -150% 0;
    margin-bottom: 24px;

    @media (max-width: $breakpointMobile) {
      top: 0;
      margin-bottom: 0;
    }
  }

  &__decoration {
    position: absolute;
    right: -19px;
    top: -26px;
    max-width: 72px;
    max-height: 72px;
    cursor: pointer;

    &.bounce {
      animation-duration: 1s;
      animation-name: bounce;
    }

    @media (max-width: $breakpointMobile) {
      right: -8px;
      top: -32px;
    }
  }

  &__footer {
    padding: 11px 24px;
    margin: 0 -24px;
    border-top: 1px solid $blue-grey30;
    display: flex;
    justify-content: flex-end;
  }

  &__close {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    background: $blue-grey60;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;

    &:only-child {
      display: none;
    }

    @media (max-width: $breakpointDesktopMedium) {
      top: 20px;
    }

    img {
      transition: transform 0.2s $easing;
    }

    &:hover {
      background: $blue-grey40;

      img {
        transform: rotate(90deg);
      }
    }

    &:active {
      background: $blue-grey20;
    }
  }

  &__header {
    @include heading2;

    padding: 0 24px 20px;
    margin: 0 -24px;
    color: $white;
    position: relative;
    line-height: 36px;

    &::before {
      content: "";
      position: absolute;
      display: block;
      left: -1px;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: $blue-grey40;
    }
  }

  @media (max-width: $breakpointMobile) {
    width: 100% !important;
    padding: 16px;

    &__close {
      right: 16px;
      top: 16px;
    }

    &__header {
      margin: 0 -16px;
    }

    &__footer {
      margin: 0 -16px;
    }
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-20px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-20px);
  }
}
</style>
