<template lang="pug">
  .success-modal
    .success-modal__img(v-if="icon")
      img(:src="require(`~/assets/img/icons/${icon}.svg`)" :alt="icon")

    .success-modal__title {{ title || $t('notification') }}

    .success-modal__text {{ text }}

    .success-modal__buttons(v-if="buttons.length")
      Button.success-modal__btn(
        v-for="(button, index) in buttons"
        :key="index"
        v-bind="button.props"
        @click="onAction(button)"
      )
        | {{ button.title }}

</template>

<script>
export default {
  name: 'SuccessModal',

  props: {
    icon: {
      type: String,
      default: ''
    },

    title: {
      type: String,
      default: ''
    },

    text: {
      type: String,
      default: '',
      required: true
    },

    buttons: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    closeModal () {
      this.$store.commit('modal/toggle')
    },

    onAction ({ action, shouldClose = true }) {
      action?.()

      if (shouldClose) {
        this.closeModal()
      }
    }
  }
}
</script>

<style lang="scss">
.modal.modal--successmodal {
  max-width: 490px;
}

.success-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    margin-top: 16px;
    color: $white;
    font-size: 32px;
    text-align: center;
  }

  &__text {
    text-align: center;
    font-size: 20px;
    line-height: 120%;
    color: $blue-grey10;
    margin-top: 16px;
  }

  &__buttons {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;

    .button {
      margin-top: 12px;
      width: 100% !important;
    }
  }
}
</style>
