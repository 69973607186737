<template lang="pug">
  .file-upload(:class="{'--password': type === 'password', '--error': error }" @click="clickInput")
    .file-upload__title(v-if="title")
      | {{ title }}
    label.file-upload__label
      input(ref="input" type="file" @change="onImageChange" accept="image/png, image/jpeg, image/jpg")
      span(v-if="value && !short") {{ value }}
      span.file-upload__placeholder(v-else-if="!short") {{ placeholder }}
      slot(name="button")
        Button(is-secondary) {{ $t(buttonText) }}
      .form-input__error(v-if="error && !short")
        | {{ error }}
</template>

<script>
import FileUpload from '~/components/FileUpload/FileUpload'

export default {
  name: 'ImageUpload',

  extends: FileUpload,

  props: {
    buttonText: {
      type: String,
      default: 'buttonChooseFile'
    },

    short: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    clickInput () {
      this.$refs.input.click()
    },

    async onImageChange () {
      const file = this.$refs.input
      const form = new FormData()
      form.append('image', file.files[0])

      if (file.files[0]) {
        this.$nuxt.$loading.start()

        try {
          const { data } = await this.$axios.post('/user/upload', form)

          this.$emit('input', data.data)
        } catch (e) {
          this.$errors.handle(e)
          console.error(
            `${new Date().toUTCString()} :: image upload error ::`,
            e
          )
        }

        this.$nuxt.$loading.finish()
      }

      this.$emit('blur')
    }
  }
}
</script>
