<template lang="pug">
  .modal-send-tournament-results
    .modal__header {{ $t('tournamentResults') }}

    .modal__body.modal-send-tournament-results__body
      .modal-send-tournament-results__field(v-for="(field, key) in fields")
        ImageUpload(
          v-if="field.type === 'image'"
          v-bind="field"
          v-model="field.value"
          @input="onFieldInput(field, key)"
          @blur="onFieldBlur(field, key)"
        )

        FormInput(
          v-else-if="field.type === 'textarea'"
          v-bind="field"
          v-model="field.value"
          @input="onFieldInput(field, key)"
          @blur="onFieldBlur(field, key)"
        )

      transition(name="fade" duration="200")
        InfoMessage(v-if="error.length" type="error") {{ error }}

      .modal-send-tournament-results__previous(v-if="previousResult && previousResult.image")
        .modal-send-tournament-results__previous-title {{ $t('sentResult') }}
        img.modal-send-tournament-results__previous-image(:src="previousResult.image" alt="Previous result")

    .modal__footer
      Button(is-secondary v-support-button) {{ $t('supportCenter') }}
      Button(is-primary :is-disabled="isButtonDisabled" @click="onSendResults") {{ $t('sendResults') }}

</template>

<script>
import { mapMutations, mapState } from 'vuex'
import FormInput from '~/components/FormInput/FormInput'
import InfoMessage from '~/components/InfoMessage/InfoMessage'
import ImageUpload from '~/components/ImageUpload/ImageUpload'

export default {
  name: 'ModalSendTournamentResults',

  components: { FormInput, InfoMessage, ImageUpload },

  props: {
    tournament: {
      type: Object,
      required: true
    },

    game: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      tournamentData: this.tournament,

      fields: {
        image: {
          type: 'image',
          value: '',
          title: this.$t('imageProof'),
          placeholder: this.$t('imageProof'),
          required: true,
          error: ''
        },

        comment: {
          type: 'textarea',
          title: this.$t('yourComment'),
          value: '',
          placeholder: this.$t('describeIfThereAreProblems'),
          error: ''
        }
      },

      error: ''
    }
  },

  computed: {
    ...mapState('user', ['user']),

    isButtonDisabled () {
      const emptyRequiredFields = Object.values(this.fields).filter(
        field => field.required && !field.value
      )

      if (emptyRequiredFields.length || this.error) {
        return true
      }

      return false
    },

    previousResult () {
      const data = this.tournamentData?.extra_data?.state?.game?.moderation_data

      if (!data) {
        return null
      }

      const myResult = data.find(r => r.id === this.user?.id)

      return myResult?.url && JSON.parse(myResult?.url)
    }
  },

  mounted () {
    this.updateTournament()
  },

  methods: {
    ...mapMutations('modal', ['toggle']),

    async updateTournament () {
      this.$nuxt.$loading.start()

      try {
        const { data } = await this.$axios.get(
          `/games/${this.game?.name}/tournaments/${this.tournament.id}`
        )

        this.tournamentData = data.data
      } catch (e) {
        console.error(e)
      }

      this.$nuxt.$loading.finish()
    },

    async onSendResults () {
      this.$nuxt.$loading.start()

      try {
        const result = {
          image: this.fields.image.value,
          comment: this.fields.comment.value
        }

        await this.$axios.post(
          `/games/${this.game.name}/tournaments/${this.tournamentData.id}/screenshot`,
          { url: JSON.stringify(result) }
        )

        this.toggle()
      } catch (e) {
        this.error = this.$errors.getText(e)
        console.error(`${new Date().toUTCString()} :: sending results error`, e)
      }

      this.$nuxt.$loading.finish()
    },

    onFieldInput (field, key) {
      this.errorMessage = ''
      this.fields[key].error = ''
    },

    onFieldBlur (field, key) {
      this.fields[key].error = ''

      if (!field.value && field.required) {
        this.fields[key].error = this.$t('fieldRequired')
      }

      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalsendtournamentresults {
  max-width: 634px;
  padding-bottom: 0;
}

.modal-send-tournament-results {
  &__body {
    margin: 24px 0;
  }

  &__previous {
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    &-title {
      @include caption-small;

      color: $grey20;
      margin-bottom: 12px;
    }

    &-image {
      max-height: 280px;
      width: auto;
      object-fit: contain;
    }
  }

  &__field:not(:last-child) {
    margin-bottom: 24px;
  }

  .info-message {
    margin-top: 12px;
  }

  .modal__footer {
    .button:not(:last-child) {
      margin-right: 8px;
    }
  }

  @media (max-width: $breakpointMobile) {
    .modal__footer {
      padding: 11px 16px;
      flex-direction: column;
      align-items: flex-end;

      button.button {
        width: 100%;
        margin-right: 0 !important;
      }

      .button + .button {
        margin: 8px 0 0;
      }
    }
  }
}
</style>
