<template lang="pug">
  .player-menu
    .player-menu__button
      Button(is-square icon="dots" :is-active="isMenuVisible" @click="toggle")

    transition(name="slide-up" duration="200")
      .player-menu__menu(v-if="isMenuVisible")
        .player-menu__item(@click="showReportModal")
          | {{ $t('complain') }}

</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'PlayerMenu',

  props: {
    player: {
      type: Object,
      required: true
    },

    location: {
      type: String,
      required: true
    },

    tournament: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      isMenuVisible: false
    }
  },

  mounted () {
    document.addEventListener('click', this.onClickOutside)
  },

  beforeDestroy () {
    document.removeEventListener('click', this.onClickOutside)
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    onClickOutside ({ target }) {
      if (this.isMenuVisible && !this.$el.contains(target)) {
        this.isMenuVisible = false
      }
    },

    toggle () {
      this.isMenuVisible = !this.isMenuVisible
    },

    showReportModal () {
      if (!this.$store.getters['user/isAuthorized']) {
        this.showModal({
          component: 'Auth',
          data: {
            mode: 'register',
            afterLogin: () => {
              this.showReportModal()
            }
          }
        })

        return
      }

      this.showModal({
        component: 'ReportPlayer',
        data: {
          player: this.player,
          location: this.location,
          tournament: this.tournament
        }
      })
    }
  }
}
</script>

<style lang="scss">
.player-menu {
  position: relative;

  &__menu {
    position: absolute;
    z-index: 4;
    max-width: 400px;
    min-width: 100%;
    width: auto;
    border-radius: 8px;
    top: 100%;
    overflow: hidden;
    right: 0;
    margin-top: 8px;
    border: 1px solid $blue-grey30;
    background: $blue-grey70;
    padding: 16px;
  }

  &__item {
    @include heading6;

    background: $blue-grey60;
    cursor: pointer;
    transition: background 0.2s $easing;
    height: 48px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    color: #fff;
    margin: 0 0 8px;
    white-space: nowrap;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background: $blue-grey40;
    }
  }
}
</style>
