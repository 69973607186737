<template lang="pug">
  .pubg-account
    .modal__header {{ $t('gameAccount', { game: game.extra_data.full_title }) }}

    form(@submit.prevent="onSubmit")
      .pubg-account__body
        InfoMessage(v-if="!isNickChanging" type="warning").pubg-account__warning
          | {{ $t('doNotForgetChangeNickname') }}
        InfoMessage(v-if="showAccountAttention" type="attention").pubg-account__warning
          | {{ $t('pubgmMinimumAccountLevel', { level: minimumLevel }) }}

        template(v-if="isNickChanging")
          FormInput(
            v-bind="fields.username"
            v-model="fields.username.value"
            @blur="onFieldBlur('username', fields.username)"
            @input="onFieldInput('username', fields.username)"
          )
        template(v-else v-for="(input, index) in fields")
          FormInput(
            :key="index"
            v-bind="input"
            v-model="input.value"
            @input="onFieldInput(index, input)"
            @blur="onFieldBlur(index, input)"
          )

      .modal__footer
        transition(name="fade" duration="200")
          InfoMessage(v-if="errorMessage" type="error")
            | {{ errorMessage }}
        Button(v-if="isNickChanging" is-primary :is-disabled="isButtonDisabled" type="submit") {{ $t('buttonSave') }}
        Button(v-else is-primary :is-disabled="isButtonDisabled" type="submit") {{ $t('buttonLink') }}

</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import FormInput from '~/components/FormInput/FormInput'
import ImageUpload from '~/components/ImageUpload/ImageUpload'

export default {
  name: 'PubgMobileAccount',

  components: { ImageUpload, FormInput },

  props: {
    gameName: {
      type: String,
      default: 'pubg-mobile'
    },

    isNickChanging: {
      type: Boolean,
      default: false
    }
  },

  data () {
    const fields = {
      username: {
        type: 'text',
        value: '',
        title: this.$t('gameNickname'),
        placeholder: this.$t('gameNickname'),
        isRequired: true,
        error: ''
      }
    }

    if (this.gameName !== 'pubg-new-state') {
      fields.id = {
        type: 'text',
        value: '',
        title: this.$t('accountId'),
        placeholder: this.$t('accountId'),
        isRequired: true,
        error: '',
        mask: '##############'
      }
    }

    return {
      errorMessage: '',
      fields
    }
  },

  computed: {
    ...mapState('games', ['gamesByName']),

    game () {
      return this.gamesByName[this.gameName]
    },

    minimumLevel () {
      return this.game.name === 'pubg-new-state' ? 10 : 25
    },

    isButtonDisabled () {
      if (this.errorMessage) {
        return true
      }

      if (this.isNickChanging) {
        return !!this.fields.username.error || !this.fields.username.value
      }

      if (
        Object.values(this.fields).some((field) => {
          return field.error
        })
      ) {
        return true
      }

      return Object.values(this.fields).some(
        field => field.isRequired && !field.value
      )
    },

    gifUrl () {
      if (this.game.name === 'free-fire') {
        return 'https://cdn.gaming-goods.com/games/free-fire/tutorial.gif'
      } else if (this.game.name === 'standoff') {
        return 'https://cdn.gaming-goods.com/games/standoff/tutorial11.gif'
      } else if (this.game.name === 'pubg-new-state') {
        return 'https://cdn.gaming-goods.com/games/pubg-new-state/tutorial.gif'
      }

      return 'https://cdn.gaming-goods.com/games/pubg-mobile/tutorial1.gif'
    },

    showAccountAttention () {
      return !this.isNickChanging && this.game?.name !== 'standoff'
    }
  },

  methods: {
    ...mapActions('modal', ['showModal']),
    ...mapMutations('modal', ['toggle']),

    async onSubmit () {
      this.errorMessage = ''

      Object.keys(this.fields).forEach(key => this.onFieldBlur(key))

      if (this.isButtonDisabled) {
        return
      }

      this.$nuxt.$loading.start()

      try {
        if (this.isNickChanging) {
          await this.$axios.put(`/games/${this.gameName}/account`, {
            username: this.fields.username.value
          })
        } else {
          const id =
            this.game.name === 'pubg-new-state'
              ? this.fields.profile_image_url.value
              : this.fields.id.value

          await this.$axios.post(`/games/${this.gameName}/account`, {
            id,
            username: this.fields.username.value
          })

          // this.$gtag.event('game_account_link', { step: 3, game: this.gameName })
        }

        await this.$store.dispatch('user/fetchUser')

        // this.toggle(false)
        this.showModal({
          component: 'ModalTournamentsAfterAccountLink',
          canClose: false,
          data: {
            gameName: this.gamesByName[this.gameName]?.extra_data.title,
            gameId: this.gamesByName[this.gameName]?.id
          }
        })
      } catch (e) {
        this.errorMessage = this.$errors.getText(e)
        console.error(`${new Date().toUTCString()} :: pubg account error ::`, e)

        this.$nextTick(() => {
          this.$emit('recalculatePosition')
        })
      }

      this.$nuxt.$loading.finish()
    },

    onFieldInput (name) {
      this.errorMessage = ''
      this.$set(this.fields[name], 'error', '')

      this.$nextTick(() => {
        this.$emit('recalculatePosition')
      })
    },

    onFieldBlur (name) {
      const field = this.fields[name]

      if (!field.value && field.isRequired) {
        field.error = this.$t('fieldRequired')
      }

      if (name === 'id' && field.value && field.value.length < 8) {
        field.error = this.$t('atLeastNumber', { number: '8' })
      }

      if (name === 'username' && /[\u180C\u3164]/.test(field.value)) {
        field.error = this.$t('hiddenSymbolsWarning')
      }

      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss">
.modal.modal--pubgmobileaccount {
  padding-bottom: 0;
  max-width: 614px;
}

.pubg-account {
  &__gif {
    margin-top: 16px;

    &-caption {
      font-style: normal;
      font-weight: $font-medium;
      font-size: 12px;
      line-height: 100%;
      text-transform: uppercase;
      color: $grey20;
      margin-bottom: 8px;
    }

    img {
      width: 100%;
    }

    @media (max-width: $breakpointMobile) {
      &-caption {
        font-size: 10px;
        line-height: 12px;
      }
    }
  }

  &__body {
    padding: 20px 24px;
    margin: 0 -24px;
    overflow-y: auto;
    max-height: calc(100vh - 180px);

    @media (max-width: $breakpointMobile) {
      padding: 20px 16px;
      margin: 0 -16px;
      max-height: calc(100vh - 145px);
    }
  }

  .form-input,
  .file-upload {
    margin-top: 24px;

    &.--error {
      padding-bottom: 20px;
    }
  }

  &__warning {
    margin-bottom: 16px;
  }

  &__warning + &__warning {
    margin-bottom: 24px;
  }

  .button {
    height: fit-content;
  }

  .modal__footer {
    flex-wrap: wrap;
  }

  .info-message.--error {
    width: 100%;
    margin-bottom: 16px;
  }
}
</style>
