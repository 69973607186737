<template lang="pug">
  .freeroll-ad-modal
    .freeroll-ad-modal__body
      .freeroll-ad-modal__title {{ $t('freerollAdModalTitle') }}
      .freeroll-ad-modal__description {{ $t('freerollAdModalDesc') }}
      .freeroll-ad-modal__description {{ adNumber }} / {{ adCount }}

      // TODO loop
      //div(v-if="adNumber === 0")
      //  video.freeroll-ad-modal__video(
      //    controls
      //    playsinline
      //    webkit-playsinline
      //    autoplay="autoplay"
      //    :src="videoSrc1"
      //    @play="onVideoStarted"
      //    @ended="onVideoEnded"
      //    @click="onVideoClick(0)"
      //  )
      //
      //div(v-else-if="adNumber === 1")
      //  video.freeroll-ad-modal__video(
      //    controls
      //    playsinline
      //    webkit-playsinline
      //    autoplay="autoplay"
      //    :src="videoSrc2"
      //    @play="onVideoStarted"
      //    @ended="onVideoEnded"
      //    @click="onVideoClick(1)"
      //  )

      div(v-if="isLoaded")
        div(v-if="adNumber === 1")
        <iframe v-if="isRussianBrowser" scrolling="no" frameborder="0" style="padding:0px; margin:0px; border:0px; border-style:none;" width="600" height="500" src="https://refbanners.com/I?tag=d_1638225m_10625c_&site=1638225&ad=10625%22"></iframe>
        <iframe v-if="isEnglishBrowser" scrolling="no" frameborder="0" style="padding:0px; margin:0px; border:0px; border-style:none;" width="600" height="500" src="https://refbanners.com/I?tag=d_1638225m_10599c_&site=1638225&ad=10599%22"></iframe>
        <iframe v-if="isPortugalBrowser" scrolling="no" frameborder="0" style="padding:0px; margin:0px; border:0px; border-style:none;" width="600" height="500" src="https://refbanners.com/I?tag=d_1638225m_8031c_&site=1638225&ad=8031"></iframe>

    .modal__footer
      Button(is-primary :is-disabled="isButtonDisabled" @click="onJoinClick")
        template
          | {{ buttonText }}
          .freeroll-ad-modal__loader(v-if="isButtonDisabled")
            img(:src="require('~/assets/img/VVV-logo.svg')" height="20" width="22" alt="logo")

</template>

<script>
const DEFAULT_SECONDS = 6
// const VIDEO_COUNT = 2

export default {
  name: 'FreerollAdModal',

  props: {
    onAdEnd: {
      type: Function,
      required: true
    },

    adCount: {
      type: Number,
      default: 1
    }
  },

  data () {
    return {
      seconds: null,
      adNumber: 0,
      isLoaded: true,
      isCurrentVideoPlayed: false,
      interval: null
    }
  },

  computed: {
    isButtonDisabled () {
      return this.adNumber < this.adCount || this.seconds > 0
    },

    isRussianBrowser () {
      const browserLang = navigator.languages
        ? navigator.languages[0]
        : navigator.language || navigator.userLanguage

      return browserLang === 'ru-RU'
    },

    isEnglishBrowser () {
      const browserLang = navigator.languages
        ? navigator.languages[0]
        : navigator.language || navigator.userLanguage

      return browserLang === 'en-EN'
    },

    isPortugalBrowser () {
      const browserLang = navigator.languages
        ? navigator.languages[0]
        : navigator.language || navigator.userLanguage

      return browserLang === 'pt-PT'
    },

    buttonText () {
      if (this.isButtonDisabled) {
        return `${this.$t('signUpForTournament')} ${this.seconds}`
      }

      return this.$t('signUpForTournament')
    },

    videoSrc1 () {
      return 'https://cdn.gaming-goods.com/video-ad/bet1x.mp4'
    },

    videoSrc2 () {
      return 'https://cdn.gaming-goods.com/video-ad/atlas.mp4'
    }
  },

  mounted () {
    this.seconds = DEFAULT_SECONDS
    this.startNewAd()
  },

  beforeDestroy () {
    clearInterval(this.interval)
  },

  methods: {
    onJoinClick () {
      this.$store.commit('modal/toggle', false)
      this.onAdEnd()
    },

    startNewAd () {
      console.log('START NEW AD')
      this.seconds = DEFAULT_SECONDS
      clearInterval(this.interval)
      this.isLoaded = true

      this.interval = setInterval(() => {
        this.seconds -= 1
        // console.log(this.seconds)

        if (this.seconds <= 0) {
          console.log('STOP AD')
          this.isLoaded = false

          this.adNumber += 1
          this.seconds = DEFAULT_SECONDS
          this.isCurrentVideoPlayed = false

          console.log('DATA:', this.adNumber, this.adCount)
          if (this.adNumber < this.adCount) {
            console.log('> TO START')
            this.startNewAd()
          } else {
            clearInterval(this.interval)
          }
        }

        if (this.adNumber === this.adCount) {
          clearInterval(this.interval)
          this.seconds = 0
        }
      }, 1000)
    },

    onVideoStarted () {
      if (this.isCurrentVideoPlayed) {
        return
      }

      this.startNewAd()
      this.isCurrentVideoPlayed = true
    },

    onVideoEnded () {},

    onVideoClick (videoId) {
      if (!this.isCurrentVideoPlayed) {
        return
      }

      const linksByVideoId = {
        0: 'https://refpa02576.top/L?tag=d_1638225m_1599c_&site=1638225&ad=1599',
        1: 'https://partner.vpnatlas.com/aff_c?offer_id=69&aff_id=2140&url_id=108'
      }

      window.open(linksByVideoId[videoId], '_blank')
    }
  }
}
</script>

<style lang="scss">
.modal.modal--freerolladmodal {
  max-width: 506px;
  padding-bottom: 0;
}

.freeroll-ad-modal {
  &__body {
    min-height: 80px;
  }

  &__video {
    margin-bottom: 16px;
    cursor: pointer;
  }

  &__title {
    @include heading4;

    color: $blue-grey05;
    margin-bottom: 8px;
  }

  &__description {
    @include hint;

    margin-bottom: 16px;
    color: $blue-grey05;
  }

  &__loader {
    animation: rotate 1s infinite $easing;
    transform-origin: 52% 38%;
    height: 20px;
    width: 22px;
    position: relative;
    margin-left: 10px;

    svg path {
      fill: $white;
    }
  }
}

#video-container {
  position: relative;
  padding-bottom: 56.25%;
}

#video-element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#ad-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
</style>
