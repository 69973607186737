<template lang="pug">
.change-color
  .modal__header(v-t="'chooseColor'")
  .change-color__picker
    verte(display="widget" v-model="newColor" :showHistory="null" picker="square" model="rgb")
  .change-color__preview(v-if="previewType === 'nickname'")
    p.change-color__preview-title(v-t="'preview'")
    span.change-color__preview-username(:style="{color: newColor}") {{ userName }}
    span.change-color__preview-userid {{ `#${userId}` }}
  .change-color__preview(v-else-if="previewType === 'avatar-frame'")
    p.change-color__preview-title(v-t="'preview'")
    img.change-color__preview-img(src="https://cdn.gaming-goods.com/images/avatar-frame.svg" :style="{'--frame-color': newColorHex}" alt="frame")
  .modal__footer
    .change-color__buttons
      Button.change-color__button(v-t="'buttonCancel'" is-secondary @click="closeModal")
      Button(v-t="'buttonSave'" is-primary @click="updateColor(newColorHex)")
</template>

<script>
import { mapMutations } from 'vuex'
import verte from 'verte'
import 'verte/dist/verte.css'

export default {
  name: 'ModalChangeColor',

  components: {
    verte
  },

  props: {
    changeColor: {
      type: Function,
      required: true
    },

    startColor: {
      type: String,
      default: '#fff'
    },

    previewType: {
      type: String,
      default: 'nickname'
    },

    userName: {
      type: String,
      required: true
    },

    userId: {
      type: Number,
      required: true
    },

    updateColor: {
      type: Function,
      required: true
    }
  },

  data () {
    return {
      newColor: this.startColor
    }
  },

  computed: {
    newColorHex () {
      return '#' + this.newColor.toString().replace('rgb(', '').replace(')', '').split(',').map(color => ('0' + (+color).toString(16)).slice(-2)).join('')
    }
  },

  methods: {
    ...mapMutations('modal', ['toggle']),

    closeModal () {
      this.toggle()
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalchangecolor {
  max-width: 528px;
  background: $blue-grey70;
}

.verte__menu {
  background-color: $blue-grey70;
}

.verte-picker__canvas {
  border-radius: 10px;
}

.verte__controller {
  display: none;
}

.verte-picker__cursor {
  border: 1px solid black;
}
</style>

<style lang="scss" scoped>
.change-color {
  width: 100%;

  &__picker {
    margin: 24px;
  }

  &__preview {
    padding-bottom: 20px;

    &-title {
      margin-bottom: 16px;
      font-weight: 400;
      font-size: 18px;
      line-height: 100%;
      color: $blue-grey10;
    }

    &-username {
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
    }

    &-userid {
      margin-left: 8px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: $blue-grey10;
    }

    &-img {
      width: 64px;
      height: 64px;

      ::v-deep circle {
        stroke: var(--frame-color);
      }
    }
  }

  &__buttons {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 16px;
    align-items: center;
    justify-content: end;
  }

  &__button {
    color: $white;
  }
}
</style>
