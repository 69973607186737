<template lang="pug">
  .layout-default
    LayoutBreak(v-if="false")
    LayoutStandart(v-else)
</template>

<script>
import LayoutStandart from '~/layouts/standart'
import LayoutBreak from '~/layouts/break'

export default {
  name: 'LayoutDefault',

  components: {
    LayoutStandart,
    LayoutBreak
  },

  head () {
    return {
      ...this.$nuxtI18nHead({ addSeoAttributes: true }),
      meta: [
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$config.WWW_HOST + this.$route.path
        },
        {
          name: 'enot',
          content: this.$config.ENOT_CONTENT
        }
      ]
    }
  }
}
</script>
