import Vue from 'vue'
import vueAwesomeCountdown from 'vue-awesome-countdown'
import SectionTitle from '~/components/SectionTitle/SectionTitle'
import Button from '~/components/Button/Button'

Vue.use(vueAwesomeCountdown, 'Countdown')

Vue.component('Button', Button)
Vue.component('SectionTitle', SectionTitle)

// dynamic loading
Vue.component('InfoMessage', () =>
  import(
    /* webpackChunkName: "InfoMessage" */ '~/components/InfoMessage/InfoMessage'
  )
)

Vue.component('Currency', () =>
  import(/* webpackChunkName: "Currency" */ '~/components/Currency/Currency')
)

Vue.component('Loader', () =>
  import(/* webpackChunkName: "Loader" */ '~/components/Loader/Loader')
)
