<template lang="pug">
  .product
    .product__body
      .product__type {{ productType }}
      .product__title {{ title }}

      ul.product__list(v-if="product.type === 'subscription_freeroll'" v-html="$t('unlimitedFreerollsListDesc')")
      .product__description(v-else) {{ description }}

      .product__bottom
        Currency(size="32" type="money").product__price {{ product.price }}

        .product__buttons
          Button(v-if="isBuyAvailable" is-primary @click="onBuyClick") {{ $t('buy') }}
          Button(v-else is-bordered is-white @click="onProlongClick") {{ $t('toSubscriptions') }}

          //Button(
          //  v-if="isSuperAdmin"
          //  class="--admin"
          //  is-red
          //  is-small
          //  v-tippy
          //  :content="$t('onlyForAdmins')"
          //  @click="onBuyClick(true)"
          //) {{ $t('getForFree') }}

    .product__image
      img(:src="product.extra_data.promo_image_url")

    .product__line
    .product__sale-info {{ saleInfo }}

</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ModalProduct',

  props: {
    product: {
      type: Object,
      required: true
    },

    title: {
      type: String,
      required: true
    },

    description: {
      type: String,
      default: ''
    },

    saleInfo: {
      type: String,
      default: ''
    },

    callback: {
      type: Function,
      default: () => {}
    }
  },

  data () {
    return {}
  },

  computed: {
    ...mapState('user', ['subscriptions']),

    isSubscription () {
      return this.product.type?.startsWith('subscription')
    },

    productType () {
      if (this.isSubscription) {
        return this.$t('subscription')
      } else {
        return this.$t('product')
      }
    },

    isBuyAvailable () {
      if (!this.isSubscription) {
        return true
      }

      return !this.userProduct
    },

    userProduct () {
      return this.subscriptions?.find(sub => sub.type === this.product.type)
    }
  },

  methods: {
    ...mapActions('modal', ['showModal']),
    ...mapActions('user', ['fetchProducts']),

    onBuyClick (isAdmin = false) {
      const product = JSON.parse(JSON.stringify(this.product))

      // TODO: Брать подписки из store
      // ID бесплатных подписок по ID платных подписок
      // const freeById = {
      //   6: 9,
      //   5: 10,
      // }
      //
      // if (isAdmin) {
      //   const id = freeById[product.id] || product.id
      //
      //   product.id = id
      //   product.price = 0
      // }

      this.showModal({
        component: 'ModalCheckout',
        data: { product, afterPay: this.callback }
      })
    },

    onProlongClick () {
      this.$router.push(this.localeLocation('/profile/account#subscriptions'))
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalproduct {
  max-width: 804px;
  padding-bottom: 0;

  //padding-right: 0;
  overflow: hidden;
}

.product {
  display: flex;

  &__image {
    max-width: 364px;
    height: auto;
    display: flex;
    align-items: flex-end;
    z-index: 1;
    margin-right: -10px;
    margin-bottom: -20px;

    img {
      border-radius: 8px;

      //filter: drop-shadow(0 100px 213px rgba(0, 0, 0, 0.07))
      //  drop-shadow(0 61.9811px 92.5753px rgba(0, 0, 0, 0.0521109))
      //  drop-shadow(0 49.9958px 50.7596px rgba(0, 0, 0, 0.0456172))
      //  drop-shadow(0 41.9955px 28.974px rgba(0, 0, 0, 0.0405998))
      //  drop-shadow(0 32.3473px 15.6449px rgba(0, 0, 0, 0.0341254))
      //  drop-shadow(0 18.737px 6.62183px rgba(0, 0, 0, 0.0231245));
    }
  }

  &__body {
    width: 100%;
  }

  &__type {
    @include caption;

    color: $blue-grey10;
    margin-bottom: 8px;
  }

  &__title {
    @include heading2;

    color: $white;
    margin-bottom: 20px;
  }

  &__description {
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    color: #9bb0cb;
    margin-bottom: 48px;
  }

  &__list {
    @include body;

    color: $white;
    margin-bottom: 28px;
    position: relative;
    left: 20px;

    li {
      //list-style-type: circle;
      //list-style-position: inside;

      span {
        color: $yellow70;
      }

      &::before {
        content: "\2022";
        color: $white;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }

  &__bottom {
    border-radius: 8px;
    display: flex;
    margin-bottom: 34px;
  }

  &__price {
    @include heading3;

    margin-right: 32px;
    color: $white;
  }

  &__buttons {
    display: flex;

    .button:not(:last-child) {
      margin-right: 16px;
    }
  }

  &__line {
    position: absolute;
    width: 120px;
    height: 300px;
    left: 700px;
    top: -110px;
    background: #1183f3;
    transform: rotate(-45deg);
  }

  &__sale-info {
    @include hint;

    position: absolute;
    top: 23px;
    left: 480px;
    color: $white;
    padding: 9px;
    max-width: 150px;
    text-align: center;
    border-radius: 4px;
    transform: rotate(-5deg);
    z-index: 2;
    background: linear-gradient(90deg, #1183f3 0%, #1183f3 100%);
    box-shadow:
      0 100px 213px rgb(0 0 0 / 7%),
      0 18.737px 6.62183px rgb(0 0 0 / 2.31%);
  }

  @media (max-width: $breakpointMobile) {
    flex-direction: column-reverse;

    &__image {
      max-width: 100%;
      margin: 44px 0 20px;
    }

    &__line {
      left: 280px;
    }

    &__sale-info {
      top: 50px;
      left: 30px;
    }

    &__bottom {
      flex-direction: column;
    }

    &__description {
      margin-bottom: 28px;
    }

    &__buttons {
      flex-direction: column;
      margin-top: 12px;

      .button {
        width: 100%;

        &:not(:last-child) {
          margin-right: 0;
          margin-bottom: 8px;
        }
      }
    }
  }
}
</style>
