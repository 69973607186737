<template lang="pug">
  .pay-for-participation
    img.pay-for-participation__image(
      src="~/assets/img/icons/cost.svg"
      :alt="$t('entryFee')"
      height="96"
      width="96"
    )

    .pay-for-participation__title {{ title || $t('payTournamentTitle') }}

    .pay-for-participation__select(v-if="!method")
      .pay-for-participation__select-title {{ $t('choosePayMethod') }}

      .pay-for-participation__select-item(class="--money" @click="onMethodClick('money')")
        .pay-for-participation__select-item-checkbox
        span {{ $t('payFromBalance') }}
        Currency(type="money")

      .pay-for-participation__select-item(class="--ticket" @click="onMethodClick('ticket')")
        .pay-for-participation__select-item-checkbox
        span {{ $t('payByTickets') }}
        //Currency(type="tickets")

    template(v-if="method === 'money'")
      .pay-for-participation__select-title {{ $t('payFromBalanceTitle') }}

      .pay-for-participation__rows
        .pay-for-participation__row
          span {{ $t('entryFeeText') }}
          strong {{ formatNumber(entryFee) }} G
        .pay-for-participation__row
          span {{ text || $t('payTournamentText') }}
          strong {{ formatNumber(commission) }} G
        .pay-for-participation__row.pay-for-participation__row--total
          span {{ $t('totalPrice') }}
          strong {{ formatNumber(total) }} G
        .pay-for-participation__row.pay-for-participation__row--balance
          span {{ $t('checkoutBalance') }}
          strong {{ minWithdrawalLimit }} G
        .pay-for-participation__row.pay-for-participation__row--last
          div(v-if="!needMoreMoney") {{ $t('checkoutFromBalance') }}

          template(v-else)
            span {{ $t('checkoutNeedMore') }}
            strong {{ formatNumber(needMoreMoney) }} G

      template(v-if="!needMoreMoney")
        Button(
          :is-primary="!isEntryFeePayed"
          :is-purple="!isEntryFeePayed"
          :is-secondary="isEntryFeePayed"
          :icon="isEntryFeePayed ? 'double-check' : null"
          @click="pay('entry')"
        )
          template(v-if="!isEntryFeePayed") {{ $t('prizePoolPay') }}
          template(v-else) {{ $t('payDone') }}
        Button(
          :is-primary="!isCommissionPayed"
          :is-secondary="isCommissionPayed"
          :icon="isCommissionPayed ? 'double-check' : null"
          @click="pay('commission')"
        )
          template(v-if="!isCommissionPayed") {{ $t('buttonPay') }} {{ text.toLowerCase() }}
          template(v-else) {{ $t('payDone') }}
      Button(v-else is-primary @click="onRefill") {{ $t('buttonRefillBalance') }}
    .pay-for-participation__earn
      .pay-for-participation__earn-row
        span.pay-for-participation__earn-row-key(v-t="'earnWithOfferwall2'")
        NuxtLink(:to="localeLocation('/missions')")
          Button.pay-for-participation__earn-row-value(is-secondary v-t="'gamesGoTo'")
      .pay-for-participation__earn-row
        span.pay-for-participation__earn-row-key(v-t="'earnWithFreeroll'")
        NuxtLink(:to="localeLocation('/tournaments')")
          Button.pay-for-participation__earn-row-value(is-secondary v-t="'gamesGoTo'")
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import numeral from 'numeral'
import TournamentMixin from '~/mixins/Tournament'
import DuelMixin from '~/mixins/Duel'
import { pay } from '~/utils/utils'

export default {
  name: 'ModalPayForParticipation',

  props: {
    title: {
      type: String,
      default: ''
    },

    text: {
      type: String,
      default: ''
    },

    tournament: {
      type: Object,
      default: null
    },

    duel: {
      type: Object,
      default: null
    },

    afterPay: {
      type: Function,
      default: () => {}
    }
  },

  data: () => {
    return {
      isCommissionPayed: false,
      isEntryFeePayed: false,
      isPaid: true,
      method: 'money'
    }
  },

  computed: {
    ...mapMutations('modal', ['toggle']),
    ...mapState('user', ['user']),
    ...mapGetters('user', ['minWithdrawalLimit']),

    needMoreMoney () {
      const total = this.total

      if (+this.minWithdrawalLimit < total) {
        return total - +this.minWithdrawalLimit
      } else {
        return 0
      }
    },

    total () {
      if (this.tournament) {
        return TournamentMixin.computed.entryFee.call(this)
      }

      return DuelMixin.computed.entryFee.call(this)
    },

    commission () {
      const total = this.total

      if (this.tournament) {
        return (total / 100) * this.tournament.prize_settings.rake
      } else if (this.duel) {
        return (total / 100) * this.duel.rake
      }

      return 0
    },

    entryFee () {
      const total = this.total

      return total - this.commission
    }
  },

  mounted () {
    this.fetchCanBeWithdrawn()
  },

  destroyed () {
    setTimeout(() => {
      this.$store.commit('user/resetBalance')
    }, 1000)
  },

  methods: {
    ...mapActions('modal', ['showModal']),
    ...mapActions('user', ['fetchCanBeWithdrawn']),

    onRefill () {
      pay('in', this.$store)
    },

    onMethodClick (value) {
      this.method = value
      this.$emit('recalculatePosition')
    },

    formatNumber (number) {
      return Number(number).toFixed(2)
    },

    formatPrice (number) {
      return numeral(number).format('0,0').replace(/,/g, ' ')
    },

    payByTicket () {
      this.afterPay('products')
    },

    pay (type) {
      if (type === 'commission') {
        if (!this.isCommissionPayed) {
          this.isCommissionPayed = true
          this.$store.commit(
            'user/setBalance',
            this.user.balance - this.commission
          )
        }
      } else if (!this.isEntryFeePayed) {
        this.isEntryFeePayed = true
        this.$store.commit('user/setBalance', this.user.balance - this.entryFee)
      }

      if (this.isEntryFeePayed && this.isCommissionPayed) {
        this.afterPay()
        this.fetchCanBeWithdrawn()
      }
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalpayforparticipation {
  padding: 0 40px 40px;
  max-width: 506px;

  @media (max-width: $breakpointMobile) {
    padding: 0 16px 32px;
  }
}

.pay-for-participation {
  &__select {
    color: #fff;

    &-title {
      @include heading5;

      margin: 24px 0 16px;
      color: #fff;
    }

    &-item {
      display: flex;
      align-items: center;
      background: $blue-grey60;
      border-radius: 8px;
      padding: 12px 16px;
      cursor: pointer;
      transition: background 0.2s $easing;

      &:hover {
        background: $blue-grey50;
      }

      &-checkbox {
        border: 2px solid $blue-grey20;
        min-width: 20px;
        height: 20px;
        border-radius: 50%;
        transition: all 0.2s $easing;
        position: relative;
        margin-right: 12px;
      }

      > span {
        width: 100%;
        font-size: 16px;
        font-weight: $font-medium;
      }

      .currency {
        min-width: 40px;
        height: 40px;
        border-radius: 50%;
        background: $green60-20;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.--ticket .currency {
        background: $teal60-20;
      }
    }

    &-item + &-item {
      margin-top: 8px;
    }
  }

  button.button {
    width: 100%;

    & + button.button {
      margin-top: 16px;
    }
  }

  &__rows {
    margin: 24px 0 32px;
  }

  &__row {
    @include caption;

    height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $blue-grey10;
    margin-bottom: 16px;

    strong {
      @include heading5;
    }

    &--ticket-total {
      height: 32px;

      strong {
        @include heading3;

        color: #fff;
      }
    }

    &--total {
      height: 32px;

      strong {
        @include heading3;

        color: $amber60;
      }
    }

    &--balance {
      height: 24px;

      strong {
        @include heading4;

        color: #fff;
      }
    }

    &--last {
      padding-top: 16px;
      border-top: 1px solid $blue-grey40;
      margin-bottom: 0;
      height: auto;

      strong {
        @include heading4;

        color: $red60;
      }

      div {
        @include text-info120;

        text-align: right;
        width: 100%;
        text-transform: none;
      }
    }
  }

  &__image {
    display: block;
    margin: 56px auto 16px;

    @media (max-width: $breakpointMobile) {
      margin: 32px auto 16px;
    }
  }

  &__title {
    @include heading2;

    border-bottom: 1px solid $blue-grey30;
    padding-bottom: 24px;
    color: #fff;
    text-align: center;
    line-height: 34px;
  }

  &__earn {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    margin-top: 32px;

    &-row {
      display: grid;
      grid-template-columns: 1fr 170px;
      grid-gap: 30px;
      align-items: center;
      border-top: 1px solid $blue-grey40;
      padding-top: 16px;

      &-key {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $white;
      }

      &-value {
        color: $white;
      }
    }
  }
}
</style>
