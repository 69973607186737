<template lang="pug">
  .layout-break
    img.layout-break__ellipsis(:src="require('~/assets/img/break-background.png')")

    .layout-break__image
      img(:src="require('~/assets/tr-info/smile.svg')")

    .layout-break__body
      .layout-break__title  {{ $t('techWorks') }}
      .layout-break__description
        | {{ $t('techWorksDescription1') }}
        br
        | {{ $t('techWorksDescription2') }} &nbsp;
        span.layout-break__date 12.10 12:00&nbsp;
        | {{ $t('techWorksDescription3') }}

</template>

<script>
export default {
  name: 'LayoutBreak',

  head () {
    return {
      ...this.$nuxtI18nHead({ addSeoAttributes: true }),
      meta: [
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$config.WWW_HOST + this.$route.path
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.layout-break {
  height: 100vh;
  max-height: 100%;
  max-height: -moz-available;
  max-height: -webkit-fill-available;
  max-height: fill-available;
  padding: 0 86px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__ellipsis {
    max-height: 808px;
    position: absolute;
    top: 0;
  }

  &__image {
    display: flex;
    justify-content: center;
    margin: 222px auto 57px;

    img {
      max-width: 176px;
    }

    @media (max-width: $breakpointMobile) {
      margin: 40px auto 45px;

      img {
        max-width: 126px;
      }
    }
  }

  &__title {
    font-size: 64px;
    font-weight: 700;
    color: $white;
    text-align: center;
    margin-bottom: 56px;

    @media (max-width: $breakpointMobile) {
      font-size: 28px;
      font-weight: 700;
      line-height: 36px;
      margin-bottom: 32px;
    }
  }

  &__description {
    font-size: 32px;
    font-weight: 400;
    line-height: 48px;
    text-align: center;
    color: $white;

    @media (max-width: $breakpointMobile) {
      @include body;
    }
  }

  &__date {
    color: $primary60;
  }

  @media (max-width: $breakpointMobile) {
    padding: 0 16px;
  }
}
</style>
