<template lang="pug">
.edit-socials
  .modal__header(v-t="'addSocialNetworks'")
  .edit-socials__links
    .edit-socials__link-container(v-for="link in links" :key="link.name")
      FormInput(:title="link.name.toUpperCase()" v-model="link.value" :placeholder="$t('enterLink')")
  .modal__footer
    .edit-socials__buttons
      Button.edit-socials__button(v-t="'buttonCancel'" is-secondary @click="closeModal")
      Button(v-t="'buttonSave'" is-primary @click="updateSocials")
</template>

<script>
import { mapMutations } from 'vuex'
import FormInput from '~/components/FormInput'

export default {
  name: 'ModalEditSocials',

  components: {
    FormInput
  },

  props: {
    setSocials: {
      type: Function,
      required: true
    },

    socials: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      links: [
        {
          name: 'telegram',
          type: 'tg',
          value: this.socials.find(s => s.type === 'tg')?.text
        },
        {
          name: 'vk',
          type: 'vk',
          value: this.socials.find(s => s.type === 'vk')?.text
        },
        {
          name: 'facebook',
          type: 'fb',
          value: this.socials.find(s => s.type === 'fb')?.text
        },
        {
          name: 'discord',
          type: 'discord',
          value: this.socials.find(s => s.type === 'discord')?.text
        },
        {
          name: 'tik-tok',
          type: 'tiktok',
          value: this.socials.find(s => s.type === 'tiktok')?.text
        },
        {
          name: 'twitch',
          type: 'twitch',
          value: this.socials.find(s => s.type === 'twitch')?.text
        },
        {
          name: 'youtube',
          type: 'yt',
          value: this.socials.find(s => s.type === 'yt')?.text
        },
        {
          name: 'email',
          type: 'email',
          value: this.socials.find(s => s.type === 'email')?.text
        },
        {
          name: 'steam',
          type: 'steam',
          value: this.socials.find(s => s.type === 'steam')?.text
        },
        {
          name: 'website',
          type: 'site',
          value: this.socials.find(s => s.type === 'site')?.text
        }
      ]
    }
  },

  mounted () {
    this.setCurrentSocialsValues()
  },

  methods: {
    ...mapMutations('modal', ['toggle']),

    closeModal () {
      this.toggle()
    },

    setCurrentSocialsValues () {
      this.links.forEach((link) => {
        console.log(this.socials.find(s => s.type === link.type))
        link.value = this.socials.find(s => s.type === link.type)?.text
      })
    },

    updateSocials () {
      const socials = this.links.filter(link => link.value && link.value !== '').map(link => ({
        type: link.type,
        link: link.value,
        text: link.value,
        tooltip: link.name
      }))
      this.setSocials(socials)
    }
  }
}
</script>
<style lang="scss">
.modal.modal--modaleditsocials {
  max-width: 528px;
  background: $blue-grey70;
}
</style>

<style lang="scss" scoped>
.edit-socials {
  &__links {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin: 24px 0;
    max-height: 40vh;
    overflow-y: auto;
  }

  &__buttons {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 16px;
    align-items: center;
    justify-content: end;
  }

  &__button {
    color: $white;
  }
}
</style>
