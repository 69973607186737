<template lang="pug">
.layout-empty
  .layout__live
    Marquee

  Header
  .layout__content
    .layout__page
      .clear__mobile
        portal-target(name='breadcrumbs')
      Nuxt
    Footer
  MobileMenu.clear__mobile
</template>

<script>
import Header from '~/components/Header/Header'
import Footer from '~/components/Footer/Footer'
import MobileMenu from '~/components/MobileMenu/MobileMenu'

export default {
  name: 'LayoutEmpty',

  components: {
    Header,
    Footer,
    MobileMenu
  },

  head () {
    return {
      ...this.$nuxtI18nHead({ addSeoAttributes: true }),
      meta: [
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$config.WWW_HOST + this.$route.path
        }
      ],
      link: [
        {
          rel: 'alternate',
          hreflang: 'en',
          href: this.$config.WWW_HOST + this.$route.path
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-empty {
  @include silent-scroll;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  max-height: 100%;
  max-height: -moz-available;
  max-height: -webkit-fill-available;
  max-height: fill-available;

  @media (min-width: $breakpointTablet) {
    @include scroll;
    overflow-x: hidden;
  }
}

.clear {
  &__mobile {
    @media (min-width: $breakpointTablet) {
      display: none;
    }
  }
}
</style>
