<template lang="pug">
  nav.tabs
    ul.tabs__menu
      li.tabs__item(v-for="(item, index) in items" v-if="item" :key="item.id || index" :class="[`--${item.id}`, {'--active': activeTabIndex === index}, {'--disabled': item.isDisabled}]")
        a.tabs__link(:class="{'--new': item.isNew }" @click.prevent="onItemClick(index)") {{ item.title }}

</template>

<script>
export default {
  name: 'Tabs',

  props: {
    items: {
      type: Array,
      required: true
    },

    activeTabIndex: {
      type: Number,
      default: -1
    }
  },

  watch: {
    activeTabIndex: {
      handler (index) {
        if (process.server || index < 0) {
          return
        }

        setTimeout(() => {
          this.$el
            .querySelectorAll('li')
            [index].scrollIntoView({ inline: 'center', block: 'nearest' })
        }, 250)
      },

      immediate: true
    }
  },

  methods: {
    onItemClick (index) {
      this.$emit('change', { index, item: this.items[index] })
    }
  }
}
</script>

<style lang="scss">
.tabs {
  max-width: 100vw;
  padding: 0 8px;

  &__menu {
    @include scroll-none;

    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    overflow: auto;
  }

  &__link {
    @include info-small;

    width: 100%;
    line-height: 20px;
    padding: 0 16px;
    color: $blue-grey10;
    position: relative;
    transition: all 0.2s $easing;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    will-change: auto;
    user-select: none;

    &.--new::before {
      content: "New";
      position: absolute;
      right: -30px;
      margin: auto;
      bottom: 23px;
      width: 40px;
      height: 17px;
      border-radius: 3px;
      background: #e91e63;
      transition: all 0.2s ease-in-out;
      font-size: 12px;
      line-height: 15px;
      color: white;
      font-weight: 800;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      bottom: 8px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: $primary60;
      opacity: 0;
      transition: all 0.2s $easing;
    }

    &:hover {
      line-height: 20px;
      color: $white;
      transform: translateY(-8px);

      &::after {
        background: $primary60;
      }
    }
  }

  &__item {
    height: 100%;
    display: flex;
    align-items: center;

    &.--active {
      .tabs {
        &__link {
          @include info;

          line-height: 20px;
          color: $white;
          transform: translateY(-8px);

          &.--new::before {
            bottom: 21px;
          }

          &::after {
            background: $primary60;
            opacity: 1;
          }
        }
      }
    }

    &.--disabled {
      opacity: 0.3;
      user-select: none;
      cursor: not-allowed;

      .tabs__link {
        pointer-events: none;
      }
    }
  }
}
</style>
