<template lang="pug">
  .tournament-page-tabs
    .tournament-page-tabs__line
      div(v-for="(tab, i) in tabs" :key="tab.id")
        .tournament-page-tabs__tab(:class="tabClass(i)" @click="setTab(i)")
          .tournament-page-tabs__tab-name(v-t="tab.i18nKey")
    template(v-if="!noContent")
      component.tournament-page-tabs__tab-content(:is="activeTab.content" :game="game" :tournament="tournament" :stages="stages")
</template>

<script>
import TabOverview from './TabOverview'
import TabParticipants from './TabParticipants'
import TabLobby from './TabLobby'
import TabMegaGrid from './TabMegaGrid'

export default {
  name: 'TournamentPageTabs',

  props: {
    tournament: {
      type: Object,
      required: true
    },

    game: {
      type: Object,
      required: true
    },

    isMega: {
      type: Boolean,
      default: false
    },

    noContent: {
      type: Boolean,
      default: false
    },

    stages: {
      type: Array,
      default: undefined
    },

    outsideTabs: {
      type: Array,
      default: undefined
    }
  },

  data () {
    return {
      activeTabIndex: 0
    }
  },

  computed: {
    tabs () {
      if (this.outsideTabs) {
        return this.outsideTabs
      }

      const list = [
        {
          id: 'review',
          i18nKey: 'tournamentPageTabOverviewTitle',
          content: TabOverview
        },
        {
          id: 'participants',
          i18nKey: 'tournamentPageTabParticipantsTitle',
          content: TabParticipants
        }
      ]

      if (!this.isMega) {
        list.push({
          id: 'lobby',
          i18nKey: 'tournamentPageTabLobbyTitle',
          content: TabLobby
        })
      } else {
        list.unshift({
          id: 'grid',
          i18nKey: 'tournamentGridTitle',
          content: TabMegaGrid
        })
      }

      return list
    },

    activeTab () {
      return this.tabs[this.activeTabIndex]
    }
  },

  methods: {
    tabClass (tabIndex) {
      return {
        'tournament-page-tabs__tab--active': tabIndex === this.activeTabIndex
      }
    },

    setTab (tabIndex) {
      this.activeTabIndex = tabIndex

      this.$emit('setTab', tabIndex)
    }
  }
}
</script>

<style lang="scss" scoped>
.tournament-page-tabs {
  width: 100%;
  overflow: hidden;

  &__line {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 18px;
    padding: 12px 0;
    overflow-x: auto;
    border-top: 1px solid $blue-grey50;
    border-bottom: 1px solid $blue-grey50;
  }

  &__tab {
    width: max-content;
    padding: 10px 16px;
    margin-right: 8px;
    background: rgba($white, 0.1);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $white;
    border-radius: 100px;
    cursor: pointer;

    &--active {
      background: linear-gradient(90deg, #1E88E5 0%, #42A5F5 100%);
    }

    &-content {
      padding-bottom: 20px;
    }
  }
}
</style>
