<template lang="pug">
  .modal-password
    .modal-password__image
      img(:src="require('./lock.svg')" alt="lock")
    .modal-password__title {{ $t('privateTournamentTitle') }}
    .modal-password__subtitle {{ $t('privateTournamentSubtitle') }}
    FormInput(v-model="password" type="password" :placeholder="$t('password')")
    Button(is-primary :is-disabled="!password" @click="submit") {{ $t('privateTournamentButton') }}

</template>

<script>
import FormInput from '~/components/FormInput/FormInput'

export default {
  name: 'ModalPassword',
  components: { FormInput },

  props: {
    tournament: {
      type: Object,
      required: true
    },

    onNext: {
      type: Function,
      required: true
    }
  },

  data: () => {
    return {
      password: ''
    }
  },

  methods: {
    submit () {
      if (!this.password) {
        return
      }
      this.onNext(this.password)
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalpassword {
  padding: 56px 24px 32px;
  max-width: 506px;
}

.modal-password {
  color: #fff;

  &__image {
    width: 96px;
    height: 96px;
    background: $orange60-20;
    border-radius: 50%;
    margin: 0 auto;
  }

  &__title {
    @include heading2;

    text-align: center;
    margin-top: 16px;
  }

  &__subtitle {
    @include body;

    margin: 8px 0 24px;
    text-align: center;
  }

  .button {
    margin-top: 16px;
    width: 100%;
  }
}
</style>
