<template lang="pug">
.section-title
  h2
    slot

  .right__title__elements
    .section-title__buttons(v-if="$slots.buttons")
      slot(name="buttons")

    .section-title__sort(v-if="$slots.sort")
      slot(name="sort")

</template>

<script>
export default {
  name: 'SectionTitle'
}
</script>

<style lang="scss">
.right__title__elements {
  display: flex;
  align-items: center;
}

.section-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background: #222f3f;
  padding: 10px;
  border-radius: 8px;
  padding-left: 20px;

  h2 {
    @include heading2;

    font-weight: 500;
    font-size: 25px;
    color: $white;
    position: relative;
    top: -1px;
  }

  &__buttons {
    display: flex;
    align-items: flex-start;
    margin-right: 16px;
  }

  @media (max-width: $breakpointTablet) {
    height: auto;
  }
}
</style>
