<template lang="pug">
.modal-questionnaire(v-if="currentPoll")
  .modal-questionnaire__header(v-if="poll === 'buy-subscription'") {{ $t('questionnaireModalBuySubscriptionTitle') }}
  .modal-questionnaire__header(v-else) {{ $t('questionnaireModalTitle') }}

  .modal-questionnaire__image
    img(src="https://cdn.gaming-goods.com/images/modal-questionnaire-coins.png" alt="coins")

  .modal-questionnaire__subtitle(v-if="poll === 'buy-subscription'")
    span(v-t="'questionnaireModalBuySubscriptionSubtitle'")
  .modal-questionnaire__help-us(v-else)
    span(v-t="'questionnaireModalHelpUs'")

  .modal-questionnaire__subtitle-label(v-if="poll === 'buy-subscription'")
    span(v-t="'questionnaireModalBuySubscriptionSubtitleLabel'")

  template(v-if="poll === 'buy-subscription'")
    .modal-questionnaire__check-question
      span(v-t="'questionnaireModalBuySubscriptionText'")
    .modal-questionnaire__checkboxes
      .modal-questionnaire__checkbox(v-for="(checkbox, index) in currentPoll.checkboxes")
        .modal-questionnaire__checkbox-pair
          CheckboxInput(
            v-model="checkboxes[index]"
          )
            span(v-t="checkbox")
          FormInput(v-if="index === 5" v-model="yourOptionText")

  template(v-else)
    .modal-questionnaire__question
      span(v-t="currentPollQuestion1")

    .modal-questionnaire__stars
      .modal-questionnaire__star(v-for="i in 7" :key="`star_1_${i}`")
        img(:src="require('~/assets/img/star.svg')" :class="getStarClass(1, i)" @click="setStars(1, i)" alt="star")

    .modal-questionnaire__line-block
      span.modal-questionnaire__line-block--left(v-t="'easy'")
      .modal-questionnaire__line-block--line
      span.modal-questionnaire__line-block--right(v-t="'difficult'")

    .modal-questionnaire__question
      span(v-t="currentPollQuestion2")

    .modal-questionnaire__stars
      .modal-questionnaire__star(v-for="i in 7" :key="`star_2_${i}`")
        img(:src="require('~/assets/img/star.svg')" :class="getStarClass(2, i)" @click="setStars(2, i)" alt="star")

    .modal-questionnaire__line-block
      span.modal-questionnaire__line-block--left(v-t="'easy'")
      .modal-questionnaire__line-block--line
      span.modal-questionnaire__line-block--right(v-t="'difficult'")

    textarea.modal-questionnaire__comment(v-model="comment" :placeholder="$t('questionnaireModalCommentPlaceholder')")

  Button.modal-questionnaire__button(is-primary v-t="'buttonSend'" @click="sendPoll")
</template>

<script>
import { mapActions } from 'vuex'
import CheckboxInput from '~/components/CheckboxInput/CheckboxInput'
import FormInput from '~/components/FormInput/FormInput'
import UsersService from '~/services/UsersService'

export default {
  name: 'ModalQuestionnaire',

  components: {
    CheckboxInput,
    FormInput
  },

  props: {
    poll: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      polls: {
        'first-tournament': {
          question1: 'questionnaireModalQuestion1Tournaments',
          question2: 'questionnaireModalQuestion2Tournaments'
        },
        'first-offerwall': {
          question1: 'questionnaireModalQuestion1',
          question2: 'questionnaireModalQuestion2'
        },
        'buy-subscription': {
          checkboxes: [
            'questionnaireModalBuySubscriptionCheckbox1',
            'questionnaireModalBuySubscriptionCheckbox2',
            'questionnaireModalBuySubscriptionCheckbox3',
            'questionnaireModalBuySubscriptionCheckbox4',
            'questionnaireModalBuySubscriptionCheckbox5',
            'questionnaireModalBuySubscriptionCheckbox6'
          ]
        }
      },
      checkboxes: [
        0, 0, 0, 0, 0, 0, 0
      ],
      comment: '',
      yourOptionText: '',
      stars1: undefined,
      stars2: undefined
    }
  },

  computed: {
    currentPoll () {
      return this.polls[this.poll]
    },

    currentPollQuestion1 () {
      return this.currentPoll?.question1
    },

    currentPollQuestion2 () {
      console.log(this.currentPoll?.question2)
      return this.currentPoll?.question2
    }
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    getStarClass (question, star) {
      return {
        'modal-questionnaire__star-img': true,
        'modal-questionnaire__star-img--active': question === 1 ? star <= this.stars1 : star <= this.stars2,
        [`modal-questionnaire__star-img--${question}`]: true,
        [`modal-questionnaire__star-img--${question}-${star}`]: true
      }
    },

    setStars (group, star) {
      if (group === 1) {
        this.stars1 = star
      } else if (group === 2) {
        this.stars2 = star
      }
    },

    sendPoll () {
      let extraData
      if (this.poll !== 'buy-subscription') {
        extraData = {
          comment: this.comment,
          scores: [
            {
              score: this.stars1,
              title: this.$t(this.currentPollQuestion1)
            },
            {
              score: this.stars2,
              title: this.$t(this.currentPollQuestion2)
            }
          ]
        }
      } else {
        extraData = {
          comment: this.yourOptionText,
          scores: this.currentPoll.checkboxes.filter((checkbox, index) => this.checkboxes[index]).map(checkbox => ({
            score: 1,
            title: this.$t(checkbox)
          }))
        }
      }
      UsersService.sendPoll(this.$axios, {
        type: this.poll,
        extra_data: extraData
      }).then(() => {
        this.showModal({
          component: 'ModalMessage',
          data: {
            title: this.$t('thanks'),
            type: 'success'
          }
        })
      }).catch((e) => {
        this.showModal({
          component: 'ModalMessage',
          data: {
            title: e,
            type: 'error'
          }
        })
        setTimeout(() => window.location.reload(true), 3000)
      })
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalquestionnaire {
  padding-bottom: 0;
  max-width: 732px;
}
</style>

<style lang="scss" scoped>
.modal-questionnaire {
  &__header {
    padding: 10px 40px;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: $white;
    text-align: center;

    @media (min-width: $breakpointDesktop) {
      padding: 30px 0 23px;
      font-size: 48px;
      line-height: 52px;
    }
  }

  &__subtitle {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: $white;
    padding: 36px 0 8px;
    margin-top: 36px;
    border-top: 1px solid $blue-grey50;

    &-label {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: $blue-grey05;
      margin-bottom: 36px;
    }
  }

  &__image {
    text-align: center;
  }

  &__help-us {
    padding: 40px 39px 28px;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: $amber40;
    text-align: center;

    @media (min-width: $breakpointDesktop) {
      padding: 40px 149px 28px;
      font-size: 32px;
      line-height: 36px;
    }
  }

  &__question {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $white;
    text-align: center;

    @media (min-width: $breakpointDesktop) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__stars {
    display: grid;
    grid-template-columns: repeat(7, 22px);
    grid-gap: 9px;
    margin: 24px auto;
    width: max-content;

    &:hover {
      .modal-questionnaire__star-img {
        ::v-deep path {
          fill: $amber40;
        }
      }
    }
  }

  &__star {
    width: 100%;

    &:hover {
      ~ .modal-questionnaire__star {
        .modal-questionnaire__star-img {
          ::v-deep path {
            fill: $blue-grey10;
          }
        }
      }
    }

    &-img {
      cursor: pointer;

      &--active {
        ::v-deep path {
          fill: $amber90 !important;
        }
      }

      ::v-deep path {
        fill: $blue-grey10;
      }

      &:hover {
        ::v-deep path {
          fill: $amber40;
        }
      }
    }
  }

  &__line-block {
    display: grid;
    grid-template-columns: 50px 1fr 50px;
    grid-gap: 12px;
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $white;

    @media (min-width: $breakpointDesktop) {
      grid-template-columns: 1fr 452px 1fr;
    }

    &--line {
      width: 100%;
      height: 11px;
      border-bottom: 1px solid $blue-grey30;
      border-left: 1px solid $blue-grey30;
      border-right: 1px solid $blue-grey30;
    }

    &--left {
      text-align: end;
    }
  }

  &__comment {
    display: block;
    width: 100%;
    max-width: 604px;
    background: $blue-grey60;
    color: $blue-grey10;
    padding: 16px;
    margin: auto;
    margin-bottom: 40px;
  }

  &__button {
    width: 70%;
    max-width: 456px;
    margin: auto;
    margin-bottom: 24px;
  }

  &__check-question {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: $white;
  }

  &__checkboxes {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    align-items: center;
    margin: 16px 0 32px;
  }

  &__checkbox {
    &-pair {
      display: grid;
      grid-template-columns: max-content 1fr;
      grid-gap: 8px;
      align-items: center;
    }
  }
}
</style>
