<template lang="pug">
  .checkoutmodal
    .modal__header {{ $t('checkoutTitle') }}

    .checkoutmodal__rows
      .checkoutmodal__row.checkoutmodal__row--title
        span {{ $t('checkoutSubtitle') }}
        strong(v-html="product.title")

      //.checkoutmodal__starting {{ $t("startingToday") }}

      .checkoutmodal__prolong-box(v-if="product.extra_data.duration === 30 || product.extra_data.duration === 7")
        .checkoutmodal__prolong-grid
          p.checkoutmodal__prolong-text {{ $t('prolong') }}
          label(for="toggle-button")
            input.checkoutmodal__prolong-toggle(type="checkbox" id="toggle-button" :checked="isProlong" v-model="isProlong")

      //p.checkoutmodal__prolong-comment(v-if="product.extra_data.duration === 30" v-t="'turnOnAutoRenewal'")

      .checkoutmodal__row.checkoutmodal__row--total
        span {{ $t('checkoutCost') }}
        strong(v-if="!productInCoins") {{ formatPrice(finalPriceWithCommission) }} {{ paymentOption === 'BALANCE' ? 'G' : '€' }}
        Currency(v-else type="coins")
          span {{ formatPriceInCoins(finalPriceWithCommission) }}
      .checkoutmodal__row.checkoutmodal__row--balance(v-if="needMoreMoney")
        span {{ $t('checkoutBalance') }}
        strong(v-if="user && !productInCoins") {{ minWithdrawalLimit }} G
        Currency(v-else-if="user" type="coins")
          span {{ coinsInBalance }}
      .checkoutmodal__row.checkoutmodal__row--last(v-if="needMoreMoney")
        // div(v-if="!needMoreMoney") {{ $t('checkoutFromBalance') }}
        template
          // v-else
          span {{ $t('needMore') }}
          strong(v-if="!productInCoins") {{ formatPrice(needMoreMoney) }} G
          Currency(v-else type="coins")
            span {{ needMoreMoney }}

      .checkoutmodal__row.checkoutmodal__row--title
        span {{ $t('choosePayMethod') }}

      .checkoutmodal__payment-methods
        .checkoutmodal__payment-methods-method(@click="paymentOption = 'BALANCE'" :class="{'checkoutmodal__payment-methods-method--active': paymentOption === 'BALANCE'}")
          img.checkoutmodal__payment-methods-method-icon(:src="require('~/assets/img/icons/payment/v.png')" alt="G")
          span.checkoutmodal__payment-methods-method-text {{ $t('siteBalance') }}
        .checkoutmodal__payment-methods-method(@click="paymentOption = 'BANKCARD'" :class="{'checkoutmodal__payment-methods-method--active': paymentOption === 'BANKCARD'}")
          img.checkoutmodal__payment-methods-method-icon(:src="require('~/assets/img/icons/payment/card.png')" alt="Card")
          span.checkoutmodal__payment-methods-method-text {{ $t('creditCard') }}
        //.checkoutmodal__payment-methods-method(v-if="isAdmin" @click="paymentOption = 'BANKCARD_RU'" :class="{'checkoutmodal__payment-methods-method--active': paymentOption === 'BANKCARD_RU'}")
        //  img.checkoutmodal__payment-methods-method-icon(:src="require('~/assets/img/icons/payment/card.png')" alt="Card RU")
        //  span.checkoutmodal__payment-methods-method-text {{ $t('ruBankCard') }}

        //BaseSelect.checkoutmodal__select(
        //  :placeholder=""
        //  :options="paymentOptions"
        //  :value="paymentOption"
        //  :with-empty-value="false"
        //  @input="onPaymentOptionChange($event)"
        //)

    .modal__footer
      .checkoutmodal__footer
        CheckboxInput(v-model="checkbox" top)
          span.checkoutmodal__footer-text(v-if="product.extra_data.duration === 7" v-html="$t('modalCheckoutCheckboxTextWeekly')")
          span.checkoutmodal__footer-text(v-else v-html="$t('modalCheckoutCheckboxText')")

      Button.checkoutmodal__btn(
        v-if="!needMoreMoney"
        is-primary
        @click="pay"
        :disabled="!checkbox || !paymentOption"
      ) {{ $t('buttonBuy') }}

      Button.checkoutmodal__btn(v-else is-primary @click="onRefill" :disabled="!checkbox || !paymentOption") {{ $t('buttonRefillBalance') }}

</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import numeral from 'numeral'
import StoreService from '~/services/StoreService'
import { pay, postUrl } from '~/utils/utils'
import CheckboxInput from '~/components/CheckboxInput/CheckboxInput'
import BaseSelect from '~/components/BaseSelect/BaseSelect'

export default {
  name: 'ModalCheckout',

  components: {
    CheckboxInput,
    BaseSelect
  },

  props: {
    product: {
      type: Object,
      default: null
    },

    afterPay: {
      type: Function,
      default: () => {}
    },

    gameId: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      checkbox: false,
      isProlong: true,
      paymentOption: undefined,
      payMethods: [
        {
          title: `${this.$t('creditCard')}`,
          commissionText: '3.3% +0.25€',
          value: 'BANKCARD',
          commission: 3.3,
          commissionFix: 25,
          icon: 'usd-card'
        },
        // {
        //   title: `${this.$t('ruBankCard')}`,
        //   commissionText: '5%',
        //   value: 'P2P',
        //   commission: 5,
        //   commissionFix: 0,
        //   icon: 'ru-card'
        // },
        {
          title: this.$t('siteBalance'),
          commissionText: '0%',
          value: 'BALANCE',
          commission: 0,
          commissionFix: 0,
          icon: 'V-round'
        }
      ]
    }
  },

  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['isAdmin', 'minWithdrawalLimit']),

    needMoreMoney () {
      if (this.paymentOption !== 'BALANCE') {
        return 0
      }

      const total = this.finalPriceWithCommission

      if (this.productInCoins && this.user?.coins < total) {
        return total - this.user?.coins
      } else if (!this.productInCoins && this.user?.balance < total) {
        return total - this.user.realBalance
      }

      return 0
    },

    productInCoins () {
      return this.product?.extra_data.price_in_coins
    },

    coinsInBalance () {
      return this.user?.coins
    },

    total () {
      return this.product.price
    },

    commission () {
      return this.payMethods.find(m => m.value === this.paymentOption)?.commission / 100
    },

    commissionFix () {
      return this.payMethods.find(m => m.value === this.paymentOption)?.commissionFix
    },

    finalPriceWithCommission () {
      if (!this.paymentOption) {
        return (this.total / 100).toFixed(2)
      }
      return (Math.ceil((this.total) * (1 + this.commission) + this.commissionFix) / 100).toFixed(2)
    }
  },

  methods: {
    ...mapActions('user', ['fetchProducts', 'fetchCanBeWithdrawn']),
    ...mapActions('modal', ['showModal']),
    ...mapMutations('modal', ['toggle']),

    onRefill () {
      pay('in', this.$store)
    },

    onPaymentOptionChange (value) {
      this.paymentOption = value
    },

    formatPrice (number) {
      return numeral(number).format('0,0.00')
    },

    formatPriceInCoins (number) {
      return number
    },

    async payWithBalance () {
      try {
        const { data } = await StoreService.purchase(this.$axios, this.product.id, { data: '', gameId: this.gameId })

        if (data?.data) {
          let text = this.$t('checkoutSuccess')

          // this.$gtag.event('buy_subscription', { product_id: this.product?.id })

          if (this.product.type === 'coins') {
            text = this.$t('checkoutSuccessCoins')
          }

          if (this.product.type === 'subscription_freeroll' && !this.productInCoins) {
            // const poll = 'buy-subscription'
            try {
              postUrl(`KochavaTracker://sendEventString?nameString=purchase&priceString=${this.total}`)
            } catch {
              console.log('no m app')
            }
            // this.showModal({
            //   component: 'ModalQuestionnaire',
            //   data: {
            //     poll
            //   }
            // })
          } else {
            this.showModal({
              component: 'ModalMessage',
              data: {
                type: 'allow',
                title: this.$t('checkoutSuccessTitle'),
                text
              }
            })
          }

          await this.fetchProducts()
          await this.fetchCanBeWithdrawn()
          if (this.afterPay) {
            this.afterPay()
            this.toggle()
          }
        }
      } catch (e) {
        this.$errors.handle(e)
      }
    },

    async directPay () {
      try {
        const { data } = await StoreService.purchaseWithCard(this.$axios, this.product?.id, this.isProlong, this.paymentOption === 'BANKCARD_RU', this.gameId)

        if (data.data) {
          // this.$gtag.event('buy_subscription', { product_id: this.product?.id })

          window.location.href = data.data.returnUrl
        }
      } catch (e) {
        this.$errors.handle(e)
      }
    },

    async pay () {
      if (!this.checkbox || !this.paymentOption) {
        return
      }

      this.$nuxt.$loading.start()

      if (this.productInCoins || this.paymentOption === 'BALANCE') {
        await this.payWithBalance()
      } else {
        await this.directPay()
      }

      this.$nuxt.$loading.finish()
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalcheckout {
  padding: 24px 24px 12px;
  max-width: 576px;

  @media (max-width: $breakpointMobile) {
    padding: 24px 16px 12px;

    .modal__close {
      top: 24px;
    }
  }
}

.checkoutmodal {
  .modal__footer {
    padding: 12px 24px 0;
  }

  button.button {
    @media (max-width: $breakpointMobile) {
      width: 100%;
    }
  }

  &__starting {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: $blue-grey10;
    margin-bottom: 24px;
  }

  &__payment-methods {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    margin-top: 12px;

    @media (min-width: $breakpointTablet) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 12px;
    }

    &-method {
      display: grid;
      grid-template-columns: 24px 1fr;
      grid-gap: 12px;
      align-items: center;
      padding: 12px;
      border-radius: 8px;
      background-color: $blue-grey60;
      color: $white;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      border: 1px solid $blue-grey60;
      cursor: pointer;

      &--active {
        border: 1px solid $primary60;
      }
    }
  }

  &__btn {
    &:disabled {
      opacity: 0.4;
    }
  }

  &__rows {
    margin: 24px 0 32px;
  }

  &__row {
    @include caption;

    height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $blue-grey10;
    margin-bottom: 20px;

    strong {
      @include heading5;
    }

    &--title {
      display: block;
      height: auto;
      margin-bottom: 0;

      span {
        min-width: 150px;
        font-size: 16px;
        font-weight: 450;
        line-height: 100%;
      }

      strong {
        @include heading4;

        display: block;
        width: 100%;
        text-transform: none;
        color: #fff;
        margin-top: 8px;
      }
    }

    &--total {
      height: 32px;

      span {
        font-size: 16px;
        font-weight: 450;
        line-height: 100%;
        text-transform: uppercase;
      }

      strong {
        font-size: 32px;
        font-weight: $font-bold;
        line-height: 100%;
        color: $amber60;
      }
    }

    &--balance {
      height: 24px;

      strong {
        @include heading4;

        color: #fff;
      }
    }

    &--last {
      padding-top: 16px;
      padding-bottom: 16px;
      border-top: 1px solid $blue-grey40;
      border-bottom: 1px solid $blue-grey40;
      margin-top: 24px;
      margin-bottom: 12px;
      height: auto !important;

      strong {
        @include heading4;

        color: $red60;
      }

      div {
        @include text-info120;

        width: 100%;
        text-transform: none;
      }
    }
  }

  &__image {
    display: block;
    margin: 56px auto 16px;
  }

  &__title {
    font-size: 28px;
    font-weight: $font-bold;
    color: #fff;
    text-align: center;
    line-height: 34px;
  }

  &__footer {
    &-text {
      display: block;
      max-width: 90%;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }

  &__prolong {
    padding: 16px;
    background-color: $blue-grey70;
    border-top: 1px solid $blue-grey50;
    border-radius: 0 0 8px 8px;

    &-box {
      @media (min-width: $breakpointDesktop) {
        display: flex;
        justify-content: space-between;
      }
    }

    &-text {
      color: $white;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    &-grid {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      @media (min-width: $breakpointDesktop) {
        justify-content: flex-start;
      }
    }

    &-comment {
      margin: 0 0 20px;
      color: $white;
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
    }

    &-toggle {
      position: relative;
      display: inline-block;
      width: 42px;
      height: 24px;
      margin: 0;
      vertical-align: top;
      background: $blue-grey30;
      border: 1px solid $blue-grey30;
      border-radius: 12px;
      outline: none;
      cursor: pointer;
      appearance: none;
      transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);

      @media (min-width: $breakpointDesktop) {
        margin-left: 16px;
      }

      &::after {
        content: "";
        display: inline-block;
        position: absolute;
        left: 3px;
        top: 3px;
        width: 16px;
        height: 16px;
        background-color: $white;
        border-radius: 50%;
        transform: translateX(0);
        transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
      }

      &:checked {
        &::after {
          transform: translateX(calc(100% + 3px));
          background-color: #fff;
        }

        background: linear-gradient(90deg, #1e88e5 0%, #42a5f5 100%);
      }
    }
  }

  &__select {
    width: 100%;
    margin-top: 20px;
  }
}
</style>
