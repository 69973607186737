<template lang="pug">
  .checkbox-switch
    label.checkbox-switch__label
      .checkbox-switch__info(v-if="title || subtitle")
        .checkbox-switch__title(v-if="title")
          | {{ title }}
        .checkbox-switch__subtitle(v-if="subtitle" v-html="subtitle")

      .checkbox-switch__text(v-if="$slots.default")
        slot
      input.checkbox-switch__input(
        type='checkbox'
        :class="{ '--checked': value }"
        :checked="value"
        @change="onChange"
      )
      .checkbox-switch__fake
        div
</template>

<script>
export default {
  name: 'CheckboxSwitch',

  props: {
    title: {
      type: String,
      default: ''
    },

    subtitle: {
      type: String,
      default: ''
    },

    text: {
      type: String,
      default: ''
    },

    value: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {}
  },

  methods: {
    onChange (event) {
      this.$emit('input', event.target.checked)
    }
  }
}
</script>

<style lang="scss">
.checkbox-switch {
  display: flex;
  align-items: center;
  user-select: none;

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__title {
    @include heading4;

    color: $white;
  }

  &__subtitle {
    @include text-display100;

    margin-top: 8px;
    color: $blue-grey10;
  }

  &__text {
    @include caption-small;

    color: $blue-grey10;
    min-width: fit-content;
  }

  &__label {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  &__fake {
    width: 35px;
    min-width: 35px;
    height: 20px;
    border-radius: 12px;
    background: rgb(255 255 255 / 30%);
    position: relative;
    transition: 0.2s $easing;
    margin-left: 15px;
    cursor: pointer;

    div {
      width: 13px;
      height: 13px;
      background: $white;
      position: absolute;
      left: 4px;
      top: 4px;
      border-radius: 50%;
      transition: 0.2s $easing;
      transform: translateX(0%);
    }
  }

  &__input {
    display: none;
  }

  &__input.--checked ~ .checkbox-switch {
    &__fake {
      background: $primary60-20;

      div {
        background: $primary60;
        transform: translateX(100%);
      }
    }
  }
}
</style>
