<template lang="pug">
  .enter-tournament-success
    .enter-tournament-success__inner
      .enter-tournament-success__top
        .enter-tournament-success__img
          img(:src="require('./img/success.svg')" alt="success")
        .enter-tournament-success__content
          .enter-tournament-success__title {{ $t('tournamentSuccess') }}

      .enter-tournament-success__access
        template(v-if="!isPhasedTournament && isLichess && hasAccess")
          .enter-tournament-success__input
            CopyInput(:title="this.$t('tournamentPassword')" :value="tournament.password" icon="lock-open")
          .enter-tournament-success__btn(class="--video")
            VideoChatButton(v-if="webcamLink" @click="onWebcamClick") {{ $t('videoLaunch') }}
            Button(is-primary @click="onGoTournamentClick") {{ $t('goToGame') }}

        template(v-else-if="isPhasedTournament")
          .enter-tournament-success__text {{ $t('tournamentLobbyDataText') }}
          .enter-tournament-success__btn
            Button(is-primary @click="onGoToStagesClick") {{ $t('buttonGoToStages') }}

        template(v-else)
          .enter-tournament-success__text {{ $t('tournamentSuccessInfo') }}
          .enter-tournament-success__btn
            Button(is-primary @click="toggleModal(false)") {{ $t('buttonOk') }}

    .enter-tournament-success__scroll
      TournamentsList(v-if="bonusTournaments" :tournaments="bonusTournaments" v-bind="listProps")
        template(v-slot:title)
          .enter-tournament-success__title.enter-tournament-success__list-title {{ $t('recommendedBonusTournaments') }}
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import CopyInput from '~/components/CopyInput/CopyInput'
import TournamentMixin from '~/mixins/Tournament'
import VideoChatButton from '~/components/VideoChatButton/VideoChatButton'
import TournamentsList from '~/components/TournamentsList/TournamentsList'

export default {
  name: 'EnterTournamentSuccess',

  components: { TournamentsList, VideoChatButton, CopyInput },

  mixins: [TournamentMixin],

  props: {
    tournament: {
      type: Object,
      required: true
    },

    isPhasedTournament: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {}
  },

  computed: {
    ...mapState('games', ['gameBonusTournaments']),

    hasAccess () {
      return (
        !this.tournament.is_anticheat_required ||
        this.isAnticheatEnabled ||
        !this.$device.isWindows
      )
    },

    listProps () {
      return { view: 'list', isSmall: true }
    },

    bonusTournaments () {
      return this.gameBonusTournaments[this.game.name]
    }
  },

  beforeMount () {
    this.$store.dispatch('games/fetchBonusTournaments', this.game.name)
  },

  beforeDestroy () {
    this.$router.go()
  },

  methods: {
    ...mapMutations('modal', { toggleModal: 'toggle' }),

    onGoTournamentClick () {
      this.onEnterEventClick()
      this.toggleModal(false)
    },

    onGoToStagesClick () {
      this.$router.push(
        this.localeLocation(
          `/${this.game.name}/phased-tournaments/${this.tournament.id}#stages`
        )
      )
      this.toggleModal(false)
    }
  }
}
</script>

<style lang="scss">
#AdTrackGenericOutstreamDesktop {
  max-width: 74%;
  margin: 12px auto 0;
}

.modal.modal--entertournamentsuccess {
  @include scroll-none;

  max-width: 700px;
  position: relative;
  background: $blue-grey80;
  overflow: hidden;
  padding: 0;

  .modal__close {
    position: fixed;
  }

  @media (max-width: $breakpointMobile) {
    border-radius: 0;
  }
}

.enter-tournament-success {
  max-height: calc(100vh - 128px);
  display: flex;
  flex-direction: column;

  @media (max-width: $breakpointMobile) {
    max-height: 100vh;
  }

  &__inner {
    padding: 24px;
    background: $blue-grey70;
    flex-shrink: 0;

    @media (max-width: $breakpointMobile) {
      padding: 18px 16px 16px;
    }
  }

  &__top {
    z-index: 2;
    display: flex;
    width: 100%;
  }

  &__access {
    margin-left: 108px;

    @media (max-width: $breakpointMobile) {
      margin-left: 0;
    }
  }

  &__scroll {
    @include scroll;

    overflow-y: auto;
  }

  .tournaments-list {
    padding: 32px 24px 16px;

    @media (max-width: $breakpointMobile) {
      padding: 24px 16px 16px;

      &__body-inner {
        margin-top: 12px;
      }
    }
  }

  &__content {
    width: 100%;
    padding-right: 56px;

    @media (max-width: $breakpointMobile) {
      padding-right: 48px;
    }
  }

  &__text {
    @include body;

    color: $blue-grey05;
    padding: 16px 0 0;
    text-align: left;
  }

  &__img {
    min-width: 108px;

    img {
      position: absolute;
      width: 80px;
      height: 80px;

      path {
        fill: $green60;
      }
    }

    @media (max-width: $breakpointMobile) {
      min-width: 60px;

      img {
        position: absolute;
        width: 48px;
        height: 48px;

        path {
          fill: $green60;
        }
      }
    }
  }

  &__title {
    @include heading3;

    color: #fff;

    @media (max-width: $breakpointMobile) {
      font-size: 18px;
      line-height: 26px;
    }
  }

  &__input {
    margin-top: 24px;

    .copy-input__box {
      max-width: 100%;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    margin-top: 24px;
    margin-left: auto;
    width: fit-content;

    .video-chat-button {
      margin-right: 16px;
    }

    &.--video {
      width: 100%;

      .button--primary,
      .video-chat-button {
        width: 100%;
      }
    }
  }
}

@media (max-width: 496px) {
  .enter-tournament-success {
    &__btn {
      flex-direction: column;
      align-items: flex-end;

      .button--secondary,
      .video-chat-button {
        margin: 0 0 16px;
      }
    }
  }
}
</style>
