<template lang="pug">
  v-date-picker.date-picker(
    value="calendarValue"
    :locale="$i18n.locale"
    is-dark
    :max-date="new Date()"
    :min-date="'1920-01-01'"
    :masks="{input: 'DD.MM.YYYY'}"
    @dayclick="onDayClick"
    @input="onDateInput"
  )
    template(v-slot="{ inputValue, inputEvents }")
      .form-input(:class="{'--error': error }")
        .form-input__title(v-if="title")
          | {{ title }}
        label.form-input__label
          input(
            v-bind="$attrs"
            :type="type"
            :value="inputValue || value"
            :placeholder="placeholder"
            v-on="inputEvents"
            @input="onInput"
          )
          .form-input__icon
            img(:src="require(`~/assets/img/icons/calendar.svg`)" alt="calendar")
          .form-input__error(v-if="error")
            | {{ error }}

</template>

<script>
import FormInput from '~/components/FormInput/FormInput'

export default {
  name: 'DatePicker',

  extends: FormInput,

  computed: {
    calendarValue () {
      return this.$dateFns.parse(this.value, 'dd.MM.yyyy', new Date())
    }
  },

  methods: {
    onDayClick ({ date }) {
      this.$emit('input', this.$dateFns.format(date, 'dd.MM.yyyy'))
      this.$emit('blur')
    },

    onDateInput (date) {
      this.$emit('input', this.$dateFns.format(date, 'dd.MM.yyyy'))
    }
  }
}
</script>

<style lang="scss">
.date-picker {
  //.vc-popover-content-wrapper {
  //}

  .vc-container {
    font-family: $mainFont !important;
    font-weight: $font-bold !important;
    border-radius: 12px !important;
    background: $blue-grey70 !important;
    border-color: $blue-grey50 !important;
  }

  .vc-pane {
    min-width: 276px !important;
  }

  .vc-weekday {
    font-family: $mainFont !important;
    padding: 0 0 12px !important;
    color: $blue-grey20 !important;
    font-size: 16px !important;
  }

  .vc-weeks {
    padding: 18px 12px 12px !important;
  }

  .vc-arrows-container {
    height: 24px !important;
    margin: 18px 0 0 !important;
    padding: 0 !important;
    left: 14px !important;
    right: 14px !important;
    width: auto !important;
  }

  .vc-arrow {
    border: none !important;
    width: 24px !important;
    height: 24px !important;
    transition: 0.2s $easing !important;

    &:hover {
      background: $blue-grey50 !important;
    }

    img {
      width: 20px !important;
      height: 20px !important;
    }
  }

  .vc-title {
    text-transform: capitalize !important;
    font-size: 16px !important;
    height: 20px !important;
    line-height: 20px !important;
    transition: 0.2s $easing !important;
  }

  .vc-day-content {
    font-size: 16px !important;
    border-radius: 4px !important;
    transition: 0.2s $easing !important;
    font-weight: $font-bold !important;
    font-family: $mainFont !important;

    &:hover {
      background: $blue-grey50 !important;
    }
  }

  .vc-day {
    .vc-highlight {
      border-radius: 4px !important;
      background: $primary60 !important;
    }
  }

  .vc-nav-popover-container {
    background: $blue-grey50 !important;
    color: #fff !important;
    border: none !important;
    padding: 8px 12px 12px !important;
  }

  .vc-nav-header {
    height: 28px !important;
    line-height: 28px !important;
    margin-bottom: 12px !important;
  }

  .vc-nav-title {
    height: 28px !important;
    border: none !important;
    font-size: 16px !important;
    color: #fff !important;
    transition: 0.2s $easing !important;

    &:hover {
      background: $blue-grey30 !important;
    }
  }

  .vc-nav-arrow {
    height: 28px !important;
    transition: 0.2s $easing !important;
    border: none !important;

    img {
      width: 20px !important;
      height: 20px !important;
    }

    &:hover {
      background: $blue-grey30 !important;
    }
  }

  .vc-nav-items {
    grid-row-gap: 4px !important;
    grid-column-gap: 4px !important;
  }

  .vc-nav-item {
    padding: 4px 8px !important;
    font-size: 16px !important;
    line-height: 16px !important;
    border: none !important;
    font-weight: $font-medium !important;
    transition: 0.2s $easing !important;
    width: 57px !important;

    &:hover {
      background: $blue-grey30 !important;
      color: #fff !important;
    }

    &.is-current {
      color: #fff !important;
    }

    &.is-active {
      background: $primary60 !important;
    }
  }

  .vc-header {
    padding: 20px 22px 0 !important;
  }

  .form-input {
    &__icon {
      left: auto;
      right: 12px;
    }
  }
}
</style>
