<template lang="pug">
  Button.help-button(is-primary v-support-button)
    img.help-button__img(:src="require('~/assets/img/icons/question-mark-white.svg')" alt="question")
    span.help-button__text {{ $t('techSupport') }}
</template>

<script>
import Button from '~/components/Button'
export default {
  name: 'HelpButton',
  components: {
    Button
  }
}
</script>

<style lang="scss" scoped>
.help-button {
  &__text {
    padding-left: 8px;
  }

  &__img {
    height: 24px;
    width: 24px;
  }
}
</style>
