<template lang="pug">
  .modal-bysub
    img.modal-bysub__image(:src="require('~/assets/img/icons/store.svg')" alt="store")

    h2.modal-bysub__title {{ texts.title }}

    Button.modal-bysub__button(is-primary @click="closeModal") {{ texts.buttonBySub }}
  </template>

<script>
import { mapMutations } from 'vuex'

export default {

  data () {
    return {
      texts: {
        title: this.$t('needSubscriptionToParticipate'),
        buttonBySub: this.$t('buttonBySub')
      }
    }
  },

  methods: {
    ...mapMutations('modal', ['toggle']),

    closeModal () {
      this.$router.push(this.localeLocation('/store/subscriptions'))
      this.toggle(false)
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalbysub {
  max-width: 420px;

  @media (min-width: $breakpointTablet) {
    padding: 40px 51px !important;
    max-width: 520px;
  }
}
</style>

<style lang="scss" scoped>
.modal-bysub {
  color: $white;
  text-align: center;

  &__image {
    height: 114px;
    width: 114px;
    margin-bottom: 29px;

    ::v-deep path {
      fill: $cyan50;
    }
  }

  &__title {
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
  }

  &__text {
    margin-bottom: 32px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  &__subtitle {
    margin-top: 20px;
    margin-bottom: 28px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }

  &__button {
    margin-top: 32px;
    width: 100% !important;

    &--muted {
      &::v-deep {
        .button__text {
          color: #90A4AE !important;
        }
      }
    }
  }
}
</style>
