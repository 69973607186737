<template lang="pug">
  transition(name="slide-up" duration="300")
    .next-tournament(
      v-if="isActive"
      :class="modifiers"
      @click="onPanelClick"
    )
      .next-tournament__inner
        .next-tournament__game(v-if="game")
          img(:src="game.extra_data.icon" @click="isOpened = false" :alt="game.extra_data.title" width="20" height="20")
        .next-tournament__text
          NuxtLink(:to="localeLocation(link)")
            span {{ nextTournament.title }}
            span(v-if="isTimeVisible")
              span.next-tournament__time-text {{ $t('timerStartsIn') }}&nbsp;
              Countdown(:end-time="startsAt")
                span.next-tournament__time-value(slot="process" slot-scope="{ timeObj }")
                  | {{ timeObj.h }}:{{ timeObj.m }}:{{ timeObj.s }}
            span.next-tournament__time-date(v-else-if="inProgress")
              span.next-tournament__time-text {{ $t('tournamentAlreadyStarted') }}
            span.next-tournament__time-date(v-else)
              span.next-tournament__time-text {{ $t('start') }}:&nbsp;
              | {{ dateText }}

        .next-tournament__lobby(v-if="isOpened && isLobbyReady")
          NuxtLink(:to="localeLocation(link)") {{ $t('enterLobby') }}
        .next-tournament__switcher(
          @click.stop="onSwitcherClick"
          @touchstart.stop="onSwitcherClick"
        )
          img(src="~/assets/img/icons/angle-double-left.svg" alt="slide-left" width="20" height="20")

</template>

<script>
import { mapState } from 'vuex'
import TournamentMixin from '~/mixins/Tournament'
import { getDateText } from '~/utils/utils'

export default {
  name: 'NextTournament',

  mixins: [TournamentMixin],

  data () {
    return {
      nextTournament: null,
      isOpened: true
    }
  },

  computed: {
    ...mapState('games', ['gamesById']),
    ...mapState('tournament', ['tournament']),
    ...mapState('team', ['team']),

    isLobbyReady () {
      const pubgLobby =
        this.nextTournament?.extra_data?.state?.game?.extra_data?.id
      const brawlLobbyCode =
        this.nextTournament?.extra_data?.state?.game?.extra_data?.code
      const clashRoyaleTag = this.nextTournament?.extra_data?.state?.game?.tag

      return !!(pubgLobby || clashRoyaleTag || brawlLobbyCode)
    },

    inProgress () {
      return (
        this.nextTournament.state === 'in_progress' ||
        this.startsAt < new Date()
      )
    },

    modifiers () {
      const isTournamentPage = [
        'game-tournaments-id',
        'game-phased-tournaments-id'
      ].includes(this.$route.name)

      const isTeam = this.tournament?.teams
      const isTeamCreated = this.team
      const canJoinTournament =
        !isTeam && isTournamentPage && this.canJoinTournament && !this.hasAccess

      return {
        '--opened': this.isOpened,
        '--lobby': this.isLobbyReady,
        '--tournament': isTournamentPage,
        '--tournament-can-join': canJoinTournament,
        '--tournament-can-join-rub': canJoinTournament && this.isPaid,
        '--team': isTournamentPage && isTeam,
        '--team-created': isTournamentPage && isTeam && isTeamCreated,
        '--leaderboard': this.$route.name.startsWith('leaderboard'),
        '--menu': this.$route.name.startsWith('menu')
      }
    },

    isActive () {
      return (
        !!this.nextTournament &&
        !this.$route.path.startsWith(this.link.split('#')[0])
      )
    },

    isTimeVisible () {
      return (
        !this.inProgress &&
        this.$dateFns.differenceInDays(this.startsAt, new Date()) < 1
      )
    },

    startsAt () {
      if (!this.nextTournament?.date) {
        return null
      }

      const date = this.nextTournament.date?.replace(' ', 'T') + 'Z'

      return this.$dateFns.parseISO(date)
    },

    link () {
      let link = ''

      const type = this.nextTournament?.isPhased
        ? 'phased-tournaments'
        : 'tournaments'

      link = `/${this.game?.name}/${type}/${this.nextTournament?.id}`

      if (this.nextTournament?.mega_tournament_id) {
        link = `/${this.game?.name}/phased-tournaments/${this.nextTournament.mega_tournament_id}#stages-${this.nextTournament.id}`
      } else if (this.isLobbyReady) {
        link += '#lobby'
      }

      return link
    },

    game () {
      return this.gamesById[this.nextTournament?.game_id]
    },

    dateText () {
      return getDateText.call(this, this.startsAt)
    }
  },

  watch: {
    nextTournament (newTournament, prevTournament) {
      if (prevTournament) {
        this.$socket.off(
          `tournament:${prevTournament.id}`,
          this.updateTournament
        )
        this.$socket.off(
          `mega-tournament:${prevTournament.id}`,
          this.updateTournament
        )
      }

      if (newTournament) {
        if (newTournament?.isPhased) {
          this.$socket.on(
            `mega-tournament:${newTournament.id}`,
            this.updateTournament
          )
        } else {
          this.$socket.on(
            `tournament:${newTournament.id}`,
            this.updateTournament
          )
        }
      }

      this.$nextTick(() => {
        if (this.isLobbyReady) {
          this.isOpened = true
        }
      })

      if (newTournament?.isPhased) {
        this.updateStages()
      }
    }
  },

  beforeMount () {
    this.refresh()
    this.isOpened =
      window.localStorage.getItem('vvv-next-tournament-opened') === '1'

    this.$socket.on('user:update', this.refresh)
  },

  mounted () {
    setInterval(this.refresh, 30000)
  },

  beforeDestroy () {
    this.$socket.off('user:update', this.refresh)
    if (this.nextTournament) {
      this.$socket.off(
        `tournament:${this.nextTournament.id}`,
        this.updateTournament
      )

      this.$socket.off(
        `mega-tournament:${this.nextTournament.id}`,
        this.updateTournament
      )
    }
  },

  methods: {
    onSwitcherClick () {
      this.setOpenedState(!this.isOpened)
    },

    onPanelClick () {
      if (!this.isOpened) {
        this.setOpenedState(true)
      }
    },

    setOpenedState (value) {
      this.isOpened = value
      window.localStorage.setItem('vvv-next-tournament-opened', value ? 1 : 0)
    },

    updateTournament (newTournament) {
      if (!['open', 'in_progress'].includes(newTournament.state)) {
        this.nextTournament = null
        this.refresh()
        return
      }

      this.nextTournament = newTournament
    },

    async updateStages () {
      if (!this.nextTournament?.isPhased) {
        return
      }

      try {
        const { data } = await this.$axios.get(
          `/games/${this.game.name}/mega-tournaments/${this.nextTournament.id}/stages`
        )

        let myLastRoom
        data.data.reverse().some(({ rooms }) => {
          myLastRoom = rooms.find(({ is_my_stage: isMy, state }) => {
            return isMy && ['open', 'in_progress'].includes(state)
          })

          return myLastRoom
        })
        if (myLastRoom) {
          this.setRoomTournament({
            ...myLastRoom,
            game_id: this.nextTournament.game_id,
            mega_tournament_id: this.nextTournament.id
          })
        }
      } catch (e) {
        console.error(`${new Date().toUTCString()} :: next stages error ::`, e)
      }
    },

    // eslint-disable-next-line camelcase
    async setRoomTournament ({ id, game_id, mega_tournament_id }) {
      try {
        const { data } = await this.$axios.get(
          `/games/${this.gamesByName[game_id]}/tournaments/${id}`
        )
        this.nextTournament = { ...data.data, mega_tournament_id }
        this.$nextTick(() => {
          this.$forceUpdate()
        })
      } catch (e) {
        console.error(
          `${new Date().toUTCString()} :: set room tournaments ::`,
          e
        )
      }
    },

    async refresh () {
      const requestData = {
        query: {
          filters: [
            {
              name: 'state',
              type: 'string_list',
              values: [{ text: 'open' }, { text: 'in_progress' }]
            }
          ],
          sort: {
            name: 'date',
            order: 'asc'
          }
        },
        url: '/games/tournaments/my'
      }

      const requests = [
        this.$store.dispatch('games/fetchTournaments', requestData),
        this.$store.dispatch('games/fetchPhasedTournaments', {
          ...requestData,
          url: '/games/mega-tournaments/my'
        })
      ]

      try {
        const [{ data }, { data: phasedData }] = await Promise.all(requests)

        if (
          phasedData?.[0] &&
          new Date(phasedData?.[0].date) < new Date(data?.[0].date)
        ) {
          this.nextTournament = { ...phasedData?.[0], isPhased: true }
        } else {
          this.nextTournament = data?.[0]
        }
      } catch (e) {
        console.error(
          `${new Date().toUTCString()} :: next tournament fetch error ::`,
          e
        )
      }
    }
  }
}
</script>

<style lang="scss">
.next-tournament {
  position: fixed;
  padding-right: 13px;
  left: 16px;
  bottom: 60px;
  z-index: 4;
  background: $blue-grey60;
  border-radius: 16px;
  overflow: hidden;
  color: #fff;
  width: 52px;
  transition: all 0.2s $easing;
  cursor: pointer;
  border: 1px solid $blue-grey70;

  &.--lobby {
    padding-right: 0;
  }

  @media (max-width: $breakpointDesktop) {
    left: 0;
  }

  @media (max-width: $breakpointMobile) {
    left: 0;
    bottom: 46px;
    border-radius: 0 16px 16px 0;
    margin-bottom: -1px;

    &.--team {
      bottom: 96px;

      &-created {
        bottom: 140px;
      }
    }

    &.--tournament-can-join {
      bottom: 130px;

      &-rub {
        bottom: 150px;
      }
    }

    &.--leaderboard {
      bottom: 108px;
    }

    &.--menu {
      bottom: 120px;
    }

    &.--tournament {
      display: none;
    }
  }

  &.--leaderboard {
    bottom: 72px;
  }

  &:hover {
    width: 80px;

    @media (max-width: $breakpointMobile) {
      width: 52px;
    }
  }

  &:hover &__switcher {
    background-color: $primary60;
    transition-delay: 0s;
  }

  &.--opened {
    width: 360px;
    cursor: default;

    @media (max-width: $breakpointMobile) {
      width: 100vw;
      border-radius: 0;
    }
  }

  &__inner {
    min-width: 360px;
    display: flex;
    align-items: stretch;
    min-height: 52px;

    @media (max-width: $breakpointMobile) {
      min-width: 100vw;
    }
  }

  &__switcher {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 52px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 4px;
    transition: background 0s;
    transition-delay: 0.2s;

    .--opened & {
      right: 0;
      width: 13px;
      transition: width 0.2s $easing;
      cursor: pointer;
      border-left: 15px solid transparent;
      box-sizing: content-box;
      background: $primary60;
      background-clip: content-box;
      justify-content: center;
      padding-right: 0;

      img {
        transform: rotate(0);
        opacity: 0;
      }

      &:hover {
        width: 28px;

        img {
          opacity: 1;
        }
      }

      @media (max-width: $breakpointMobile) {
        width: 32px;
        border-left: 0 solid transparent;
        justify-content: center;
        border-radius: 8px 0 0 8px;

        img {
          opacity: 1;
        }
      }
    }

    img {
      transition: opacity 0.2s $easing;
      transform: rotate(-180deg);
    }
  }

  &__lobby {
    display: flex;
    align-items: center;
    padding-right: 22px;

    a {
      @include hint-small;

      color: $primary60;
    }
  }

  &__game {
    min-width: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 16px 16px 0;
    background: $blue-grey50;
    position: relative;
    z-index: 2;
  }

  &__text {
    flex-grow: 1;
    margin-left: 12px;

    > a {
      @include hint-small;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 100%;
      width: fit-content;

      > span:last-child {
        margin-top: 2px;
      }
    }
  }

  &__time {
    &-text {
      color: $blue-grey05;
      text-transform: lowercase;
    }

    &-date &-text {
      text-transform: none;
    }

    &-value {
      @include hint;
    }
  }
}
</style>
