class CurrencyService {
  constructor () {
    if (CurrencyService.instance) {
      return CurrencyService.instance
    }

    this.currencies = []
    this.needUpdate = true

    CurrencyService.instance = this

    return this
  }

  getAllCurrencies (engine) {
    return engine
      .get('/platform/currencies')
      .then((response) => {
        if (response.data?.data) {
          return [
            ...response.data.data,
            {
              id: 1000013,
              code: 'EUR',
              exchange_rate: 1
            }
          ]
        }
        return []
      })
      .catch((err) => {
        console.error(err)
      })
  }

  async getCurrencies (engine) {
    if (this.needUpdate) {
      const result = await engine.get('/platform/currencies')
      this.currencies = result.data.data
      this.needUpdate = false
      setTimeout(() => { this.needUpdate = true }, 600000)
    }
    return this.currencies
  }

  convertRUBToEUR (sum) {
    const rub = this.currencies.find(item => item.code === 'RUB')

    return sum * rub.exchange_rate
  }

  setCurrencyForCurrentUser (engine, currencyCode) {
    return engine.put('/user', { currency: currencyCode })
  }
}

export default new CurrencyService()
