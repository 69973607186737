class StoreService {
  constructor () {
    if (StoreService.instance) {
      return StoreService.instance
    }
  }

  updateMarketplaceCategorySeoData (engine, categoryId, lang, seoData) {
    return engine.post(`/marketplace/category/${categoryId}/seo`, { lang, seoData })
  }
}

export default new StoreService()
