<template lang="pug">
  .recommended-tournaments-modal
    .modal__header {{ $t('recommendedTournaments') }}

    .recommended-tournaments-modal__body
      .recommended-tournaments-modal__text
        | {{ $t('weHaveTournamentsForYou') }}

      TournamentsList(:tournaments="recommendedTournaments" v-bind="listProps")

</template>

<script>
import { mapState } from 'vuex'
import TournamentCard from '~/components/TournamentCard/TournamentCard'
import TournamentsList from '~/components/TournamentsList/TournamentsList'

export default {
  name: 'RecommendedTournamentsModal',

  components: {
    TournamentsList,
    TournamentCard
  },

  props: {
    game: {
      type: String,
      required: true
    },

    count: {
      type: Number,
      default: 3
    }
  },

  computed: {
    ...mapState('games', ['gameRecommendedTournaments']),

    recommendedTournaments () {
      return this.gameRecommendedTournaments[this.game]?.slice(0, this.count)
    },

    listProps () {
      return { view: 'list', isSmall: true }
    }
  }
}
</script>

<style lang="scss">
.modal.modal--recommendedtournamentsmodal {
  padding-bottom: 0;
  max-width: 736px;
}

.recommended-tournaments-modal {
  &__body {
    margin-top: 24px;
  }

  &__text {
    font-size: 18px;
    color: $blue-grey05;
  }

  .tournaments-list {
    &__body-inner {
      margin-top: 16px;
    }

    .tournament-card {
      background: $blue-grey60;
    }
  }

  @media (max-width: $breakpointTablet) {
    padding-bottom: 24px;
  }
}
</style>
