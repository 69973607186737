<template lang="pug">
  form.reset-password.reset-password__body(:class="{'--error': errorMessage}" @submit.prevent="onSubmit")
    a.reset-password__back-button(v-if="isReset && !isProfile" @click="onBackButton")
      img(:src="require('~/assets/img/icons/arrow-left.svg')" alt="arrow left")

    .reset-password__btn-wraper
      a.reset-password__btn {{ isReset ? $t('resetPassword') : $t('newPassword') }}

    .reset-password__input(v-for="(input, index) in fields" :key="index")
      FormInput(
        v-bind="input"
        v-model="input.value"
        @input="onFieldInput(index, input)"
        @blur="onFieldBlur(index, input)"
        @keypress="onFieldKeypress($event, input)"
      )

    .reset-password__text(v-if="isReset")
      | {{ enterText }}

    transition(name="fade" duration="200")
      InfoMessage(v-if="warningMessage" type="info")
        | {{ warningMessage }}

    transition(name="fade" duration="200")
      InfoMessage(v-if="errorMessage" type="error")
        | {{ errorMessage }}

    .reset-password__send
      Button(is-primary type="submit" :is-disabled="isButtonDisabled")
        | {{ buttonText }}

</template>

<script>
import { mapActions } from 'vuex'
import FormInput from '~/components/FormInput/FormInput'
import InfoMessage from '~/components/InfoMessage/InfoMessage'
import { EMAIL_REGEX } from '~/utils/constants'

export default {
  name: 'ResetPassword',

  components: {
    InfoMessage,
    FormInput
  },

  props: {
    mode: {
      type: String,
      default: 'reset'
    },

    code: {
      type: String,
      default: ''
    },

    email: {
      type: String,
      default: ''
    },

    isProfile: {
      type: Boolean,
      default: false
    }
  },

  data (props) {
    return {
      isReset: props.mode === 'reset',

      verifyCode: props.code,

      resetFields: {
        email: {
          type: 'text',
          value: props.email,
          title: 'Email',
          placeholder: this.$t('typeEmail'),
          required: true
        }
      },

      newPasswordFields: {
        password: {
          type: 'password',
          value: '',
          title: this.$t('password'),
          placeholder: this.$t('chooseNewPassword'),
          required: true
        },

        confirmPassword: {
          type: 'password',
          value: '',
          title: this.$t('repeatPassword'),
          placeholder: this.$t('repeatNewPassword'),
          required: true
        }
      },

      errorMessage: '',

      warningMessage: ''
    }
  },

  computed: {
    fields () {
      return this.isReset ? this.resetFields : this.newPasswordFields
    },

    enterText () {
      return this.$t('youWillReceiveInstructions')
    },

    buttonText () {
      return this.isReset ? this.$t('sendLink') : this.$t('setNewPassword')
    },

    isButtonDisabled () {
      if (
        Object.values(this.fields).some((field) => {
          return field.error
        })
      ) {
        return true
      }

      return Object.values(this.fields).some(field => !field.value)
    }
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    onSubmit () {
      this.reset()
    },

    onBackButton () {
      this.showModal({
        component: 'Auth',
        data: {
          mode: 'register'
        }
      })
    },

    async reset () {
      this.$nuxt.$loading.start()

      this.errorMessage = ''
      const action = this.isReset ? 'resetPassword' : 'setNewPassword'

      Object.keys(this.fields).forEach(key => this.onFieldBlur(key))

      if (this.isButtonDisabled) {
        return
      }

      try {
        let data = Object.keys(this.fields).reduce((result, key) => {
          result[key] = this.fields[key].value
          return result
        }, {})

        if (!this.isReset) { data = { ...data, code: this.verifyCode } }

        await this.$store.dispatch(`user/${action}`, data)

        if (this.isReset) {
          this.showModal({
            component: 'SuccessModal',
            data: {
              icon: 'email',
              title: this.$t('checkYourEmail'),
              text: this.$t('weSentEmailWithInstructions', {
                email: this.resetFields.email.value
              })
            }
          })
        } else {
          this.showModal({
            component: 'SuccessModal',
            data: {
              icon: 'checkmark-green',
              title: this.$t('passwordWasChanged'),
              text: this.$t('nowYouCanLogin'),
              buttons: [
                {
                  props: { isPrimary: true },
                  title: this.$t('auth'),
                  shouldClose: false,
                  action: () => {
                    this.showModal({
                      component: 'Auth',
                      data: { mode: 'register' }
                    })
                  }
                }
              ]
            }
          })
        }
      } catch (e) {
        this.errorMessage = this.$errors.getText(e)
      }

      this.$nuxt.$loading.finish()
    },

    onFieldInput (name, field) {
      this.errorMessage = ''
      this.$set(field, 'error', '')

      if (name === 'confirmPassword' || name === 'password') {
        this.$set(this.fields.password, 'error', '')
        this.$set(this.fields.confirmPassword, 'error', '')
      }
    },

    onFieldBlur (name, field) {
      if (this.isReset && name === 'email') {
        if (this.fields[name].value) {
          if (
            !EMAIL_REGEX.test(String(this.fields[name].value).toLowerCase())
          ) {
            this.fields[name].error = this.$t('errorEmail')
          }
        }
      }

      if (
        !this.isReset &&
        (name === 'confirmPassword' || name === 'password')
      ) {
        if (
          this.fields.password.value &&
          this.fields.confirmPassword.value &&
          this.fields.password.value !== this.fields.confirmPassword.value
        ) {
          this.fields.password.error = this.$t('passwordsDoNotMatchUp')
          this.fields.confirmPassword.error = this.$t('passwordsDoNotMatchUp')
        } else {
          this.fields.password.error = ''
          this.fields.confirmPassword.error = ''
        }
      }

      if (field && field.type === 'password') {
        this.warningMessage = ''
      }

      this.$forceUpdate()
    },

    onFieldKeypress (event, field) {
      this.warningMessage = ''
      if (field.type === 'password' && event.getModifierState('CapsLock')) {
        this.warningMessage = this.$t('capsLockWarning')
      }
    }
  }
}
</script>

<style lang="scss">
.modal.modal--resetpassword {
  max-width: 490px;
}

.reset-password {
  // &.--error {
  //   animation: shake 0.3s;
  // }

  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__btn-wraper {
    display: flex;
    align-items: center;
  }

  &__btn {
    @include heading4;

    padding: 0 16px 26px;
    position: relative;
    cursor: pointer;
    color: $blue-grey10;
    transition: 0.2s $easing;
  }

  &__text {
    margin-top: 32px;
    color: $blue-grey10;
    font-size: 16px;
  }

  &__input {
    margin-top: 24px;
    width: 100%;

    &-button {
      font-size: 14px;
      text-transform: uppercase;
      line-height: 100%;
      font-weight: $font-medium;
      color: $grey20;
      margin-bottom: 16px;
    }
  }

  &__send {
    width: 100%;
    margin-top: 32px;

    .button {
      width: 100%;
    }
  }

  &__back-button {
    position: absolute;
    top: 24px;
    left: 24px;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    background: $blue-grey60;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
  }

  &__back-button:hover {
    background: $blue-grey40;
  }

  .info-message {
    margin-top: 32px;
  }
}
</style>
