class PendingRewardsService {
  constructor () {
    if (PendingRewardsService.instance) {
      return PendingRewardsService.instance
    }

    PendingRewardsService.instance = this

    return this
  }

  getCount (engine) {
    return engine.get('/pending-rewards/count')
  }

  getCanBeWithdrawal (engine) {
    return engine.get('/pending-rewards/sum')
  }

  getMyVVVStat (engine) {
    return engine.get('/pending-rewards/myvvv-stat')
  }

  get (engine) {
    return engine.get('/pending-rewards/')
  }
}

export default new PendingRewardsService()
