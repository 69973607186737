import axios from 'axios'
import qs from 'qs'

// из-за CORS на files.gaming-goods.com запрашиваем через nuxt-server

export default function (req, res) {
  const params = qs.parse(req.url.split('?')[1]) || {}
  const { type, name, lang } = params

  if (!lang || !type || !name) {
    res.statusCode = 400
    return res.end('Not enough parameters')
  }

  const path = process.env.PROJECT_ENV === 'production' ? 'prod' : 'stage'

  axios
    .get(
      `https://files.gaming-goods.com/${path}/translations/${lang}/${name}.${type}`
    )
    .then((data) => {
      if (type === 'json') {
        res.statusCode = 200
        res.setHeader('Content-Type', 'application/json; charset=utf-8')
        res.end(JSON.stringify(data.data))
      } else {
        res.statusCode = 200
        res.setHeader('Content-Type', 'text/html; charset=utf-8')
        res.end(data.data)
      }
    })
    .catch((e) => {
      res.statusCode = 500
      const error =
        (e && e.response && e.response.data && e.response.data.message) ||
        '[nuxt] Ошибка при получении переводов'
      res.setHeader('Content-Type', 'text/html; charset=utf-8')
      res.end(error)
      console.error(`${new Date().toUTCString()} :: files error ::`, e)
    })
}
