<template lang="pug">
.main-banner
  transition(name='fade', duration='200')
    .main-banner__slide(
      v-for='(slide, index) in currentBanners',
      v-if='currentSlideIndex === index',
      :key='index',
      :style='getSlideStyles(slide)'
    )
      .main-banner__slide-body
        .main-banner__slide-content
          span.main-banner__slide-title
            | {{ getSlideField(slide, "title") }}
          .main-banner__slide-text(v-html="getSlideField(slide, 'subtitle') || ' '")
          .main-banner__slide-btn
            Button(
              v-if='slide.btn1_text && slide.btn1_url',
              is-primary,
              @click='onButtonClick(slide.btn1_url)'
            )
              | {{ getSlideField(slide, "btn1_text") }}

            Button(
              v-if='slide.btn2_text && slide.btn2_url',
              is-bordered,
              is-white,
              @click='onButtonClick(slide.btn2_url)'
            )
              | {{ getSlideField(slide, "btn2_text") }}

  .main-banner__items(v-if='currentBanners.length > 1')
    button(v-for='(slide, index) in currentBanners' @click='setSlide(index)')
      .main-banner__item(
        :class='{ "--active": currentSlideIndex === index }',
      )
        .main-banner__item-inner(
          v-if='currentSlideIndex === index',
          :style='{ width: `${barWidth}%` }'
        )
</template>

<script>
import { mapGetters } from 'vuex'

const SLIDE_DURATION = 7000
const INTERVAL = 200
let intervalId = null

export default {
  name: 'MainBanner',

  props: {
    banners: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      currentSlideIndex: 0,
      slideTime: 0
    }
  },

  computed: {
    ...mapGetters('user', ['isAuthorized']),
    ...mapGetters('global', ['getPlatformRemoteFeatures']),

    barWidth () {
      return (this.slideTime / SLIDE_DURATION) * 100
    },

    currentBanners () {
      return this.banners.filter(banner => !banner.extra_data?.isHidden)
    }
  },

  mounted () {
    if (this.currentBanners.length > 1) {
      this.startInterval()
    }
  },

  beforeDestroy () {
    clearInterval(intervalId)
  },

  methods: {
    getSlideField (slide, field) {
      const locale = this.$i18n.locale.split('-')[0]

      if (locale !== 'ru') {
        return (
          slide.extra_data?.[locale]?.[field] ||
          slide.extra_data?.en?.[field] ||
          slide[field]
        )
      }

      return slide[field]
    },

    onButtonClick (path) {
      if (path?.startsWith('http')) {
        window.open(path, '_blank')
      } else if (path.includes('#registration')) {
        this.$store.dispatch('modal/showModal', {
          component: 'Auth',
          data: {
            mode: 'register'
          }
        })
      } else if (path === '#back') {
        window.history?.back()
      } else {
        this.$router.push(this.localeLocation(path))
      }
    },

    getSlideStyles (slide) {
      const image = this.getSlideImage(slide)

      return image === 'gradient'
        ? {
          backgroundImage: 'linear-gradient(120deg, #05004b 0%, #000305 100%)'
        }
        : { backgroundImage: `url(${image})` }
    },

    getSlideImage (slide) {
      if (!slide?.background_image_url) {
        return ''
      }
      return slide.background_image_url
    },

    startInterval () {
      intervalId = setInterval(() => {
        this.slideTime += INTERVAL

        if (this.slideTime >= SLIDE_DURATION) {
          let slideIndex = this.currentSlideIndex + 1

          if (slideIndex >= this.currentBanners.length) {
            slideIndex = 0
          }

          this.slideTime = 0
          this.currentSlideIndex = slideIndex
        }
      }, INTERVAL)
    },

    setSlide (index) {
      clearInterval(intervalId)
      this.slideTime = 0
      this.currentSlideIndex = index
      this.startInterval()
    }
  }
}
</script>

<style lang="scss">
.main-banner {
  width: auto;
  border-radius: 0;
  height: 392px;
  position: relative;
  overflow: hidden;
  margin: 0;

  @media (min-width: 1500px) {
    width: calc(1435px - 64px);
    height: 340px;

    &__slide.--cursor-pointer {
      background-image: url(https://cdn.gaming-goods.com/integration/sovcombank/main-banner-desktop-wide3.png) !important;
    }
  }

  @media (min-width: $breakpointTablet) {
    border-radius: 20px;
    height: 396px;
  }

  @media (min-width: $breakpointMobile) {
    margin: 47px 24px 56px;
  }

  &__items {
    position: absolute;
    bottom: 32px;
    left: 32px;
    height: 4px;
    display: flex;
    z-index: 2;

    button {
      padding: 10px 0;
      background-color: transparent;
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    @media (max-width: $breakpointTablet) {
      bottom: 24px;
      left: 24px;
    }

    @media (max-width: $breakpointMobile) {
      bottom: 24px;
      left: 16px;
    }
  }

  &__item {
    height: 4px;
    width: 32px;
    border-radius: 2px;
    background: $blue-grey20;
    margin-right: 12px;
    cursor: pointer;
    overflow: hidden;
    transition: background 0.2s $easing;

    &-inner {
      background: #f1f0f0;
      pointer-events: none;
      height: 4px;
      transition: width 0.2s linear;
    }

    @media (min-width: $breakpointTablet) {
      width: 64px;
    }
  }

  &__slide {
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
    background-position: center center;
    top: 0;
    left: 0;

    &.--cursor-pointer {
      cursor: pointer;
    }

    &::before {
      content: "";
      background:
        linear-gradient(
          47deg,
          rgb(11 20 32 / 70%) 0%,
          rgb(11 20 32 / 0%) 73.96%
        );
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      @media (max-width: $breakpointMobile) {
        background:
          linear-gradient(
            0180deg,
            rgb(11 20 32 / 50%) 0%,
            rgb(11 20 32 / 80%) 50%
          );
      }
    }

    &-body {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      padding: 0 16px;
      box-sizing: border-box;
      z-index: 2;

      @media (min-width: $breakpointMobile) {
        padding: 0 24px;
      }

      @media (min-width: $breakpointTablet) {
        padding: 0 32px;
      }
    }

    &-content {
      max-width: 940px;
      width: 100%;
      height: 100%;
      padding: 20px 0 44px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-title {
      @include heading1;

      color: $white;
      z-index: 10;
      position: relative;
      text-shadow: 1px 1px #00000087;
      width: 900px;
      font-size: 30px;
      line-height: 38px;

      @media (max-width: $breakpointMobile) {
        font-size: 34px;
        line-height: 34px;
        width: auto;
      }
    }

    &-text {
      @include slider-text;

      color: $white;
      z-index: 10;
      position: relative;
      text-shadow: 1px 1px #00000087;
      margin-top: 16px;
      max-width: 555px;
      min-height: 58px;
    }

    &-btn {
      margin-top: 32px;
      display: flex;
      flex-wrap: wrap;

      button {
        &:first-child {
          margin-right: 16px;
          margin-bottom: 16px;
        }

        &.button--bordered.button--white:hover .button__text {
          font-weight: 500;
        }
      }
    }
  }
}
</style>
