<template lang="pug">
  .pubgm-tdm
    template(v-if="game === 'pubg-mobile'")
      img.pubgm-tdm__img(:src="require(`~/assets/img/pubgm-tdm.png`)" alt="background")

      .pubgm-tdm__container
        h1.pubgm-tdm__title {{ $t('pubgmTdmTitle') }}
        p.pubgm-tdm__text {{ $t('pubgmTdmText1') }}
        p.pubgm-tdm__text {{ $t('pubgmTdmText2') }}
        p.pubgm-tdm__text {{ $t('pubgmTdmText3') }}
        Button.pubgm-tdm__button#pubgm-tdm__button--modal(is-primary @click="goToPUBGMobileTDMPage") {{ $t('selectTournament') }}

    template(v-else-if="game === 'pubg-desktop'")
      img.pubgm-tdm__img(:src="require(`~/assets/img/pubgm-tdm.png`)" alt="background")

      .pubgm-tdm__container
        h1.pubgm-tdm__title {{ $t('pubgTdmTitle') }}
        p.pubgm-tdm__text {{ $t('pubgTdmText1') }}
        p.pubgm-tdm__text {{ $t('pubgTdmText2') }}
        p.pubgm-tdm__text {{ $t('pubgTdmText3') }}
        Button.pubgm-tdm__button#pubgm-tdm__button--modal(is-primary @click="goToPUBGTDMPage") {{ $t('selectTournament') }}

</template>

<script>
export default {
  name: 'ModalPUBGMobileTDM',

  props: {
    game: {
      type: String,
      default: 'pubg-mobile'
    }
  },

  methods: {
    goToPUBGMobileTDMPage () {
      this.$router.push(this.localeLocation('/pubg-mobile/duels'))
      this.$store.commit('modal/toggle')
    },

    goToPUBGTDMPage () {
      this.$router.push(this.localeLocation('/pubg-desktop/duels'))
      this.$store.commit('modal/toggle')
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalpubgmobiletdm {
  min-width: 360px;
  max-width: 508px !important;
  padding: 0 !important;
}

.pubgm-tdm {
  &__img {
    width: 100%;
    max-height: 292px;
    border-radius: 16px;
  }

  &__container {
    background-color: $blue-grey70;
    margin-top: -16px;
    padding: 0 16px;
    color: $white;
  }

  &__title {
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
  }

  &__link {
    cursor: pointer;
  }

  &__button {
    width: 100% !important;
    margin-bottom: 32px;
  }
}
</style>
