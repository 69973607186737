<template lang="pug">
.rating-changed
  .rating-changed__image-block
    img.rating-changed__image(:src="require('~/assets/img/icons/chart-down.svg')" alt="chart down")

  p.rating-changed__title {{ $t("yourRatingHasChanged") }}

  p.rating-changed__text {{ $t("yourRatingHasChangedText") }}

  .rating-changed__info
    img.rating-changed__info-icon(:src="require('~/assets/img/icons/coins-in-round.svg')" alt="coins")
    p.rating-changed__info-text {{ $t("getAConcolationPrize") }}

  Button.rating-changed__button(is-primary @click="getCoins") {{ $t("dailyRewardButton") }}
</template>

<script>
import TournamentsService from '@/services/TournamentsService'

export default {
  props: {
    tournamentId: {
      type: Number,
      required: true
    },

    game: {
      type: String,
      required: true
    }
  },

  methods: {
    closeModal () {
      this.$store.commit('modal/toggle')
    },

    async getCoins () {
      try {
        await TournamentsService.payForRatingChanged(this.$axios, this.game, this.tournamentId)
      } catch (e) {
        console.log(e)
      }
      this.$cookiz.set('lastCompletedTournament', this.tournamentId)
      this.closeModal()
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalratingchanged {
  padding-bottom: 0;
  max-width: 528px;
}
</style>

<style lang="scss" scoped>
.rating-changed {
  padding: 24px;

  &__image {
    width: 64px;
    height: 64px;
    object-fit: contain;

    &-block {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__title {
    margin: 16px;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    color: $white;
    text-align: center;
  }

  &__text {
    margin: 0 0 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: $blue-grey05;
    text-align: center;
  }

  &__info {
    display: grid;
    grid-template-columns: 32px 1fr;
    grid-gap: 10px;
    padding: 16px;
    margin: 0 0 16px;
    border-radius: 8px;
    background: $blue-grey60;

    &-icon {
      width: 32px;
      height: 32px;
    }

    &-text {
      color: $white;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  &__button {
    width: 100%;
    max-width: 100%;
  }
}
</style>
