<template lang="pug">
  .stream-buttons
    Button.stream-buttons__telegram(v-if="telegramLink" is-secondary icon="telegram" @click="onTelegramClick") {{ $t('streamButton4') }}
    Button.stream-buttons__youtube(v-if="youtubeLink" is-secondary icon="youtube" @click="onYoutubeClick") {{ $t('streamButton') }}
    Button.stream-buttons__twitch(v-if="twitchLink" is-secondary icon="twitch" @click="onTwitchClick") {{ $t('streamButton2') }}
    Button.stream-buttons__tiktok(v-if="tiktokLink" is-secondary icon="tiktok" @click="onTiktokClick") {{ $t('streamButton3') }}
    Button.stream-buttons__discord(v-if="discordLink" is-secondary icon="discord" @click="onDiscordClick") {{ $t('discordDiscuss') }}
    Button.stream-buttons__discord(v-if="discordLink2" is-secondary icon="discord" @click="onDiscordClick") {{ $t('discordDiscuss') }}

    Button.stream-buttons__telegram(v-if="showTelegram" is-secondary icon="telegram" @click="onTelegramButtonClick") {{ $t('streamButton4') }}
    Button.stream-buttons__discord(v-if="showDiscord" is-secondary icon="discord" @click="onDiscordClick") {{ $t('discordDiscuss') }}

</template>

<script>
import TournamentMixin from '~/mixins/Tournament'

export default {
  name: 'SocialButtons',

  mixins: [TournamentMixin],

  props: {
    tournament: {
      type: Object,
      required: true
    },

    gameName: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      telegramChannels: {
        lichess: 'vvv_lichess',
        'free-fire': '+pMpQ3ykUOAdiNDNk',
        'brawl-stars': '+1cNz8sRhnv4zMjFk',
        'clash-royale': '+W6D4dY6YcNY2MGQ0',
        'pubg-desktop': '+05C23BQpCNFmYzk0',
        'pubg-mobile': '+tZ9bHaQTPm85Njhk'
      }
    }
  },

  computed: {
    isSponsored () {
      return this.tournament.is_sponsored
    },

    showDiscord () {
      if (this.isSponsored) {
        return false
      }

      return !this.discordLink
    },

    showTelegram () {
      if (this.isSponsored) {
        return false
      }

      const gameList = Object.keys(this.telegramChannels)
      const isGameInList = gameList.includes(this.gameName)

      return !this.telegramLink && isGameInList
    },

    discordLink2 () {
      return this.tournament?.stream_links?.discord2
    }
  },

  methods: {
    onDiscordClick (isSecond) {
      if (isSecond) {
        return window.open('https://discord.gg/XvagBkhPpS', '_blank')
      }

      if (this.discordLink) {
        return window.open('https://discord.gg/XvagBkhPpS', '_blank')
      }

      window.open('https://discord.gg/XvagBkhPpS', '_blank')
    },

    onTelegramButtonClick () {
      const channelLink = this.telegramChannels[this.gameName]

      window.open(`https://t.me/${channelLink}`, '_blank')
    },

    onYoutubeClick () {
      window.open(this.youtubeLink, '_blank')
    },

    onTwitchClick () {
      window.open(this.twitchLink, '_blank')
    },

    onTiktokClick () {
      window.open(this.tiktokLink, '_blank')
    },

    onTelegramClick () {
      window.open(this.telegramLink, '_blank')
    }
  }
}
</script>

<style lang="scss">
.stream-buttons {
  margin: 16px 0;

  button.button {
    width: 100%;

    .button__text {
      color: $white;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__youtube.button.button--secondary {
    background: $red60;

    &:hover {
      background: $red50;
    }

    &:active {
      background: $red40;
    }

    .button__icon svg path {
      fill: #fff;
    }
  }

  &__twitch.button.button--secondary {
    background: #9147ff;

    &:hover,
    &:active {
      background: #772ce8;
    }

    .button__icon svg path {
      fill: #fff;
    }
  }

  &__tiktok.button.button--secondary {
    background: $white;

    .button__text {
      color: #161823;
    }

    &:hover,
    &:active {
      background: #e2e2e2;
    }
  }

  &__telegram.button.button--secondary {
    background: #3390ec;

    &:hover,
    &:active {
      background: #3390ecd6;
    }

    svg path {
      fill: $white;
    }
  }

  &__discord.button.button--secondary {
    background: $indigo60 !important;

    &:hover {
      background: $indigo50 !important;
    }

    &:active {
      background: $indigo40 !important;
    }

    svg path {
      fill: $white;
    }
  }
}
</style>
