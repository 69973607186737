// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_WQTML{width:100%;max-width:600px;padding-top:10px}.title_OdlDP{color:#fff;font-size:32px;line-height:120%;font-weight:700;margin-bottom:32px}.label_UeeIa{margin:24px 0 12px;padding-left:8px;border-left:8px solid #90a4ae;color:#90a4ae;font-size:12px;font-weight:500;line-height:12px;text-transform:uppercase}.withImg_nv5ve{display:grid;grid-template-columns:1fr 48px;grid-gap:8px;align-items:center}.img_xKHS6{width:48px;height:48px;object-fit:cover;border-radius:8px}.button_wNgxp{width:280px;margin:24px 0 0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "modal_WQTML",
	"title": "title_OdlDP",
	"label": "label_UeeIa",
	"withImg": "withImg_nv5ve",
	"img": "img_xKHS6",
	"button": "button_wNgxp"
};
module.exports = ___CSS_LOADER_EXPORT___;
