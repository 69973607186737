export default ({ $axios, store }) => {
  $axios.onRequest((config) => {
    const url = config.url

    // for handling url error
    if (url.includes('gtm')) {
      console.log('Request:', url)
    }

    if (!config?.url?.startsWith('http') && store.state.user?.token) {
      config.headers.Authorization = store.state.user.token
    }

    return config
  })

  $axios.onError(() => {
    // const errorCode = parseInt(error?.response?.status, 10)

    // if (errorCode === 401) {
    //   store.commit('user/setToken', null)
    // }
  })
}
