<template lang="pug">
iframe(:src="offerwallUrl" frameborder="0" width="100%" height="100%")
</template>

<script>
import { mapState } from 'vuex'
import OfferwallService from '@/services/OfferwallService'

export default {
  props: {
    id: {
      type: Number,
      required: true
    }
  },

  computed: {
    ...mapState('user', ['user']),

    offerwallUrl () {
      return OfferwallService.getOfferwallGroup(this.$axios, this.user.id, this.id)?.offerWallLink()
    }
  }
}
</script>

<style lang="scss">
.modal.modal--offerwallmodal {
  padding: 60px 0 0 !important;
  height: 90vh;
  width: 100%;
  max-width: 756px;
}
</style>
