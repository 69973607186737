<template lang="pug">
  .subscription-advert
    img.subscription-advert__img(:src="require(`~/assets/img/subscriptions-advert.jpg`)" alt="Balance icon")
    .subscription-advert__container
      h1.subscription-advert__title {{ $t('subscriptionAdvertModalTitle') }}
      p.subscription-advert__text {{ $t('subscriptionAdvertModalSubtitle') }}
      Button.subscription-advert__button#subscription-advert__button--modal(is-primary @click="goToSubscription") {{ $t('buy') }}
</template>

<script>
export default {
  methods: {
    goToSubscription () {
      this.$router.push(this.localeLocation('/store/subscriptions?purchase=6'))
      this.$store.commit('modal/toggle')
    }
  }
}
</script>

<style lang="scss">
.modal.modal--modalsubscriptionadvert {
  min-width: 360px;
  max-width: 508px !important;
  padding: 0 !important;
}

.subscription-advert {
  &__img {
    width: 100%;
    max-height: 292px;
    border-radius: 16px;
  }

  &__container {
    background-color: $blue-grey70;
    margin-top: -36px;
    padding: 0 16px;
    color: $white;
  }

  &__title {
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
  }

  &__link {
    cursor: pointer;
  }

  &__button {
    width: 100% !important;
    margin-bottom: 32px;
  }
}
</style>
