<template lang="pug">
  .header-menu
    .header-menu__section(v-for="(section, index) in items" :key="index")
      .header-menu__item(
        v-for="(item, i) in section"
        v-if="!item.isHidden"
        :key="i"
        :class="section.action"
      )
        div.header-menu__item-wrapper
          a.header-menu__link(@click.prevent="onAction(item)")
            span.header-menu__link-dot(v-if="(item.route === '/profile/orders' || item.route === '/profile/sales') && newMessagesNotEmpty")
            .header-menu__ico-box
              .header-menu__ico-wrap
                img.header-menu__ico(:src="require(`~/assets/img/icons/${item.ico}.svg`)" :alt="item.text" alt="icon")

            span.header-menu__text {{ $t(item.text) }}
            template(v-if="item.action === 'lang'")
              span : {{ lang }}
              img.header-menu__item-right(:src="require(`~/assets/img/icons/angle-right.svg`)" alt="angle left")

            template(v-if="item.action === 'currency'")
              span : {{ userCurrency }}
              img.header-menu__item-right(:src="require(`~/assets/img/icons/angle-right.svg`)" alt="angle right")
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import sections from './data/sections.json'
import UsersService from '~/services/UsersService'
import { NATIVE_LANG_BY_LOCALE } from '~/utils/lang'

export default {
  name: 'HeaderMenu',

  computed: {
    ...mapState('user', ['user', 'token', 'showOfferwallPath']),
    ...mapState('chat', ['newMessages']),
    ...mapGetters('user', ['isAdmin', 'isSuperAdmin', 'currency', 'userCurrency']),

    lang () {
      return NATIVE_LANG_BY_LOCALE[this.$i18n.locale]
    },

    newMessagesNotEmpty () {
      return this.newMessages?.length
    },

    isOfferwallPath () {
      return (
        this.showOfferwallPath ||
        !(
          this.$cookiz.get('offerwall-tickets-dot-hide') &&
          this.$cookiz.get('offerwall-euro-dot-hide')
        )
      )
    },

    adminLink () {
      return `${this.$config.VUE_APP_ADMIN_LINK}?token=${this.token}`
    },

    showAdminLogin () {
      return this.isSuperAdmin && UsersService.isUserEmployee(this.user)
    },

    oldToken () {
      return this.$cookiz.get('old_token')
    },

    items () {
      const items = [...sections]
      const sectionList = {}

      if (this.isAdmin) {
        items.push({
          action: 'admin',
          ico: 'gear',
          text: this.$t('menuAdmin'),
          section: 1
        })
      }

      if (this.isSuperAdmin && UsersService.isUserEmployee(this.user)) {
        items.push({
          action: 'admin-login',
          ico: 'exit',
          text: this.$t('loginAsAnotherUser'),
          section: 1
        })
      }

      if (this.oldToken) {
        items.push({
          action: 'admin-return',
          ico: 'exit',
          text: this.$t('returnToOwnAccount'),
          section: 6
        })
      } else {
        items.push({
          route: '/',
          ico: 'exit',
          text: 'menuSignOut',
          action: 'logout',
          section: 6
        })
      }

      if (this.user.is_seller) {
        items.push({
          route: '/profile/sales',
          ico: 'time-schedule',
          text: 'mySales',
          section: 4
        })
        items.push({
          route: '/profile/goods',
          ico: 'shop',
          text: 'myGoods',
          section: 4
        })
      }

      items.forEach((item) => {
        if (!sectionList[item.section]) {
          sectionList[item.section] = []
        }

        sectionList[item.section].push(item)
      })

      return Object.values(sectionList).map((item) => {
        if (Array.isArray(item)) {
          return item.map((item) => {
            if (item.route === '/profile/account') {
              return {
                ...item,
                route: '/profile'
              }
            }

            return item
          })
        }

        return item
      })
    }
  },

  mounted () {
    document.addEventListener('click', this.onClickOutside)

    this.$store.commit('notifications/clearNewNotifications')
  },

  beforeDestroy () {
    document.removeEventListener('click', this.onClickOutside)
  },

  methods: {
    onAction ({ action, route }) {
      if (action === 'admin-return') {
        this.onReturn()
      } else if (action === 'admin-login') {
        this.$store.dispatch('modal/showModal', { component: 'ModalUserLogin' })
      } else if (action === 'admin') {
        window.open(this.adminLink, '_blank')
      } else if (action === 'logout') {
        this.$store.dispatch('user/logout', {
          $router: this.$router,
          $route: this.$route
        })
      } else if (action === 'profile') {
        this.$router.push(this.localeLocation(`/user/${this.user.alias}`))
      } else if (action === 'lang') {
        this.$emit('lang')
      } else if (action === 'currency') {
        this.$emit('currency')
      } else if (route) {
        this.$router.push(this.localeLocation(route))
      }

      this.$emit('close')
    },

    onClickOutside ({ target }) {
      if (!this.$el.contains(target)) {
        this.$emit('close')
      }
    },

    onReturn () {
      this.$nuxt.$loading.start()

      this.$cookiz.set('auth_token', this.oldToken)
      this.$cookiz.set('old_token', null)
      window.location.reload()
    }
  }
}
</script>

<style lang="scss">
.header-menu {
  @include scroll-none;

  box-sizing: border-box;
  position: absolute;
  top: 64px;
  right: 4px;
  margin-top: 10px;
  border: 1px solid $blue-grey40;
  border-radius: 8px;
  background: $blue-grey70;
  padding: 8px;
  width: 300px;
  color: #fff;
  overflow-y: auto;
  max-height: calc(100vh - 148px);
  transition: top 0.2s $easing;

  .--mini & {
    top: 48px;
  }

  &__section:not(:last-child) {
    padding-bottom: 8px;
    border-bottom: 1px solid $blue-grey40;
    margin-bottom: 8px;
  }

  &__item {
    height: 48px;
    box-sizing: border-box;
    border-radius: 8px;
    transition: background-color 0.3s $easing;
    background: $blue-grey70;

    &:hover {
      background: $blue-grey60;
    }

    &-wrapper {
      padding: 0 8px;
    }

    &-right {
      margin-left: auto;

      path {
        fill: #fff;
      }
    }
  }

  &__link {
    @include text-display100;

    position: relative;
    display: flex;
    align-items: center;
    height: 48px;
    cursor: pointer;
    color: #fff;

    &-dot {
      position: absolute;
      top: 20px;
      right: 10px;
      height: 8px;
      width: 8px;
      background-color: $pink60;
      border-radius: 50%;
      display: inline-block;
    }
  }

  &__ico-box {
    height: 100%;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__ico-wrap {
    width: 20px;
    height: 20px;

    img {
      width: 20px;
      height: 20px;

      path {
        fill: #fff;
      }
    }
  }

  &__text {
    margin-left: 16px;
  }

  @media (max-width: $breakpointHeaderMobile) {
    top: 44px;
  }

  @media (max-width: $breakpointHeaderMobile) {
    max-height: calc(100vh - 112px);
  }
}
</style>
