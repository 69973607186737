<template lang="pug">
  .info-message(:class="classModifiers")
    .info-message__title(v-if="title") {{ title }}

    img(v-if="withIcon && type === 'info'" :src="require('~/assets/img/icons/info.svg')" alt="info")
    img(v-if="withIcon && ['error', 'warning'].includes(type)" :src="require('~/assets/img/icons/warning.svg')" alt="warning")
    img(v-if="withIcon && type === 'attention'" :src="require('~/assets/img/icons/attention.svg')" alt="attention")
    img(v-if="withIcon && type === 'success'" :src="require('~/assets/img/icons/success.svg')" alt="success")
    img(v-if="withIcon && type === 'deny'" :src="require('~/assets/img/icons/denied.svg')" alt="denied")
    img(v-if="withIcon && type === 'allow'" :src="require('~/assets/img/icons/allow.svg')" alt="allow")
    slot

</template>

<script>
export default {
  name: 'InfoMessage',

  props: {
    type: {
      type: String,
      default: 'info'
    },

    title: {
      type: String,
      default: ''
    },

    withIcon: {
      type: Boolean,
      default: true
    },

    isSmall: {
      type: Boolean,
      default: false
    },

    isWhite: {
      type: Boolean,
      default: false
    }
  },

  data ({ type }) {
    return {
      isPasswordVisible: false,
      classModifiers: {
        '--small': this.isSmall,
        '--info': type === 'info' || type === 'deny' || type === 'allow',
        '--error': type === 'error',
        '--warning': type === 'warning',
        '--success': type === 'success',
        '--attention': type === 'attention',
        '--white': this.isWhite
      }
    }
  },

  methods: {}
}
</script>

<style lang="scss">
.info-message {
  @include heading6;

  width: 100%;
  padding: 16px 16px 16px 56px;
  position: relative;
  border-radius: 8px;
  background: $blue-grey60;
  color: $blue-grey05;

  > img {
    position: absolute;
    left: 18px;
    top: 18px;
  }

  &.--white {
    background: $white;
    color: $blue-grey80;
  }

  &.--info {
    padding: 24px 24px 24px 81px;
    min-height: 74px;
  }

  &.--small {
    padding: 8px 8px 8px 32px;
    font-size: 14px;

    > img {
      max-width: 16px;
      max-height: 16px;
      left: 8px;
      top: 8px;
    }
  }

  &.--warning {
    background: $yellow60-20;
    color: $amber60;

    svg path,
    svg rect {
      fill: $yellow40;
    }
  }

  &.--error {
    background: $red60-20;
    color: $red50;
  }

  &.--attention {
    background: $black-transparent;
    color: $blue-grey10;
  }

  &.--success {
    background: $green60-20;
    color: $green50;
  }

  &__title {
    @include heading4;

    color: $white;
    margin-bottom: 16px;
  }
}
</style>
